import React, { Component } from 'react';
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import CONSTANT from '../Constant'; 
import Loader from '../Loader';
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  flag:false,
} 

class AppSubscription extends Component {
  constructor(props) {
    super(props);
	this.state = initialState;
  } 


  async componentDidMount() { 
    let {session_token,store_id,store_name} = this.props.match.params;

		//console.log(session_token + '-------' +store_id); return   
    let res = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:session_token});
    if(res.data.response_status === 1){
//console.log(res.data.response_data); return

        localStorage.setItem("token",res.data.response_data.session_token);
        localStorage.setItem("email",res.data.response_data.profile.email);
        localStorage.setItem("loggedInUserId",res.data.response_data.profile._id);
        localStorage.setItem("loggedInUser_thumb_profile_image",res.data.response_data.profile.profile_image);
        localStorage.setItem("loggedInUserName",res.data.response_data.profile.name);
        localStorage.setItem("store_id",store_id);
        localStorage.setItem("store_name",store_name);

        document.location.href='/subscription';
    }else{
        this.setState({flag:true});
       //this.props.history.push('/404')
    }  

   
  }

render() {
	return(
<>{this.state.flag ? (<>Not Found</>) : <></>}</>
);
    
  }
} 
export default AppSubscription;