import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 

  friendList: {},
  joobdetailsId:'',
  applicantList:[],
  jobProviderId:'',
  loggedInUserId:'',
  isOpen:false
} 

class JobsDetails extends Component {
  constructor(props) {
    super(props);
  const token = localStorage.getItem("token");        
  this.state = initialState;
  } 

		

  async componentDidMount() { 
  	let {id} = this.props.match.params;
	 //var sd = this.props.location.state.jstatus;
	//console.log(sd);

    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
	
    this.setState({loading: true}); 

   const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }  

				//var redirectValue="/jobs-details/"+e.job_id;
				var formData = {"session_token":localStorage.getItem("token"),"service_id":id};


  
   			    let response1 =   await axios.post(CONSTANT.STABASE_URL +'users/getjobIdDetails', formData, config);

   			    console.log(response1.data.response_status);
   			   // return false;
   			    //.then((res) =>

   			    if(response1.data.response_status==1){

   			    	localStorage.setItem("stat",response1.data.response_data.getjobIdDetails.status);

   			    }else{
this.props.history.push('/404');
   			    }
   			    

   			    	//});



   			    //    			    	console.log("responseaks");

   			    //    			  if(response1.data.response_data.getjobIdDetails != null){

   			    // 	//console.log(response.data.response_data.getjobIdDetails.status);
   			    // 	localStorage.setItem("stat",response1.data.response_data.getjobIdDetails.status);
   			    // }else{

   			    // 	this.props.history.push('/404');

   			    // }


    
  // console.log(this.props.match.params);
    
    var formData = {"session_token":localStorage.getItem("token"),"service_id":id,"status":localStorage.getItem("stat")}   
  
    let response = await axios.post(CONSTANT.STABASE_URL +'users/getjobDetails', formData, config);
   
  var loggedInUserId=localStorage.getItem("loggedInUserId");

  //console.log("response11111");
  //console.log(response);
  //console.log("response2222222");

  if(response.data.response_msg!='Already logged on other device'){

    if(response.data.response_data.getjobDetails != null){
    	//let imagecheck=response.data.response_data.getjobDetails.job_image.length>0 ? response.data.response_data.getjobDetails.job_image[0].name : '';
      this.setState({friendList: response.data.response_data.getjobDetails,loggedInUserId:loggedInUserId,joobdetailsId:response.data.response_data.getjobDetails._id,jobProviderId:response.data.response_data.getjobDetails.customer_id,applicantList: response.data.response_data.users,loading: false});
    } else {

    	 var formData = {"session_token":localStorage.getItem("token"),"service_id":id,"status":"5"}   
  
    let response = await axios.post(CONSTANT.STABASE_URL +'users/getjobDetails', formData, config);
   
  var loggedInUserId=localStorage.getItem("loggedInUserId");
this.setState({friendList: response.data.response_data.getjobDetails,loggedInUserId:loggedInUserId,joobdetailsId:response.data.response_data.getjobDetails._id,jobProviderId:response.data.response_data.getjobDetails.customer_id,applicantList: response.data.response_data.users,loading: false});

      this.setState({loading: false});
      this.props.history.push('/404');
    }

}
else{
	
		localStorage.removeItem("token");
		localStorage.removeItem("store_id");
		localStorage.removeItem("imgs");
		localStorage.removeItem("store_name");
		localStorage.removeItem("address");
		localStorage.removeItem("description");
		localStorage.removeItem("store_contact_number");		
		localStorage.removeItem("loggedInUserId");		
		localStorage.removeItem("email");		
		localStorage.removeItem("loggedInUser_thumb_profile_image");		
		localStorage.removeItem("loggedInUserName");		
		localStorage.removeItem("checkStoreStatus");		
		localStorage.removeItem("otp");		
		localStorage.removeItem("mobile");		
		localStorage.removeItem("longitude");		
		localStorage.removeItem("latitude");		
		localStorage.removeItem("storePhoneNo");		
		localStorage.removeItem("country_code");		
		this.setState({loggedIn:false, showing: true})
		document.location.href = '/login'
	

 //this.props.history.push('/login');

}



//   console.log("fffff");
//     console.log(loggedInUserId);
//     console.log(this.state.jobProviderId);
//   console.log("fffff11111");
    // var formData = {"session_token":localStorage.getItem("token"),"job_id":id}   
  
    // let response = await axios.post(CONSTANT.STABASE_URL +'users/getcancelAppliedjobList', formData, config);
    // console.log("fffff");
    // console.log(response.data.response_data.getjobDetails);

    // if(response.data.response_data.getjobDetails._id != null){
    //   this.setState({applicantList: response.data.response_data.getjobDetails,joobdetailsId:this.joobdetailsId,loading: false});
    // } else {
    //   this.setState({loading: false});
    //   this.props.history.push('/404');
    // }




  }

timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}



setStatus = async e =>{
	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		var statusr = e.target.value;

		if(status==="1"){
			status = "5";
		  } else {
			status = "1";
		  }

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"_id":this.state.joobdetailsId,"status":status}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/updateJobStatus', formData, config);
		if(response.data.response_status == 1){
			localStorage.setItem("stat", status)
			this.componentDidMount();
			}
		
	}		
}

	   showPopup(){
		    this.setState({
		      isOpen:true,
		    })
  }

 hidePopup(){
    this.setState({
      isOpen:false,
    })
  }


		applyagreeJob = async e=>{
		this.setState({isOpen:true,loading:false })

			return false;
		}

cancelAgree = async e=>{
		this.setState({isOpen:false,loading:false })

			return false;
		}



setPrivate = async () =>{
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"status":"0"}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/setProfilePrivate', formData, config);
		if(response.data.response_status == 1){
			//localStorage.setItem("follow", true)
		}
		this.componentDidMount();
	}		
}




applyJob = async e =>{



	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		var status = e.target.value;

		if(status==="1"){
			status = "0";
		  } else {
			status = "1";
		  }



		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"job_id":this.state.joobdetailsId,"job_provider_id":this.state.jobProviderId}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/jobRequestApi', formData, config);
		if(response.data.response_status == 1){

		this.setPrivate();
		this.setState({isOpen:false,loading:false })
			//localStorage.setItem("follow", true)
		}
		this.componentDidMount();
	}		
}

cancelJob = async e =>{
	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		var status = e.target.value;

		if(status==="1"){
			status = "0";
		  } else {
			status = "1";
		  }

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"job_id":this.state.joobdetailsId}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/getcancelAppliedjobList', formData, config);
		if(response.data.response_status == 1){
			//localStorage.setItem("follow", true)
		}
		this.componentDidMount();
	}		
}




completeJob = async e =>{
	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		//var status = e.target.value;

		// if(status==="1"){
		// 	status = "0";
		//   } else {
		// 	status = "1";
		//   }

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"job_id":this.state.joobdetailsId}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/completeRequestApi', formData, config);
		if(response.data.response_status == 1){
			localStorage.setItem("stat", 4)
			document.location.href = '/jobs-listing'
			//localStorage.setItem("follow", true)
			//localStorage.setItem("stat", 4)
			//this.componentDidMount();
		}
		//this.componentDidMount();
	}		
}

  
  

  applicantsList =  (cFriend) => {
    const res = cFriend.length > 0 && cFriend.map((item) => {
    	 var nwval=item.email;
    	// console.log(nwval[1]);
    	// item.email = item.email.replace(/.com/g,'');
    	  item.email = item.display_name;


       if(item.profile_image != '' && item.profile_image != undefined){
	var profileImgapplicant = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image;
	} 
	else {
		var profileImgapplicant = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';		
	}
    //var timeago=this.timeSince(new Date(item.updated_at));


      return( 
        <li key={Math.random()}>
        
		<a href="" onClick={() => this.props.history.push('/people/'+item.email)}> 
          <div className="product_name">
			

			

			<div class="media">
   			<img className="mr-3" src="#" alt="img" src={profileImgapplicant} width="80" height="80" style={{'border-radius': '50%'}} />
  <div class="media-body">
    <h5 class="mt-0" style={{color: '#000'}} >{item.name}</h5>
    <p style={{color: '#000', lineHeight : '23px'}} > Phone no : {item.mobile} <br /> Email : {nwval}  <br /> Profile : <a href="" onClick={() => this.props.history.push('/people/'+item.email)}> Profile</a></p>


  </div>
</div>


        </div>
        </a>
       
      
      </li>
      )                                   
    })  
    return <>{res}</>
  }

bindImages(data) {

	
      var returnData = 
        data.map((cat, index) => {
		// console.log("ansdfdshfsdhjfds");
		// console.log(cat.name);


			if(cat.name!='' && cat.name!=null){
          var imgs = <img className="img-fluid 8" src={CONSTANT.NSTABASE_URL+'images/store/'+cat.name} alt=""></img>;
     		 }else{
      	          var imgs = <img className="img-fluid 8" src={CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} alt=""></img>;


      }

          return (
           imgs
          );
        })
    return returnData;
  }



  
//let imglength=friendList.lenght > 0 ? friendList.job_image[0].name : '';

  
  



  render() {
    
    
   
    const { friendList} = this.state;
    const { applicantList} = this.state;
   const {joimage} =this.state;


   //  

//let imglength=friendList.lenght > 0 ? friendList.job_image[0].name : '';
// console.log("friendList111");
// console.log(friendList.job_image[0]);
// console.log("22222");


console.log("friendList11111");
console.log(friendList);

const kkk=friendList.job_image!=undefined ? friendList.job_image : '';



//let eeee=kkk!='' ? kkk : '';
//const listItems1 = eeee.map((d) => {d.name});
// console.log(friendList.job_image);
// console.log("friendList111222");
//const data=friendList.job_image!=undefined ? JSON.stringify(friendList.job_image):'';
//const data=friendList.job_image;
// const dd=JSON.stringify(friendList.job_image);

// let result=dd.map(job_image :)
// //const ff=JSON.parse(dd);
//console.log(data);
// //const yy= Object.values(friendList.job_image);
// let hhhhh=dd.length>0 ? dd.name : '';
// console.log(hhhhh);
// console.log("22222");


//     const listItems = dd.map((d) => <li>{d.name}</li>);

// console.log(listItems);

//const data =[{"name":"test1"}];
    // const listItems = data.map((d) => <img src="#" alt="img" src={d.name} width="134" height="134" />);
    // console.log(listItems);
    // return (
    //   <div>
    //   {listItems }
    //   </div>
    // );

	//console.log(friendList.job_image[0]);
	//const fruits = friendList.job_image

	// data.forEach(function (item) {
 //                       console.log(item)
 //                    });

// var links = [{"name":"1634122081564-1634122064445.jpg"}];

// ///var links = data;

// //console.log(links[0].name);

// {links.map((item) =>
// 	<img src={item.name} alt="img" /> 
//  )}


//  const listItems = links.map((link) =>
       

   
//    					 return (   
				

// 				 <img src={link.name} alt="img" /> 
// 						); 
// 				})





 //    if(friendList.job_image!= ''){
	// var profileImg = CONSTANT.NSTABASE_URL+'images/store/'+''
	// } 
	// else {
	// 	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
	// }
	//var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;
	var jobPdf=friendList.job_pdf;
	var jobPdfUrl=CONSTANT.NSTABASE_URL+'images/store/'+friendList.job_pdf

	var timeago=this.timeSince(new Date(friendList.appointment_date));
	var checked1 = "";
	      if(friendList.status == "5"){
	        checked1 = "checked";		
	      } else {
	        checked1 = "";
	      }



 //    const { applicantList } = this.state;

 //    if(applicantList.profile_image != '' && applicantList.profile_image != undefined){
	// var profileImgapplicant = CONSTANT.NSTABASE_URL+'images/profile/'+applicantList.profile_image[0];
	// } 
	// else {
	// 	var profileImgapplicant = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
	// }


//bindImages(data) {

	// console.log("ansdfdshfsdhjfds");
	// console.log(data);
 //      var returnData = 
 //        data.map((cat, index) => {


 //          var imgs = <img className="img-fluid" src={CONSTANT.NSTABASE_URL+'images/store/'+cat.name} alt=""></img>;

 //          return (
 //           imgs
 //          );
 //        })
 //    return returnData;
 //  //}



  
    return (
      <>
       <Metatitle title="Jobs Details | aBlueC" metaDescription="Jobs Details" ogTitle="Jobs Details" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
        <div className="container">  
       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    

<div className="card mt-4" ><div className="card-header"> Job details </div>

<div className="card-body"> 

	<div className="job-details-sec">
				<div className="profile_details profile_user">
				
						<div className="profile_area">						  
						  <div className="profile_content">
						  <div className="row"> <div className="col-md-8">  
						    <div className="profile-pic"> 
							{kkk.length>0? this.bindImages(friendList.job_image):<></>} 
						   <span className='details-title'>  {friendList.job_title}</span>							
							 <span> {timeago} </span> 
                                            
							
							</div> </div>
							  
							<div className="col-md-4">
							

							{((this.state.loggedInUserId==this.state.jobProviderId) )?
							<>
								 

								
		
		  <div className="wrap available_sale clearfix">
			<small> Status</small>
			<input type="checkbox" id="s4" value={friendList.status} onChange={this.setStatus} checked={checked1}/>
			<label className="slider-v2" htmlFor="s4" />  
			<span class="edit_profile"><a href="#/"> <img onClick={() => this.props.history.push('/edit-jobs/'+friendList._id)} src="/assets/images/edit.png" />  </a> </span>
		</div>
	 
							
							</>
							:
							<>
<div className="col-md-4"> <div className="wrap available_sale clearfix">  <br/></div></div>
							</>
							}


				
				</div>
							
							 </div>							
						


						<div className="user-experience ">
                                   
                                    <div className="user_exp_list">
                                        <div className="row">
										<div className="col-md-12">										
				                        <div className="ex_inner_list">
										<ul>
										<li><span className='label'>Job Description:</span>	<span>{friendList.job_desc} </span></li>
										{friendList.complete_address !='' ? <li><span className='label'>Job Location:</span>	<span>{friendList.complete_address}</span>	</li> : <li><strong>Job Location:</strong>	<span>{friendList.location_type}</span>	</li> }

										{jobPdf!='' && jobPdf!=null ? <li><span className='label'>Job Attachment:</span>	<span><a target="_blank" href={jobPdfUrl}>{jobPdf}</a></span>	</li> : '' }

									 	</ul>

									 	{(applicantList.length > 0 && ( this.state.loggedInUserId==this.state.jobProviderId) )?
									<>
				 						

										
										 
									<ul>
										<li><strong className="address_content">{applicantList.length} Applicant</strong></li>		
										{applicantList.length > 0 ? this.applicantsList(applicantList):<></>} 
									</ul>	
										 


									
									
									</>
									:
									<>

									</>
									}		

</div>

{((this.state.loggedInUserId==this.state.jobProviderId) )?
									<>
				 						

									
				                       <div><span className="btn btn-primary" onClick={this.completeJob} >Pause Job Listing  </span></div>


										
									
									</>
									:
									<>

									</>
									}	
										 


										</div>	




									{((this.state.loggedInUserId!=this.state.jobProviderId) )?
									<>
				 						

										<div className="col-md-12">
				                      {friendList.already_applied == 1 ? <div><span className="btn btn-primary" onClick={this.cancelJob} >Cancel Application</span></div> : <div><span className="btn btn-primary" onClick={this.applyagreeJob} >Apply</span></div> } 


										</div>
									
									</>
									:
									<>

									</>
									}	

									

									

                                        </div>
                                     
                                    </div>
                                </div>	

							
                                          
                                   
									

									


							
							
								

								  
							

								 
	

</div></div>
						</div>						 
						  </div>				
						  </div>
						  </div>		  

   









    </div>
        </div> 

        <Modal id="modal_store" show={this.state.isOpen} onHide={this.hidePopup.bind(this)} size="l"> 
<div class="card-header"><h5 class="modal-title">Apply</h5>
</div> 
    <Modal.Body>     
      
    <p>Please note that your profile details including your contact information, work history, skills, resume and cover letter will be shared with the company or person who posted the job/work. <br/> <br/>
    <span style={{"color":"RED"}}>*Please note only public accounts can apply for a job.If you apply for this job your account will become public.You can always make your account private again once you stop looking for work.
</span></p>         
     </Modal.Body>
     <div className="modal-footer">
     <button type="button" className="save_bt" onClick={this.cancelAgree}>Cancel</button>
      <button type="button" className="save_bt" onClick={this.applyJob}>Agree</button>

      </div>
    </Modal>


    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default JobsDetails;