import React, { Component } from 'react';
import loaderImg from './loder.png';
class Loader extends Component {
    render() {
        return (       
            <>
				<div class="loader"> 
					<img src={loaderImg} alt="" />
				</div>
			</>
        );
    }
}
export default Loader;