import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import Metatitle from "../../components/metaTitle";
import React, { Component } from 'react'; 
import{ Redirect } from 'react-router-dom';
import CONSTANT from '../../Constant';
import axios from 'axios';
import qs from 'qs';
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {registerUser,clearErrors} from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import Loader from '../../Loader';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


class Register extends Component {
       
    constructor(props){
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }      
        this.state={
            loading:false,
            email:'',
            password:'',
            mobile:'',
            name: '',
            display_name: '',
            tnc:0,
            loggedIn,
            registered:'',
            mobileError:'',
            nameError:'',
            emailError:'',
            passwordError: '',
            displayNameError: '',
            tncError:'',
            cls:'',
            latitude: 28.541397,
            longitude: 77.397034,
            display_name:'',
            profile: {},
        }

        this.onChange = this.onChange.bind(this);
        this.tncCheck = this.tncCheck.bind(this);
        this.submitOTPForm = this.submitOTPForm.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
       }
       
       handleUsernameChange = e => {
        this.setState({[e.target.id]: e.target.value});
    }

    async componentDidUpdate() {
        // document.getElementById("mobile").className = "form-control"
        $('.PhoneInputCountryIcon').css({'width':'54px','height':'36px','border-radius':'.25rem'});
    }
    
    async componentDidMount() {
        
    }
    
    storePhoneNoChange = e => {
        if(e.value){                            
            const phoneNumber = parsePhoneNumber(e.value)       
            if (phoneNumber) {
                localStorage.setItem("mobile", phoneNumber.nationalNumber)
                localStorage.setItem("country_code", phoneNumber.countryCallingCode)
            }
        }           
    }
    
    onChange(e){
        
        if(e.target.name==="display_name"){
            // if(e.target.value===""){
                // this.setState({
                    // [e.target.name] : ''
                // })  
            // } else {
                this.setState({
                    [e.target.name] : e.target.value
                })
            // }
        } else {
            this.setState({
                [e.target.name] : e.target.value
            })
        }
        

    }

    tncCheck(e) {
        
        if (e.target.checked) {
            console.log(e.target.checked)
            this.setState({
                tnc:1
            })
        } else {
            this.setState({
                tnc: 0
            })
        }
    }
    
    async submitOTPForm(e){
        e.preventDefault();
        const isValid = this.validate();
        isValid.then(async response => {
            if(response){
                
                this.setState({passwordError: "",displayNameError:"", "tncError": "", nameError: ""});
                
                const {email} = this.state;
            
                const configVerify = {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
                    }
                }
                let getProfile = await axios.post(CONSTANT.STABASE_URL+'users/checkaccount', {mobile:localStorage.getItem("mobile"), email:email}, configVerify);
                
                if(getProfile.data.response_status == 1){
                    
                    if(getProfile.data.response_data.email == email && getProfile.data.response_data.mobile == localStorage.getItem("mobile")){
                        let emailError = "Email ID Already Exists.";
                        let mobileError = "Phone Already Exists.";
                        this.setState({emailError,mobileError});
                    } else if(getProfile.data.response_data.email == email){                    
                        let emailError = "Email ID Already Exists.";
                        this.setState({emailError})
                        this.setState({"mobileError": ""})
                    } else if(getProfile.data.response_data.mobile == localStorage.getItem("mobile")){                  
                        let mobileError = "Phone Already Exists.";
                        this.setState({mobileError})
                        this.setState({"emailError": ""})
                    }
                    return false;
                    
                } else {
                    
                    this.setState({ 
                        successmessage:"Please wait...",
                        loading: true
                    })
                    
                    var verification_otp = Math.floor(1000 + Math.random() * 9000);
                    
                    axios.post(CONSTANT.STABASE_URL +'users/sendEmail',qs.stringify({
                        email: email,
                        name: 'Sign Up',
                        verification_otp: verification_otp
                    }),{
                        headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
                      }).then(res => {
                            
                        if (res.data.response_status === 0) {
                            var otpMsg = 'Please Enter Valid Email'
                            this.setState({emailError:otpMsg, loading: false})
                        }else{
                            // document.getElementById('Otpb').click();
                            document.getElementById('OtpForm').style.display = "block";
                            localStorage.setItem('otp', res.data.response_data.OTP);                    
                            localStorage.setItem('email', email);
                            this.setState({loading: false})
                        }
                    },
                    (error) => { 
                        this.setState({ 
                            successmessage:"Please provide valid login credential."
                        })
                    });
                    
                }               
            }

        });
    }
    
    resetPassword = async e => {
        e.preventDefault();
        this.setState({loading: true})
        const isValid1 = this.validate1();
        if(isValid1){           
            var validOTP = localStorage.getItem('otp');
            var enterOTP = this.state.otp
            if(validOTP != enterOTP){
                var otpMsg = 'Please Enter Valid OTP'
                this.setState({otpMsg:otpMsg,loading: false})
                return false
            } else if(validOTP == enterOTP){
                this.setState({otpMsg:otpMsg})
                
                this.setState({ 
                    successmessage:"Please wait..."
                })          
                const { email, password, name, mobile, latitude, longitude, display_name} = this.state; 
                const userData = {
                    email: email,
                    password: password,
                    name:name,
                    mobile:localStorage.getItem("mobile"),
                    country_code:localStorage.getItem("country_code"),
                    display_name: '@'+display_name,
                    request_type: 2,
                    device_id: "123",
                    device_token: "abcdE",
                    device_type: 1,
                    lat: this.state.latitude,
                    long: this.state.longitude
                }           
                this.props.registerUser(userData);          
                this.setState({message:"Logged in successfully",loggedIn:true,progress:"Please wait...",loading: false})
                document.getElementById('OtpForm').style.display = "none";
            }
        }
    }
    validate1 = (e) =>{
        if(this.state.otp == ''){
            var otpMsg = 'Please Enter Valid OTP'
            this.setState({otpMsg:otpMsg, otp: '',loading: false})
            return false;
        } else {
            return true;
        }
    }
    

    // submitForm(e){       
        // e.preventDefault();
        // const isValid = this.validate();
        // if(isValid){         
            // this.setState({ 
                // successmessage:"Please wait..."
            // })           
            // const { email, password, name, mobile, latitude, longitude, display_name} = this.state; 
            // const userData = {
                // email: email,
                // password: password,
                // name:name,
                // mobile:localStorage.getItem("mobile"),
                // country_code:localStorage.getItem("country_code"),
                // display_name: display_name,
                // request_type: 2,
                // device_id: "123",
                // device_token: "abcdE",
                // device_type: 1,
                // lat: this.state.latitude,
                // long: this.state.longitude,
            // }            
            // this.props.registerUser(userData);           
            // this.setState({message:"Logged in successfully",loggedIn:true,progress:"Please wait..."})           
        // }
    // }

    componentDidMount(){        
        if (this.state.loggedIn) {
            var cls = "alert alert-success";
        }else{
            cls = "alert alert-danger";
        }
        this.setState({
            cls
        })
        this.getMyLocation();
    }


    validate = async () =>{
        
        let emailError = '';
        let passwordError = '';
        let mobileError = '';
        let nameError = '';
        let displayNameError = '';
        let tncError = '';

        if(!this.state.email){
            emailError = "Email ID field can not be blank.";
        }

        if (!this.state.display_name) {
            displayNameError = "Display name field can not be blank.";
        }

        
        if (this.state.display_name==="@") {
            displayNameError = "Display name field can not be blank.";
        }

        var re = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);

        if (!re.test(this.state.password)){
            passwordError = "Password must contain Minimum eight characters, at least one letter, one number and one special character.";
        } 

        if(!this.state.name){
            nameError = "Name field can not be blank.";
        }

        if(!document.getElementById("mobile").value){           
            mobileError = "Phone field can not be blank.";
        }

        if (!document.getElementById("mobile").value) {
            tncError = "Please accept our Terms and Conditions";
        }

        if(emailError){
            this.setState({emailError});
            this.setState({nameError});
            this.setState({mobileError});
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if(nameError){
            this.setState({emailError});
            this.setState({nameError});
            this.setState({mobileError});
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if(mobileError){
            this.setState({emailError});
            this.setState({nameError});
            this.setState({mobileError});
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if(passwordError){
            this.setState({emailError});
            this.setState({nameError});
            this.setState({mobileError});
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if (displayNameError) {
            this.setState({ emailError });
            this.setState({ nameError });
            this.setState({ mobileError });
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if (tncError) {
            this.setState({ emailError });
            this.setState({ nameError });
            this.setState({ mobileError });
            this.setState({ passwordError });
            this.setState({ displayNameError });
            this.setState({ tncError });
            return false;
        }
        if (!passwordError && !mobileError && !nameError && !emailError && !displayNameError && !tncError) {
            return true;
        }

    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation        
        if (location) {
          location.getCurrentPosition((position) => {
            this.setState({lat: position.coords.latitude,long: position.coords.longitude})
          }, (error) => {
            // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
          })
        }    
      }


      handleKeyDown = e => {
        if (e.key === " ") {
          e.preventDefault();
        }
      };
      

    render() { 
  
        if(this.state.loggedIn){ 
            return <Redirect to="/" />      
        }

        return (
            <>
            {(this.state.loading) ? (<Loader/>) : (<></>)}
            <Metatitle title="Sign up | aBlueC" metaDescription="Vendor Store" ogTitle="Vendor Store" ogImage=""></Metatitle>
            <Header />
            <section id="login">
                <div id="feed_wrapper_section" className="register_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="login-user-wrapper">
                                    <div className="login-box">  
                                    <h5>Register</h5>        
                                    
                                    <form className="flp" onSubmit={this.submitOTPForm}>
                                        {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="name" id="name" placeholder="Name" value={this.state.name} onChange={this.onChange}/>
                                            <small className="text-muted_error_message">{this.state.nameError}</small>
                                        </div>
                                        
                                            <div className="form-group displayname">
                                                <input className="form-control" type="text" name="display_name" id="display_name" placeholder="Display Name" value={this.state.display_name} onChange={this.onChange} onKeyDown={this.handleKeyDown}/>
                                                <small className="text-muted_error_message">{this.state.displayNameError}</small>
                                                <span>@</span>
                                            </div>
                                            
                                        <div className="form-group">
                                                <input autoComplete="email" className="form-control" type="email" name="email" id="email" placeholder="Email Address" value={this.state.email} onChange={this.onChange}/> 
                                            <small className="text-muted_error_message">{this.state.emailError}</small>
                                        </div>
                                        <div className="form-group">
                                            <PhoneInput
                                                    placeholder="Enter phone number"
                                                    defaultCountry="US"
                                                    id="mobile"
                                                    name={this.state.mobile}
                                                    value={this.state.mobile}
                                                    onChange={value => this.storePhoneNoChange({ value })}
                                            />
                                            {/*<PhoneInput flags={flags} className="" defaultCountry="US" placeholder="Enter phone number" id="mobile" value={this.state.mobile} onChange={this.storePhoneNoChange}/>
                                            <input className="form-control" type="number" name="mobile" id="mobile" placeholder="Phone" value={this.state.mobile} onChange={this.onChange}/>*/}
                                            <small className="text-muted_error_message">{this.state.mobileError}</small>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="password" name="password" id="password" placeholder="Password" value={this.state.password} onChange={this.onChange}/>
                                            <small className="text-muted_error_message">{this.state.passwordError}</small>
                                            </div>
                                        <div className="terms-condition-inner">
                                            <div className="checked_box clearfix">
                                                <label className="container_checked">
                                                        <input type="checkbox" onClick={this.tncCheck}/>
                                                <span className="checkmark" /> </label>
                                            </div>
                                                <div className="term_right">By creating an account you agree to our<br />
                                                <a href="/user-agreement" target="_blank">  EULA </a> | <a href="/terms" target="_blank"> Terms &amp; Conditions.</a> | <a href="/privacy" target="_blank">Privacy </a> </div>
                                                <small className="text-muted_error_message">{this.state.tncError}</small>
                                        </div>
                                        <div className="post_bt_public register_login">
                                            <ul>
                                                <li>
                                                    <button type="submit" className="save_bt">Register</button>
                                                </li>
                                            </ul>
                                        </div>
                                    <div className="already-pass"> Already have an account?<a href="/login"> Login here</a> | <a href="/contact">  Contact Us </a> </div>
                                    </form>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-bottom"><svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 className="svg replaced-svg">
                        <title>sApp Shape</title>
                        <desc>Created with Sketch</desc>
                        <defs></defs>
                        <g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                                <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                                    id="sApp-v1.0">
                                    </path>
                            </g>
                        </g>
                    </svg>
                </div>
                {/*<button type="hidden" class="btn btn-primary mr-1" id="Otpb" data-toggle="modal" data-target="#OtpForm" style={{"display":"none"}}>OTP</button>*/}
                
                <div className="overlay-addstore" id="OtpForm" style={{"display":"none"}}>
                    <div className="addstore-main">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Please enter email verification code</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{"color":"white"}}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.resetPassword}>                 
                                    <div className="form-row">
                                        <div className="form-group col-md-12">  
                                            <p style={{"color":"blue"}}>{(this.state.doneMsg != "") ? this.state.doneMsg : ""}</p>
                                            <input type="text" className="form-control" id="otp" aria-describedby="emailHelp" placeholder="Ex: 1234" name="otp" key="otp" onChange={this.handleUsernameChange} required />
                                            <p style={{"color":"red"}}>{(this.state.otpMsg != "") ? this.state.otpMsg : ""}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn btn-primary my-2 px-5 py-3">Verify</button>
                                    </div>  
                                </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>


<span className="contacts-signup" data-toggle="modal" data-target="#Contacts" > <img src='assets/images/white-logo.png' /> </span>

 <form className="login-form">
<div className="modal fade" id="Contacts" tabindex="-1" aria-labelledby="ContactLabel" aria-hidden="true" data-backdrop="false">
<div className="modal-dialog  modal-md">
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title" id="exampleModalLabel">Contact</h5>
<p><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></p></div>
<div className="modal-body">
<div className="mb-3"><label for="exampleInputName" className="form-label">Name</label><input type="text" className="form-control" id="name" aria-describedby="emailHelp" /></div>
<div className="mb-3"><label for="exampleInputEmail1" className="form-label">Email address</label><input type="text" className="form-control" id="email" aria-describedby="emailHelp" /></div>
<div className="mb-3"><label for="exampleInputPassword1" className="form-label">Comments</label><textarea className="form-control" id="comment" placeholder="Comments"></textarea></div>
<button type="submit" className="btn btn-primary">Submit</button>
</div>
</div>
</div>
</div>
</form>


            <Footer />
            </>
        );
    }

}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { registerUser,clearErrors }
)(Register);