import React, { Component } from 'react';
import axios from 'axios';
import { useHistory, Redirect, NavLink, BrowserRouter, Route, Link, Switch } from "react-router-dom";
import _ from "lodash";
import "../../menu.css";
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {getFooterPagesMenu, clearErrors} from "../../actions/HomepagesActions";


class Footer extends Component {
	
	async componentDidMount() {    
		let posts = await this.props.getFooterPagesMenu()		
		const response_status = localStorage.getItem('response_status') == 1;
		const profileData = response_status ? localStorage.getItem('profileData') : '';    
	}
	
	
	render(){
		
		const {getFooterData} = this.props.homepages
		if(getFooterData.length > 0){
			var footerNavFirstArray = getFooterData.length > 0 && getFooterData.filter((item) => {
					var url = (item.url).split("/")
					item.slug = url[3]
					return item;
			});			
		} else {			
			var footerNavFirstArray = []
		}
		
		return (
			<>
				<div className="jobs_footer">
					<ul className="user_policy">
						{footerNavFirstArray.length > 0 && footerNavFirstArray.map((item,i) =>
							<li key={i}><a href={'/'+item.slug}>{item.title}</a></li>
						)}
					</ul>
					<span>Copyright &#169; 2021 aBlueC</span>
				</div>
			</>
		);
	}
}


Footer.propTypes = {
	auth: PropTypes.object.isRequired,
	homepages: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    homepages: state.homepages,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getFooterPagesMenu, clearErrors}
)(Footer);