import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
// import Loader from "react-loader-spinner";
import qs from 'qs';
import Header from '../components/partials/Header'
import Footer from '../components/partials/Footer';
import '../autosearch.css';
import '../listDesign.css';
import Loader from '../Loader';
import ShowMore from 'react-show-more';
import ShowMoreText from 'react-show-more-text';
import CONSTANT from '../Constant';
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {getProductContent, getPagesContent, getPeopleContent, getTopicsContent, getStoreContent,getJobContent,clearErrors} from "../actions/SearchresultActions";
import Metatitle from "../components/metaTitle";
import _ from "lodash";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

async function getSuggestions(value) {
	const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim();
	this.setState({searchfilterdata: escapedValue});
	if (escapedValue === '') {
		return [];
	}
	const regex = new RegExp('^' + escapedValue, 'i');
	if(escapedValue.split('').length > 2)
	return await this.props.getPagesContent({search_txt: escapedValue,latitude: this.state.latitude,longitude: this.state.longitude})
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
	if(suggestion.getTitle == "products"){
		var images = suggestion.product_image
		var source = 'products'
	} else if(suggestion.getTitle == 'stores'){

		// console.log("asdsadas");
		// console.log(suggestion.store_image);
		// 	console.log("asdsada1111s");



		var images1 = suggestion.store_image

			if(images1!=''){
				var images = CONSTANT.NSTABASE_URL+'images/store/'+suggestion.store_image;
			}else{
				var images = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
			}
		//var images = suggestion.images;
		var source = 'stores'
	} else if(suggestion.getTitle == 'pages'){
		var images = suggestion.images
		var source = 'pages'
	} else if(suggestion.getTitle == 'jobs'){
	
		if(suggestion.images !=undefined){
			var images = CONSTANT.NSTABASE_URL+'images/store/'+suggestion.images;
		}else{
			var images = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
		}

		var source = 'jobs'
	}else {
		var images = suggestion.images
		var source = 'people'
	}
	return (<div><a href={"Search-result?q="+suggestion.name+"&source="+source}><span><img src={images} rel={suggestion.getTitle} width="500px" height="50px"/> </span> <span>{suggestion.name}</span></a></div>);
}

class SearchResult extends Component {

	constructor(props){
		super(props);
		const token = localStorage.getItem("token");
		let loggedIn = true;
		if (token == null) {
		  loggedIn = false;
		}
		this.state = {
			loggedIn,
			searchText:'',			
			session_token:token,
			cls:'',
			results:[],
			suggestionResults:[],
			suggestions: [],			
			newloader: false,
			value: '',
			suggestions: [],
			pages: [],
			email: "",
			password: "",
			latitude: 28.605331625541074,
			longitude: 77.3479261775324,
			showmessage:'',
			profileData: "",
			response_status: "",
			profile: {},
			getStoreInfo: {},
			stripeConnect: "",
			searchfilterdata:'',
			per_page:12, 
            current_page:1,
			getPages: [],
			loading: false,
			goStatus: "",
			source: "",
		}
		getSuggestions = getSuggestions.bind(this);
		this.searchData = this.searchData.bind(this);
	}
	
	async componentDidMount() {
		this.setState({loading: true})
		const onSuccess = (position) => {
			this.setState({"latitude":position.coords.latitude,"longitude":position.coords.longitude})
		};		
		const onError = (error) => {
			console.log(error.code+' '+error.message);
		}
		navigator.geolocation.getCurrentPosition(onSuccess, onError);
		var search =this.props.location.search;
		var params = new URLSearchParams(search);
		var q = params.get('q');
		var source = params.get('source');
		if(source == 'products'){			
			this.setState({value: q,selectedSearchOption:5, source: source})
			setTimeout(()=> {
				this.getProducts(q)	
				this.setState({loading: false})
			}, 1000);								
		} else if(source == 'stores'){			
			this.setState({value: q,selectedSearchOption:6, source: source})
			setTimeout(()=> {
				this.getVendors(q)
				this.setState({loading: false})
			}, 1000);
			
		} else if(source == 'pages'){			
			this.setState({value: q,selectedSearchOption:4, source: source})
			setTimeout(()=> {
				this.getPages(q)
				this.setState({loading: false})
			}, 1000);
		}
			else if(source == 'jobs'){			
			this.setState({value: q,selectedSearchOption:7, source: source})
			setTimeout(()=> {
				this.getJobs(q)
				this.setState({loading: false})
			}, 1000);
			
		} else {			
			this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />,value: q,selectedSearchOption:3, source: source});
			setTimeout(()=> {
				this.searchData(q)
				this.setState({loading: false})
			}, 1000);
		}
		document.getElementById("Search").addEventListener("keyup", async (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
				var search =this.props.location.search;
				var params = new URLSearchParams(search);
				var q = params.get('q');
				var source = params.get('source');
				if(source == 'products'){			
					this.setState({value: this.state.value,selectedSearchOption:5, source: source})
					setTimeout(()=> {
						this.getProducts(this.state.value)	
						this.setState({loading: false})
					}, 1000);
					this.props.history.push(`/Search-result?q=${this.state.value}&source=products`);
				} else if(source == 'stores'){			
					this.setState({value: this.state.value,selectedSearchOption:6, source: source})
					setTimeout(()=> {
						this.getVendors(this.state.value)
						this.setState({loading: false})
					}, 1000);
					this.props.history.push(`/Search-result?q=${this.state.value}&source=stores`);
				} else if(source == 'pages'){			
					this.setState({value: this.state.value,selectedSearchOption:4, source: source})
					setTimeout(()=> {
						this.getPages(this.state.value)
						this.setState({loading: false})
					}, 1000);
					this.props.history.push(`/Search-result?q=${this.state.value}&source=pages`);
				}
				else if(source == 'jobs'){			
					this.setState({value: this.state.value,selectedSearchOption:7, source: source})
					setTimeout(()=> {
						this.getJobs(this.state.value)	
						this.setState({loading: false})
					}, 1000);
					this.props.history.push(`/Search-result?q=${this.state.value}&source=jobs`);
				}

				 else {			
					this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />,value: this.state.value,selectedSearchOption:3, source: source});
					setTimeout(()=> {
						this.searchData(this.state.value)
						this.setState({loading: false})
					}, 1000);
					// this.props.history.push(`/Search-result?q=${this.state.value}&source=topics`);
				}
			}
		});
	}
	
	async getDistance(latitude,longitude){		
		var getDistanceData = await this.props.getDistance({lat1:this.state.latitude,long1:this.state.longitude,lat2:latitude,long2:longitude})				
	}
	
	onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {		
		var filterUserName = suggestion.name;
		var setTitle = suggestion.getTitle;
		this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />,value: filterUserName,selectedSearchOption:3});
		this.props.history.push(`/Search-result?q=${filterUserName}&source=${setTitle}`);
	};
	
	onChange = (event, { newValue, method }) => {
		this.setState({newloader: <Loader type="Puff" className="loaderSpinner" color="#00BFFF"  height={30} width={30} timeout={3000} />,value: newValue});
	};
    
	onSuggestionsFetchRequested = async ({ value }) => {    
		this.setState({newloader: <Loader type="Puff" color="RED" className="loaderSpinner"  height={30} width={30} timeout={3000} />,suggestions: await getSuggestions(value)});
	};
  
    onSuggestionsClearRequested = () => {
		this.setState({
		  suggestions: []
		});
    };
	
	handleUsernameLoginChange = e => {
		this.setState({ [e.target.id]: e.target.value});
	}

	changeselectedSearchOption(index){

		const search =this.props.location.search;
		const params = new URLSearchParams(search);
		const q = params.get('q');
		this.setState({
            selectedSearchOption: index,
            successmessage:null,
			loading: true
        });
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
			if (index === 2) {		
				this.getTopics(q);
			}
			if (index === 3) {
				this.getPeople(q);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=people`);
			}
			if (index === 4) {
				this.getPages(q);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=pages`);
			}
			if (index === 5) {
				this.getProducts(q);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=products`);
			}
			if (index === 6) {
				this.getVendors(q);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=stores`);
			}
			if (index === 7) {
				this.getJobs(q);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=jobs`);
			}
			this.setState({loading: false});
        }, 1000);       
    }
	
	async getPeople(searchText){		
		if (searchText) {
			const escapedValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  
			if (escapedValue === '') {
				return [];
			}
			const regex = new RegExp('^' + escapedValue, 'i');
			const session_token = localStorage.getItem("token");
			const user_id = localStorage.getItem("loggedInUserId");

			var getPagesContent = await this.props.getPeopleContent({search_txt: escapedValue,latitude: this.state.latitude,longitude: this.state.longitude,_id:(user_id) ? user_id:''})
		}		
	}
	
	async getProducts(searchText){		
		if (searchText) {
			const escapedValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  
			if (escapedValue === '') {
				return [];
			}
			const regex = new RegExp('^' + escapedValue, 'i');
			var getPagesContent = await this.props.getProductContent({search_txt: escapedValue,latitude: this.state.latitude,longitude: this.state.longitude})
		}
	}

	// code added by AKS
	async getJobs(searchText){		
		if (searchText) {
			const escapedValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  
			if (escapedValue === '') {
				return [];
			}
			const user_id = localStorage.getItem("loggedInUserId");
			const regex = new RegExp('^' + escapedValue, 'i');
			var getJobContent = await this.props.getJobContent({search_txt: escapedValue,latitude: this.state.latitude,longitude: this.state.longitude,user_id:user_id})
		}
	}
	
	async getTopics(searchText){		
		if (searchText) {
			const escapedValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  
			if (escapedValue === '') {
				return [];
			}
			const regex = new RegExp('^' + escapedValue, 'i');
			var getTopicsContent = await this.props.getTopicsContent({topic: escapedValue,startIndex:1,count:10})			
		}		
	}
	
	async getPages(searchText){
		if (searchText) {
			this.setState({getPages:[]});
		}		
	}
	
	async getVendors(searchText){
		if (searchText) {
			const escapedValue = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  
			if (escapedValue === '') {
				return [];
			}
			const regex = new RegExp('^' + escapedValue, 'i');
			var getStoreContent = await this.props.getStoreContent({"search_txt": escapedValue,"long": this.state.longitude, "page": this.state.current_page, "lat": this.state.latitude, "row_count": this.state.per_page})		
		}		
	}

	searchData(searchText){ 
	
		this.setState({
			loading: true,
            searchText:searchText,
            product_list:[],
            stores:[],
        }, () => {
			
			if(searchText.length >=3){

				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.setState({loading: false});
				}, 1000);
			
				if(this.state.selectedSearchOption==2){  
					this.getTopics(searchText);
				}
				if(this.state.selectedSearchOption==3){
					this.getPeople(searchText);					
				}				
				if(this.state.selectedSearchOption==5){  
					this.getProducts(searchText);
				}
				if(this.state.selectedSearchOption==6){   
					this.getVendors(searchText);
				}
				if(this.state.selectedSearchOption==7){   
					this.getJobs(searchText);
				}
				
			} else {
				
				if(this.state.selectedSearchOption==3){  
					this.getPeople(searchText);
				}
				
				this.setState({ 
					product_list_show:false, 
					stores_show:false,
					total:0,
					total1:0,
				})
				
			}
        });
    }
	
	iconSearchData = e => {		
		this.setState({loading: true})
		if(this.state.value){			
			var search =this.props.location.search;
			var params = new URLSearchParams(search);
			var q = params.get('q');
			var source = params.get('source');
			if(source == 'products'){			
				this.setState({value: this.state.value,selectedSearchOption:5, source: source})
				setTimeout(()=> {
					this.getProducts(this.state.value)	
					this.setState({loading: false})
				}, 1000);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=products`);
			} else if(source == 'stores'){			
				this.setState({value: this.state.value,selectedSearchOption:6, source: source})
				setTimeout(()=> {
					this.getVendors(this.state.value)
					this.setState({loading: false})
				}, 1000);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=stores`);
			} else if(source == 'pages'){			
				this.setState({value: this.state.value,selectedSearchOption:4, source: source})
				setTimeout(()=> {
					this.getPages(this.state.value)
					this.setState({loading: false})
				}, 1000);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=pages`);
				}else if(source == 'jobs'){			
				this.setState({value: this.state.value,selectedSearchOption:7, source: source})
				setTimeout(()=> {
					this.getJobs(this.state.value)
					this.setState({loading: false})
				}, 1000);
				this.props.history.push(`/Search-result?q=${this.state.value}&source=jobs`);
			} else {			
				this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />,value: this.state.value,selectedSearchOption:3, source: source});
				setTimeout(()=> {
					this.searchData(this.state.value)
					this.setState({loading: false})
				}, 1000);
			}
		}
	}
	
	setStore = e => {
		localStorage.setItem("store_id", e.id);
		localStorage.setItem("store_name", e.store_name);
		document.location.href = "/store/"+e.store_name
	}
	
setJobDetail = e => { 
	localStorage.setItem("stat",e.stat);
	//localStorage.setItem("store_name", e.store_name);
	document.location.href = "/jobs-details/"+e.job_id
  }

	setStoreProduct = el =>{ 
		localStorage.setItem("store_id", el.id);
		localStorage.setItem("store_name", el.store_name);
		if(el.type == 'store'){
		document.location.href = "/store/"+el.store_name
		}
		else{
			this.setState({loading: true})
			if(localStorage.getItem("loggedInUserId") != undefined){
				this.addActivity({'product_id':el.product_id,'store_id':el.id,'store_name':el.store_name,'user_id':localStorage.getItem("loggedInUserId")})
			}
			setTimeout(()=> {
				document.location.href = "/store/"+el.store_name+"/"+el.product_id
				this.setState({loading: false})
			}, 3000);
		
			
			
		}
	}

	addActivity = async (e) =>{
		   //console.log('rrrrrrrrrrrrrr'+e); return false
			//const formData = {"session_token":localStorage.getItem("token"),e}
			const formData = e			
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL +'web/store/addActivity', formData, config);
			 if(response.data.response_status == 1){
				//localStorage.setItem("friend", true)
			 }
			
			
	}
	
	addFriend = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"add_friend_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'friend/addFriend', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("friend", true)
			}
			this.componentDidMount();
		}		
	}

	unFriend = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"un_friend_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'friend/UnFriendRequest', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("friend", true)
			}
			this.componentDidMount();
		}		
	}
	
	addFollow = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/follow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
	}	

	unFollow = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/unfollow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
	}	
	
	
	render(){
		
		const { selectedSearchOption, value,  newloader, pages, profileData, response_status, profile, stripeConnect, getPages } = this.state;
		const {getDataPage, getDataResult, getSearchPeopleData, getSearchProductData,getSearchJobData,getSearchTopicsData, getSearchStoreData} = this.props.searchresult

		//console.log(getSearchPeopleData); 
		const inputProps = {
			id: 'Search',
			placeholder: 'Search',
			value,
			onChange: this.onChange		
		};	
		
		var newUserFilterList = []
	    var newProductsFilterList = []
	    var newStoreFilterList = []
	    var newJobFilterList = []
	    
		getDataResult.length > 0 && getDataResult.map((items) => {
			if(items.title == "Users")
				newUserFilterList.push(items.languages)
			else if(items.title == "Products")
				newProductsFilterList.push(items.languages)
			else if(items.title == "Jobs")
				newJobFilterList.push(items.languages)
			else
				newStoreFilterList.push(items.languages)
		});
		
		newUserFilterList = newUserFilterList[0] ? newUserFilterList[0] : []
		newProductsFilterList = newProductsFilterList[0] ? newProductsFilterList[0] : []
		newStoreFilterList = newStoreFilterList[0] ? newStoreFilterList[0] : []
		newJobFilterList = newJobFilterList[0] ? newJobFilterList[0] : []
		var getTitle
		newUserFilterList.length > 0 && newUserFilterList.map((items) => {
			items.getTitle = "users"
		});
		newProductsFilterList.length > 0 && newProductsFilterList.map((items) => {
			items.getTitle = "products"
		});
		newStoreFilterList.length > 0 && newStoreFilterList.map((items) => {
			items.getTitle = "stores"
		});
		newJobFilterList.length > 0 && newJobFilterList.map((items) => {
			items.getTitle = "jobs"
		});
		
		getSearchPeopleData.length > 0 && getSearchPeopleData.map((items) => {
			//items.emailUrl = items.email.replace(/.com/g,'');

			items.emailUrl = items.display_name;

			
		});
		
		var getDataFilterResult = [...newUserFilterList,...newProductsFilterList,...newStoreFilterList,...newJobFilterList]
		
		
			
		return (
			<>
			<Metatitle title="Serach Result | aBlueC" metaDescription="" ogTitle="" ogImage=""></Metatitle>
			<Header />
			<div className="container-fluid">			
				<div className="main-wrapper">
					<div className="job_search_categary"> 
						<div className="main_logo">
							<div className="logo_store">
								<a href="/"><img src="../assets/images/logo.png" alt="Layer"/></a> 
							</div>
						</div>
						<div className="search_wrp"> 
							<Autosuggest 
								suggestions={getDataFilterResult.slice(0,10)}
								onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
								onSuggestionsClearRequested={this.onSuggestionsClearRequested}
								getSuggestionValue={getSuggestionValue}
								renderSuggestion={renderSuggestion}
								onSuggestionSelected={this.onSuggestionSelected}
								onChange={this.handleOnInputChange}
								inputProps={inputProps} />
								<div className="inner_tabpanel">
									<div className="defult_sreach">									
										<img src="../assets/images/search.png" onClick={this.iconSearchData} class="main_search_img"/>
									</div>
								</div>
						</div>
						<div className="store_wrap">
							<div className="row">
								<div className="col-md-12">
									<ul className="jobs_change_categary search_result" >
										<li><a href="#" className={this.state.selectedSearchOption === 3 ? 'active' : ''} onClick={() =>
										this.changeselectedSearchOption(3)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/people.svg"/>&nbsp;People</a></li>
										<li><a href="#" className={this.state.selectedSearchOption === 5 ? 'active':''} onClick={() =>
										this.changeselectedSearchOption(5)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/products.svg"/>&nbsp;Products</a></li>
										<li><a href="#" className={this.state.selectedSearchOption === 2 ? 'active' : ''} onClick={() =>
										this.changeselectedSearchOption(2)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/topics.svg"/>&nbsp;Topics</a></li>
										{/*<li><a href="#" className={this.state.selectedSearchOption === 4 ? 'active' : ''} onClick={() =>
										this.changeselectedSearchOption(4)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/pages.svg"/>&nbsp;Pages</a></li> */}
										<li><a href="#" className={this.state.selectedSearchOption === 6 ? 'active':''} onClick={() =>
										this.changeselectedSearchOption(6)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/store.svg"/>&nbsp;Stores</a></li>
										<li><a href="#" className={this.state.selectedSearchOption === 7 ? 'active':''} onClick={() =>
										this.changeselectedSearchOption(7)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/search-jobs.svg"/>&nbsp;Jobs</a></li>
										<li><a href="/service-provider" className={this.state.selectedSearchOption === 8 ? 'active':''} onClick={() =>
										this.changeselectedSearchOption(8)}><img style={{"vertical-align":"top","margin":"4px 0px 0px 2px"}} src="../assets/images/search-service-provider.svg"/>&nbsp;Find a Service Provider</a></li>
									</ul> 
								</div>
							</div>
						</div>
					</div>
					</div>
					</div>
					<div className="container-fluid bdr-top innerheight">
					<div className="main-wrapper">
					<div className="search_height">
						<div id="feed_wrapper_section">
							<div className="row">
								<div className="col-md-7">
									<div className="full_wrp">
										<ul className="list-group">
											{(selectedSearchOption == 3) &&
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												{getSearchPeopleData.length > 0 && getSearchPeopleData.map((item) =>
													<li className="list-group-item People" >
														<img src={item.profile_image ? CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded" alt="Cinque Terre" /> 
														<a href="" onClick={() => this.props.history.push('/people/'+item.emailUrl)}> {item.name}</a>
														{/* {(localStorage.getItem("friend") == undefined) ?
														(<button type="button" onClick={() => this.addFriend(item._id)} className="btn btn-primary btn-sm float-right" style={{"marginLeft": "5px"}}>Add Friend</button>) : (<><div className="btn btn-primary btn-sm float-right" >Friend</div></>)}
														 */}
														{(item.already_send_request == undefined || item.already_send_request == 0) ?
														(<button type="button" onClick={() => this.addFriend(item._id)} className="btn btn-primary btn-sm float-right" style={{"marginRight": "5px"}}>Add Friend</button>) :
														
														
														(<><button type="button" onClick={() => this.unFriend(item._id)} className="btn btn-light btn-sm float-right" style={{"marginRight": "5px"}}>Cancel Request</button></>)}
													
														
														
														{(item.already_followed == undefined || item.already_followed == 0) ?
														(<button type="button" onClick={() => this.addFollow(item._id)} className="btn btn-primary btn-sm float-right" style={{"marginRight": "5px"}}>Follow</button>) :
														
														
														(<><button type="button" onClick={() => this.unFollow(item._id)} className="btn btn-primary btn-sm float-right" style={{"marginRight": "5px"}}>Unfollow</button></>)}
													</li>
												)}
												</>
											}
												
											{ (selectedSearchOption == 2) && 
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												{getSearchTopicsData.length > 0 && getSearchTopicsData.map((item) =>
													<li className="media Topics">
														<img style={{"width": "100px","height": "100px", "border-radius": "0px", "border": "1px solid #ebebeb", "background": "#fff","padding": "2px", "box-shadow": "0 0 3px rgb(0 0 0 / 20%)"}} src={(item.pagemap != undefined && item.pagemap != "" && item.pagemap.cse_image) ? item.pagemap.cse_image[0].src : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded" alt="" /> 
														<div className="media-body 111">
														<h5 className="mt-0"> {item.title} </h5>
														
														<a href={item.link} target="_blank">{item.displayLink}</a>
														
														<p><span className="">{item.snippet}</span> </p>
														</div>
													</li>
												)}
												</>												
											}
											
											{ (selectedSearchOption == 5) &&  
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												{getSearchProductData.length > 0 && getSearchProductData.map((item) =>
													<li className="media Product">
														<a href="#" onClick={() => this.setStoreProduct({"id":item.storeinfo[0]._id,"store_name":item.storeinfo[0].store_name,"type":'product',"product_id":item._id})}><img src={item.images[0] ? CONSTANT.NSTABASE_URL+'images/store/'+item.images[0] :item.product_image ? item.product_image : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded search-list-img" alt="" /></a>
														<div className="media-body">
														<h5 class="mt-0 mb-1"> <a href="#" onClick={() => this.setStoreProduct({"id":item.storeinfo[0]._id,"store_name":item.storeinfo[0].store_name,"type":'product',"product_id":item._id})}>Product name: {item.name}</a></h5>
														<h5 class="mt-0 mb-1 store-name"> <a href="#" onClick={() => this.setStoreProduct({"id":item.storeinfo[0]._id,"store_name":item.storeinfo[0].store_name,"type":'store',"product_id":item._id})}>Store name: <span>{(item.storeinfo.length > 0) ? item.storeinfo[0].store_name : ""}</span></a></h5>
														{/*<h5 class="mt-0 mb-1"> <a  href={(item.storeinfo.length > 0) ? "/store/"+item.storeinfo[0].store_name+"/"+item._id : "#"}>Store address: {(item.storeinfo.length > 0) ? item.storeinfo[0].store_address : ""}</a></h5>*/}
														<p><span className="distance"><span>Distance </span>:{(item.distanceData != undefined || _.has(item.distanceData, 'distance')) ? item.distanceData.distance : 0}</span> <span className="duration"><span>Duration</span>: {(item.distanceData != undefined || _.has(item.distanceData, 'duration')) ? item.distanceData.duration : 0}</span>
														<span className="price-sec"> <a href="#"> Price: <span className="product-price"> {item.product_sale_price ? "$"+item.product_sale_price : item.product_price}</span></a> </span>
														</p> 
													</div>
													</li>             
												)}
												</>
											}
											
											{ (selectedSearchOption == 4) && 
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No Data Found</span>
												</li>												
												</>
											}
											
											{ (selectedSearchOption == 6) && 
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												{getSearchStoreData.length > 0 && getSearchStoreData.map((item) =>
													<li className="media Store">
														<a style={{"color":"#636363"}} href="#" onClick={() => this.setStore({"id":item.store_info._id,"store_name":item.store_info.store_name})} ><img src={(item.store_info.store_image != undefined && item.store_info.store_image != "") ? CONSTANT.NSTABASE_URL+'images/store/'+item.store_info.store_image : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded" alt="" /></a>
														<div class="media-body">

														<h5 class="mt-0 mb-1">  <a style={{"font-weight":"600"}} href="#" onClick={() => this.setStore({"id":item.store_info._id,"store_name":item.store_info.store_name})}  > {item.store_info.store_name} </a> </h5>
												        {/* <h5 class="mt-0 mb-1 store-name"> 	<a  href="#" onClick={() => this.setStore({"id":item.store_info._id,"store_name":item.store_info.store_name})}  >{item.store_info.store_address}</a> </h5>  */}
														<h5 class="mt-0 mb-1 store-name"> 	<a  href="#" >{item.store_info.store_description}</a> </h5> 
												         <p><span className="distance"><span>Distance </span>: {item.store_info.google_distance}</span> <span className="duration"><span>Duration</span>: {item.store_info.duration}</span></p> 
														
														
													   
													   {/*<div className="col-4"><span className="float-right">Ratings</span> </div>*/}
													    
														
															
														
														</div>
													</li>
												)}
												</>
											}
											
											{ (selectedSearchOption == 7) &&  
												<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												{getSearchJobData.length > 0 && getSearchJobData.map((item) =>
													<li className="media Job">
													 
														<a style={{"color":"#636363"}} href="#" onClick={() => this.setJobDetail({'job_id':item._id,'stat':item.status})} ><img src={(item.job_image[0] != undefined && item.job_image[0] != "") ? CONSTANT.NSTABASE_URL+'images/store/'+item.job_image[0] : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded" alt="" /></a>
														<div class="media-body">

														<h5 class="mt-0 mb-1">  <a style={{"font-weight":"600"}} href="#"  onClick={() => this.setJobDetail({'job_id':item._id,'stat':item.status})}  > {item.job_title} </a> </h5>
												     
														<h5 class="mt-0 mb-1 store-name"> 	<a  href="#" >{item.customer_name}</a> </h5> 
															
														
														</div>
													</li>        
												)}
												</>
											}

											
											{(selectedSearchOption == 2 && getSearchTopicsData.length == 0) &&
											<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No Data Found</span>
												</li>												
											</>
											}
											
											{(selectedSearchOption == 3 && getSearchPeopleData.length == 0) &&
											<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No People Found </span>
												</li>												
											</>
											}
											
											{(selectedSearchOption == 5 && getSearchProductData.length == 0) &&
											<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No Product Found </span>
												</li>												
											</>
											}
											
											{(selectedSearchOption == 6 && getSearchStoreData.length == 0) &&
											<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No Store Found</span>
												</li>												
											</>
											}
											
											{(selectedSearchOption == 7 && getSearchJobData.length == 0) &&
											<>
												{this.state.loading ? (<Loader/>) : (<></>)}
												<li className="list-group-item disabled">
													<span className="float-left">No Jobs Found</span>
												</li>												
											</>
											}

											
										</ul> 
									</div>
									
									{/*<div className=" pagination-sec clearfix">
										<div className="pagination-section">
											{(selectedSearchOption != 2)? 
											<><label className="first-arrow">«</label><span className="active">1</span><label className="last-arrow">»</label></>
											:
											<>&nbsp;</>
											}
										</div>
									</div>*/}									
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			
			<Footer />
			</>
		  );
		}
	}
 
SearchResult.propTypes = {
	auth: PropTypes.object.isRequired,
	searchresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    searchresult: state.searchresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getProductContent, getPagesContent, getPeopleContent, getTopicsContent, getStoreContent,getJobContent,clearErrors}
)(SearchResult);