import {
    GET_SEARCHRESULTDATA,
    GET_SEARCHRESULT,
    GET_DISTANCE,
    GET_SEARCHPRODUCTRESULT,
    GET_SEARCHJOBRESULT,
    GET_SEARCHPEOPLERESULT,
    GET_SEARCHTOPICSRESULT,
    GET_SEARCHSTORESRESULT,
    SEARCHRESULT_LOADING
   
} from "../actions/types";

const initialState = {
    statec: {},
    getSearchData: {},
    getDistanceData: {},
    getSearchProductData: {},
    getSearchJobData:{},
    getSearchPeopleData: {},
    getSearchTopicsData: {},
    getSearchStoreData: {},
    getDataResult: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
		case GET_DISTANCE:
            return {
                ...state,
                getDistanceData: action.payload,
                loading: false
                
            };
		case GET_SEARCHSTORESRESULT:
            return {
                ...state,
                getSearchStoreData: action.payload,
                loading: false
                
            };
		case GET_SEARCHTOPICSRESULT:
            return {
                ...state,
                getSearchTopicsData: action.payload,
                loading: false
                
            };
		case GET_SEARCHPEOPLERESULT:
            return {
                ...state,
                getSearchPeopleData: action.payload,
                loading: false
                
            };
		case GET_SEARCHPRODUCTRESULT:
            return {
                ...state,
                getSearchProductData: action.payload,
                loading: false
                
            };
        case GET_SEARCHJOBRESULT:
            return {
                ...state,
                getSearchJobData: action.payload,
                loading: false
                
            };   
		case GET_SEARCHRESULT:
            return {
                ...state,
                getDataResult: action.payload,
                loading: false
                
            };
        case SEARCHRESULT_LOADING:
			return {
				...state,
				loading: true
			};              
        default:
        return state;
    }
}
