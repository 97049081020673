import Header from '.././partials/Header'
import Footer from '.././partials/Footer';
import InnerPages from '.././Pages/InnerPages';
import React, { Component } from 'react';
// import imageExists from 'image-exists';
import Loader from '../../Loader';
import CONSTANT from '../../Constant'; 

import axios from 'axios';
import qs from 'qs';
import Modal from "react-bootstrap/Modal";
import { Multiselect } from 'multiselect-react-dropdown';
import { createAcademic,deleteAcademic,createExprience,deleteExperience,getAllCategory,getSubCategory,createSkill,editSkill,deleteSkill,deleteAttachments} from '../../components/Api'; 
import PlacesAutocomplete, { geocodeByAddress,getLatLng,geocodeByPlaceId  } from 'react-places-autocomplete';

import $ from "jquery";
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.min.css';
import '../../assets/css/owl.theme.default.min.css';
import ShowMoreText from 'react-show-more-text';
import closeRedImg from "../../close-red.png"
import likeSvg from "../../like.svg";
import unlikeSvg from "../../unlike.svg";
import chatCommentSvg from "../../chat_comment.svg";
import shareSvg from "../../share.svg";

import post_btn_1 from "../../post-btn-1.png";
import post_btn_2 from "../../post-btn-2.png";
import logo_not_post from "../../logo-not-post.png";
import VideoThumbnail from 'react-video-thumbnail';
import TextareaAutosize from 'react-textarea-autosize';
import DocViewer from "react-doc-viewer";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const initialState = { 

// jobtitle:'',
// jobdesc:'',
// location_type:'',
// jobpdf:''

 
// } 

class AddJob extends Component {
  constructor(props) {
    super(props);
  const token = localStorage.getItem("token");        
 // this.state = initialState;


  this.state = {
          jobtitle:'',
          jobdesc:'',
          location_type:'',
          flag:1,
          complete_address:''
         // jobpdf:''

    };
    this.onImageChange = this.onImageChange.bind(this);


  } 

   
   handleSelects = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
    const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
    const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
    const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
    const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
    const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
    const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
    var cityNames = '';
    if (cityNameNeighbour) cityNames = cityNameNeighbour; else if (cityNameLocality) cityNames = cityNameLocality; else { cityNames = cityName; }
    this.setState({ complete_address: address, storeLat: latLng.lat, storeLong: latLng.lng, state: stateName, city: cityNames, zip: postalCode, country: countryName, street: streetNumber + ' ' + routesName,flat:'' })
  };

  handleChanges = (address) => {
    this.setState({ complete_address: address });
  }; 

  async componentDidMount() { 
    //let {id} = this.props.match.params;

    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    this.setState({loading: false}); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
   
}
 //jobpdf: URL.createObjectURL(img),

 onImageChange = event => {

  var filename=event.target.name;
  var filevalue=event.target.value;
  var fileextension = filevalue.split('.').pop();
// console.log("11111111");
//   console.log(fileextension);

var jobImage='';
var jobPdf='';
if(event.target.name=="job_image"){
    if (event.target.files && event.target.files[0]) {
      let jobImage = event.target.files[0];
      this.setState({
        jobImage:event.target.files[0]
      });
    }
  }

  if(event.target.name=="job_pdf"){
    if (event.target.files && event.target.files[0]) {
      let jobPdf = event.target.files[0];
      this.setState({
        jobPdf:event.target.files[0]
      });
    }
  }

   if(event.target.value=="OnSite"){

    // let flag = 2;
     this.setState({
        flag:2
      });
   
   }
   if(event.target.value=="Remote"){

    // let flag = 2;
     this.setState({
        flag:1
      });
   
   }


    this.setState({ [event.target.name]: event.target.value});
  };




    onSubmitAddJob = async (event) =>{
    //  this.setState({loading:true});
     event.preventDefault();


     const isValid = await this.validateAddjob();
       if(isValid){
      this.setState({loading:true});


    var formData = new FormData();


formData.append("session_token", localStorage.getItem("token"))
formData.append("job_title", this.state.jobtitle)
formData.append("job_desc", this.state. jobdesc)
formData.append("lat", '0.0.1')
formData.append("long", '0.0.2')
formData.append("job_image", this.state.jobImage)
formData.append("job_pdf", this.state.jobPdf)
formData.append("serviceProviderIds",'');
formData.append("location_type",this.state.location_type);
if(this.state.complete_address=='')
this.state.complete_address="Remote";
formData.append("complete_address",this.state.complete_address);



// console.log("sfasfsdfds");
// console.log(this.state.location_type);
// console.log("amffff");

// return false;


    const config = {
      headers: {
      'Content-Type': 'multipart/form-data',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }  


        let getProfile = await axios.post(CONSTANT.STABASE_URL +'users/sendjobrequest', formData,config);
console.log("getProfile11111");
console.log(getProfile);
console.log(getProfile);
    if(getProfile.data.response_status === 1){
      this.setState({loading:false, showEditProfile:false,smsg:getProfile.data.response_msg});
      document.location.href = '/jobs-listing'
    } else {
      this.setState({loading:false, emsg:getProfile.data.response_msg});
    }

  }

}


validateAddjob = () =>{
    let nameError='';
    let locationError=''; 
    let jdError=''; 

    if(!this.state.jobtitle){
    nameError = "Job Title field is required.";
    }
    if(!this.state.location_type){
    locationError = "Select Location field is required.";
    }
    if(!this.state.jobdesc){
    jdError = "Job Desc field is required.";
    }
    if(nameError || locationError || jdError ){
      this.setState({nameError,locationError,jdError});
      return false;
    } else {
      this.setState({nameError,locationError,jdError});
      return true;
    } 
  }  


   

  render() {
    return (
      <>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
      <div className="container"> 
        <div className="row justify-content-center">
            <div className="col-md-6 mt-5 p-0"> 
        <div class="card"> 
       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    <div className="card-header"> Add Job</div>

  <div className="card-body">
      
 <form enctype="multipart/form-data" onSubmit={this.onSubmitAddJob} >
      <div className="main_detail_jobs p-0">
       
           
            
           
            <div className="app_add_agent m-0">
                                   
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Job Title</label>
                        <input type="text" className="form-control" name="jobtitle" placeholder="Job Title" value={this.state.jobtitle} onChange={this.onImageChange}/>
                         <span className="error-msg">{this.state.nameError}</span>
                      </div>
                      <div className="form-group">
                        <label className="label_title edit_label">Remote / Onsite</label>
                        <select class="form-control" name="location_type" id="location_type" onChange={this.onImageChange} >
                          <option onChange={this.onImageChange} value="">Select</option>
                          <option onChange={this.onImageChange} value="Remote">Remote</option>
                          <option onChange={this.onImageChange} value="OnSite">OnSite</option>
                        </select>
                         <span className="error-msg">{this.state.locationError}</span>
                      </div>

                      {this.state.flag==2 ?
                       <>

                       <PlacesAutocomplete
                              value={this.state.complete_address}
                              onChange={this.handleChanges}
                              onSelect={this.handleSelects}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Address ...',
                                      className: 'form-control search_input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span className="list-item-description">{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>


                       {/*<div className="form-group">
                        <label className="label_title edit_label">Enter Location</label>
                        <input type="text" className="form-control" name="complete_address" placeholder="Enter Location" value={this.state.complete_address} onChange={this.onImageChange}/>
                      </div>*/}

                      </>
                      : '' }

                       <div className="form-group">
                        <label className="label_title edit_label">Job Description</label>
                        <input type="text" className="form-control" name="jobdesc" placeholder="Job Description" value={this.state.jobdesc} onChange={this.onImageChange}/>
                          <span className="error-msg">{this.state.jdError}</span>
                      </div>
                       <div className="form-group">
                        <label className="label_title edit_label">Job Image</label>
                        <input type="file" className="form-control" name="job_image" accept=".jpeg,.png" placeholder="job_image" value={this.state.job_image} onChange={this.onImageChange}/>
                      </div>
                      <div className="form-group">
                        <label className="label_title edit_label">Job Pdf</label>
                        <input type="file" className="form-control" name="job_pdf" accept=".pdf" placeholder="job_pdf" value={this.state.job_pdf} onChange={this.onImageChange}/>
                      </div>
                    </div>
                   
                  </div>
                </div>
        </div>
              <div className="post_bt_public request_bt edit_profile">
              <ul className="clearfix">
                <li>
                  <button type="submit" className="save_bt">SAVE</button>
                </li>
              </ul>
            </div> 
             
            </div>
      </div>
      </form>




     
              </div>
              


   







              </div>
    </div>
    </div>
        </div>
        </div>
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default AddJob;