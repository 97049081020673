import {
    SET_CURRENT_USER,
	 GET_ACCOUNT_INFO
    
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    user: {},
	currentUser:{},
    loading: false,
};
export default function(state = initialState, action) {
    //console.log("Ayaa")
    //console.log(action.type);
    switch (action.type) {
       
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
			 case GET_ACCOUNT_INFO:
            return {
                ...state,
                currentUser: action.payload
            };
        default:
            return state;
    }
}
