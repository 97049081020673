import React, { Component } from 'react';
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../Constant'; 
import Loader from '../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  combinedFriends: [],
  friendList: [],
  inviteFriendList:[],
  privateFollowList:[],
  friendListCount:''
} 

class Friends extends Component {
  constructor(props) {
    super(props);
  const token = localStorage.getItem("token");        
  this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    this.setState({loading: true}); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
    let getcombinedFriend = await axios.post(CONSTANT.STABASE_URL +'friend/getCombineFriendRecord', {session_token:localStorage.getItem("token")},config);
    if(getcombinedFriend.data.response_status === 1){
     this.setState({inviteFriendList:getcombinedFriend.data.response_data.getCombineFriendRecord.getFriendListData,privateFollowList:getcombinedFriend.data.response_data.getCombineFriendRecord.RequestToFollowList});
    }  

    
    var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}   
  
    let response = await axios.post(CONSTANT.STABASE_URL +'friend/getAllFriendsList', formData, config);
    
    if(response.data.response_data != null){
      this.setState({friendList: response.data.response_data.getAllFriendsList,friendListCount:response.data.response_data.total_count,loading: false});
    } else {
      this.setState({loading: false});
    }
  }


  acceptFriend = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"confirm_friend_id":e}    
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'friend/confirmFriendInList', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 
  
  
  rejectFriend = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"delete_friend_id":e}   
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'friend/deleteFriendInList', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 
  
  removeFriend = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"un_friend_id":e}   
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'friend/UnFriendRequest', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 

  
  acceptFollow = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"user_id":e}    
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'post/followAccepted', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 

  
  rejectFollow = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"user_id":e}    
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'post/followRejected', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 

  friendsList =  (cFriend) => {

    console.log("111111");
    console.log(cFriend);
       console.log("22222");
    const res = cFriend.length > 0 && cFriend.map((item) => {

           // item.email = item.email.replace(/.com/g,'');
           
            item.email = item.display_name;

      if(item.profile_image != '' && item.profile_image != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
       //var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
      // } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }
    

      return( 
        <li key={Math.random()}>
        
          <div className="product_name row">
          

          {/*<div className="friends-img"> <a href="" onClick={() => this.props.history.push('/people-profile/'+item._id)}> <img src={profileImg} alt="img" /></a> <p><a style={{"color" : "#212529"}} href="" onClick={() => this.props.history.push('/people-profile/'+item._id)}> {item.name}</a></p></div> */}
          <div className="col-md-6 col-3">
          <div className="friends-img"> <a href="" onClick={() => this.props.history.push('/people/'+item.email)}> <img src={profileImg} alt="img" /></a> <p><a style={{"color" : "#212529"}} href="" onClick={() => this.props.history.push('/people/'+item.email)}> {item.name}</a></p></div>
          </div>
           <div className="friends-action col-md-6 col-9">   <button type="button" class="btn btn-light" onClick={() => this.removeFriend(item._id)}>Remove</button>
           </div>                
          </div>
        
       
      
      </li>
      )                                   
    })  
    return <>{res}</>
  }
  


  
  inviteFriendsList =  (iFriend) => {
    const res = iFriend.length > 0 && iFriend.map((item) => {
    

                //item.email = item.email.replace(/.com/g,'');
                 item.email = item.display_name;

 if(item.profile_image != '' && item.profile_image != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }

      //console.log(item);

      return( 
        <li key={Math.random()}>
        
          <div className="product_name">
          {/*<div className="friends-img"> <a href="" onClick={() => this.props.history.push('/people-profile/'+item._id)}> <img src={profileImg} alt="img"  /></a> <p><a style={{"color" : "#212529"}} href="" onClick={() => this.props.history.push('/people-profile/'+item._id)}>{item.name}</a></p></div> */}
         
         <div className="friends-img"> <a href="" onClick={() => this.props.history.push('/people/'+item.email)}> <img src={profileImg} alt="img"  /></a> <p><a style={{"color" : "#212529"}} href="" onClick={() => this.props.history.push('/people/'+item.email)}>{item.name}</a></p></div> 

          <div className="friends-action">
          <button type="button" class="btn btn-primary" onClick={() => this.acceptFriend(item._id)}>Accept</button>
         <button type="button" class="btn btn-light" onClick={() => this.rejectFriend(item._id)}>Cancel Request</button>
          </div>
          </div>         
      </li>
      )                                   
    })  
    return <>{res}</>
  }
  


  
  followReqList =  (iFollow) => {
    const rest = iFollow.length > 0 && iFollow.map((item) => {
              //  item.email = item.email.replace(/.com/g,'');

                item.email = item.display_name;
                console.log("asdasdasdasdasdas");
                console.log(item.profile_image);

 if(item.profile_image != '' && item.profile_image != undefined && item.profile_image != "noimage.jpg"){
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }

      //console.log(item);

      return( 
        <li key={Math.random()}>
        
          <div className="product_name row">
          <div className="col-md-6 col-3">
          <a href="" onClick={() => this.props.history.push('/people/'+item.email)}>
          <div className="friends-img"> <img src={profileImg} alt="img"  /> <p>{item.name}</p></div> 
         </a>
         </div>
          <div className="friends-action col-md-6 col-9">
          <button type="button" class="btn btn-primary" onClick={() => this.acceptFollow(item._id)}>Accept</button>
         <button type="button" class="btn btn-light" onClick={() => this.rejectFollow(item._id)}>Cancel Request</button>
          </div>
          </div>         
      </li>
      )                                   
    })  
    return <>{rest}</>
  }



  render() {
    
    
   
    const { inviteFriendList, friendList,friendListCount,privateFollowList} = this.state;
  
   console.log(privateFollowList);
   //console.log('88888888888888888888888888888888888888');
  //console.log(friendList);
    return (
      <>
       <Metatitle title="Friends | aBlueC" metaDescription="Friends" ogTitle="Friends" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
      {/* <Search></Search>  */}
      {/* <Button></Button>     */}
     
      <div className="row">  

       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    

      <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="categories-slide-sec main_store checkout-main container">
        <div className="row justify-content-center"> 
        {inviteFriendList.length > 0 ?
        <div className="col-lg-6 col-md-6 col-xs-12">
       
          <div className="checkout-sec mb-3"> 
          <div class="card">
  <div class="card-header">
  Friends Invitations
  </div>
<div className="card-body">
<ul className="friends-list">

             {inviteFriendList.length > 0 ? this.inviteFriendsList(inviteFriendList):<></>} 
             </ul>
          
</div>

  </div>
        
          </div>
        
          
        </div>  :''}

        {privateFollowList.length > 0 ?
        <div className="col-lg-6 col-md-6 col-xs-12">
       
          <div className="checkout-sec mb-3"> 
          <div class="card">
  <div class="card-header">
  Follow Request
  </div>
<div className="card-body">
<ul className="friends-list">

             {privateFollowList.length > 0 ? this.followReqList(privateFollowList):<></>} 
             </ul>
          
</div>

  </div>
        
          </div>
        
          
        </div>  :''}
        <div className="col-lg-6 col-md-6 col-xs-12">
        <div className="checkout-sec"> 
          <div class="card">
  <div class="card-header">
 My Friends <small> ({friendListCount}) </small>
  </div>
<div className="card-body">
       <ul className="friends-list">
             {friendList.length > 0 ? this.friendsList(friendList):<></>} 
             </ul>
           
</div>

  </div>
        
          </div>
        </div>
        </div>
        

         
         
        </div>
      </div>
    </div>





    
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default Friends;