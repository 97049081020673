import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import React, { Component } from 'react';
import { useHistory, Redirect, BrowserRouter } from "react-router-dom";
// import imageExists from 'image-exists';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../../Loader';

// import React from "react";
import ReactDOM from "react-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import FormCredit from "../../components/demos/FormCredit";
import AfterpayClearpayMessage from "../../components/demos/AfterpayClearpayMessage";
import Metatitle from "../../components/metaTitle";
import CONSTANT from '../../Constant'; 
import _ from "lodash";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const stripePromise = loadStripe(CONSTANT.loadStripeKey);


class ActivitySubscription extends Component {
    constructor(props) {
        super(props);
		this.state = { keyValue:'',newloader: false, getListData: [], getCreditData:[], default_card: "", card_id:"",creditType:'',credit_type_id:'',showMsg:false, sentMsg: false,isOpen2 : false,isOpen3 : false, credit: "", getCheckSubscription: ""};

    }
	
	async componentDidMount() {		
		this.setState({newloader: true});	
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/listCards', formData, config);
		
		if(response.data.response_data != null){
			this.setState({default_card: response.data.response_data.default_card, getListData: response.data.response_data.data, newloader: false});
		} else {
			this.setState({newloader: false});
		}
		await this.getCheckSubscriptionExist();
		await this.getCreditType();
	}
	
	async getCreditType(){
		
		this.setState({newloader: true});	
		
		// var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}		
		// let response = await axios.post(CONSTANT.STABASE_URL+'store/checkCreditScore', formData, config);
		// this.setState({getCreditData: response.data.response_data.creditType, newloader: false});
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/subscriptionActivityProductPrice', formData, config);
        
		if(response.data.response_data != null){
			var filterData = _.filter(response.data.response_data, function(item) {
				return item.name == 'Activity Plan';
			});

			this.setState({getCreditData: filterData, newloader: false});
		} else {
			this.setState({newloader: false});
		}
	}
	
	async getCheckSubscriptionExist(){
		
		if(localStorage.getItem("store_id") == undefined){
			this.props.history.push('/vendor/myStores')
			return false;
		}
		
		this.setState({newloader: true});	
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}
		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/checkActivitySubscriptionExist', formData, config);
		
		if(response.data.response_data.status == false){
			this.setState({getCheckSubscription: 0, newloader: false});
		} else {
			this.setState({getCheckSubscription: 1, newloader: false});
		}
	}
	
	onChange = e => {
        this.setState({ credit_type_id: e.target.value, showMsg: false, "keyValue": e.target.options[e.target.selectedIndex].text  });
    };
	
	async setMakeDefaultPayment(e){
		//console.log(this.state.keyValue.split(' ').slice()[1]); return false
		if(this.state.credit_type_id == ""){
			
			this.setState({showMsg: true});
			
		} else {
			
			this.setState({newloader: true});
			
			// var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id"),"credit_type_id":this.state.credit_type_id}		
			// let response = await axios.post(CONSTANT.STABASE_URL+'store/paymentCreditScoreByCard', formData, config);
			
			var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id"),"price_id":this.state.credit_type_id,'quantity':this.state.keyValue.split(' ').slice()[1]}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'store/addActivitySubscription', formData, config);
			
			if(response.data.response_status == 1){
				const store_name = localStorage.getItem("store_name");
				this.setState({newloader: false, sentMsg: true, isOpen2: true});
                //setTimeout(function(){ document.location.href = "/vendor/store/"+store_name; }, 3000);	
				setTimeout(function(){ document.location.href = "/vendor/store/store-activity"}, 3000);	
                
			} else {
				this.setState({newloader: false, sentMsg: true, isOpen3: true});				
			}				
		}			
	}
	
	async setMakeDefault(e){
		
		this.setState({newloader: true});		
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: e}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/makecarddefault', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.setState({newloader: false});		
		}
		
	}
	
	async confirmCard(e){		
		this.setState({newloader: true, card_id: e});		
		this.setState({newloader: false});
		$("#showDeletePopup").css({"display":"block"})
	}
	
	async closeCardpopup(e){		
		this.setState({newloader: true});
		this.componentDidMount();
		$("#showDeletePopup").css({"display":"none"})
		this.setState({newloader: false});
	}
	
	async deleteCard(e){
		this.setState({newloader: true});	
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: this.state.card_id}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/deleteCard', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.closeCardpopup(1);
			this.setState({newloader: false});		
		}		
	}
	
	addCardModal = e =>{
		this.setState({
			isOpen3:true
		}) 
	}
	
	hidePopup(){
		this.setState({
			isOpen3:false,
		})
	}
		
	render() {
		
		const { newloader = true, getListData, default_card, getCreditData, getCheckSubscription } = this.state;
		
		return (
			<>
				<Metatitle title="Store Activity Subscription | aBlueC" metaDescription="Store Activity Subscription" ogTitle="Store Activity Subscription" ogImage=""></Metatitle>
				{(this.state.newloader)? (<Loader />): ""}
				<Header data="inner-head"></Header>
				<div className="container-fluid bdr-top innerheight">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-xs-12">
							<div class="categories-slide-sec main_store checkout-main container">
							<div className="checkout-sec">
								<div className="card">
									<div className="card-header">Subscription</div>
										<div className="card-body">	
										<div className="row">							
											<div className="col-md-12">
												
												<div className="Demo">
													{(this.state.loading)? (<Loader />): ""}
													{(getListData.length != 0) ? 
															<>
																<div className="full_wrp">
																		<ul className="added-card-list">
																		{getListData.length > 0 && getListData.map((item) =>	
																			<li className="list-group-item People">
																			{(item.id.toString() == default_card.toString())?
																			(<>
																			<span className="brand_img"> 
																			<input type="radio" name="color" value="orange" checked />
																			{(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
																			</span>
																			</>)
																			:
																			(<><span className="brand_img"> <input type="radio" name="color" value="orange" onClick={() => this.setMakeDefault(item.id)} />
																			{(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
																			</span>
																			</>)
																			}
																			<span className="removecard" onClick={() => this.confirmCard(item.id)}>X</span>
																			<div className="cardnumber">
																			<label>Card : {'*************'+item.last4} <span className="cardlistStyle">{item.brand}</span> <span> Expires on :  {item.exp_month} / {item.exp_year} </span></label>
																			</div>
																			{(item.id.toString() == default_card.toString() && localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "" && getCheckSubscription == 0)?
																			<label>
																				<select className="form-control" id='credit_type_id' value={this.state.credit_type_id} onChange={this.onChange}>
																					<option value=''>Select Plan For Subscription</option>
																					{getCreditData.length > 0 && getCreditData.map((item) =>
																					<option key={(item.amount/100)} value={item.id}>{'Top'+' '+item.nickname} - {+' '+item.interval_count+' '+item.interval} - {"$"+item.amount/100+' '+item.currency.toUpperCase()}</option>
																					)}  
																				</select>															
																				<p style={{"color": "red"}}>{(this.state.showMsg)? (<>Plaese Select Subscription Plan First!!</>): (<></>)}</p>
																			</label>
																			:
																			(<></>)
																			}
																			{(item.id.toString() == default_card.toString() && localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "" && getCheckSubscription == 0)?
																			(<><div className="paynow"><button type="button" onClick={() => this.setMakeDefaultPayment(item.id)}>Pay Now</button></div></>)
																			:
																			(<></>)
																			}
																			</li>
																		)}
																	</ul>
																</div>
																<br/>
															</>
															: 
															<>
															  {/* <p>  No Data Found  </p>*/ }
															</> 
														}
											</div>
											
										</div>	
										<div className="col-md-12">
											
										<div className="checkout_bt mt-4 mb-0">         
												  <button type="button" className="btn btn-secondary" id="addCardM" onClick={() => this.addCardModal({"order_data":2222})} >AddCard</button>
												</div>
												</div>
												</div>
										
									</div>				
								</div>				
							</div>
							</div>
						</div>
					</div>
				</div>
				<div className="overlay-addstore" id="showDeletePopup" style={{"display": "none"}}>
					<div className="addstore-main" style={{"background": "none", "margin": "1rem auto"}}>
						<div className="modal-dialog modal-dialog-centered" role="document">
						  <div className="modal-content confirmpopup">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeCardpopup(1)}>
							  <span aria-hidden="true" className="delete_yes_no">×</span>
							</button>
							<div className="modal-body sure_delete_wrp">
							  <h5 id="delete-title">Are you sure you want to delete it?</h5>
							  <div className="post_bt_public cancel_order">
								<ul>
								  <li>
									<button type="button" className="save_bt" onClick={() => this.deleteCard(1)}>YES</button>
								  </li>
								  <li>
									<button type="button" className="back_bt reject_bt" onClick={() => this.closeCardpopup(1)}>NO</button>
								  </li>
								</ul>  
							  </div>
							</div>
						  </div>
						</div>
					</div>
				</div>
				<Modal id="modal_body_clone_store" show={this.state.isOpen3} onHide={this.hidePopup.bind(this)} backdrop = "static"> 
					<Modal.Body>
						<button type="button" className="close store post-close" onClick={this.hidePopup.bind(this)} style={{"position":"relative"}}><span aria-hidden="true">×</span></button>
						<div className="manage-add-products"> 
							<div className="modal-body">
								<div class="card-header">    Add New card  </div> 
								<Elements stripe={stripePromise}>
                                <FormCredit />
								</Elements>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Footer></Footer>
			</>
		);
    }
}
 
export default ActivitySubscription;