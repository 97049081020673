import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import React, { Component } from 'react'; 
import{ Redirect } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import axios from 'axios';
import qs from 'qs';
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {loginUser,clearErrors} from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
import CONSTANT from '../../Constant';

class Login extends Component {
       
    constructor() {
        super();
		const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
        this.state = {
			loggedIn,
            password: "",
            errors: {},
			email: "",
			emailError:'',
            passwordError:'',
            latitude: 28.541397,
            longitude: 77.397034,
			cls:'',
			loginStatus: 'ok',
        };
		this.onChange = this.onChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
		const queryString = require('query-string');
		const parsed = queryString.parse(this.props.location.search);
		if(parsed.token == "no"){
			this.setState({
				successmessage:"Please Fill Valid Data..."
			})
		} else if(parsed.register == 1){
			this.setState({
				successmessage:"Please login to continue.",
				email: localStorage.getItem("email")
			})
		} else if(parsed.register == 0){
			this.setState({
				successmessage:"Please Fill Valid Data...",
				email: localStorage.getItem("email")
			})
		} else {
			this.setState({
				successmessage:"Please login to continue."
			})
		}
		
        if (this.state.loggedIn) {
            var cls = "alert alert-success";
        }else{
            cls = "alert alert-danger";
        }
        this.setState({
            cls
        })
        if (this.state.loggedIn) {
			this.props.history.push("/");
        }
    };
	componentWillUnmount() {
		this.props.clearErrors();
	}
   
	onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    submitForm(e){
		e.preventDefault();
		const isValid = this.validate();
		if(isValid){
			this.setState({ 
                successmessage:"Please wait..."
            })
			const userData = {
				email: this.state.email,
				password: this.state.password,
				lat:this.state.latitude,
				long:this.state.longitude,
				device_id: "123",
				device_token: "abcdE",
				device_type: 1,
			};	

            axios.post(CONSTANT.STABASE_URL+"users/login", userData, {headers: { "x-access-token": CONSTANT.HEADER }}).then(res => {

                if (res.data.response_status === 0) {
                    this.setState({ 
                        successmessage:res.data.response_msg,
                        loggedIn:false,
                    })
                } else {
                    //console.log(res.data.response_data);
                    localStorage.setItem("token",res.data.response_data.session_token);
                    localStorage.setItem("email",res.data.response_data.profile.email);
                    localStorage.setItem("loggedInUserId",res.data.response_data.profile._id);
                    localStorage.setItem("loggedInUser_thumb_profile_image",res.data.response_data.profile.profile_image);
                    localStorage.setItem("loggedInUserName",res.data.response_data.profile.name);

                    //console.log(res);


                    this.setState({
                        message:"Logged in successfully",
                        loggedIn:true,
                        progress:"Please wait...",
                    }) 
                   // window.location.reload();   
                
                }
                

            },
            (error) => { 
                this.setState({ 
                    successmessage:"Please provide valid login credential."
                })
            });

			// var data = this.props.loginUser(userData);	
			// console.log("mmmmmmmmmmm")
			// console.log(data)
			// console.log("mmmmmmmmmmm")
			// this.setState({
			// 	message:"Logged in successfully",
			// 	loggedIn:true,
			// 	progress:"Please wait...",
			// })			
		}       
		  
    }
	
	validate = () =>{
        let emailError = '';
        let passwordError = '';
        if(!this.state.email){
            emailError = "email field can not be blank.";
        }
        if(!this.state.password){
            passwordError = "Password field can not be blank.";
        }
        if(emailError || passwordError){
            this.setState({emailError,passwordError});
            return false;
        } else {
            this.setState({emailError,passwordError});
            return true;
        }
    }
    


    render() {
		
		if(this.state.loggedIn){ 
            return <Redirect to="/" />		
        }

        return (
			<>
            <Metatitle title="Login | aBlueC" metaDescription="Login" ogTitle="Login" ogImage=""></Metatitle>
			<Header />
			<section id="login">
                <div id="feed_wrapper_section" className="register_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="login-user-wrapper">
                                    <div className="login-box">  
                                    <h5>Login</h5>                                    
                                    <form className="flp" onSubmit={this.submitForm}>
                                        {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
                                        <div className="form-group">          
                                            <input autoComplete="email"
                                            className="form-control" 
                                            type="text" name="email" 
                                            id="email" placeholder="Email" 
                                            value={this.state.email} onChange={this.onChange}/>
                                            <small id="emailHelp" className="form-text text-muted_error_message">{this.state.emailError}</small>  
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="password" name="password" id="password" placeholder="Password" value={this.state.password} onChange={this.onChange}/>
                                            <small className="form-text text-muted_error_message">{this.state.passwordError}</small>
                                        </div>
                                        <div className="post_bt_public register_login">
                                            <ul>
                                            <li>
                                                <button type="submit" className="save_bt">Login</button>
                                            </li>
                                            </ul>
                                        </div>
                                        <div className="forgot-pass"> <a href="/forgot-password">Forgot your password?</a> </div>
										<div className="forgot-pass"> <a href="/signup">Sign up</a> </div>
                                    </form>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
				<div className="shape-bottom"><svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 className="svg replaced-svg">
						<title>sApp Shape</title>
						<desc>Created with Sketch</desc>
						<defs></defs>
						<g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
								<path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
									id="sApp-v1.0">
									</path>
							</g>
						</g>
					</svg>
				</div>
            </section>
			<Footer />
			</>
        );
    }

}


Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser,clearErrors }
)(Login);