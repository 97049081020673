import Header from './partials/Header'
import Footer from './partials/Footer';
import InnerPages from './Pages/InnerPages';
import React, { Component } from 'react';
// import imageExists from 'image-exists';
import Loader from '../Loader';
import CONSTANT from '../Constant'; 
import axios from 'axios';
import qs from 'qs';
import Modal from "react-bootstrap/Modal";
import { Multiselect } from 'multiselect-react-dropdown';
import { createAcademic,deleteAcademic,createExprience,deleteExperience,getAllCategory,getSubCategory,createSkill,editSkill,deleteSkill,deleteAttachments} from '../components/Api'; 
import PlacesAutocomplete, { geocodeByAddress,getLatLng,geocodeByPlaceId  } from 'react-places-autocomplete';
import $ from "jquery";
import OwlCarousel from 'react-owl-carousel';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';
import ShowMoreText from 'react-show-more-text';
import closeRedImg from "../close-red.png"
import likeSvg from "../like.svg";
import unlikeSvg from "../unlike.svg";
import chatCommentSvg from "../chat_comment.svg";
import shareSvg from "../share.svg";

import post_btn_1 from "../post-btn-1.png";
import post_btn_2 from "../post-btn-2.png";
import logo_not_post from "../logo-not-post.png";
import VideoThumbnail from 'react-video-thumbnail';
import TextareaAutosize from 'react-textarea-autosize';
import DocViewer from "react-doc-viewer";
window.jQuery = $;
window.$ = $;
global.jQuery = $;



class ProfileUser extends Component {
    constructor(props) {
        super(props);		
		this.state = {
			profileData: {},
            friendData: [],
            postData: [],
            skillData: [],
            experienceData: [],
			academicsData: [],
			getprofileInfofromuserid:[],
			response_status: "",
			name:'',
            display_name:'',
            email:'',
            mobile:'',
            about:'',
			pageUrl: "",
			imageURL:'',
			images:'',
			cover_letter:'',
			cover_letter_url:'',
			showEditProfile:false,
			showAddress:false,
			flatno : "",
			street : "",
			city : "",
			state : "",
			zip : "",
			country : "",
			complete_address : "",
			address:'',
			showAcademic:false,
			college_or_school_name:'',
            title:'',
            start_year:'',
            end_year:'',
			persuing:'',
			showExperience:false,
			experience_id:'',
            company:'',
			description:'',
			categoryOptions:'',
			subcategoryOptionss:'',
			showSkill:false,
			experience:'',
			otherdata:'',
			category_id:'',
			sub_category_id:[],
			valueother:"hidden",
			showField: false,
			opt:{responsive: {

				0: {
				  items: 1,
				},
				450: {
				  items: 1,
				},
				600: {
				  items: 1,
				},
				1000: {
				  items: 1,
				},
			  }},
			};
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		
    }
	
	async componentDidMount() {
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		}
		this.setState({loading: true});	
		//let {name} = this.props.match.params
		const user_id = localStorage.getItem("loggedInUserId");

		//console.log(this.props.match.params);
		//getData = await this.getData(name)	
		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':CONSTANT.HEADER
			}
		}	
		//'1630312292142'	//localStorage.getItem("token")
			let res = await axios.post(CONSTANT.STABASE_URL+'post/getpostListsProfile',{session_token:localStorage.getItem("token"),post_time_zone:'Asia/Kolkata',page:1},config);	


console.log("33333");
console.log(res.data.response_data.getprofileInfo);
console.log("55555");
			this.setState({loading: false});	
			if(res.data.response_data.getprofileInfo.address == undefined){
				res.data.response_data.getprofileInfo.address = ''
			}


		this.setState({
			profileData:res.data.response_data.getprofileInfo, 
			friendData: res.data.response_data.getFriendData,
			postData:(res.data.response_data.postData !== undefined) ? res.data.response_data.postData:'',
			skillData: res.data.response_data.getprofileInfo.addSkills,
            experienceData: res.data.response_data.getprofileInfo.addExperiences,
			academicsData: res.data.response_data.getprofileInfo.addAcademic,
			name:res.data.response_data.getprofileInfo.name,
            display_name:res.data.response_data.getprofileInfo.display_name,
            about:res.data.response_data.getprofileInfo.about,
            mobile:res.data.response_data.getprofileInfo.mobile,
			email:res.data.response_data.getprofileInfo.email,
			imageURL:res.data.response_data.getprofileInfo.resume ? CONSTANT.NSTABASE_URL+'images/post/'+res.data.response_data.getprofileInfo.resume:CONSTANT.NSTABASE_URL+'images/profile/noimage.png',
			cover_letter_url:res.data.response_data.getprofileInfo.cover_letter ? CONSTANT.NSTABASE_URL+'images/post/'+res.data.response_data.getprofileInfo.cover_letter:CONSTANT.NSTABASE_URL+'images/profile/noimage.png',
			flatno:res.data.response_data.getprofileInfo.address.flatno, street:res.data.response_data.getprofileInfo.address.street,
				 city:res.data.response_data.getprofileInfo.address.city, 
				 state:res.data.response_data.getprofileInfo.address.state, 
				 zip:res.data.response_data.getprofileInfo.address.zip, 
				 country:res.data.response_data.getprofileInfo.address.country, 
				 complete_address:res.data.response_data.getprofileInfo.address.complete_address,
				 address:res.data.response_data.getprofileInfo.address.complete_address,
		});		
		await this.categoryList({'session_token':localStorage.getItem("token")});
	}

	
	handleImageError = e => {
		// imageExists(e, function(exists) {
			// if (exists) {
				// return e;
			// }
		// });		
	}
	
	handleUsernameChange = async e => {
		
		if (e.target.files && e.target.files[0]) { 
			// this.setState({
			// 	//imageURL: URL.createObjectURL(e.target.files[0]),
			// 	images:e.target.files[0]
			// });

			var formData = new FormData();
			formData.append("resume", e.target.files[0]);
			formData.append('session_token', localStorage.getItem("token"));
			formData.append('user_id', localStorage.getItem("loggedInUserId"));

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}	
			
			let response = await axios.post(CONSTANT.STABASE_URL+'post/uploadResume', formData, config)
			if(response.data.response_status != 0){
			//console.log(response.data.response_data);
			//this.setState({imageURL:CONSTANT.NSTABASE_URL+'images/post/'+response.data.response_data.resume});
			await this.componentDidMount()
			}
		}
		
		this.setState({ [e.target.id]: e.target.value});
		
	}
	
	handleCoverLetterChange = async e => {
		
		if (e.target.files && e.target.files[0]) { 
			// this.setState({
			// 	//imageURL: URL.createObjectURL(e.target.files[0]),
			// 	images:e.target.files[0]
			// });

			var formData = new FormData();
			formData.append("cover_letter", e.target.files[0]);
			formData.append('session_token', localStorage.getItem("token"));
			formData.append('user_id', localStorage.getItem("loggedInUserId"));

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}	
			
			let response = await axios.post(CONSTANT.STABASE_URL+'post/uploadCoverLetter', formData, config)
			if(response.data.response_status != 0){
			//console.log(response.data.response_data);
			//this.setState({imageURL:CONSTANT.NSTABASE_URL+'images/post/'+response.data.response_data.resume});
			await this.componentDidMount()
			}
		}
		
		this.setState({ [e.target.id]: e.target.value});
		
	}

	handleProfileChange = async e => {
		
		if (e.target.files && e.target.files[0]) { 
			// this.setState({
			// 	//imageURL: URL.createObjectURL(e.target.files[0]),
			// 	images:e.target.files[0]
			// });

			var formData = new FormData();
			formData.append("profile_image", e.target.files[0]);
			//formData.append("thumb_profile_image",e.target.files[0]);
			formData.append('session_token', localStorage.getItem("token"));
			// formData.append('user_id', localStorage.getItem("loggedInUserId"));

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}	
			
			let response = await axios.post(CONSTANT.STABASE_URL+'post/profileImage', formData, config)
			if(response.data.response_status != 0){
			//console.log(response.data.response_data);
			//this.setState({imageURL:CONSTANT.NSTABASE_URL+'images/post/'+response.data.response_data.resume});
			await this.componentDidMount()
			}
		}
		
		this.setState({ [e.target.id]: e.target.value});
		
	}


	


	handleChangeAddress = (address) => {
		this.setState({ address:address });
	}

	onChangePersonal =(e) => {
		this.setState({ [e.target.name]: e.target.value});
	}
	onChange=(e) => {
		this.setState({ [e.target.name]: e.target.value});
	}
	
	handleSelect = async (address,placeId) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const [place] = await geocodeByPlaceId(placeId);
		const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
		const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
		const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
		const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
		const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
		const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
		const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
		var cityNames='';
		if(cityNameNeighbour) cityNames=cityNameNeighbour;else if(cityNameLocality)cityNames=cityNameLocality;else{cityNames=cityName;}
		this.setState({address: address, complete_address: address,userAddLat:latLng.lat,userAddLong:latLng.lng,state:stateName,city:cityNames ,zip:postalCode,country:countryName,street:streetNumber+' '+routesName})
	};

	async updateAddress(event){
		const isValid = await this.validateAddress();
		   if(isValid){
			this.setState({loading:true});
		
			
		var headers = {'x-access-token': CONSTANT.HEADER}
		var updateAddress = {
			"session_token":localStorage.getItem("token"),
			"flatno" : this.state.flatno,
			"street" : this.state.street,
			"city" : this.state.city,
			"state" : this.state.state,
			"zip" : this.state.zip,
			"country" : this.state.country,
			"complete_address" : this.state.complete_address,
			"lat":this.state.userAddLat,
			"long":this.state.userAddLong
		}
		let getUpdateAddress = await axios.post(CONSTANT.STABASE_URL+"users/saveAddress", updateAddress,{headers: headers});
		if(getUpdateAddress.data.response_status === 1){
			this.setState({loading:false, showAddress:false,smsg:getUpdateAddress.data.response_msg});
		} else {
			this.setState({loading:false, emsg:getUpdateAddress.data.response_msg});
		}
		
		await this.componentDidMount();
	}
	}


  
	validateAddress = () =>{

		let addressError = '';
		let cityError = '';
		let stateError = '';
		let zipError = '';
		let countryError = '';
		
		
		if(!this.state.address){
			addressError = "Address field is required.";
		}
		if(!this.state.address){
			cityError = "City field is required.";
		}
		if(!this.state.address){
			stateError = "State field is required.";
		}
		if(!this.state.address){
			zipError = "Zip field is required.";
		}
		if(!this.state.address){
			countryError = "Country field is required.";
		}
	   
		if(addressError){
			this.setState({addressError});
			return false;
		} else {
			this.setState({addressError});
			return true;
		} 
	}  

	

	 onSubmitPersonal = async (event) =>{
		 event.preventDefault();
		 const isValid = await this.validatePersonal();
		   if(isValid){
			this.setState({loading:true});
		
		// var formData = new FormData();
		// formData.append("name", this.state.name);
		// formData.append("email", this.state.email);
		// formData.append("mobile", this.state.mobile);
		// formData.append('session_token', localStorage.getItem("token"));
		// formData.append('display_name', this.state.display_name);
		// formData.append('about', this.state.about);
		var formData = {
			"session_token":localStorage.getItem("token"),
			"phone":this.state.mobile,
			"name":this.state.name,
			"email": this.state.email,
			"display_name":this.state.display_name,
			"about":this.state.about
		}	

		const config = {
			headers: {
				
			  'x-access-token':CONSTANT.HEADER
			}
		  }	

		let getProfile = await axios.post(CONSTANT.STABASE_URL+"users/editProfile", formData,config);
		if(getProfile.data.response_status === 1){
			this.setState({loading:false, showEditProfile:false,smsg:getProfile.data.response_msg});
		} else {
			this.setState({loading:false, emsg:getProfile.data.response_msg});
		}
		
		await this.componentDidMount();
	}
	}

	validatePersonal = () =>{

		let nameError=''; 
		let displayNameError = '';
		let mobileError = '';
		let emailError ='';
		
		
		if(!this.state.name){
			nameError = "Name field is required.";
		}
	
		if(!this.state.display_name){
			displayNameError = "Display Name field is required.";
		}else if(this.state.display_name.toLowerCase().includes("abluec")){
			displayNameError = "You cannot use aBlueC in display name.";
		}
		if(!this.state.mobile){
			mobileError = "Mobile field is required.";
		}
	
		if(!this.state.email){
			emailError = "Email field is required.";
		}
		if(nameError || displayNameError || mobileError || emailError ){
			this.setState({nameError,displayNameError,mobileError,emailError});
			return false;
		} else {
			this.setState({nameError,displayNameError,mobileError,emailError});
			return true;
		} 
	}  

	
	
	truncate(source, size) {
		return source.length > size ? source.slice(0, size - 1) + "…" : source;
	}

	showPopupEditProfile = () =>{
        this.setState({
            showEditProfile:true
        })    
	}
	
	closePopupEditProfile = () =>{
        this.setState({
            showEditProfile:false
        })    
	}


	

	
	
	showPopupAddress = () =>{
        this.setState({
            showAddress:true
        })    
	}
	
	closePopupAddress = () =>{
        this.setState({
            showAddress:false
        })    
    }
	
	 Address = (addParms) => {
		//console.log(addParms);
			 return (
				<div className="ex_inner_list">
				<ul>
                    <li>Flat: <span>{addParms.flatno}</span></li>                
                    <li>Street: <span>{addParms.street}</span></li>
                    <li>Country: <span>{addParms.country}</span></li>
                    <li>State: <span>{addParms.state}</span></li>
                    <li>City: <span>{addParms.city}</span></li>
                    <li>Zip: <span>{addParms.zip}</span></li>
                    </ul>
					
					
				</div>
			
			 )
	
	}

	 AcademicList = (acdParam) => {
		const options = acdParam.map(r => (
			<div className="col-md-6" key={Math.random()}>
				<div className="ex_inner_list">
					<div>
					<h2>{r.title} <span className="edit_profile float-right"> <img className="delete-user-profile" src="/assets/images/delete-post.png" onClick={() => this.onDeleteAcademic(r._id)}/></span></h2>
					
					</div>
					<ul className="how-experience ex_list-view">
					<li>
						<div className="user-pro"><label className="address_user">College Name:</label><span>{r.college_or_school_name}</span></div>
					</li>
				</ul>
				<div className="left_diaply">	
				<ul className="how-experience ex_list-view">
					
						<li><div className="user-pro"><label className="address_user">Start Year:</label><span>{r.start_year}</span></div></li>
						<li><div className="user-pro"><label className="address_user">Pursuing:</label><span>{r.persuing ==1 ? 'Yes':'No'} </span></div></li>
				</ul>
				</div>
				<div className="right_diaply">
				<ul className="how-experience ex_list-view">							
						<li><div className="user-pro"><label className="address_user">End Year:</label><strong></strong><span>{r.end_year}</span></div></li>
						
					
				</ul>
				</div>
				  
				</div>
			</div>
		))
		return options;
	}

	showPopupAcademic = () =>{
        this.setState({
            showAcademic:true
        })    
	}
	
	closePopupAcademic = () =>{
        this.setState({
            showAcademic:false
        })    
	}

pursuingCheck = e => {
        
    if (e.target.checked) {
        console.log(e.target.checked)
        this.setState({
            persuing:1
        })
    } else {
        this.setState({
            persuing: 0
        })
    }
}
	yearList(){
		const yOptions = [];
		for (var y = new Date().getFullYear(); y >= 1980; y--) {
	
			yOptions.push(y);
		}
		return (  
			yOptions.map(yoption => ( 
			<option value={yoption}>{yoption}</option>
			))
		);
	}
	onSubmitAcademic =  e => {
		e.preventDefault();
		const isValid =  this.validateAcademic();
		if(isValid){
			this.setState({  
			  loading:true
			}) 
		//var userData = new FormData();
		
		
		const academicData = {
			title: this.state.title,
			college_or_school_name: this.state.college_or_school_name,
			session_token:localStorage.getItem("token"),
			start_year:this.state.start_year,
			end_year:this.state.end_year,
			persuing:this.state.persuing,
	  };
		
	  //this.createSkill(skillData)
	
	
	 createAcademic(academicData).then(async data => {
		if (data.response_status === 0) {
			this.setState({ 
				successmessage:data.response_msg,
				cls:"alert alert-danger",
				loading:false,
				
			})
		}else{
			this.setState({
				//successmessage:"Academic added successfully",
				loading:false,
				showAcademic:false,
				cls : "alert alert-success"
			}) 
			   // return <Redirect to="/search"/>   
			   //await this.componentDidMount();
			   window.location.reload();
		}
			
		});
		}
	  
	};

	onDeleteAcademic =  async (e) => {
		this.setState({  
			loading:true
		  }) 
		const academicData = {
			session_token:localStorage.getItem("token"),
			academic_id:e,
	  };
		deleteAcademic(academicData).then(async data => {
			if (data.response_status === 0) {
				this.setState({ 
					successmessage:data.response_msg,
					cls:"alert alert-danger",
					loading:false,
					
				})
			}else{
				this.setState({
					//successmessage:"Academic deleted successfully",
					loading:false,
					showAcademic:false,
					cls : "alert alert-success"
				}) 
				   // return <Redirect to="/search"/>   
				   await this.componentDidMount();
				   //window.location.reload();
			}
				
			});

	}

	

validateAcademic = () =>{

    let aTitleError=''; 
    let aSchoolError = '';
    let aStartYearError = '';
    let aEndYearError = '';
    //console.log(this.state.category_id);
    if(!this.state.title){
        aTitleError = "Title field is required.";
    }

    if(!this.state.college_or_school_name){
        aSchoolError = "College/School Name field is required.";
    }
    if(!this.state.start_year){
        aStartYearError = "Please Select Start Year.";
    }

    if(!this.state.end_year){
        aEndYearError = "Please Select End Year.";
    }

    if(aTitleError || aSchoolError || aStartYearError || aEndYearError){
        this.setState({aTitleError,aSchoolError,aStartYearError,aEndYearError});
        return false;
    } else {
        this.setState({aTitleError,aSchoolError,aStartYearError,aEndYearError});
        return true;
    } 
}   

	 SkillsList = (skillParam) => {
		const options = skillParam.map(r => (
			<div className="col-md-6" key={Math.random()}>
				<div className="ex_inner_list">
					<h2>{r.categoryName} <span className="edit_profile float-right"> <img className="delete-user-profile" src="/assets/images/delete-post.png" onClick={() => this.onDeleteSkill(r._id,r.randNum)}/></span></h2>
					<div>
					<h2>{r.title} </h2>
					
					</div>

					
					<ul className="how-experience">
						<li>
							<span>{
								
								(r.subCat[0].name=="Other") ? r.subCat[0].otherdata :r.subCat[0].name }</span>
 							
						</li>
					</ul>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group-1 row">
								<label htmlFor="inputPassword" className="col-sm-7 col-form-label Service_label">
									Service Experience
								</label>
								<div>
									{r.subCat[0].experience}
								</div>
								{/* <div className="col-sm-5 pd-remove">
									<input
										type="password"
										className="form-control month-exp"
										id="inputPassword"
									/>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
		return options;
	}


	showPopupSkils = () =>{
        this.setState({
            showSkill:true
        })    
	}
	
	closePopupSkils = () =>{
        this.setState({
            showSkill:false
        })    
	}
	// onSelect = e => {
	// 	this.setState({sub_category_id:e});
	//    };
	   
	   onSelect = (selectedList, selectedItem) => {
		var filterArray = []
		selectedList.filter((item)=>{
		filterArray.push(item._id)
		});
		this.setState({sub_category_id:filterArray});
	}
	

    onRemove(selectedList, removedItem) {
       }
	categoryList = async (token) => { 
		await getAllCategory(token).then(data => {
		if(data.response_data.length>0){
			  let catData = data.response_data.map((cat,index) => {  
			  return (   
				<option value={cat._id}>{cat.name}</option>
						); 
				})
				this.setState({
					categoryOptions:catData, 
				  })  
			  
			  } else {
				this.setState({
					categoryOptions:'', 
				  
				})   
			  }
		});
	  }


	  onChangeCategory = async e => {

	  	this.setState({"valueother":"hidden"});

        this.setState({'category_id':e.target.value});
          
        const catData = {
            "category_id": e.target.value,
            "session_token":localStorage.getItem("token")
          };
         await getSubCategory(catData).then(data => {
            if(data.response_data.subCategories.length>0){
				
                       this.setState({
                         subcategoryOptionss:data.response_data.subCategories, 
                      
                     }) 
                  } else {
                    this.setState({
                        subcategoryOptionss:[], 
                      
                    })   
                  }
                
            });
	  };

	  onChangeSubCat = e =>{

		var filterArray = []
		
		filterArray.push(e.target.value)
		console.log("oooooooooooooooooo");
				console.log(e.target.name);
console.log(e.target.value);
		console.log("99999999999999999999");

		this.setState({"valueother":"hidden"});
		

		this.setState({"showField":true});

		this.setState({[e.target.getAttribute('rel')]: e.target.value});

		if(e.target.value=="5f89785791c010591fdb682e"){
		
		this.setState({"showField":true});
		}else{
			this.setState({"showField":false});
		}
		this.setState({sub_category_id:filterArray});
	  }
	  
	  
	  validateSkill = () =>{

		let catError=''; 
		let subCatError = '';
		//console.log(this.state.category_id);
		if(!this.state.category_id){
			catError = "Please Select Category.";
		}
	
		if(!this.state.sub_category_id){
			subCatError = "Please Select SubCategory.";
		}
	
		if(catError || subCatError){
			this.setState({catError,subCatError});
			return false;
		} else {
			this.setState({catError,subCatError});
			return true;
		} 
	}   
	
	onSubmitSkills = async e => {
		e.preventDefault();
		const isValid = await this.validateSkill();
		if(isValid){
			this.setState({  
			  loading:true
			}) 
		//var userData = new FormData();
		
		
		const skillData = {
			categoryId: this.state.category_id,
			//subCategoryId: JSON.stringify(this.state.sub_category_id),
			subCategoryId: this.state.sub_category_id,
			experience:this.state.experience,
			otherdata:this.state.otherdata,
			session_token:localStorage.getItem("token")
	  };
		
	//console.log('hhhhhhhhhhh');
	
	//console.log(skillData); return
	  //this.createSkill(skillData)
	
	
	   createSkill(skillData).then( async data => {
		if (data.response_status === 0) {
			this.setState({ 
				successmessage:data.response_msg,
				cls:"alert alert-danger",
				loading:false,
			})
		}else{
			this.setState({
				//successmessage:"Skill added successfully",
				showSkill:false,
				loading: false,
				cls : "alert alert-success"
			}) 
			   // return <Redirect to="/search"/>   
			   //await this.componentDidMount();
			   window.location.reload();
		}
			
		});
		}
	  
	};

	onDeleteSkill =  async (e,rNo) => {
		this.setState({  
			loading:true
		  }) 
		const skillData = {
			session_token:localStorage.getItem("token"),
			_id:e,
			randNum:rNo
	  };

//console.log(skillData); return

		deleteSkill(skillData).then(async data => {
			if (data.response_status === 0) {
				this.setState({ 
					successmessage:data.response_msg,
					cls:"alert alert-danger",
					loading:false,
					
				})
			}else{
				this.setState({
					//successmessage:"Skill deleted successfully",
					loading:false,
					showSkill:false,
					cls : "alert alert-success"
				}) 
				   // return <Redirect to="/search"/>   
				   await this.componentDidMount();
				   //window.location.reload();
			}
				
			});

	}



	ExperienceList = (expParam) => {
		const options = expParam.map(r => (
			<div className="col-md-6" key={Math.random()}>
				<div className="ex_inner_list">
					<h2>{r.company} <span className="edit_profile float-right"> <img className="delete-user-profile" src="/assets/images/delete-post.png" onClick={() => this.onDeleteExperience(r._id)}/></span></h2>
					<div>
					<h2>{r.title}</h2>					
					</div>
					<ul className="how-experience ex_list-view">
						<li>
							<span>{r.description}</span>
						</li>
					</ul>
				</div>
			</div>
		))
		return options;
	}
	showPopupExperience = () =>{
        this.setState({
            showExperience:true
        })    
	}
	
	closePopupExperience = () =>{
        this.setState({
            showExperience:false
        })    
	}

	onSubmitExprience =  e => {
		e.preventDefault();
		const isValid =  this.validateExprience();
		if(isValid){
			this.setState({  
			  loading:true
			}) 
		//var userData = new FormData();
		

	  const exprienceData = {
        company: this.state.company,
        description: this.state.description,
        session_token:localStorage.getItem("token"),
       
        };
	  //this.createSkill(skillData)
	
	
	  createExprience(exprienceData).then(async data => {
		if (data.response_status === 0) {
			this.setState({ 
				successmessage:data.response_msg,
				cls:"alert alert-danger",
				loading:false,
				
			})
		}else{
			this.setState({
				//successmessage:"Experience added successfully",
				loading:false,
				showExperience:false,
				cls : "alert alert-success"
			}) 
			   // return <Redirect to="/search"/>   
			   //await this.componentDidMount();
			   window.location.reload();
		}
			
		});
		}
	  
	};


validateExprience = () =>{

    let companyError=''; 
    let descriptionError = '';
    if(!this.state.company){
        companyError = "Company Name field is required.";
    }

    if(!this.state.description){
        descriptionError = "Description field is required.";
    }
    

    if(companyError || descriptionError){
        this.setState({companyError,descriptionError});
        return false;
    } else {
        this.setState({companyError,descriptionError});
        return true;
    } 
}   
	
onDeleteExperience =  async (e) => {
	this.setState({  
		loading:true
	  }) 
	const expData = {
		session_token:localStorage.getItem("token"),
		experience_id:e,
  };
	deleteExperience(expData).then(async data => {
		if (data.response_status === 0) {
			this.setState({ 
				successmessage:data.response_msg,
				//cls:"alert alert-danger",
				loading:false,
				
			})
		}else{
			this.setState({
				//successmessage:"Experience deleted successfully",
				loading:false,
				showAcademic:false,
				cls :"alert alert-success"
			}) 
			   // return <Redirect to="/search"/>   
			   await this.componentDidMount();
			   //window.location.reload();
		}
			
		});

}
	
onDeleteAttach =  async (e) => {
	this.setState({  
		loading:true
	  }) 
	const attachData = {
		session_token:localStorage.getItem("token"),
		attachment_type:e,
  };
  deleteAttachments(attachData).then(async data => {
		if (data.response_status === 0) {
			this.setState({ 
				successmessage:data.response_msg,
				//cls:"alert alert-danger",
				loading:false,
				
			})
		}else{
			this.setState({
				//successmessage:"Experience deleted successfully",
				loading:false,
				showAcademic:false,
				cls :"alert alert-success"
			}) 
			   // return <Redirect to="/search"/>   
			   await this.componentDidMount();
			   //window.location.reload();
		}
			
		});

}

setPrivate = async e =>{
	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		if(status==="1"){
			status = "0";
		  } else {
			status = "1";
		  }

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"status":status}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/setProfilePrivate', formData, config);
		if(response.data.response_status == 1){
			//localStorage.setItem("follow", true)
		}
		this.componentDidMount();
	}		
}

// code added by AKS
setavalibleForwork = async e =>{
	e || e.preventDefault();
	if(localStorage.getItem("token") == undefined){
		return this.props.history.push('/login')
	} else {
		var status = e.target.value;
		if(status==="0"){
			status = "1";
		  } else {
			status = "0";
		  }

		  //console.log(e.target.value); return
		const formData = {"session_token":localStorage.getItem("token"),"status":status}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'users/changeAvailablityStatus', formData, config);
		if(response.data.response_status == 1){
			//localStorage.setItem("follow", true)
		}
		this.componentDidMount();
	}		
}

 
bindImages(data) {
	var returnData = 
	  data.map((cat, index) => {

		if (cat.ImageType === "video/mp4") {
		  var imgs = <video controls src={CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + cat.ImageName} ></video>;
		} else {
		  var imgs = <img className="img-fluid" src={CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + cat.ImageName} alt=""></img>;
		}
		return (
		 imgs
		);
	  })
  return returnData;
}
checkLikeUnlike(arr){
	// console.log(arr);
		if(arr.length > 0){
	
		  let obj = arr.find(o => o.user_id === this.state.logged_in_user_id);
		  if(obj){
			return <img src={likeSvg} alt=""/>
		  }else{
			return <img src={unlikeSvg} style={{height: 20, width: 20}} alt=""/>
		  }
		}else{
		  return <img src={unlikeSvg} style={{height: 20, width: 20}} alt=""/>
		}
		
	  }
   
	  _handleLike(post_id){  //console.log(post_id); return;
		this.setState({
		  loading:true
		})
		axios.post(CONSTANT.STABASE_URL +'post/postLike',qs.stringify({
		  session_token: localStorage.getItem("token"),
		  user_name: localStorage.getItem("loggedInUserId"),
		  postId:post_id
		}),{
			headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
		}).then(res => { 
	
			if (res.data.response_status === 0) {
				this.setState({ 
					successmessage:res.data.response_msg,
					error:true,
				})
			} else {
			  // console.log(res.data);
			  //this.getFeedData();
			  this.componentDidMount();
			  this.setState({
				loading: false
			  })
			}
		},
		(error) => { 
			this.setState({ 
				successmessage:"Something went wrong"
			})
		});
	  }
	
	  _handleKeyDownComment = (e,post_id) => {
		if (e.key === 'Enter') {
		  // console.log(e+'------'+post_id)
				axios.post(CONSTANT.STABASE_URL +'post/addComments',qs.stringify({
				  session_token: localStorage.getItem("token"),
				  comment_user_id: localStorage.getItem("loggedInUserId"),
				  post_comment:e.target.value,
				  post_id:post_id
				}),{
					headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
				}).then(res => { 
	
					if (res.data.response_status === 0) {
						this.setState({ 
							successmessage:res.data.response_msg,
							error:true,
						})
					}else{
						this.componentDidMount();
					  //this.getFeedData();
					}
					
	
				},
				(error) => { 
					this.setState({ 
						successmessage:"Something went wrong"
					})
				});
			}
	  }
	render() {
		
		const {profileData,academicsData,skillData,postData,showEditProfile,showAddress,showAcademic,experienceData,showExperience,showSkill,showField} = this.state;
//console.log(this.state.subcategoryOptionss);

if(profileData.thumb_profile_image == undefined && profileData.profile_image != undefined){
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+profileData.profile_image
}else if(profileData.thumb_profile_image != undefined && profileData.profile_image != undefined){
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+profileData.profile_image
}  
else {
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
}

var checked1 = "";
      if(profileData.is_private == "0"){
        checked1 = "checked";		
      } else {
        checked1 = "";
      }
//


	var areyousp = "";
	

      if(skillData.length > 0){
      	console.log("sdfsdf");
	console.log(skillData.length);

        areyousp = "checked1";	
      } else {
      	console.log("sdfsd11111f");
	console.log(skillData);
        areyousp = "";
      }




var checkedafw = "";
if(profileData.availability_status == "0"){
checkedafw = "";		
} else {
checkedafw = "checked";
}

var dateJoin ='';
if(profileData._created_at != undefined)
dateJoin = new Date(profileData._created_at).toLocaleString();


const FeedResult = (props) => {
	console.log("Called");
	console.log(props.results);
	const options = props.results.map(r => (
	  
	  // console.log(r)
	  
	  <div key={r._id} className="post_detels_wrapper ">
					  <div className="create_post row">
						<div className="left_edit_user col-md-8">
						  <div className="create_img">
							<div className="user_profile_pic">  
							{r.post_user_profile_image ?
								  <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.post_user_profile_image}/> :
								  <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
							  }                          
							</div>
							<div className="user_info"> <span>{r.post_user_name}</span> <span></span> </div>
						  </div>
						</div>
						<div className="right_edit_user feeds-time col-md-4 ">
						  <span className="post_days">{r.post_time_status}</span>
						  {/* <span className="post_days"><button onClick={(e) => this.deletePost(r._id)}>Remove</button></span> */}
						  {(r.user_id == this.state.logged_in_user_id) ?
						  <span className="post-delete" onClick={(e) => this.showWarningModal(r._id)} > <img src="/assets/images/delete-post.png" alt="deletepost" /></span>
						  :null}
						</div>
					  </div>
					  <div className="user_post_cont">
		  <p>
			<ShowMoreText
			  /* Default options */
			  lines={3}
			  more='Read more'
			  less='Read less'
			  anchorClass=''
			  expanded={false}
			  width={680}
			>
			  {r.post_content}
			</ShowMoreText>
			
		  </p>
		</div>
	   
		<div className="post_img_detels"> 
		  <OwlCarousel className="owl-theme" loop margin={10} nav items={1} responsive={this.state.opt.responsive} dots={false} autoplay={false} >
  
			{r.images.length > 0 ?
			  // <SliderImages results={r.images}></SliderImages>
			  this.bindImages(r.images)
			  : ''}
		  </OwlCarousel>
						
					  </div>
					  <div className="user_views">
						<ul className="clearfix">
						  <li><button type='button'><img src="/assets/images/sm_like.png" alt=""/> {r.totalCountLike}</button></li>
						  <li><button type='button'>{r.totalComments} Comments</button></li>
						  {/* <li><a href="#">3333 views</a></li> */}
						</ul>
					  </div>
					  <div className="user_comments">
						<div className="comment clearfix">
						  <ul>
							<li><button type='button' onClick={e => this._handleLike(r._id)}>{this.checkLikeUnlike(r.like_users)}Like</button></li>
							<li><button type='button'><img src={chatCommentSvg} alt=""/>Comment</button></li>
							<li><button type='button'><img src={shareSvg} alt=""/>Share</button></li>
						  </ul>
						</div>
					  </div>
					  {/*comment section here*/}
					  <div className="type_comments_wrapper clearfix">
						<div className="type_comment_user">
					  <div className="user_profile_pic"> 
					  {/* <img alt="" src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + this.state.user_profile_image} style={{width: '45px', height: '45px'}}/> */}
  
						{
						localStorage.getItem("loggedInUser_thumb_profile_image")!=="undefined" ?
						<img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + localStorage.getItem("loggedInUser_thumb_profile_image")} style={{width: '45px', height: '45px'}} />:
						<img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{width: '49px', height: '49px'}} /> 
						}   
  
  
					  </div>
						</div>
						<div className="type_comment">
			<div className="textarea-write">
			  <TextareaAutosize
				placeholder="Add Comment"
				onKeyPress={e => this._handleKeyDownComment(e, r._id)}
				  // maxRows={}
				  style={{
					lineHeight: 2,
					// fontSize: 10,
					border: 0,
					height:52,
					boxSizing: 'border-box', 
				  }}
				/>
							{/* <input type="text" name placeholder="Add Comment" name="addComment" onKeyPress={e => this._handleKeyDownComment(e,r._id)}/> */}
							 </div>
							 
						</div>
					  </div> 
					  <CommentSec results = {r.comments}></CommentSec>
					  
					  {/*comment section here*/}
					</div>
	))
	return options
  }

const CommentSec = (props) => {
	const comment = props.results.map(r => (
					  <div key={r.comment_id} className="create_comment_details clearfix">
						<div className="user_comment_pic"><div className="user_profile_pic"> 
					   
						{/* <img src={ CONSTANT.NSTABASE_URL+CONSTANT.PROFILE_IMAGE+r.profile_image} style={{width: '45px', height: '45px'}} alt="" /> */}
						
						{r.profile_image ?
							<img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.profile_image} style={{ height: 45, width: 45 }} /> :
		      					<img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
						}
  
						</div>
						</div>
  
						<div className="main_section_comment">
		  
						  < div class="user_comments_wrapper" >
							<div class="user_comments_content">
							  <div class="user_info_comments">
								<div class="left_user_name">{r.user_name}</div>
								<div class="right_user_time">{new Date(r.comment_timestamp).toLocaleDateString()}</div>
							  </div>
							  <p>{r.user_comment}</p>
							</div>
						  </div >
						</div>
					  </div>
					))
				return comment;
   }

        return (
        <>
        <Header></Header>
        <section className="bdr-top innerheight" >
	<div className="container">
		<div className="row">
		{
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
			<div className="col-12">
				<div className="profile_details profile_user">
				
						<div className="profile_area">						  
						  <div className="mx-md-5 profile_content">
						    <div className="profile-pic">  <img src="#" alt="img" src={profileImg} width="134" height="134" /> <p><span> {profileData.name} </span> &nbsp; <span> {profileData.display_name} </span> <span className="edit_profile">{<a href="#/" onClick ={this.showPopupEditProfile}> <img style={{"max-width":"15px"}} src="/assets/images/edit-white.png" />	 </a> } </span> </p> <label>Joined on {dateJoin}</label>
							
							
                                            
							
							</div>							
						<div className="row">
							<div className="col-md-8">
							<p className="profile_text">
	<label>{profileData.post_count}</label><span>Posts</span>
										<label onClick={() => this.props.history.push('/follower')}>{profileData.follow}</label><span onClick={() => this.props.history.push('/follower')}>Followers</span>
										<label onClick={() => this.props.history.push('/following')}>{profileData.following}</label><span onClick={() => this.props.history.push('/following')}>Following</span>
										<label onClick={() => this.props.history.push('/friend')}>{profileData.friends_count}</label><span onClick={() => this.props.history.push('/friend')}>Friends</span>
									</p>
							</div>
							<div className="col-md-4">
                
                  <div className="wrap available_sale clearfix">
                    <small> Private</small>
                    <input type="checkbox" id="s3" value={profileData.is_private} onChange={this.setPrivate} checked={checked1}/>
                    <label className="slider-v2" htmlFor="s3" />                  
                </div>
              </div>
						</div>
							
                                          
                                   
									

									


									<p className="profile-btn">
										{/* {(localStorage.getItem("friend") == undefined) ?
										(<a className="btn btn-primary mr-2" href="#" role="button" id="addFriend" onClick={this.handleLoginPage}>Add Friend</a>) : (<><div className="btn btn-primary mr-2" id="addFriend">Friend</div></>)}
										{(localStorage.getItem("follow") == undefined) ?
										(<a className="btn btn-primary" href="#" role="button" id="follow" onClick={this.handleLoginPage}>Follow</a>) : (<><div className="btn btn-primary" id="follow">Followed</div></>)}
									 */}
								
										
									
									</p>
							
									<div className="user-experience">
                                   
                                    <div className="user_exp_list">
                                        <div className="row">
										<div className="col-md-6">
										<strong className="address_content">Contact</strong> 
				                        <div className="ex_inner_list">
										<ul>
										<li>Mobile:	<span>{profileData.mobile} </span></li>
										<li>Email:	<span>{profileData.email}</span>	</li>
									 	</ul>
										 </div>


										</div>	
										<div className="col-md-6" key={Math.random()}>
				 <strong className="address_content">Address {(profileData.address != undefined && profileData.address != '') ? <a href="#/" onClick ={this.showPopupAddress}> <img src="/assets/images/edit.png" /></a> :
				 
				
				 
				 <span onClick ={this.showPopupAddress} class="add_skill"> <span>+</span></span>
				 
				 }
				 
				 
				 </strong> 	
										{(profileData.address != undefined && profileData.address != '') ? this.Address(profileData.address) :''}
										</div>
                                        </div>
                                     
                                    </div>
                                </div>	

								 <div className="user-experience" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <strong className="address_content">Academic</strong>
                                        </div>
                                    </div>
                                    <div className="user_exp_list academic_1 ex_inner_list" style={{"min-height": "100px"}}>
                                        <div className="row">
                                             {/* <AcademicList results={this.state.academicsData}></AcademicList> */}

											 {(academicsData.length > 0) ? this.AcademicList(academicsData) :''}
                                        </div>
                                    <p className="add_skill" onClick={this.showPopupAcademic}> <span>+</span> Add Academic</p>    
                                    </div>
                                </div>	  
                                <br/>
                                <strong> Are you a Service Provider? </strong> 
								<div className="user-experience ex_inner_list" id="skillbox">
								 Do you want to have people find you and the services you offer? &nbsp;

								 <input type="checkbox" id="avalibleforwork" value={profileData.availability_status} onChange={this.setavalibleForwork} checked={checkedafw}/>
								 <br/><br/>
								 <strong>*</strong> Please note that your contact information including you phone number will be shared with the person searching for your service.<br/><br/>
								<strong>*</strong> Services are based on skills that you have – Please update the skills section in order for people to find your services.<br/>
								 <br/>
								 </div>
									<div className="user-experience ex_inner_list" id="skillbox">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <strong className="address_content">Skills</strong>
                                        </div>
                                    </div>
                                    <div className="user_exp_list">
                                        <div className="row">
                                          
											{(skillData.length > 0) ? this.SkillsList(skillData) :''}
                                        </div>
                                    <p className="add_skill" onClick={this.showPopupSkils}><span>+</span> Add Skill</p>    
                                    </div>
                                </div>  

								<div className="user-experience ex_inner_list">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <strong className="address_content">Work Experience</strong>
                                        </div>
                                    </div>
                                    <div className="user_exp_list">
                                        <div className="row">
                                          
											{(experienceData.length > 0) ? this.ExperienceList(experienceData) :''}
                                        </div>
                                    <p className="add_skill" onClick={this.showPopupExperience}><span>+</span> Add Work Experience</p>    
                                    </div>
                                </div>  
								<div className="row">
								<div className="col-md-6">										
										<strong className="address_content py-2">Upload Cover Letter</strong>
										<span className="fileupload">										
								
											
								<iframe  src={this.state.cover_letter_url} />
								<input type="file" aria-describedby="emailHelp" className="form-control" accept=".pdf" name="cover_letter"  onChange={this.handleCoverLetterChange} />
								<img className="delete-user-profile" src="/assets/images/delete-post.png" onClick={() => this.onDeleteAttach(2)}/>
										</span>
										</div>
								<div className="col-md-6">								
								<strong className="address_content py-2">Upload Resume</strong>		
								<span className="fileupload">										
																			
											<iframe  src={this.state.imageURL} />
								            <input type="file" aria-describedby="emailHelp" className="form-control" accept=".pdf" name="resume"  onChange={this.handleUsernameChange} /> 
											<img className="delete-user-profile" src="/assets/images/delete-post.png" onClick={() => this.onDeleteAttach(1)}/>
										</span> </div>
										

										
						</div>	
						{postData.length>0 ? 					
        <div className="row">
        <div className="col-md-12">
        <div className="card" style={{"margin-top":"40px"}}>
        <div className="card-header"> Posts </div>
        <div className="card-body">

						
                  <FeedResult results={postData}></FeedResult> 
</div></div></div></div>
:''} 

</div></div>
						</div>
						  </div>
						

					
					
			
		</div>
	</div>
	  
<Modal show={showEditProfile} onHide={this.closePopupEditProfile} > 
<form onSubmit={this.onSubmitPersonal} id="personalsForm">
<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closePopupEditProfile}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
       
            {/* <form id="form1"> */}
              <div className="user-img">
                {/* <img id="blah" src="images/user-profile-edit.png" />  */}
               
               
				<img src="#" alt="img" src={profileImg} width="134" height="134" />
               



                <div id="hidelabel">
                  <label className="hand-cursor_wrp">
                    {/* <input type="file" id="imgInp" name="files[]" /> */}
					<input type="file" id="profile_image" accept="image/*" name="profile_image"  onChange={this.handleProfileChange} />
                    <div className="camera-wrapper">
                      <i className="fas fa-plus" aria-hidden="true" />
                    </div>
                  </label>
                </div>
              </div>
            {/* </form> */}
            <div className="app_add_agent">
            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
                                   
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Name</label>
                        <input type="text" className="form-control" name="name" placeholder="Name" value={this.state.name} onChange={this.onChangePersonal}/>
                    <span className="error-msg">{this.state.nameError}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Display Name</label>
                        <input type="text" className="form-control" name="dispaly_name" placeholder="Display Name" value={this.state.display_name} onChange={this.onChangePersonal}/>
                    <span className="error-msg">{this.state.displayNameError}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Phone No</label>
                        <input type="text" className="form-control" name="mobile" placeholder="Mobile" value={this.state.mobile} onChange={this.onChangePersonal}/>
                    <span className="error-msg">{this.state.mobileError}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Email Id</label>
                        <input type="text" className="form-control" name="email" placeholder="Email" value={this.state.email} onChange={this.onChangePersonal}/>
                    <span className="error-msg">{this.state.emailError}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">About</label>
                        <textarea className="form-control about_profile" name="about" placeholder="About" value={this.state.about} onChange={this.onChangePersonal}></textarea>
                    <span className="error-msg">{this.state.aboutError}</span>
                      </div>
                    </div>
                  </div>
                </div>
				</div>
              <div className="post_bt_public request_bt edit_profile">
              <ul className="clearfix">
                <li>
                  <button type="submit" className="save_bt">SAVE</button>
                </li>
              </ul>
            </div> 
             
            </div>
			</div>
    </Modal.Body>
	</form>
    </Modal>
		  
<Modal show={showAddress} onHide={this.closePopupAddress} size="lg"> 
<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closePopupAddress}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
        <div className="app_add_agent">
            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
                                     
              <div className="row">
			  <div className="form-row">
											<div className="form-group col-md-12">
												<PlacesAutocomplete 
													value={this.state.address}
													onChange={this.handleChangeAddress}
													onSelect={this.handleSelect}
												  >
													{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
													  <div>
														<input
														  {...getInputProps({
															placeholder: 'Search Address ...',
															className: 'form-control search_input',
														  })}
														/>
														<div className="autocomplete-dropdown-container">
														  {loading && <div>Loading...</div>}
														  {suggestions.map(suggestion => {
															const className = suggestion.active
															  ? 'suggestion-item--active'
															  : 'suggestion-item';
															// inline style for demonstration purpose
															const style = suggestion.active
															  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
															  : { backgroundColor: '#ffffff', cursor: 'pointer' };
															return (
															  <div
																{...getSuggestionItemProps(suggestion, {
																  className,
																  style,
																})}
															  >
																<span className="list-item-description">{suggestion.description}</span>
															  </div>
															);
														  })}
														</div>
													  </div>
													)}
												  </PlacesAutocomplete>
												  
													<span className="error-msg">{this.state.addressError}</span>
											</div>
											<div className="form-group col-md-12">
												<input type="text" className="form-control" id="flatno" placeholder="Apt No/House No:/BuildingNo" name="flatno" value={this.state.flatno} onChange={this.handleUsernameChange} required/>
												{/*<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_apt_no}</p>*/}
											</div>
											<div className="form-group col-md-12">
												<input type="text" className="form-control" id="street" placeholder="Street" name="street" value={this.state.street} onChange={this.handleUsernameChange} required/>
												{/*<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_street}</p>*/}
											</div>
											<div className="form-group col-md-6">
												<input type="text" className="form-control" id="city" placeholder="City" name="city" value={this.state.city} onChange={this.handleUsernameChange} required/>
												<span className="error-msg">{this.state.cityError}</span>
											</div>
											<div className="form-group col-md-6">
												<input type="text" className="form-control" id="state" placeholder="State" name="state" value={this.state.state} onChange={this.handleUsernameChange} required/>
												<span className="error-msg">{this.state.stateError}</span>
											</div>
											<div className="form-group col-md-6">												
												<input type="text" className="form-control" id="zip" placeholder="Zip Code" name="zip" value={this.state.zip} onChange={this.handleUsernameChange} required/>
												<span className="error-msg">{this.state.zipError}</span>
											</div>
											<div className="form-group col-md-6">												
												<input type="text" className="form-control" id="country" placeholder="Country" name="country" value={this.state.country} onChange={this.handleUsernameChange} required/>
												<span className="error-msg">{this.state.countryError}</span>
											</div>
										</div>	
				</div>
              <div className="post_bt_public request_bt edit_profile">
              <ul className="clearfix">
                <li>
				<button className="btn btn-primary mr-3" onClick={()=> this.updateAddress()}>Update</button>
                </li>
              </ul>
            </div> 
            
            </div>
			</div>
    </Modal.Body>
    </Modal>


	  
	<Modal show={showAcademic} onHide={this.closePopupAcademic} > 

<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closePopupAcademic}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
       
           
            <div className="app_add_agent">
            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
			<form onSubmit={this.onSubmitAcademic} id="academicform">              
             <div className="row">
              <div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">Title</label>
                    <input type="text" className="form-control" name="title" placeholder="title" value={this.state.title} onChange={this.onChange}/>
                    <span className="error-msg">{this.state.aTitleError}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">College/School</label>
                    <input type="text" className="form-control" name="college_or_school_name" placeholder="College School" value={this.state.college_or_school_name} onChange={this.onChange}/>
                    <span className="error-msg">{this.state.aSchoolError}</span>
                  </div>
                </div>
                  <div className="col-md-12">
                      <div className="form-group">
                     
                        <label className="label_title edit_label">Select Start Year</label>
                        <div className="select_wrp">
                        <select className="form-control select_category" name="start_year" value={this.state.start_year} onChange={this.onChange}>
                <option defaultValue="">Select Start Year</option> 
                {this.yearList()}
              </select> 
              <i class="fas fa-chevron-down drop-down-chevron"></i>
              </div>
              <span className="error-msg">{this.state.aStartYearError}</span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label_title edit_label">Select End Year</label>
                        <div className=" select_wrp">
                        <select className="form-control select_category" name="end_year" value={this.state.end_year} onChange={this.onChange}>
                <option defaultValue="">Select End Year</option> 
                {this.yearList()}
              </select> 
                <i class="fas fa-chevron-down drop-down-chevron"></i>
              </div>
              <span className="error-msg">{this.state.aEndYearError}</span>
                      </div>
                     
                    </div>
                    <div className="col-md-12">
                 
                                            <div class="terms-condition-inner">
    <div class="checked_box clearfix">
        <label class="container_checked">
            <input type="checkbox" onClick={this.pursuingCheck} />
    <span class="checkmark"></span> 
        </label>
    </div>
    <div class="term_right">Pursuing</div>
    <small class="text-muted_error_message"></small>
</div>
                                            </div>
                    <div className="col-md-12">
                    <div className="post_bt_public request_bt edit_profile add_skill_profile"><ul class="clearfix"><li><button type="submit" class="save_bt">SAVE</button></li></ul></div>
                    </div>

                    </div>
             </form>
            </div>
			</div>
    </Modal.Body>
	
    </Modal>

	<Modal show={showExperience} onHide={this.closePopupExperience} > 

<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closePopupExperience}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
       
           
            <div className="app_add_agent">
            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
			<form onSubmit={this.onSubmitExprience} id="experienceForm">              
			<div className="row">
                  <div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">Company Name</label>
                    <input type="text" className="form-control" name="company" placeholder="company" value={this.state.company} onChange={this.onChange}/>
                    <span className="error-msg">{this.state.companyError}</span>
                  </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                    <label className="label_title">Description</label>
                     {/* <input type="text" className="form-control" name="description" placeholder="description" value={this.state.description} onChange={this.onChange}/> */}
                    {/* <textarea name="description"
          onChange={this.onChange}
          value={this.state.description}/>
       */}
<textarea class="form-control about_profile" name="description" placeholder="description" value={this.state.description} onChange={this.onChange}></textarea>
                    <span className="error-msg">{this.state.descriptionError}</span>
                  </div>
                     
                    </div>
                    <div className="col-md-12">
                    <div className="post_bt_public request_bt edit_profile add_skill_profile"><ul class="clearfix"><li><button type="submit" class="save_bt">SAVE</button></li></ul></div>
                    </div>

                    </div>
             </form>
            </div>
			</div>
    </Modal.Body>
	
    </Modal>

	<Modal show={showSkill} onHide={this.closePopupSkils} > 

<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closePopupSkils}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
       
           
            <div className="app_add_agent">
            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
			<form onSubmit={this.onSubmitSkills} id="skillForm">              
			<div className="row">
                  <div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">Category</label>
                    

					<select className="form-control select_category" name="category_id" value={this.state.category_id} onChange={this.onChangeCategory}>
                <option defaultValue="">Select category</option> 
                {this.state.categoryOptions}
              </select> 
                    <span className="error-msg">{this.state.catError}</span>
                  </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                    <label className="label_title">Sub Category</label>
					{/* <Multiselect
                         options={(this.state.subcategoryOptionss) ? this.state.subcategoryOptionss:[]} // Options to display in the dropdown
                        //selectedValues={this.state.categoryIds} // Preselected value to persist in dropdown
                        onSelect={this.onSelect} // Function will trigger on select event
                        onRemove={this.onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                      />  */}
					  <select className="form-control select_category" name="sub_category_id" value={this.state.sub_category_id} onChange={this.onChangeSubCat}>
                <option defaultValue="">Select Sub category</option> 
                {/* {this.state.subcategoryOptionss} */}

             
{this.state.subcategoryOptionss.length > 0 && this.state.subcategoryOptionss.map((item) =>
	<option name={item.name} key={item._id} value={item._id}>{item.name}</option>
	
 )}



              </select>
                    <span className="error-msg">{this.state.subCatError}</span>
                    
 </div>
 </div>

{showField ? (
         
       



                     <div className="col-md-12">
                  <div className="form-group">
                   <label className="label_title">Others Category</label>

                   <input type="text" className="form-control" name="otherdata"  placeholder="Others" value={this.state.otherdata} onChange={this.onChange} />  
                  </div>
				   </div> 


 ) : null}






                  


                   
					<div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">Service Experience</label>
                    <input type="text" className="form-control" name="experience" placeholder="Service Experience" value={this.state.experience} onChange={this.onChange}/>
                    <span className="error-msg">{this.state.experienceError}</span>
                  </div>
                    </div>
                    <div className="col-md-12">
                    <div className="post_bt_public request_bt edit_profile add_skill_profile"><ul class="clearfix"><li><button type="submit" class="save_bt">SAVE</button></li></ul></div>
                    </div>

                    </div>
             </form>
            </div>
			</div>
    </Modal.Body>
	
    </Modal>

</section>
    <Footer></Footer>
    </>  );
    }
}
 
export default ProfileUser;