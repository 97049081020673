import {
    GET_HEADER,
    GET_FOOTER,
    GET_HOMEPAGES,
    HOMEPAGES_LOADING
   
} from "../actions/types";

const initialState = {
    statec: {},
    getHeaderData: {},
    getFooterData: {},
    getDataPage: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {		
		case GET_HEADER:
            return {
                ...state,
                getHeaderData: action.payload,
                loading: false
                
            };
		case GET_FOOTER:
            return {
                ...state,
                getFooterData: action.payload,
                loading: false
                
            };
        case GET_HOMEPAGES:
            return {
                ...state,
                getDataPage: action.payload,
                loading: false
                
            };			
		case HOMEPAGES_LOADING:
			return {
				...state,
				loading: true
			};              
        default:
        return state;
    }
}
