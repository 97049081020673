import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  friendList: [],
  completeList:[]
 
} 

class AppliedJobs extends Component {
  constructor(props) {
    super(props);
  const token = localStorage.getItem("token");        
  this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    this.setState({loading: true}); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
   
    

 var formData1 = {"session_token":localStorage.getItem("token")}   
  
    let response1 = await axios.post(CONSTANT.STABASE_URL +'users/getAppliedjobList', formData1, config);
 //    console.log("fffff");
 //   console.log(response1.data.response_data.getAppliedjobList);
 // console.log("11111111");
 if(response1.data.getAppliedjobList!='Already logged on other device'){
    if(response1.data.response_data.getAppliedjobList != null && response1.data.response_data.getAppliedjobList!=''){
      this.setState({friendList: response1.data.response_data.getAppliedjobList,loading: false});
    } else {
      this.setState({loading: false});
    }
  }
  else{

    localStorage.removeItem("token");
    localStorage.removeItem("store_id");
    localStorage.removeItem("imgs");
    localStorage.removeItem("store_name");
    localStorage.removeItem("address");
    localStorage.removeItem("description");
    localStorage.removeItem("store_contact_number");    
    localStorage.removeItem("loggedInUserId");    
    localStorage.removeItem("email");   
    localStorage.removeItem("loggedInUser_thumb_profile_image");    
    localStorage.removeItem("loggedInUserName");    
    localStorage.removeItem("checkStoreStatus");    
    localStorage.removeItem("otp");   
    localStorage.removeItem("mobile");    
    localStorage.removeItem("longitude");   
    localStorage.removeItem("latitude");    
    localStorage.removeItem("storePhoneNo");    
    localStorage.removeItem("country_code");    
    this.setState({loggedIn:false, showing: true})
    document.location.href = '/login'

    //this.props.history.push('/login');
  }
  
}
  

removeJob = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
      
      const formData = {"session_token":localStorage.getItem("token"),"job_id":e}   
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'users/getcancelAppliedjobList', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 



  setJobDetails = e => { 
    localStorage.setItem("stat",e.stat);
    //localStorage.setItem("store_name", e.store_name);
    document.location.href = "/jobs-details/"+e.job_id
    }

timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}



  friendsList =  (cFriend) => {
    const res = cFriend.length > 0 && cFriend.map((item) => {
     if(item.job_image[0] != '' && item.job_image[0] != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/store/'+item.job_image[0]
     
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }
    
    if(item.job_status==1)  var jobstatus="Active";
    if(item.job_status==5)  var jobstatus="Inactive";
    var timeago=this.timeSince(new Date(item.updated_at));


      return( 
        <li key={Math.random()}>
        
          <div className="product_name">

         {/* <a href="" onClick={() => this.props.history.push('/jobs-details/'+item._id)}> */}
         <a href="#" onClick={() => this.setJobDetails({'job_id':item._id,'stat':item.status})}>

          <div className="friends-img" style={{'max-width': '300px'}}> <img src={profileImg} alt="img" /> 

         <p>{item.job_title} <br/>
         {/* <span>{item._id}</span> */}
         </p><br/> 
          <div className="status-sec"></div>

          </div>        

          </a>
          
           <div className="friends-action"> 
          
            <button type="button" class="btn btn-light" onClick={() => this.removeJob(item._id)}>Cancel</button>    
           </div>                
          </div>
        
       
      
      </li>
      )                                   
    })  
    return <>{res}</>
  }
 

  

  


  
  



  render() {
    
    
   
    const { friendList} = this.state;
  const {completeList}= this.state;
  
    return (
      <>
       <Metatitle title="Job Listing | aBlueC" metaDescription="Job Listing" ogTitle="Job Listing" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
        <div className="container">  
       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
      <div className="row">
        <div className="col-md-12 my-2">
        </div>
      </div>
    
     
          <div className="row justify-content-center"> 

          <div className="col-md-12">
          <div className="checkout-sec mb-3"> 
            <div class="card">
                  <div class="card-header">
                Job Applied For
                  </div>
                  <div className="card-body">
                          <ul className="friends-list posted"> 
                            {friendList.length > 0 ? this.friendsList(friendList):<> <li>No Current Job Found</li></>} 
                      </ul>
          
                </div>

          </div>
        </div>
          </div>
        

           </div>
     



    








</div> 
    
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default AppliedJobs;