
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import Metatitle from "../components/metaTitle";
import React, { Component, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import PlacesAutocomplete, { geocodeByAddress,getLatLng,geocodeByPlaceId  } from 'react-places-autocomplete';
import {getStores, getStoresCheck, clearErrors} from "../actions/storeActions";
import Loader from '../Loader';
import CONSTANT from '../Constant';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').css('background-image', 'url('+e.target.result +')');
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}


class FeedsPages extends Component {
    constructor(props) {
		super(props);
		const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
		this.state = {
			newloader: false,
			loggedIn,
			loadingnew: false,
			getFilterData: [],
			profile: {},
			stripeConnect: "",
			country_code:"",
			store_apt_no:"",
			storeAddress:"",
			storeLat:'',
			storeLong:'',
			store_street:"",
			store_country:"",
			store_state:"",
			store_city:"",
			store_zip_code:"",
			otp:"",
			otpMsg:"",
			doneMsg:"",
			addStoreMsg:"",
			formValidS: "",
			formErrorss: {
				storeImg: "",
				storeName: "",
				storePhoneNo: "",
				storeDesc: "",
				storeAddress: "",
				store_apt_no:"",
				store_street:"",
				store_country:"",
				store_state:"",
				store_city:"",
				store_zip_code:"",
				country_code:""
			},
			files:[],
			file:[],
			images:"",
			getStoreData: {},
			storeresult: [],
			longitude: "77.3479261775324",
			latitude:"28.605331625541074"
		};
		this.handleUsernameChange = this.handleUsernameChange.bind(this);

    }
	
	// async componentDidMount() {
		// this.setState({newloader: false});
	// }
	
	async componentDidUpdate() {
		var getLocationArray = []
		navigator.geolocation.getCurrentPosition(function(position) {
			getLocationArray.push({latitude: position.coords.latitude,longitude: position.coords.longitude})
		});
		if(getLocationArray.length > 0){
			localStorage.setItem("longitude",getLocationArray.longitude);
			localStorage.setItem("latitude",getLocationArray.latitude);
		} else {
			localStorage.setItem("longitude",this.state.longitude);
			localStorage.setItem("latitude",this.state.latitude);
		}
		const {getStoreData} = this.props.storeresult
		if (Object.keys(getStoreData).length === 0 && getStoreData.constructor === Object) {
				
		} else {
			localStorage.setItem("checkStoreStatus", getStoreData.response_status);
			return <Redirect push to={`/manageactivity`} />
		}
		// this.setState({"loadingnew": false})
		document.getElementById("storePhoneNo").className = "form-control"
		$('.PhoneInputCountryIcon').css({'width':'54px','height':'36px','border-radius':'.25rem'});
	}
	
	async componentDidMount() {
		this.setState({newloader: true});
		var search =this.props.location.search;
		var params = new URLSearchParams(search);		
		var showStorePopup = params.get('showStorePopup');		
		if(showStorePopup == 'yes'){
			document.getElementById("showStorePopup").style.display = "block"
		}
		const userData = {
			"session_token": localStorage.getItem("token"),
			"user_id": localStorage.getItem("loggedInUserId")
		};
		var data = await this.props.getStores(userData);
		setTimeout(() => {
			this.setState({newloader: false}); 
		}, 3000);		
	}
	
	storePhoneNoChange = e => {
		if(e){			
			const phoneNumber = parsePhoneNumber(e)		
			if (phoneNumber) {
				localStorage.setItem("storePhoneNo",phoneNumber.nationalNumber);
				localStorage.setItem("country_code",phoneNumber.countryCallingCode);
			}			
		}	
	}
	
	handleUsernameChange = e => {			
		if (e.target.files && e.target.files[0]) {
			$('#storeImg').css({'position':'absolute','width':'100%','height':'100%'});		
			$("body").append('<style>.upload-logo::before{border: none;border-radius: none;content:none;}</style>');
			$("body").append('<style>.upload-logo::after{opacity:.4;background:white;padding-top: 12px;color: black;font-size: 35px;font-weight: bolder;font-size:0;}</style>');
			//$("body").append('<style>.upload-logo::after{opacity:.4;font-weight: bolder;background:white;padding-top: 12px;font-size:0;}</style>');
			this.setState({
				imageURL: URL.createObjectURL(e.target.files[0]),
				images:e.target.files[0]
			});
		}		
		this.setState({ [e.target.id]: e.target.value});	
	}	
	

	  handleKeyDown = e => {
        if (e.key === " ") {
          e.preventDefault();
        }
      };


	handleSelect = async (address,placeId) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const [place] = await geocodeByPlaceId(placeId);
		const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
		const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
		const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
		const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
		const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
		const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
		const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
		var cityNames='';
		if(cityNameNeighbour) cityNames=cityNameNeighbour;else if(cityNameLocality)cityNames=cityNameLocality;else{cityNames=cityName;}
		this.setState({storeAddress: address,storeLat:latLng.lat,storeLong:latLng.lng,store_state:stateName,store_city:cityNames ,store_zip_code:postalCode,store_country:countryName,store_street:streetNumber+' '+routesName})
	};
		
	
	handleChange = (address) => {
		this.setState({ storeAddress:address });
	};
  
	checkLoggedIn = e => {
		e.preventDefault();
		if(this.state.loggedIn == true){			
		} else {			
			this.setState({loginData: "click",profileData:"", response_status: 0})
			e.target.setAttribute("id", "login")
			e.target.setAttribute("data-toggle", "modal")
			e.target.setAttribute("data-target", "#Login")			
		}	
	}
	
	verifyOtp = async e => {
		


		// e.preventDefault();		
		this.setState({newloader: true});		
		var formData = new FormData();
		formData.append("store_image", this.state.images)
		formData.append("store_logo", this.state.images)
		formData.append("store_name", this.state.storeName)
		formData.append('country_code', localStorage.getItem("country_code"));
		formData.append('store_contact_number', localStorage.getItem("storePhoneNo"));
		formData.append('store_description', this.state.storeDesc);
		formData.append('store_address', this.state.storeAddress);
		formData.append('store_complete_address', this.state.storeAddress);
		formData.append('store_apt_no', this.state.store_apt_no);
		formData.append('store_street', this.state.store_street);
		formData.append('store_country', this.state.store_street);
		formData.append('store_state', this.state.store_state);
		formData.append('store_city', this.state.store_city);
		formData.append('store_zip_code', this.state.store_zip_code);

		formData.append('store_latitude', this.state.storeLat);
		formData.append('store_longitude', this.state.storeLong);

		// formData.append('store_latitude', localStorage.getItem("latitude"));
		// formData.append('store_longitude', localStorage.getItem("longitude"));
		formData.append('session_token', localStorage.getItem("token"));
		formData.append('user_id', localStorage.getItem("loggedInUserId"));
		formData.append('status', 0);
		formData.append('service_time', '');
		formData.append('service_time_unit', '');

// var storeData={
// 	"store_image": this.state.images,
// 	"store_logo": this.state.images,
// 	"store_name": this.state.storeName,
// 	'country_code': localStorage.getItem("country_code"),
// 	'store_contact_number': localStorage.getItem("storePhoneNo"),
// 	'store_description': this.state.storeDesc,
// 	'store_address': this.state.storeAddress,
// 	'store_complete_address': this.state.storeAddress,
// 	'store_apt_no': this.state.store_apt_no,
// 	'store_street': this.state.store_street,
// 	'store_country': this.state.store_street,
// 	'store_state': this.state.store_state,
// 	'store_city': this.state.store_city,
// 	'store_zip_code': this.state.store_zip_code,
// 	'store_latitude': localStorage.getItem("latitude"),
// 	'store_longitude': localStorage.getItem("longitude"),
// 	'session_token': localStorage.getItem("token"),
// 	'user_id': localStorage.getItem("loggedInUserId"),
// 	'status': 0,
// 	'service_time': '',
// 	'service_time_unit': ''


// }
// localStorage.setItem('storeData', JSON.stringify(storeData));
		
		this.setState({
			errors:'Please wait...',
			errorstatus:true,
		})
		
		// var validOTP = localStorage.getItem('otp');
		// var enterOTP = this.state.otp;		
		
		// if(validOTP == enterOTP){
			
			
			

			// if(localStorage.getItem("stripeConnect") == 0){	
			
			// 	setTimeout(async function(){
			// 		const config = {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data',
			// 				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			// 			}
			// 		}	
					
			// 		let response = await axios.post(CONSTANT.STABASE_URL+'store/addTempStore', formData, config);
			// 		if(response.data.response_status != 0){
			// 		//document.location.href = CONSTANT.stripeConnectLocalURL+'&state='+response.data.response_data+'#/';
			// 		document.location.href = CONSTANT.stripeConnectLiveURL+'&state='+response.data.response_data+'#/';
			// 		}

			// 	}, 1000);
								
			// 		//document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_ASFm4qvJ5XkC60W0pUzGMCzm8yxsdjer&scope=read_write&redirect_uri=http://www.abluec.com:9024/stripeConnect/stripeConnectAuth&state=169#/'	
			// 		//document.location.href = CONSTANT.stripeConnectLiveURL;
					
			// 		//document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_Eaog1YFoxc7zgGy9AXcbWItNPvsylYH7&scope=read_write&redirect_uri=http://localhost:7103/stripeConnect/stripeConnectAuth&state=169#/'					
					
				
				
			// } else {

				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
					}
				}	
				
				let responses = await axios.post(CONSTANT.STABASE_URL+'store/addStore', formData, config);
				
				// console.log("MMMMMMMMM")
				// console.log(response.data)
				// console.log("MMMMMMMMM")
	
				if(responses.data.response_status != 0){
					// document.getElementById('Otpb').click();
					document.getElementById('showOtpPopup').style.display = 'none';
					localStorage.removeItem('otp');
					var doneMsg = 'Store added Sucessfully';
					this.setState({doneMsg:doneMsg,newloader: false})
					// document.location.href = "/addcard"
					// document.location.href = "/vendor/myStores"
				}

				localStorage.setItem("stripeConnect", 1)
				setTimeout(function(){
					document.location.href = "/vendor/myStores";
				}, 2000);
			// }
			
			
		// } else {
			
			// var otpMsg = 'Please Enter Valid OTP'
			// this.setState({otpMsg:otpMsg})
		// }
	}
	
	
	connectStripe = async e => {
		e || e.preventDefault();
		
		this.setState({newloader: true});
		
		if(this.state.storeName && this.state.storeDesc && this.state.storeAddress && this.state.store_street && this.state.store_country  && this.state.store_state  && this.state.store_city  && this.state.store_zip_code){
			
			var getStoresCheck = await this.props.getStoresCheck({store_name: this.state.storeName})	

			// console.log("jjjjjjjjj")
			// console.log(getStoresCheck)
			// console.log("jjjjjjjjj")
			
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => {
				var checkStoreStatus = localStorage.getItem("checkStoreStatus");
				if(checkStoreStatus == 1){
					
					var addStoreMsg = 'Store Name Already Exists!'
					this.setState({addStoreMsg:addStoreMsg,newloader: false})
					$("#showStorePopup").scrollTop(0);
					
				} else {
					
					// const configVerify = {
						// headers: {
							// 'Content-Type': 'application/json',
							// 'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
						// }
					// }
					
					// var verifyOtp = {
						// country_code: this.state.country_code,
						// mobile_number: this.state.storePhoneNo
					// };
					
					// let verifyOtpResponse = await axios.post(CONSTANT.STABASE_URL+'users/verifyOtp', verifyOtp, configVerify);
					
					// if(verifyOtpResponse.data.response_status != 0){
						// document.getElementById('StoreForm').click();
						// document.getElementById("StoreForm").className = "modal fade show";
						// document.getElementById('StoreForm').click();
						// document.getElementById('showStorePopup').style.display = 'none';
						// document.getElementById('showOtpPopup').style.display = 'block';
						// document.getElementById("OtpForm").className = "modal fade1 show";
						this.setState({newloader: false});
						
						this.verifyOtp();
						// localStorage.setItem('otp', 1234);
						// localStorage.setItem('otp', verifyOtpResponse.data.response_data.OTP);
					// } else {
						// this.setState({addStoreMsg:"Please enter correct number"})
					// }
						
					// var headers = {
						// 'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
					// }
					// var checkData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id:localStorage.getItem("store_id")}			
					// let response = await axios.post(CONSTANT.STABASE_URL+'store/paymentCreditScoreByCard',checkData,{headers: headers});
				
					// console.log("pppppppppppppp")
					// console.log(response)
					// console.log("pppppppppppppp")
					
				}
			}, 10000);
			
		 } else {
			 
			let fieldValidationErrorss = this.state.formErrorss;
			let storeNameValid = this.state.storeName;
			let country_codeValid = this.state.country_code;
			let storePhoneNoValid = this.state.storePhoneNo;
			let storeDescValid = this.state.storeDesc;
			let storeAddressValid = this.state.storeAddress;
			// let store_apt_noSignValid = this.state.store_apt_no;
			let store_streetValid = this.state.store_street;
			let store_countryValid = this.state.store_country;
			let store_stateValid = this.state.store_state;
			let store_cityValid = this.state.store_city;
			let store_zip_codeValid = this.state.store_zip_code;		
			
			storeNameValid = this.state.storeName;
			fieldValidationErrorss.storeName = storeNameValid ? '' : 'Please enter name';
			
			
			var pattern = new RegExp(/^[0-9\b]+$/);
			country_codeValid = pattern.test(this.state.country_code);
			fieldValidationErrorss.country_code = country_codeValid ? '' : 'Not valid Code';
			
			var pattern = new RegExp(/^[0-9\b]+$/);
			storePhoneNoValid = pattern.test(localStorage.getItem('storePhoneNo'));
			fieldValidationErrorss.storePhoneNo = storePhoneNoValid ? '' : 'Please enter valid number!';
			
			storeDescValid = this.state.storeDesc;
			fieldValidationErrorss.storeDesc = storeDescValid ? '' : 'Please enter description!';
			
			storeAddressValid = this.state.storeAddress;
			fieldValidationErrorss.storeAddress = storeAddressValid ? '' : 'Please enter address!';
			
			// store_apt_noSignValid = this.state.store_apt_no;
			// fieldValidationErrorss.store_apt_no = store_apt_noSignValid ? '' : 'Please enter apt no!';
			
			store_streetValid = this.state.store_street;
			fieldValidationErrorss.store_street = store_streetValid ? '' : 'Please enter street name!';
			
			store_countryValid = this.state.store_country;
			fieldValidationErrorss.store_country = store_countryValid ? '' : 'Please enter country name!';
			
			store_stateValid = this.state.store_state;
			fieldValidationErrorss.store_state = store_stateValid ? '' : 'Please enter state name!';
			
			store_cityValid = this.state.store_city;
			fieldValidationErrorss.store_city = store_cityValid ? '' : 'Please enter city name!';
			
			store_zip_codeValid = this.state.store_zip_code;
			fieldValidationErrorss.store_zip_code = store_zip_codeValid ? '' : 'Please enter zip code!';
			
			this.setState({
				newloader: false,
				formErrorss: fieldValidationErrorss,
				storeNameValid: storeNameValid,
				country_codeValid: country_codeValid,
				storePhoneNoValid: storePhoneNoValid,
				storeDescValid: storeDescValid,
				storeAddressValid: storeAddressValid,
				// store_apt_noSignValid: store_apt_noSignValid,
				store_streetValid: store_streetValid,
				store_countryValid: store_countryValid,
				store_stateValid: store_stateValid,
				store_cityValid: store_cityValid,
				store_zip_codeValid: store_zip_codeValid,
			}, () => this.setState({formValidS: this.state.storeNameValid && this.state.country_codeValid && this.state.storePhoneNoValid && this.state.storeDescValid && this.state.storeAddressValid && this.state.store_apt_noSignValid && this.state.store_streetValid && this.state.store_countryValid && this.state.store_stateValid && this.state.store_cityValid && this.state.store_zip_codeValid}));
			 
			 
		 }
		
	}
	

	handleClose = e => {
		e.preventDefault();
	}
	
	
	
	clickPages = e => {
		e.preventDefault();
		if(e.target.id == 'store')
		document.getElementById("showStorePopup").style.display = "block"
	}
	
	productClickPages = e => {
		e.preventDefault();
		document.location.href = `/Search-result?q=&source=products`;
		// if(e.target.id == 'store')
		// document.getElementById("showStorePopup").style.display = "block"
	}	
	
	closePages = e => {
		e.preventDefault();
		document.getElementById("showStorePopup").style.display = "none"
	}
	
	manageStore = e => {
		e.preventDefault();

		// console.log("Sdasdasdasdasdasdasdasd");
		// 	console.log(e.target.id);
		// return false;
		if(e.target.id == 'mstore')
		this.setState({"mstore": true})
		if(e.target.id == 'store1')
		this.setState({"mstore": true})
	}
	
	renderRedirect = () => {
		if(this.state.mstore)		
		return <Redirect push to="/vendor/myStores" />	
	}
	stripeConnect = e => {
		e.preventDefault();
		//document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_ASFm4qvJ5XkC60W0pUzGMCzm8yxsdjer&scope=read_write&redirect_uri=http://www.abluec.com:9024/stripeConnect/stripeConnectAuth&state=169#/'	
		document.location.href = CONSTANT.stripeConnectLiveURL;
					//document.location.href = CONSTANT.stripeConnectLocalURL;
	}
	
		
	render() {
		
		// const { newloader = true } = this.state;
		
		if(this.state.loggedIn == false){ 
            return <Redirect to="/login" />		
        }
		
		const { getFilterData, response_status, profileData, profile } = this.state;
		const {getStoreData, getStoreListData} = this.props.storeresult
		
		var getData = Object.assign({},...getFilterData)
		
		var getStoreInfo = getData.getStoreInfo ? getData.getStoreInfo : 0
		
		return (
			<>
				<Metatitle title="Manage Stores | aBlueC" metaDescription="Vendor Store" ogTitle="Vendor Store" ogImage=""></Metatitle>
				<Header data="inner-head"></Header>
				<section className="sidebar-layout bdr-top innerheight">
						<div className="container-fluid">
							<div className="row">															
								<div className="col-12">
									<h3 id="title" className="mt-5">&nbsp;</h3>
									{this.renderRedirect()}
									<div id="content" className="mystore" style={{"textAlign":"center"}}>
									{/* {((localStorage.getItem('stripeConnect') == 0) || (localStorage.getItem('stripeConnect') == undefined)) ?
									<>
									<div className="stripe-img">  <img src="assets/images/stripe-not-connected.png" alt="" /></div>
									</>
									:
									<>
									<div className="stripe-img">  <img src="assets/images/stripe-connected.png" alt="" /></div>
									</>
									} */}
									{this.state.newloader ? (<Loader/>) : (<></>)}
									<a style={{"width": "400px","height": "150px", "font-size": "24px", "margin-bottom": "10px", "color": "white",     "background-color": "#3e9bff"}} className="btn btn-primary mr-1" onClick={this.productClickPages}>
										<svg style={{"marginTop":"28px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
										  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
										</svg><br/>
										Search for Products</a><br/>
									{(getStoreListData.length == 0)?
									<>
									<a style={{"width": "400px","height": "150px", "font-size": "24px", "color": "white", "background-color": "black"}} className="btn btn-primary mr-1" id="store" onClick={this.clickPages}>
										<svg id="store" style={{"marginTop":"28px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
											<path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
										</svg><br/>Open a Store – Sell Online</a>
									</>
									:
									<>
									<a onClick={this.manageStore} style={{"width": "400px","height": "150px", "font-size": "24px", "color": "white", "background-color": "black"}} className="btn btn-primary mr-1" id="mstore">
										<svg id="store1" style={{"marginTop":"28px"}}  xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
											<path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"/>
										</svg><br/>Manage your Store</a>
									</>
									}
																			
																			
									</div>								
								</div>
							</div>
						</div>
				</section>
				<Footer></Footer>
				<div className="overlay-addstore" id="showStorePopup" style={{"display":"none"}}>
					<div className="addstore-main">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">
								{(getStoreListData.length == 0) ?
									'Open Your First Store' :'Add Store'}
									</h5>
								<button type="button" className="close addstore-close" data-dismiss="modal" aria-label="Close" onClick={this.closePages}>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<p style={{"color":"red","float": "left"}}>{(this.state.addStoreMsg != "") ? this.state.addStoreMsg : ""}</p><br/>
								<form enctype="multipart/form-data" method="post" noValidate onSubmit={this.connectStripe}>
								<div className="form-row">
									<div className="form-group col-md-3">
										<span className="fileupload">										
											<input type="file" className="upload-logo" id="storeImg" aria-describedby="emailHelp" placeholder="Store Image" name="storeImg" key="storeImg" value={this.state.storeImg} onChange={this.handleUsernameChange} />

											{(this.state.images) ? 												
													<img src={this.state.imageURL} style={{width: '105px', height: '76px'}} alt={this.state.images}/>							
											 :null}
										</span>
									</div>
									{/*(this.state.images) ? 
									<div className="col-lg-9">
										<img src={this.state.imageURL} style={{width: '70px', height: '70px'}} alt={this.state.images}/>
									</div> 
									 :null*/}
								</div>
								<div className="form-row">
									<div className="form-group col-md-12">												
										<input type="text" className="form-control" id="storeName" aria-describedby="emailHelp" placeholder="Store Name" name="storeName" key="storeName" value={this.state.storeName} onChange={this.handleUsernameChange} required />
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeName}</p>
									</div>
									<div className="form-group col-md-12">
										<PhoneInput flags={flags} className="" defaultCountry="US" placeholder="Enter phone number" id="storePhoneNo" value={this.state.storePhoneNo} onChange={this.storePhoneNoChange}/>
										{/*<input type="text" className="form-control" id="storePhoneNo" placeholder="Store Phone No" name="storePhoneNo" value={this.state.storePhoneNo} onChange={this.handleUsernameChange} required />*/}
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storePhoneNo}</p>
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-12">												
										<textarea className="form-control" id="storeDesc" placeholder="Store Description" rows="3" name="storeDesc" value={this.state.storeDesc} onChange={this.handleUsernameChange} required></textarea>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeDesc}</p>													
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-12">
										<PlacesAutocomplete 
											value={this.state.storeAddress}
											onChange={this.handleChange}
											onSelect={this.handleSelect}
										  >
											{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											  <div>
												<input
												  {...getInputProps({
													placeholder: 'Search Address ...',
													className: 'form-control search_input',
												  })}
												/>
												<div className="autocomplete-dropdown-container">
												  {loading && <div>Loading...</div>}
												  {suggestions.map(suggestion => {
													const className = suggestion.active
													  ? 'suggestion-item--active'
													  : 'suggestion-item';
													// inline style for demonstration purpose
													const style = suggestion.active
													  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
													  : { backgroundColor: '#ffffff', cursor: 'pointer' };
													return (
													  <div
														{...getSuggestionItemProps(suggestion, {
														  className,
														  style,
														})}
													  >
														<span className="list-item-description">{suggestion.description}</span>
													  </div>
													);
												  })}
												</div>
											  </div>
											)}
										  </PlacesAutocomplete>
										  <p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeAddress}</p>
									</div>
									<div className="form-group col-md-12">
										<input type="text" className="form-control" id="store_apt_no" placeholder="Apt No/House No:/BuildingNo" name="store_apt_no" value={this.state.store_apt_no} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_apt_no}</p>
									</div>
									<div className="form-group col-md-12">
										<input type="text" className="form-control" id="store_street" placeholder="Street" name="store_street" value={this.state.store_street} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_street}</p>
									</div>
									<div className="form-group col-md-6">
										<input type="text" className="form-control" id="store_city" placeholder="City" name="store_city" value={this.state.store_city} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_city}</p>
									</div>
									<div className="form-group col-md-6">
										<input type="text" className="form-control" id="store_state" placeholder="State" name="store_state" value={this.state.store_state} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_state}</p>
									</div>
									<div className="form-group col-md-6">												
										<input type="text" className="form-control" id="store_zip_code" placeholder="Zip Code" name="store_zip_code" value={this.state.store_zip_code} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_zip_code}</p>
									</div>
									<div className="form-group col-md-6">												
										<input type="text" className="form-control" id="store_country" placeholder="Country" name="store_country" value={this.state.store_country} onChange={this.handleUsernameChange} required/>
										<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_country}</p>
									</div>
									{/* (localStorage.getItem("stripeConnect") == 0) ?
									(<div class="media">
									
									<div class="media-body">
									  <p> <small> We partner with Stripe for Payment Processing
								  You will be redirected to Stripe to create an account If you already have an account with Stripe you can use it.</small> </p>
									</div> <a href='' onClick={this.stripeConnect}><img class="ml-3" src="assets/images/stripe.png" alt="stipe"/></a>
								  </div>   ):''*/}
									<div className="col-md-12 text-center">
										<button type="button" className="btn btn-primary my-2 px-5 py-3" onClick={this.connectStripe}>Save and Continue</button>
									</div>
								</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="overlay-addstore" id="showOtpPopup" style={{"display":"none"}}>
					<div className="addstore-main">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Add Store</h5>
								<button type="button" className="close addstore-close" data-dismiss="modal" aria-label="Close" onClick={this.closePages}>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<form noValidate onSubmit={this.verifyOtp}>
								<div className="form-row">
									<div className="form-group col-md-12">	
										<p style={{"color":"blue"}}>{(this.state.doneMsg != "") ? this.state.doneMsg : ""}</p>
										<input type="text" className="form-control" id="otp" aria-describedby="emailHelp" placeholder="Ex: 1234" name="otp" key="otp" value={this.state.otp} onChange={this.handleUsernameChange} required />
										<p style={{"color":"red"}}>{(this.state.otpMsg != "") ? this.state.otpMsg : ""}</p>
									</div>
								</div>
								<div className="col-md-12 text-center">
									<button type="button" className="btn btn-primary my-2 px-5 py-3" onClick={this.verifyOtp}>Save and Continue</button>
								</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				
			</>
		);
    }
}
 
FeedsPages.propTypes = {
	auth: PropTypes.object.isRequired,
	storeresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getStoresCheck, getStores,clearErrors}
)(FeedsPages);