import Header from './partials/Header'
import Footer from './partials/Footer';
import InnerPages from './Pages/InnerPages';
import React, { Component } from 'react';
// import imageExists from 'image-exists';
import Loader from '../Loader';
import CONSTANT from '../Constant'; 
import axios from 'axios';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


async function getData(name) {	
	const user_id = localStorage.getItem("loggedInUserId");
	const config = {
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded'
		}
	}
	let response = await axios.post(CONSTANT.NSTABASE_URL+'web/getFilterData',{name: name,_id:(user_id) ? user_id:''});	
	return response.data.response_data
}

async function checkImg(img) {	
	await axios.get(img).then((response) => {
		if(response){
			return response
		}		
	})	
}

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

class Profile extends Component {
    constructor(props) {
        super(props);		
		this.state = {
			getFilterData : [],
			response_status: "",
			profileData: "",
			pageUrl: "",
			profile: {},
		};
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
    }
	
	async componentDidMount() {
		this.setState({loading: true});	
		let {name} = this.props.match.params
		const user_id = localStorage.getItem("loggedInUserId");

		//console.log(this.props.match.params);
		//getData = await this.getData(name)	
		// console.log(name);
		// console.log("sfsdf");
		// return false;
		let response = await axios.post(CONSTANT.NSTABASE_URL+'web/getFilterData',{name: name,_id:(user_id) ? user_id:''});	


		// console.log("sadasdasdasdasdasdasd");
		// var getData1 = Object.assign({},...response.data.response_data);
		// 		console.log(getData1.userProfesionalProfile);
		// 		console.log("tttttttttt");


		this.setState({getFilterData:response.data.response_data, loginData: "", pageUrl: name});		
		const response_status = localStorage.getItem('response_status');
		const profileData = localStorage.getItem('token') ? localStorage.getItem('token') : '';			
		let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:profileData});
		this.setState({profile:(getProfile.data.response_data) ? getProfile.data.response_data.profile:'', "response_status": response_status});
		this.setState({loading: false});	



		// const storeData = {
			// session_token: this.state.profile.session_token,
			// user_id: this.state.profile._id,
			// status:1
		// }
		// const config = {
			// headers: {
				// 'Content-Type': 'application/json','x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			// }
		// }
		
		// let response = await axios.post(CONSTANT.STABASE_URL+'store/updateStore', JSON.stringify(storeData), config);
		
		// if(response.data.response_status != 0){				
			// let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:this.state.profile.session_token});
			// var filterUserName = getProfile.data.response_data.profile.email.replace(/.com/g,'');
			// document.location.href = `../${filterUserName}`+"?clicked=store&stripe=connected"			
		// }
		
		// const stripeConnect = localStorage.getItem('stripeConnect');
		
		// if(stripeConnect != undefined || stripeConnect == '')
		// {
			// this.setState({stripeConnect: stripeConnect});
		// } else {
			// this.setState({stripeConnect: stripeConnect});
		// }
		
		// if(urlParam('clicked') === "store"){
			// document.getElementById('pills-contact-tab').click();
		// } else if(urlParam('stripe') === "connected"){
			
						
		// }
	}

	addFriend = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined) {
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"add_friend_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'friend/addFriend', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("friend", true)
			}
			this.componentDidMount();
		}		
	}

	unFriend = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"un_friend_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'friend/UnFriendRequest', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("friend", true)
			}
			this.componentDidMount();
		}		
	}
	
	addFollow = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/follow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
	}	

	unFollow = async e =>{
		e || e.preventDefault();
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/unfollow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
	}	
	
	handleImageError = e => {
		// imageExists(e, function(exists) {
			// if (exists) {
				// return e;
			// }
		// });		
	}
	
	handleUsernameChange = e => {
		
		if (e.target.files && e.target.files[0]) {
			this.setState({
				imageURL: URL.createObjectURL(e.target.files[0]),
				images:e.target.files[0]
			});
		}
		
		this.setState({ [e.target.id]: e.target.value});
		
	}
	
	handleLoginPage = e => {		
		e || e.preventDefault();
		
		const response_status = localStorage.getItem('token');
		const profileData = response_status ? localStorage.getItem('token') : '';	

		if(response_status && e.target.id == 'postImg'){	
		
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "/feed"
			
		} else if(response_status && e.target.id == 'addFriend'){
			
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "#"
			
		} else if(response_status && e.target.id == 'follow'){
			
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "#"
			
		} else if((response_status && e.target.id == 'pills-profile-tab') || (response_status && e.target.id == 'pills-contact-tab')){
			
			this.setState({profileData:profileData, response_status: 1});			
			document.location.href = "#"
			
		} else {
			
			//this.setState({loginData: "click",profileData:"", response_status: 0})
			//e.target.setAttribute("id", "login")
			//e.target.setAttribute("data-toggle", "modal")
			//e.target.setAttribute("data-target", "#Login")	
			this.props.history.push('/login')		
		}		
	}
	
	truncate(source, size) {
		return source.length > size ? source.slice(0, size - 1) + "…" : source;
	}
	
	render() {
		
		const { getFilterData, response_status, profileData, pageUrl, profile, stripeConnect } = this.state;
		
// 		 if(getFilterData==undefined){
// //this.props.history.push('/')	;
// document.location.href = "/404"

// 		 }
		var getData = Object.assign({},...getFilterData)
		var friendData = getData.userFriends ? getData.userFriends : 0
		//https://www.abluec.com/images/post/1638798994458_sample.pdf
		
		if(getData.resume!=''){
			var imageURL1=CONSTANT.NSTABASE_URL+'images/post/'+getData.resume;
		}
		else{
			var imageURL1 = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
		}

		if(getData.cover_letter!=''){
			var imageURL2=CONSTANT.NSTABASE_URL+'images/post/'+getData.cover_letter;
		}
		else{
			var imageURL2 = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
		}
		//var imageURL1=getData.cover_letter!='' ? CONSTANT.NSTABASE_URL+'images/post/'+getData.cover_letter:CONSTANT.NSTABASE_URL+'images/profile/noimage.png',
		// console.log("imageURL1");
		// 		console.log(getData);





		

		var userProfesionalProfileCheck=getData.userProfesionalProfile;	
		console.log(userProfesionalProfileCheck);
		//return false;

		var postsList = getData.postsList ? getData.postsList : 0
		var userPProfile = getData.userProfesionalProfile ? getData.userProfesionalProfile : 0
		var categoryList = userPProfile.categoryList ? userPProfile.categoryList : 0
		//var subCategoryList = userPProfile.categoryList.subCat ? userPProfile.categoryList.subCat : 0
		var getStoreInfo = getData.getStoreInfo ? getData.getStoreInfo : 0
		var AcademicData = userPProfile.AcademicData ? userPProfile.AcademicData : 0
		var userExprienceData =userPProfile.userExprienceData ? userPProfile.userExprienceData : 0
		
		
		if(getData.thumb_profile_image == undefined && getData.profile_image != undefined){
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+getData.profile_image
		} else if(getData.thumb_profile_image != undefined && getData.profile_image == undefined){
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+getData.thumb_profile_image
		} else if(getData.thumb_profile_image != undefined && getData.profile_image != undefined){
			var profileImg =CONSTANT.NSTABASE_URL+'images/profile/'+getData.profile_image
		} else {
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
		}
		let postCount = (postsList.length > 0) ? postsList.length:0 
		if(postsList.length > 0){
			
			postsList = postsList.length > 0 && postsList.filter((item) => {			
				if(item.images != undefined && item.images.length > 0){
					return item;
				}			
			});
			
		} else {
			postsList = []
		}
		



		
		var dateObj = new Date(getData._created_at);
		var month = dateObj.getUTCMonth('MMM') + 1; //months from 1-12
		var day = dateObj.getUTCDate();
		var year = dateObj.getUTCFullYear();
		var date = year + "-" + day + "-" + month;	
		var d = new Date(date.split("-")[0]+'-'+date.split("-")[2]+'-'+date.split("-")[1]).toLocaleString('en', { month: 'short', year: 'numeric' })
		
		var subItems = []
		if(categoryList.length > 0){
			
			categoryList.map((item) => {			
				item.subCat.map((subItem) => {			
					subItems.push(subItem.name)
				});
			});
			
		} else {
			subItems = []
		}

        return (
        <>
        <Header data="inner-head" loginData={this.state.loginData}></Header>
        <section className="bdr-top innerheight" >
	<div className="container">
		<div className="row">
		{
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
			<div className="col-12">
				<div className="profile_details">
				
						<div className="profile_area">						  
						  <div className="mx-md-5 profile_content">
						    <div className="profile-pic">  <img src={profileImg} alt="img" width="134" height="134" /> <p>{getData.name} &nbsp;{getData.display_name}</p><label>Joined on {d}</label></div>							
							{(getData.email == 'connect@abluec.com') ? 
								<>
								<p className="profile-btn" style={{"display": "block", "padding": 0, "clear": "both"}}>
									<a style={{"display": "none"}} className="btn" href="#" role="button" id="addFriend">&nbsp;</a>
									<a style={{"display": "none"}} className="btn" href="#" role="button" id="follow">&nbsp;</a>
								</p>
								</>
								: 
								<>
									<p className="profile_text">
										<label>{postCount}</label><span>Posts</span>
										<label>{getData.followUserListRowCount}</label>
										{(getData.is_private != 1 || getData.already_followed==1) ?( 
											<a href="" onClick={() => this.props.history.push( {pathname: '/follower',
											state: { 'userId':getData._id }})}><span>Followers</span></a>
									
										
										) :(<><span>Followers</span></>)}
										<label>{getData.followingUserListRowCount}</label>
										{(getData.is_private != 1 || getData.already_followed==1) ?( 
											<a href="" onClick={() => this.props.history.push( {pathname: '/following',
											state: { 'userId':getData._id }})}><span>Following</span></a>
									
										
										) :(<><span>Following</span></>)}
										
										<label>{friendData.length}</label>

										{/*<span>Friends</span>*/}

										{(getData.is_private != 1 || getData.already_followed==1) ?( 
											<a href="" onClick={() => this.props.history.push( {pathname: '/friend',
											state: { 'userId':getData._id }})}><span>Friends</span></a>
									
										
										) :(<><span>Friends</span></>)}


									</p>
									<p className="profile-btn">
										{/* {(localStorage.getItem("friend") == undefined) ?
										(<a className="btn btn-primary mr-2" href="#" role="button" id="addFriend" onClick={this.handleLoginPage}>Add Friend</a>) : (<><div className="btn btn-primary mr-2" id="addFriend">Friend</div></>)}
										{(localStorage.getItem("follow") == undefined) ?
										(<a className="btn btn-primary" href="#" role="button" id="follow" onClick={this.handleLoginPage}>Follow</a>) : (<><div className="btn btn-primary" id="follow">Followed</div></>)}
									 */}
									 {(getData.already_followed == undefined || getData.already_followed == 0) ?
														(<button type="button" onClick={() => this.addFollow(getData._id)} className="btn btn-primary mr-2">Follow</button>) :
														
														
														(<><button type="button" onClick={() => this.unFollow(getData._id)} className="btn btn-primary mr-2">Unfollow</button></>)}
									{(getData.already_send_request == undefined || getData.already_send_request == 0) ?
														(<button type="button" onClick={() => this.addFriend(getData._id)} className="btn btn-primary mr-2">Add Friend</button>) :
														
														
														(<><button type="button" onClick={() => this.unFriend(getData._id)} className="btn btn-light mr-2">Cancel Request</button></>)}
													
														
														
														
													
									
									</p>
								</>
							}					    
						   
						  </div>
						</div>
						{(getData.is_private != 1  || getData.already_followed==1) ?(
						<div className="tab-sec">
						<ul class="nav nav-pills mb-3" id="myTab" role="tablist" style={{"justify-content":"left"}}>
  <li class="nav-item" role="presentation">
    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#homes" role="tab" aria-controls="home" aria-selected="true">Posts</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profiles" role="tab" aria-controls="profile" aria-selected="false">PR</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">STORE</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="homes" role="tabpanel" aria-labelledby="home-tab">
  {/*<div className="row postImg justify-content-center"> */}
  <div className="row postImg ml-3">

  {(postsList.length != 0) ? 
								<>

  {postsList.length > 0 && postsList.map((item) => 
			<div className="store-sec">
			<span className="store-img"> 	 
				<img onError={this.handleImageError(CONSTANT.NSTABASE_URL+"images/post/"+item.images[0].ImageName)} src={item.images[0] ? CONSTANT.NSTABASE_URL+"images/post/"+item.images[0].ImageName : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} alt="" id="postImg" onClick={this.handleLoginPage} />
				</span><p> {this.truncate(item.post_content,100)}  </p>
				
				</div>
		)} 
  	</>
								: 
								<>
									 <p> No Post Found <br/><br/> </p>
								</> 
							}
</div>
  </div>
  <div class="tab-pane fade" id="profiles" role="tabpanel" aria-labelledby="profile-tab">
  <div className="row ml-3">
  <div className="col-md-8">
				<h5>Academic</h5>
				<div className="list-sec">
					<ul>
{(userProfesionalProfileCheck=='') ? <><p> No Academic Found</p></>:<></>} 
					{(AcademicData.length != 0) ? 
								<>


						{AcademicData.length > 0 && AcademicData.map((items) => 
							<div className="row">
							<div className="col-md-12">
							<li>{items.title ? items.title : ""} | College Name : {items.college_or_school_name} | Years : {items.start_year}-{items.end_year}</li>
							</div>
							</div>
						)}

						</>
								: 
								<>
									 <p> No Academic Found </p>
								</> 
							}

					</ul>
				</div>
				<h5>Skills</h5>
				<div className="list-sec">
					<ul>
					{(userProfesionalProfileCheck=='') ? <><p> No Skills Found </p></>:<></>} 
						
					{(categoryList.length != 0) ? 
								<>

						{categoryList.length > 0 && categoryList.map((items) => 

							
							<div class="row"><div class="col-md-12">
							<li>Category : {items.categoryName ? items.categoryName : ""} | Sub Category : {items.subCat.map((subItem) => subItem.name=="Other" ? subItem.otherdata : subItem.name	)} | Experience : {items.subCat.map((subItem) => subItem.experience	)}</li>
							
							</div></div>
						)}
						</>
								: 
								<>

									 <p> No Skills Found </p>
								</> 
							}


						
					</ul>
				</div>
				<h5>Work Experience</h5>
				<div className="list-sec">
					{/* <ul>
						<li>  {parseFloat(userPProfile.end_year - userPProfile.start_year)} Years in Academic </li>
					</ul> */}

<ul>

	{(userProfesionalProfileCheck=='') ? <><p> No Work Experienc Found </p></>:<></>} 
					{(userExprienceData.length != 0) ? 
								<>



						{userExprienceData.length > 0 && userExprienceData.map((items) => 
							<div className="row">
							<div className="col-md-12">
							<li>Company : {items.company ? items.company : ""} | Description : {items.description}</li>
							</div>
							</div>
						)}

						</>
								: 
								<>
									 <p> No Work Experience Found </p>
								</> 
							}



					</ul>
				</div>

				<div className="resume d-flex justify-content-left">
				<div className="mr-3">
						<h5>Cover Letter</h5>
						<span className="profile_details profile_user">
						<span className="fileuploadResume">
						{(getData.cover_letter != '' && getData.cover_letter != undefined ) ? 
								<>
								<iframe  src={imageURL2} /><br/>
							<a href={imageURL2} target="_blank">Preview</a>

						</>
								: 
								<>
									<img src="../assets/img/cover-letter.png" alt="Cover Letter" />
								</> 

						}

						
						</span>
						</span>
					</div>

					<div className="mr-2">
						<h5>Resume</h5>
				<span className="profile_details profile_user">
				<span className="fileuploadResume">
						{(getData.resume != '' && getData.resume != undefined ) ? 
								<>
								<iframe  src={imageURL1} /><br/>
						<a href={imageURL1} target="_blank">Preview</a>

						</>
								: 
								<>
									<img src="../assets/img/resume.png" alt="resume" />
								</> 

						}
				</span>
				</span>
				</div>
						
				</div>
			</div>

			</div>


  </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
  <div className="store-sec-main row ml-3">
  {(getStoreInfo.length != 0) ? 
								<>
									{getStoreInfo.length > 0 && getStoreInfo.map((item) =>
										
										<div className="store-sec" onClick={() => this.props.history.push('/store/'+item.store_name)}>
											<span className="store-img">
												<input type="radio" id="kwik" name="jo" value="kwik" />
												<label for="kwik"> </label>
												<img src={item.store_logo ? CONSTANT.NSTABASE_URL+"images/store/"+item.store_logo : CONSTANT.NSTABASE_URL+"images/profile/noimage.png"} style={{width:90, height:78}} alt="" />
											</span>
											<p>{item.store_name}</p>
										</div>
									)}																		
								</>
								: 
								<>
									 <p> No Store Found <br/><br/> </p>
								</> 
							}

</div>
  </div>
</div>
</div>
						):(<><div className="tab-sec"><img style={{"width":"22px"}} src="/assets/images/lock.png"/> This Account is Private.<br/> Follow this account to see their Photos , Videos & Feeds</div></>)}
					
				</div>
			</div>
		</div>
	</div>
</section>
    <Footer></Footer>
    </>  );
    }
}
 
export default Profile;