import axios from "axios";
import Constant from '../Constant';
import {
    GET_ERRORS,
    SET_MESSAGE,
    GET_INNERPAGES,
    GET_DATAACF,
    INNERPAGES_LOADING
} from "./types";


export const getPagesContent = (name) => (dispatch) => {
	dispatch(setLoading());
	axios.get("https://abluec.com:8443/wp-json/wp/v2/pages?slug="+name)
        .then((res) => {
            var filter = res.data
			dispatch({
                type: GET_INNERPAGES,
                payload: filter
            });
        })
        .catch(err => {
            if(err.response.data=="Unauthorized"){
                window.location.href = "/";
            }else{
                dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
            }
        });
};

export const getDataACF = (name, history) => (dispatch) => {
	dispatch(setLoading());
	axios.get("https://abluec.com:8443/wp-json/wp/v2/pages?slug="+name)
        .then((res) => {			
            var filter = res.data			
			axios.get("https://abluec.com:8443/wp-json/acf/v3/posts/"+filter[0].id).then((res) => {
					var filter = res.data.acf
					dispatch({
						type: GET_DATAACF,
						payload: filter
					});
				})
				.catch(err => {
					if(err.response.data=="Unauthorized"){
						window.location.href = "/";
					}else{
						dispatch({
							type: GET_ERRORS,
							payload: err.response.data
						})
					}
				});			
        })
        .catch(error => {
			const errorMessage = (()=> {
				if (error.response) {
				history.push("/404");
				return error.response.data;
				} else if (error.request) {
				history.push("/404");
				return error.request;
				} else {
				history.push("/404");
				return error.message;
				}
			})();
			dispatch({
				type: GET_ERRORS,
				payload: errorMessage
			})
        });
	
};



export const setLoading = () => {
    return {
        type: INNERPAGES_LOADING
    };
};

export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        payload: msg
    };
};

export const clearErrors = () => {
    return {
        type: GET_ERRORS,
        payload: {}
    };
}
