import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import messageReducer from "./messageReducers";
import InnerpagesReducers from "./InnerpagesReducers";
import HomepagesReducers from "./HomepagesReducers";
import SearchresultReducers from "./SearchresultReducers";
import StoreReducers from "./storeReducers";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
	messages: messageReducer,
    innerpages: InnerpagesReducers,
    homepages: HomepagesReducers,
    searchresult: SearchresultReducers,
    storeresult: StoreReducers,
});
