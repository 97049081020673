import React, { Component } from 'react';
import { getPackageTransaction } from '../Api'; 
import Button from "./Button";  
//import  OrderItem  from './OrderItem'; 
//import Search from "../../user/Search"; 
//import Button from "../../user/Button"; 
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import CONSTANT from '../../Constant';
 

const initialState = {  
    status:1,
    order_id:'',
    transactionData:'',
    isOpen:false,
    order_status:'',
    product_name:'',
    custom_order_id:'',
    store_name:'',
    storeName:'',
    imageURL:'',
 } 

class PackageTransaction extends Component {

    constructor(props) {
        super(props); 
        this.state = initialState;    
    } 


    async componentDidMount() { 
      if(localStorage.getItem("store_id") == undefined || localStorage.getItem("store_id") ==''){
        this.props.history.push('/404');
    }
      const user_id = localStorage.getItem("loggedInUserId");
      const session_token = localStorage.getItem("token"); 
      const store_id = localStorage.getItem("store_id"); 
      this.transactionList(session_token,store_id,user_id);  
    
     var formData = {"session_token":session_token,"user_id":user_id,"store_id":store_id}	
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
    }		
        let storeData = await axios.post(CONSTANT.STABASE_URL+'web/store/getStores',formData,config );
        if(storeData.data.response_status === 1){
            if(storeData.data.response_data.store_list.length > 0){
        this.setState({storeName:storeData.data.response_data.store_list[0].store_name,imageURL:(storeData.data.response_data.store_list[0].store_image) ? CONSTANT.NSTABASE_URL+ '../images/store/'+ storeData.data.response_data.store_list[0].store_image:CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
             
        });
            }else{
                this.setState({storeName:'',imageURL:''});
            }  
        }
  }

    
   showPopup(){
    this.setState({
      isOpen:true,
    })
  }

  hidePopup(){
    this.setState({
      isOpen:false,
    })
  }

  
  onChange = e => {

    this.setState({[e.target.name] : e.target.value})
  };

   
    
    transactionList = async (session_token,store_id,status) => { 
      this.setState({loading:true})
      getPackageTransaction(session_token,store_id,status).then(data => { 
		
      this.setState({loading:false})
      this.setState({transactionData:data.response_data});
      

         
    });
  }  






  
    
  

    render() {
      let {transactionData}=this.state;


        return (
          <>
          <Header/> 
           
            {/* <Search></Search> 
            <Button></Button>   */}

           {/* {console.log(this.state.transactionData)}  */}
          <section id="tabs" className="project-tab bdr-top innerheight">
            <div className="container">
                <div className="row">
                {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
                    <div className="col-md-12">
                      

                     
                        
                            <div className="tab-pane fade show active order-main" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="header_catehory"> <h2 class="jobs_heading mb-2 ">Package Transaction</h2> </div> 
                            <div className="card">
                            <div className="card-header"><Button></Button></div>
                            <div className="card-body px-0">

                             <div className="col-lg-12 col-md-12 col-xs-12">
                              <div className="row">
                                      <div className="col-lg-3 col-md-3 col-xs-12">
                        <div className="card">
                        <div className="card-body">
                        <div className="d-flex flex-column align-items-center text-center">
                        <span className="store-img">  <img src={this.state.imageURL} alt={this.state.imageURL}  /> </span>
                                  <div className="mt-3">
                          <h4>{this.state.storeName}</h4> 
                                    
                        </div>
                        <div className="mt-3">
                        <a href={`/vendor/store/${this.state.storeName}`} className="btn btn-primary">Add product</a>
                                    
                        </div>
                            </div>
                              </div>
                      </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-xs-12">
             
                                <table className="table" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Package</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Customer Name</th>
                                            <th>Store</th>
                                            <th>Transaction Id</th>
                                            <th>Status</th>
                                           
                                           
                                            
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { transactionData!=undefined  ?  transactionData.length > 0 && transactionData.map((c,i) => 
                                         <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{c.package_type}</td>
                                            <td>{c.amount}</td>
                                            <td>{c.quantity}</td>
          <td>{c.customer.name}</td>
          <td>{c.store.store_name}</td>
          <td>{c.subscription_id}</td>
                                            {/* <td>{(c.product.length > 0) ? c.product[0].product_title :''}</td>
                                            <td>{c.order_total}</td>
                                            <td>{c.createdAt}</td> */}
                                            <td>{(c.status == 1) ? 'Active':'Deactive'}</td>
                                            
                                          
                                        </tr>
                                      
                                      
                                        )
                                        :<tr><td colSpan="8"> <br /> <br /> No order found</td></tr>}


                                    </tbody>
                                </table>
                            </div>
                            </div>
                            </div>  </div>
                           
                          </div>
                    </div>
               
  </div>
                
            </div>
</div>
           
        </section>
          <Footer/>
        
          </>
        );
    }
}

export default PackageTransaction;