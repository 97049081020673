import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import {getAllCategory,getSubCategory} from '../../components/Api'; 

import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  loading:false
} 

class Jobs extends Component {
  constructor(props) {
    super(props);
       
  this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    this.setState({loading: false});  
    }



jobsListing = e => {
    e.preventDefault();
    document.location.href = `/jobs-listing`;
    // if(e.target.id == 'store')
    // document.getElementById("showStorePopup").style.display = "block"
  } 


searchForWork = e => {
    e.preventDefault();
    document.location.href = `/Search-result?q=&source=jobs`;
    // if(e.target.id == 'store')
    // document.getElementById("showStorePopup").style.display = "block"
  } 


  becomeServiceProvider = e => {
       e.preventDefault();
        document.location.href = `/profile#skillbox`;
      }

    seachForServiceProvider = e => {
     e.preventDefault();
      document.location.href = `/service-provider`;
    }


    
  render() {
    
    
   
  
    return (
      <>
       <Metatitle title="ServiceProvider | aBlueC" metaDescription="ServiceProvider" ogTitle="ServiceProvider" ogImage=""></Metatitle>
      <Header/> 

       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

     
        <section className="sidebar-layout bdr-top innerheight">
            <div className="container">
              <div className="row">                             
               
              
              <div className="col-12">   <div id="content" className="mystore" style={{"textAlign":"center"}}></div> </div>             
                  {this.state.newloader ? (<Loader/>) : (<></>)}
                </div>
                <div className="row justify-content-center">
                 <div className="col-12 col-sm-5 mt-2"> 
                  <a  onClick={this.jobsListing}  className="btn btn-primary jobs-boxes" id="joblisting" >
                    <img src="/assets/images/post-a-job.svg" alt="Post a job" style={{"marginTop":"28px"}} /><br/>Post a Job</a>
                    </div>
              
                  <div className="col-12 col-sm-5 mt-2"> 
                  <a onClick={this.searchForWork}  className="btn btn-primary jobs-boxes" id="sfw">
                  <img src="/assets/images/search-for-work.svg" alt="Search for work" style={{"marginTop":"28px"}} /><br/>Search for Work</a>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                  <div className="col-12 col-sm-5 mt-2"> 
                  <a onClick={this.becomeServiceProvider} className="btn btn-primary jobs-boxes" id="bsp">
                  <img src="/assets/images/service-provider.svg" alt="service Provider" style={{"marginTop":"28px"}} /><br/>Become a service provider</a>
                    </div>
                    <div className="col-12 col-sm-5 mt-2">  
                     <a onClick={this.seachForServiceProvider}  className="btn btn-primary jobs-boxes" id="sfsp">
                     <img src="/assets/images/search-for-service-provider.svg" alt="Search for service provider" style={{"marginTop":"28px"}} /><br/>Search for a Service Provider</a>
                    </div>
                 
                                      
                                      
                               
                </div>
              </div>
        </section>



      
    <Footer/>
   
   
  </>
    );
  }

} 
 

 
export default Jobs;