import {
    GET_INNERPAGES,
    GET_DATAACF,
    INNERPAGES_LOADING
   
} from "../actions/types";

const initialState = {
    statec: {},
    getDataPage: {},
    getDataAF: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_INNERPAGES:
            return {
                ...state,
                getDataPage: action.payload,
                loading: false
                
            };			
		case GET_DATAACF:
            return {
                ...state,
                getDataAF: action.payload,
                loading: false                
            };        
		case INNERPAGES_LOADING:
			return {
				...state,
				loading: true
			};              
        default:
        return state;
    }
}
