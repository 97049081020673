import React, { Component } from 'react';
import { getCustomerOrderList } from '../Api'; 
//import  OrderItem  from './OrderItem'; 
//import Search from "../../user/Search"; 
//import Button from "../../user/Button"; 
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import CONSTANT from '../../Constant';
 

const initialState = {  
    status:1,
    pending:'',
    accepted:'',
    completed:'',
    current_product:'',
    order_id:'',
    order_date:'',
    customer_name:'',
    customer_address:'',
    instruction:'',
    order_total:0,
    items:'',
    orderData:'',
    isOpen:false,
    isOpen1:false,
    order_status:4,
    product_name:'',
	product_qty:'',
	product_price:'',
	product_sku:'',
    custom_order_id:'',
    store_name:'',
 } 

class CustomerOrder extends Component {

    constructor(props) {
        super(props); 
        this.state = initialState;
    } 


    orderDetails(order){
      //console.log(order.product);
        let items = order.product.map((item,index) => { 
              return ( 
                <tr>
                <td>
                <div className="product_name">
                <h2>{item.qty}x{item.product_title}</h2>
                </div>
                </td>
                <td>
                &nbsp;
                </td>
                <td>
                &nbsp;
                </td>
              <td><span>{CONSTANT.CURRENCY}{item.price}</span></td>
                </tr>
            ); 
        })  
              
      var orderDate = new Date( Date.parse(order.createdAt));
      this.setState({
        order_id:order._id, 
        order_date:orderDate.toLocaleString(),
        customer_name:order.customer.name,
        customer_address:order.address.complete_address,
        instruction:order.instruction,
        order_total:order.order_total,
        items:items,
      })
    }
    
    orderList = async (session_token,store_id,status,user_id) => { 
	
	 this.setState({loading:true})
    getCustomerOrderList(session_token,store_id,status,user_id).then(data => {
		data.response_data.Order_Info.payment_type = (data.response_data.Order_Info.payment_type != undefined) ? data.response_data.Order_Info.payment_type : ""
      var i = 0;  
      this.setState({loading:false})
      this.setState({orderData:data.response_data.Order_Info});        
    });
  }  

    
    componentDidMount() { 
        const user_id = localStorage.getItem("loggedInUserId");
        const session_token = localStorage.getItem("token"); 
        const store_id = localStorage.getItem("store_id"); 
        this.orderList(session_token,1,user_id);  
       // this.orderList(session_token,store_id,2);  
       // this.orderList(session_token,store_id,3);  
    }

    showPopup(){
      this.setState({
        isOpen:true,
      })
    }
  
    hidePopup(){
      this.setState({
        isOpen:false,
      })
    }

	viewOrder = e => {
    this.setState({
      isOpen:true
  })

  
    if(e.order_data){
      this.setState({
        custom_order_id:e.order_data.custom_order_id,
        customer_name:e.order_data.customer.name,
        order_total:e.order_data.order_total,
        product_name:(e.order_data.product.length > 0) ? e.order_data.product[0].product_title :'',
        product_qty:(e.order_data.product.length > 0) ? e.order_data.product[0].qty :'',
        product_price:(e.order_data.product.length > 0) ? e.order_data.product[0].total_price :'',
        product_sku:(e.order_data.product.length > 0) ? e.order_data.product[0].sku :'',
        order_date:e.order_data.createdAt,
        order_status:e.order_data.status,
        order_id:e.order_data._id,
        store_name:e.order_data.store.store_name,
        payment_type:e.order_data.payment_type,

    })
    }else{
      this.setState({
        custom_order_id:'',
        customer_name:'',
        order_total:'',
        product_name:'',
        product_qty:'',
        product_price:'',
        product_sku:'',
        order_date:'',
        order_status:'',
        order_id:'',
        store_name:''
    })
    }
   
  }
  
  showWarningModal =(e) => {
    this.setState({isOpen1:true,order_id:e})
   };
  hideWarningModal =() =>{
    this.setState({isOpen1:false})
  };

  cancelOrder = async e =>{
    this.setState({  
      loading:true
    }) 
    const order_id = e;  
    const user_id = localStorage.getItem("loggedInUserId");  
    const token   = localStorage.getItem("token");  
//console.log(order_status); return
const X_ACCESS_TOKEN = CONSTANT.HEADER;  

const headers = {
  'x-access-token': X_ACCESS_TOKEN
}
        var data = {"session_token":token,"user_id":user_id,"order_id":this.state.order_id,"status":4}

       let response = await axios.post(CONSTANT.STABASE_URL+'store/changeOrderStatus',data,{headers: headers});
       if(response.data.response_status != 0){	
         // window.location.reload();
         this.setState({  
          loading:false,isOpen1:false
        }) 
         await this.componentDidMount();
       }
         

  }

  
  submitForm = (e) => {  
    e.preventDefault(); 
    const isValid = this.validate();

   
    if(isValid){
    const X_ACCESS_TOKEN = CONSTANT.HEADER;  

    const headers = {
      'x-access-token': X_ACCESS_TOKEN
    }

    this.setState({  
      loading:true
    }) 

    const {order_id,order_status} = this.state;  
    const user_id = localStorage.getItem("loggedInUserId");  
    const token   = localStorage.getItem("token");  
//console.log(order_status); return
       
        var data = {"session_token":token,"user_id":user_id,"order_id":order_id,"status":4}

        axios.post(CONSTANT.STABASE_URL+'store/changeOrderStatus',data,{
          headers: headers
        }).then(res => {
          this.setState({  
            loading:false
           
          }) 

          window.location.reload();
         
        }) 
     }
    }

 
    validate = () =>{
      let statusError = '';
      if(!this.state.order_status){
        statusError = "Please select order status";
      }  
      if(statusError){ 
          this.setState({statusError});
          return false;
      } else { 
          this.setState({statusError});
          return true;
      } 
    }  

    render() {
      var imgs1 = process.env.PUBLIC_URL + "/assets/images/svg/pin.svg"; 
      var imgs2 = process.env.PUBLIC_URL + "/assets/images/svg/visa.svg"; 
      let {orderData}=this.state;

      orderData.length > 0 && orderData.map(
        (item) => {

item.createdAt = new Date(item.createdAt).toDateString();
        })

       console.log(orderData);
        return (
          <>
          <Header/> 
         
            {/* <Search></Search> 
            <Button></Button>   */}

           {/* {console.log(this.state.orderData)}  */}
          <section id="tabs" class="project-tab bdr-top innerheight">
            <div class="container">
                <div class="row">
                {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
                    <div class="col-md-12">
                      
                        
                            <div class="tab-pane fade show active order-main" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="card"> 
                            <div className="card-header">Shopping Orders</div>
                            <div className="card-body px-0">
                                <table className="table" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Store</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th>Order Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { orderData.length > 0 ?
          orderData.length > 0 && orderData.map(
              (c,i) => 
                                         <tr key={i}>
                                            <td><a href="#">{c.custom_order_id}</a></td>
          <td>{c.customer.name}</td>
          <td><a href={`/store/${c.store.store_name}`}>{c.store.store_name} </a></td>
                                            <td>{(c.product.length > 0) ? c.product[0].product_title :''}</td>
                                            <td>{c.order_total}</td>
                                            <td>{c.createdAt}</td>
                                            <td>{(c.status == 1) ? 'Pending':(c.status == 2) ? 'Inprocessed':(c.status == 3) ? 'Completed':(c.status == 4) ? 'Cancel':''}</td>
                                            <td>
                                            <span onClick={() => this.viewOrder({"order_data":c})} className="order-btns">Details</span>
                                           { (c.status != 3 && c.status != 4) ? <span onClick={() => this.showWarningModal(c._id)} className="order-btns cancel">Cancel</span>:<></>}
                                            </td>
                                        </tr>
                                      
                                      
                                        )
                                        :<tr><td>No order found</td></tr>}


                                    </tbody>
                                </table>
                            </div>
                            </div>
                            </div>
                        
                    </div>
                </div>
                <Modal show={this.state.isOpen} onHide={this.hidePopup.bind(this)} backdrop="static"> 
                <div class="modal-header card-header"><h5 class="modal-title">Order Details</h5><button type="button" className="close store post-close" onClick={this.hidePopup.bind(this)}><span aria-hidden="true">×</span></button></div> 
                <form onSubmit={this.submitForm} id="feedform" method="post">
        <Modal.Body>
        <ul className="list-group order-list">
   <li className="row"> <div className="label_title col-md-4">Order Id :</div>  <div className="col-md-8">  {this.state.custom_order_id} </div> </li>
  <li className="row"> <div className="label_title col-md-4">Customer Name :</div> <div className="col-md-8">{this.state.customer_name}</div></li>
  <li className="row"><div className="label_title col-md-4">Store Name :</div>  <div className="col-md-8"><a href={`/store/${this.state.store_name}`}>{this.state.store_name}</a></div></li>
  <li className="row"><div className="label_title col-md-4">Product SKU No :</div> <div className="col-md-8">{this.state.product_sku}</div></li>
  <li className="row"><div className="label_title col-md-4">Product Name :</div> <div className="col-md-8">{this.state.product_name}</div></li>
  <li className="row"><div className="label_title col-md-4">Product Qty :</div> <div className="col-md-8">{this.state.product_qty}</div></li>
  <li className="row"><div className="label_title col-md-4">Payment Type :</div> <div className="col-md-8">{this.state.payment_type}</div></li>
  <li className="row"><div className="label_title col-md-4">Product Price :</div> <div className="col-md-8">{this.state.product_price}</div></li>
  <li className="row"><div className="label_title col-md-4">Order Total Amount :</div> <div className="col-md-8">{this.state.order_total}</div></li>
  <li className="row"><div className="label_title col-md-4">Order Date :</div>  <div className="col-md-8">{this.state.order_date}</div></li>
  <li className="row">
  <div className="label_title col-md-4">Status :</div><div className="col-md-8">{(this.state.order_status == 1) ? 'Pending':(this.state.order_status == 2) ? 'Inprocessed':(this.state.order_status == 3) ? 'Completed':(this.state.order_status == 4) ? 'Cancel':''}</div>
  
  
  
  <div className="col-md-8">  
		{/* <select  className="form-control"                        
		name='order_status'
		id='order_status'
		onChange={this.onChange}
		value={this.state.order_status}
		>
		<option value="">Select Status</option>
		<option value="1">Pending</option>
		<option value="2">Inprocessed</option>
		<option value="3">Completed</option> 
		<option value="4">Cancel</option>
		</select>*/}
		<li style={{"list-style-type": "none"}}>
		{(this.state.order_status == 1 || this.state.order_status == 2) ?
		(<button type="submit" className="save_bt">Cancel Order</button>)
		:(<></>)
		}
		</li>
	</div>
  </li>
</ul>

        
<div className="error-msg"> {this.state.statusError}</div>


        
       
</Modal.Body>
        

        
         
          </form>
    </Modal>

               
<Modal id="modal_body" show={this.state.isOpen1} onHide={this.hideWarningModal}> 
    <Modal.Body> 
        <div className="modal-dialog" role="document">
          <div className="modal-content confirmpopup">
           
            
            <div class="card-header text-center">    Are you sure you want to cancel order it?  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideWarningModal}>
            <span aria-hidden="true">×</span>
            </button> </div>
            <div className="modal-body sure_delete_wrp">
             
              <div className="post_bt_public cancel_order">
                <ul>
                  <li>
                    <button type="button" className="save_bt" onClick={(e) => this.cancelOrder()}>YES</button>
                  </li>
                  <li>
                    <button type="button" className="back_bt reject_bt" onClick={this.hideWarningModal}>NO</button>
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div> 
    </Modal.Body>
    </Modal>
            </div>
        </section>
          <Footer/>
        
          </>
        );
    }
}

export default CustomerOrder;