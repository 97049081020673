import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import React, { Component } from 'react';
import { useHistory, Redirect } from "react-router-dom";
// import imageExists from 'image-exists';
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../Loader';

class FeedsPages extends Component {
    constructor(props) {
        super(props);
		this.state = { newloader: true};

    }
	
	async componentDidMount() {
		this.setState({newloader: false});
	}
		
	render() {
		
		const { newloader = true } = this.state;
		
		return (
			<>
				<Header data="inner-head"></Header>
				<section className="sidebar-layout">
						<div className="container-fluid bdr-top">
							<div className="row">															
								<div className="col-12">
									<h3 id="title" className="mt-5">Feeds</h3>								
									<div id="content" className="mystore">										
										{this.state.newloader ? (<Loader/>) : (<></>)}
										<h3 id="title">Coming Soon...</h3>																		
									</div>								
								</div>
							</div>
						</div>
				</section>
				<Footer></Footer>
			</>
		);
    }
}
 
export default FeedsPages;