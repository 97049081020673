import axios from "axios";
import CONSTANT from '../Constant';
import {
    GET_ERRORS,
    SET_MESSAGE,
    GET_SEARCHRESULTDATA,
    GET_DISTANCE,
    GET_SEARCHRESULT,
    GET_SEARCHPRODUCTRESULT,
    GET_SEARCHJOBRESULT,
    GET_SEARCHPEOPLERESULT,
    GET_SEARCHTOPICSRESULT,
    GET_SEARCHSTORESRESULT,
    SEARCHRESULT_LOADING
} from "./types";

export const getDistance = (sendData) => (dispatch) => {	
	dispatch(setLoading());
    axios.post(CONSTANT.NSTABASE_URL+"store/getStoreById",sendData).then((res) => {
		var filter = res.data.response_data.languages
		dispatch({
			type: GET_DISTANCE,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};


export const getPagesContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());
    axios.post(CONSTANT.NSTABASE_URL+"web/search",sendData).then((res) => {
		var filter = res.data.response_data.languages
		dispatch({
			type: GET_SEARCHRESULT,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getProductContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());
    axios.post(CONSTANT.NSTABASE_URL+"web/search",sendData).then((res) => {
		
		if(res.data.response_data.languages.length > 0 && res.data.response_status != 0)		
			var filter = res.data.response_data.languages[1].languages ? res.data.response_data.languages[1].languages : []
		else
			var filter = []		
		
		dispatch({
			type: GET_SEARCHPRODUCTRESULT,
			payload: filter
		});
		
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getJobContent = (sendData) => (dispatch) => {	
	console.log(GET_SEARCHPRODUCTRESULT);
	dispatch(setLoading());
    axios.post(CONSTANT.NSTABASE_URL+"web/search",sendData).then((res) => {
		
		if(res.data.response_data.languages.length > 0 && res.data.response_status != 0)		
			var filter = res.data.response_data.languages[3].languages ? res.data.response_data.languages[3].languages : []
		else
			var filter = []		
		
		dispatch({
			type: GET_SEARCHJOBRESULT,
			payload: filter
		});
		
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getPeopleContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());
	axios.post(CONSTANT.NSTABASE_URL+"web/searchPeopleLists",sendData).then((res) => {
		if(res.data.response_data.languages.length > 0 && res.data.response_status != 0)			
			var filter = res.data.response_data.languages[0].languages ? res.data.response_data.languages[0].languages : []
		else 
			var filter = []

		dispatch({
			type: GET_SEARCHPEOPLERESULT,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getTopicsContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());	
	axios.post(CONSTANT.STABASE_URL +'store/topicSearchNew',sendData,{headers: { "x-access-token": CONSTANT.HEADER}
        }).then((res) => {
		if(res.data.response_status != 0 && res.data.response_data.topicSearch.items != undefined){
			var filter = res.data.response_data.topicSearch.items			
		} else {
			var filter = []
		}
		dispatch({
			type: GET_SEARCHTOPICSRESULT,
			payload: filter
		});	
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};


export const getStoreContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());
	axios.post(CONSTANT.STABASE_URL +'store/searchStoreByNameNew1',sendData,{headers: { "x-access-token": CONSTANT.HEADER}
        }).then((res) => {
		if(res.data.response_status != 0)
			if(res.data.response_data.near_buy_stores.length > 0)			
				var filter = res.data.response_data.near_buy_stores ? res.data.response_data.near_buy_stores : []
			else 
				var filter = []
		else
			var filter = []
				
		dispatch({
			type: GET_SEARCHSTORESRESULT,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const setLoading = () => {
    return {
        type: SEARCHRESULT_LOADING
    };
};

export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        payload: msg
    };
};

export const clearErrors = () => {
    return {
        type: GET_ERRORS,
        payload: {}
    };
}