import Header from '../partials/Header'
import Footer from '../partials/Footer';
import React, { Component } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../../Loader';
// New One
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { getPagesContent, getDataACF, clearErrors } from "../../actions/InnerpagesActions";
import Metatitle from "../../components/metaTitle";

class InnerPages extends Component {
	constructor(props) {
		super(props);
		this.state = { newloader: true, errors: {}, errorstatus:true, showmessage: '', pageName: "" };
	}

	componentWillUnmount() {
		this.props.clearErrors();
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (nextProps.errors) {
			return {
				errors: nextProps.errors
			}
		}
	}
	async componentDidMount() {
		let { name } = this.props.match.params
		var getPagesContent = await this.props.getPagesContent(name)
		var getDataACFContent = await this.props.getDataACF(name, this.props.history)
		this.setState({ newloader: false, pageName: name });
	}

	render() {

		const { errorstatus, errors, showmessage, pageName } = this.state;
		const { getDataPage, getDataAF } = this.props.innerpages
		var heading_name = getDataAF.heading_name ? getDataAF.heading_name : ""
		var choosepages = getDataAF.choose_pages ? getDataAF.choose_pages : []

		var getData = (getDataPage.length > 0) ? Object.assign({}, ...getDataPage) : []

		getDataPage.length > 0 && getDataPage.map(item => {
			if (item.content.rendered != "") {
				document.getElementById("content").innerHTML = item.content.rendered
				document.getElementById("title").innerHTML = item.title.rendered
			} else {
				document.getElementById("content").innerHTML = "<h2>No Data Found!</h2>"
				document.getElementById("title").innerHTML = item.title.rendered
			}
		})

		return (
			<>
				<Metatitle title={(getDataPage.length > 0) ? getDataPage[0].title.rendered : ""} metaDescription="" ogTitle="" ogImage=""></Metatitle>
				<Header data="inner-head" loginData={this.state.loginData}></Header>
				<section className={(this.state.pageName == 'login' || this.state.pageName == 'signup') ? "sidebar-layout innerwave innerPages" : "sidebar-layout bdr-top innerheight"}>
					<div className={(this.state.pageName == 'about' || this.state.pageName=='support') ? "" : "wave-sell" }>
					<div className="container">
						<div className="row">
							{((heading_name != undefined && choosepages.length > 0) || (heading_name != "" && choosepages.length > 0) || (heading_name != "" && choosepages.length == 0)) ?
								<>
									<div className="col-3">
										<div className="sidebar">
											<h3>{heading_name ? heading_name : ""}</h3>
											<hr style={{ background: `#1da1f2`, height: 2 }} />
											<ul>
												{choosepages.length > 0 && choosepages.map((Item) =>
													<li className="has-children"><a href={'/' + Item.post_name} className="active">{Item.post_title}</a></li>
												)}
											</ul>
										</div>
									</div>
								</>
								:
								<>
								</>
							}
							<div className={((heading_name != undefined && choosepages.length > 0) || (heading_name != "" && choosepages.length > 0) || (heading_name != "" && choosepages.length == 0)) ? "col-9" : "col-12"}>
								{this.state.newloader ? (<Loader />) : (<></>)}
								<h3 className={(this.state.pageName == 'login' || this.state.pageName == 'signup') ? "text-center text-white" : "text-center"} id="title"></h3>

								<div id="content"></div>
							</div>
						</div>
					</div>
					</div>
				</section>
				<Footer></Footer>
			</>
		);
	}
}

InnerPages.propTypes = {
	auth: PropTypes.object.isRequired,
	innerpages: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth,
	innerpages: state.innerpages,
	errors: state.errors
});
export default connect(
	mapStateToProps,
	{ getPagesContent, getDataACF, clearErrors }
)(InnerPages);