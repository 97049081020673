import React, {useState} from "react";
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import axios from "axios";
import CONSTANT from '../Constant'; 

const Contactpackage = () =>{
 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [menu, setMenu] = useState('');
    const [comment, setComment] = useState('');
    const [successmessage,setSuccessmessage] =useState('');
    const [error, setErrors] = useState('');
    const [cls, setCls] = useState('');
   
   
   const handleValidation =()=> {
      let fields = {name, email,menu,comment};
//      console.log(fields);
      let errors = {};
      let formIsValid = true;
      if (!fields.name) {
        formIsValid = false;
        errors["name"] = "Name field is required";
      }else  if (!fields.name.match(/[a-zA-Z][a-zA-Z ]*/)) {
        formIsValid = false;
        errors["name"] = "Name field accept only alphanumeric characters";
      }
      if (!fields.email) {
        formIsValid = false;
        errors["email"] = "Email field is required";
      }else if (!/\S+@\S+\.\S+/.test(fields.email)) {
        formIsValid = false;
        errors["email"] = 'Email is invalid format';
      }
      if (!fields.menu) {
        formIsValid = false;
        errors["menu"] = "Package field is required";
      }
      if (!fields.comment) {
        formIsValid = false;
        errors["comment"] = "Comment field is required";
      }
      setErrors(errors);
      return formIsValid;
    }
  

  const handleSubmit =  (e) => {
    e.preventDefault();
    const isValid = handleValidation();
   //console.log(isValid);
    if(!isValid) return
   const contactInfo = {name, email,menu,comment};
    axios.post(CONSTANT.STABASE_URL+"users/sendContactEmail", contactInfo, {headers: { "x-access-token": CONSTANT.HEADER }}).then(res => {
    if (res.data.response_status === 0) {
        setCls('alert alert-danger')
        setSuccessmessage(res.data.response_msg);
    } else {
      setCls('alert alert-success')
      setSuccessmessage(res.data.response_msg);
      setTimeout(function(){  document.location.href = "/"; }, 3000);

  }
},
(error) => { 
  setCls('alert alert-danger')
  setSuccessmessage("Please provide valid login credential.");
  
});

  };
   
  return(<>
  <Header data="inner-head"></Header>
				<section className="container-fluid bdr-top innerheight">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-xs-12">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="package-form-sec mt-4">
                  
										<div className="card">
										<div className="card-header">
											Contact for Package Details
										</div>
                   
										<div className="card-body">
                    <form onSubmit={handleSubmit} className="login-form">
                    {successmessage?<p className={cls}>{successmessage}</p>:''}
										<div className="mb-3">
    <label htmlFor="exampleInputName" className="form-label">Name</label>
    <input type="text" className="form-control" id="name" onChange={e => setName(e.target.value)} aria-describedby="emailHelp" />    
    { error.name!='' ? <span className="p-2 text-danger">{error.name}</span> : '' }
  </div>
  										
  <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
    <input type="text" className="form-control" id="email" onChange={e => setEmail(e.target.value)} aria-describedby="emailHelp" />    
    { error.email!='' ?  <span className="p-2 text-danger">{error.email}</span> : '' }
  </div>
 
   <div className="mb-3">
      <label htmlFor="disabledSelect" className="form-label">Select Package</label>
      <select className="form-control" id="menu" onChange={e => setMenu(e.target.value)}>
      <option value={''}>Select one</option>
      <option value={'Basic Starter'}>Basic Starter</option>
      <option value={'Standard'}>Standard</option>
      <option value={'Advanced'}>Advanced</option>
      <option value={'Enterprise'}>Enterprise</option>
    </select>
    <span className="p-2 text-danger">{error.menu}</span>
    </div>
    
  <div className="mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">Comments</label>
    <textarea className="form-control" id="comment" placeholder="Comments" onChange={e => setComment(e.target.value)} ></textarea>
    <span className="p-2 text-danger">{error.comment}</span>
  </div> 
   
  <button type="submit" className="btn btn-primary">Submit</button>
  </form>
</div></div>

										</div>
									</div>
								</div>
							</div>
						</div>
						</div></section>

						<Footer></Footer>
  
  </>);
}

export default Contactpackage;