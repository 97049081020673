import Header from './partials/Header'
import Footer from './partials/Footer';
import React, { Component, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
import Loader from "react-loader-spinner";
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {getAllStores, clearErrors} from "../actions/storeActions";
import Pagination from "react-js-pagination";
import CONSTANT from '../Constant'; 

class myStores extends Component {
    constructor(props) {
        super(props);
		const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
        this.state = {
			loggedIn,
			getFilterData: [],
			newloader: false,
			stripeConnect: "",
			getStoreData: {},
			storeresult: [],
			latitude: 28.541397,
			longitude: 77.397034,
			activePage: 1
		};
    }
	
	handlePageChange(pageNumber) {
		console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
	}

	
	async componentDidUpdate() {
		
	}
	
	async componentDidMount() {
		var getLocationArray = []
		navigator.geolocation.getCurrentPosition(function(position) {
			getLocationArray.push({latitude: position.coords.latitude,longitude: position.coords.longitude})
		});
		if(getLocationArray.length > 0){
			var  userData = {
				"long": getLocationArray.longitude,
				"lat": getLocationArray.latitude
			};	
		} else {
			var userData = {
				"long": this.state.longitude,
				"lat": this.state.latitude
			};
		}		
		var data = await this.props.getAllStores(userData);
	}
	
	componentWillUnmount() {
		this.props.clearErrors();
	}
	
	setStore = e => {
		localStorage.setItem("store_id", e.id);
		localStorage.setItem("store_name", e.store_name);
		document.location.href = "/store/"+e.store_name
	}
	
	previousPage = () => {
	  if (this.state.currentPage !== 1)
		this.setState((prevState) => ({currentPage: (prevState.currentPage - 1)}))
	}

	nextPage = () => {
	  if (this.state.currentPage + 1 < this.state.githubData.lenght)
		this.setState((prevState) => ({currentPage: (prevState.currentPage + 1)}))
	}

	
	render() {	


		if(this.state.loggedIn == false){ 
            return <Redirect push to="/" />		
        }
		
		const {getAllStoreData} = this.props.storeresult
		
		return (		
			<>
				<Header data="inner-head"></Header>
				<section className="sidebar-layout">
						<div className="container">
							<div className="row">
															
								<div className="col-12">
									<h3 id="title" className="text-white mt-5">All Stores</h3>
								
									<div id="content" className="mystore">
											{(getAllStoreData.length != 0) ? 
												<>
													{getAllStoreData.length > 0 && getAllStoreData.map((item) =>
														
														<div className="store-sec">
															<span className="store-img">
																<input type="radio" id="kwik" name="jo" value="kwik" />
																<label for="kwik"> </label>
																<a  onClick={() => this.setStore({"id":item._id,"store_name":item.store_name})}><img src={item.store_logo ? CONSTANT.NSTABASE_URL+"images/store/"+item.store_logo : CONSTANT.NSTABASE_URL+"images/profile/noimage.png"} style={{width:90, height:78}} alt="" /></a>
															</span>
															<p>{item.store_name}</p>
														</div>
													)}
													{/*<Pagination
														activePage={this.state.activePage}
														itemsCountPerPage={3}
														totalItemsCount={getAllStoreData.length}
														pageRangeDisplayed={5}
														onChange={this.handlePageChange.bind(this)}
													/>*/}
													{/*).slice(0, this.state.activePage)}
													<Pagination
													  activePage={10}
													  itemsCountPerPage={this.state.activePage}
													  totalItemsCount={getAllStoreData.length}
													  pageRangeDisplayed={5}
													  onChange={this.handlePageChange.bind(this)}
													/>
													<Pagination
													  activePage={this.state.activePage}
													  itemsCountPerPage={10}
													  totalItemsCount={getAllStoreData.length}
													  pageRangeDisplayed={5}
													  onChange={this.handlePageChange.bind(this)}
													/>*/}
												</>
												: 
												<>
													<br/><hr/><br/>No Store Found<br/><br/>
												</> 
											}											
									</div>								
								</div>
							</div>
						</div>
				</section>
				<Footer></Footer>
			</>
		);
    }
}
 
myStores.propTypes = {
	auth: PropTypes.object.isRequired,
	storeresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getAllStores, clearErrors}
)(myStores);