import axios from 'axios';
import CONSTANT from '../Constant';

const X_ACCESS_TOKEN = CONSTANT.HEADER; 
const BASE_URL = CONSTANT.NTABASE_URL;
const BASE_URL_STORE = CONSTANT.STABASE_URL;

const headers = {
  'Content-Type': 'application/json',
  'x-access-token': X_ACCESS_TOKEN
}

 
export function getstorelists(user_id,session_token) {
  var data = {
    'user_id': user_id,
    'session_token': session_token
} 
  return axios.post(BASE_URL_STORE + 'store/getStores',data,{
    headers: headers
  }).then(res => res.data)
}


export function getCategories(user_id,session_token,store_id) {
    var data = {
      'session_token': session_token,
      'store_id': store_id,
      'user_id':user_id
    }
   return axios.post(BASE_URL_STORE + 'store/getStoreCategory',data,{
     headers: headers
   }).then(res => res.data)
} 



export function getMyStoreLists(user_id,session_token,store_id) {
  var data = {
    'user_id': user_id,
    'session_token': session_token,
    'store_id':store_id
} 
  return axios.post(BASE_URL_STORE + 'store/getStores',data,{
    headers: headers
  }).then(res => res.data)
}


export function adminCategoryList(session_token) {
  var data = { 
    'session_token': session_token
} 
  return axios.post(BASE_URL_STORE + 'store/categoryListing',data,{
    headers: headers
  }).then(res => res.data)
}

export function deletecategory(cat_id,store_id,session_token) {
  var data = { 
    'session_token': session_token,
    'store_id':store_id,
    'category_id':cat_id
} 
  return axios.post(BASE_URL_STORE + 'store/deleteCategory',data,{
    headers: headers
  }).then(res => res.data)
}


export function getagentlists(store_id,session_token) {
  var data = { 
    'session_token': session_token,
    'store_id' : store_id,
} 
  return axios.post(BASE_URL_STORE + 'store/listAgent',data,{
    headers: headers
  }).then(res => res.data)
}


export function getProductList(page,per_page,user_id,session_token,store_id,cat_id) {
  var data = {
    'page':page,
    'row_count':per_page,
    'session_token': session_token,
    'store_id': store_id,
    'user_id':user_id,
    'category_id':cat_id,
    'search_txt':'',
  } 


 return axios.post(BASE_URL_STORE + 'store/getAllChildCategoryProducts',data,{
   headers: headers
 }).then(res => res.data)
} 

export function getNearestStoresList(session_token,lat,long) {
  var data = {
    'session_token': session_token,
    'long': long,
    'lat':lat, 
  } 

 return axios.post(BASE_URL_STORE + 'store/getStoresNearBy',data,{
   headers: headers
 }).then(res => res.data)
} 



export function getProducts(user_id,session_token,store_id,cat_id) {
  var data = {
    'session_token': session_token,
    'store_id': store_id,
    'user_id':user_id,
    'category_id':cat_id,
  } 

 return axios.post(BASE_URL_STORE + 'store/customerGetStoreProductByCategory',data,{
   headers: headers
 }).then(res => res.data)
} 



export function addFavourite(user_id,session_token,product_id) {
  var data = {
    'session_token': session_token,
    'product_id': product_id,
    'user_id':user_id, 
  } 

 return axios.post(BASE_URL_STORE + 'store/customerSaveFavouriteProduct',data,{
   headers: headers
 }).then(res => res.data)
} 


export function removeFavourite(user_id,session_token,product_id) {
  var data = {
    'session_token': session_token,
    'product_id': product_id,    
    'user_id':user_id, 
  } 

 return axios.post(BASE_URL_STORE + 'store/customerRemoveFavouriteProduct',data,{
   headers: headers
 }).then(res => res.data)
} 


export function getFeatureProduct(user_id,session_token) {
  var data = {
    'session_token': session_token,
    'user_id':user_id, 
  } 

 return axios.post(BASE_URL_STORE + 'store/customerFavouriteProductList',data,{
   headers: headers
 }).then(res => res.data)
} 


export function addRemoveCart(user_id,session_token,product_id,quantity) {
  var data = {
    'session_token': session_token,
    'user_id':user_id, 
    'product_id':product_id,
    'qty':quantity,
  } 

 return axios.post(BASE_URL_STORE + 'store/addUpdateProductToCart',data,{
   headers: headers
 }).then(res => res.data)
} 
 


export function getCart(user_id,session_token) {
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
  } 

 return axios.post(BASE_URL_STORE + 'store/getCart',data,{
   headers: headers
 }).then(res => res.data)
} 


export function createOrder(session_token,user_id,flat,street,city,stat,zip,country,lat,long,complete_address,instruction, delivery_method, payment_method) { 
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
    'flatno':flat,
    'street':street,
    'city':city,
    'state':stat,
    'zip':zip,
    'country':country,
    'lat':lat,
    'long':long,
    'complete_address':complete_address,
    'instruction':instruction,
    'delivery_method':delivery_method,
    'payment_type':payment_method,
	
  }  
//console.log(data); return

 return axios.post(BASE_URL_STORE + 'web/store/createOrder',data,{
   headers: headers
 }).then(res => res.data)
} 


export function getSimilarProducts(session_token,product_id,user_id) {
  var data = {
    'session_token': session_token,
    'product_id':product_id,  
    'user_id':user_id
  } 
 return axios.post(BASE_URL_STORE + 'store/similarProducts',data,{
   headers: headers
 }).then(res => res.data)
} 



export function deleteStore(user_id,session_token,store_id) {
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
    'store_id':store_id
  } 
 return axios.post(BASE_URL_STORE + 'store/deleteStore',data,{
   headers: headers
 }).then(res => res.data)
} 


export function cloneStore(new_store_name,copy_category,copy_product,copy_calender) { 
  var data = {
    'store_id': localStorage.getItem("store_id"),
    'user_id': localStorage.getItem("loggedInUserId"),
    'session_token': localStorage.getItem("token"),
    'store_name': new_store_name,
    'copy_category': copy_category,
    'copy_products': copy_product,
    'copy_calender': copy_calender
  }  
 return axios.post(BASE_URL_STORE + 'store/cloneStore',data,{
   headers: headers
 }).then(res => res.data)
} 



export function setStoreCalender(data) {  
 return axios.post(BASE_URL_STORE + 'store/setStoreCalender',data,{
   headers: headers
 }).then(res => res.data)
}


export function getOrderList(session_token,store_id,status) {
  var data = {
    'session_token': session_token,
    'store_id':store_id,  
    'status':status
  } 
 return axios.post(BASE_URL_STORE + 'web/store/getOrderByStoreId',data,{
   headers: headers
 }).then(res => res.data)
} 


export function getCustomerOrderList(session_token,status,user_id) {
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
    'status':status
  } 
 return axios.post(BASE_URL_STORE + 'web/store/getOrderByCustomerId',data,{
   headers: headers
 }).then(res => res.data)
}

export function getBarCodeProductInfo(sku,session_token) {
  var data = {
    'session_token': session_token,
    'barcode':sku
  } 
  console.log(data);
 return axios.post(BASE_URL_STORE + 'store/getBarCodeProductInfo',data,{
   headers: headers
 }).then(res => res.data)
} 


export function otherCategory(session_token) {
  var data = {
    'session_token': session_token
  } 
  console.log(data);
 return axios.post(BASE_URL_STORE + 'store/otherCategories',data,{
   headers: headers
 }).then(res => res.data)
} 


export function getStoreParentCategories(session_token,store_id) {
  var data = {
    'session_token': session_token,
    'store_id':store_id
  }  
 return axios.post(BASE_URL_STORE + 'store/getStoreParentCategories',data,{
   headers: headers
 }).then(res => res.data)
} 



export function getvendorDetails(session_token,user_id,store_id) {
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
    'store_id':store_id,
  } 
 return axios.post(BASE_URL_STORE + 'store/getStoreById',data,{
   headers: headers
 }).then(res => res.data)
} 



export function deleteProduct(store_id,product_id,session_token) {
  var data = {
    'session_token': session_token,
    'product_id':product_id,  
    'store_id':store_id
  } 
 return axios.post(BASE_URL_STORE + 'store/deleteProduct',data,{
   headers: headers
 }).then(res => res.data)
} 




export function getPage(pagename) {
  var data = {
    'page_name': pagename,
  } 
 return axios.post(BASE_URL + 'pages/page-details',data,{
   headers: headers
 }).then(res => res.data)
} 



export function changeOnlineStatus(user_id,store_id,available_status,session_token) {
  var data = {
    'user_id': user_id,
    'store_id':store_id,  
    'available_status':available_status,
    'session_token':session_token
  } 
 return axios.post(BASE_URL_STORE + 'store/changeAvailabilityStatus',data,{
   headers: headers
 }).then(res => res.data)
} 

export function getProductListByStore(page,per_page,user_id,session_token,store_id,cat_id) {
  var data = {
    'page':page,
    'row_count':per_page,
    'session_token': session_token,
    'store_id': store_id,
    'user_id':user_id,
    'category_id':cat_id,
    'search_txt':'',
  } 


 return axios.post(BASE_URL_STORE + 'web/store/getAllChildCategoryProducts',data,{
   headers: headers
 }).then(res => res.data)
}


export function getStoresList(user_id,session_token,store_id) {
  var data = {
    'user_id': user_id,
    'session_token': session_token,
    'store_id':store_id
} 
  return axios.post(BASE_URL_STORE + 'web/store/getStores',data,{
    headers: headers
  }).then(res => res.data)
}


export function creditScore(session_token,store_id,user_id) {
	var data = {
		'session_token': session_token,
		'store_id':store_id,
		'user_id':user_id
	}
	return axios.post(BASE_URL_STORE + 'store/checkCreditScore',data,{
	headers: headers
	}).then(res => res.data)
} 

export function checkSubscription(session_token,store_id,user_id) {
	var data = {
		'session_token': session_token,
		'store_id':store_id,
		'user_id':user_id
	}
	return axios.post(BASE_URL_STORE + 'store/checkSubscriptionExist',data,{
	headers: headers
	}).then(res => res.data)
} 

export function getProductDetails(session_token,product_id,user_id) {
	var data = {
		'session_token': session_token,
		'product_id':product_id,  
		'user_id':user_id
	}
	return axios.post(BASE_URL_STORE + 'web/store/getProductDetails',data,{headers: headers}).then(res => res.data)
}



export function changeOrderStatus(user_id,session_token,order_id,status) {
  var data = {
    'session_token': session_token,
    'user_id':user_id,  
    'order_id':order_id,
    "status":status

  } 
 return axios.post(BASE_URL_STORE + 'store/changeOrderStatus',data,{
   headers: headers
 }).then(res => res.data)
} 


export function getAllTypeofProduct(page,per_page,user_id,session_token,store_id) {
  var data = {
    'page':page,
    'row_count':per_page,
    'session_token': session_token,
    'store_id': store_id,
    'user_id':user_id,
    } 


 return axios.post(BASE_URL_STORE + 'web/store/getAllTypeofProduct',data,{
   headers: headers
 }).then(res => res.data)
}


export function getActivity(page,per_page,user_id,session_token,store_id) {
  var data = {
    'per_page':per_page,
    'session_token': session_token,
    'store_id': store_id,
    'user_id':user_id
  }
 return axios.post(BASE_URL_STORE + 'web/store/getActivity',data,{
   headers: headers
 }).then(res => res.data)
} 


export function createAcademic(params) { 
  return axios.post(BASE_URL_STORE + 'post/addAcademic',params,{
    headers: headers
  }).then(res => res.data)
 } 
 
 
export function deleteAcademic(params) { 
  return axios.post(BASE_URL_STORE + 'post/deleteAcademic',params,{
    headers: headers
  }).then(res => res.data)
 } 

 
export function createExprience(params) { 
  return axios.post(BASE_URL_STORE + 'post/addExperience',params,{
    headers: headers
  }).then(res => res.data)
 } 


 export function deleteExperience(params) { 
  return axios.post(BASE_URL_STORE + 'post/deleteExperience',params,{
    headers: headers
  }).then(res => res.data)
 } 

 
export function getAllCategory(params) {
 return axios.post(BASE_URL_STORE + 'category/list',params,{
   headers: headers
 }).then(res => res.data)
} 

export function getSubCategory(params) {
  return axios.post(BASE_URL_STORE + 'category/subCategories',params,{
    headers: headers
  }).then(res => res.data)
 } 
 
 
export function createSkill(params) { 
  return axios.post(BASE_URL_STORE + 'users/addSkills',params,{
    headers: headers
  }).then(res => res.data)
 } 

 
export function editSkill(params) { 
  return axios.post(BASE_URL_STORE + 'users/editSkills',params,{
    headers: headers
  }).then(res => res.data)
 } 


 export function deleteSkill(params) { 
  return axios.post(BASE_URL_STORE + 'users/deleteSkills',params,{
    headers: headers
  }).then(res => res.data)
 } 
 
 export function deleteAttachments(params) { 
  return axios.post(BASE_URL_STORE + 'users/removeAttachments',params,{
    headers: headers
  }).then(res => res.data)
 } 
 
 
export function getPackageTransaction(session_token,store_id,user_id) {
  var data = {
    'session_token': session_token,
    'store_id':store_id,  
    'user_id':user_id
  } 
 return axios.post(BASE_URL_STORE + 'store/getPackageTransaction',data,{
   headers: headers
 }).then(res => res.data)
} 

export function  addZeroes(num) {
  // Cast as number
  var num = Number(num);
  // If not a number, return 0
  if (isNaN(num)) {
      return 0;
  }
  // If there is no decimal, or the decimal is less than 2 digits, toFixed
  if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
      num = num.toFixed(2);
  }
  // Return the number
  return num;
}
