import React, { Component } from 'react';
import { NavLink, } from "react-router-dom"; 
import axios from 'axios';
import { getStoresList } from '../Api';
import CONSTANT from '../../Constant';
import Loader from '../../Loader';
import $ from 'jquery'; 
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const initialState = { 
      store_data:'',
      store_count:0,
      store_data_array:'',
      empty:false, 
      selectedImage:'',
      selectedImage1:'',
      loading:false,
      is_selected_image:true,
      is_selected_image1:true,
      is_selected:false,
      error_msg:'', 
      formatted_address:'',
  } 

 

class SidebarStore extends Component {

    constructor(props) {
        super(props); 
        this.state = initialState; 
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    } 

     

    handleChange = address => {
      this.setState({ address });
    };
   
    handleSelect = address => {
      geocodeByAddress(address)
        .then(results => {
          getLatLng(results[0])
          this.setState({
            formatted_address:results[0]['formatted_address']
          })
        })
        .then(latLng => {
          console.log('Success', address);
          this.setState({
            address:address
          })
        })
        .catch(error => console.error('Error', error));
    };




    showModal() {
     this.setState({
       isOpen:true,
     })
    };
  
    hideModal() {
      this.setState({
        isOpen:false,
        is_selected:false,
        store_name:'',
        address:'',
        description:'',
        storeError : '',
        addressError : '',
        descriptionError : '',
        imgError : '', 
      })
    };



    showError() {
      this.setState({
        isError:true,
      })
     };
   
     hideError() {
       this.setState({
        isError:false,
       })
     };
    

   


    selectStore(store_id,imgs,store_name,address,description,store_contact_number,isAvailable,store_complete_address){
      localStorage.setItem("store_id",store_id);
      localStorage.setItem("imgs",imgs);
      localStorage.setItem("store_name",store_name);
      localStorage.setItem("address",address);
      localStorage.setItem("formatted_address",store_complete_address);
      
      localStorage.setItem("description",description);
      localStorage.setItem("store_contact_number",store_contact_number);
      localStorage.setItem("isAvailable",isAvailable);
      
      window.location.reload();
    }


    
    selectStore1(store_id,imgs,store_name,address,description,store_contact_number,isAvailable,store_complete_address){
      localStorage.setItem("store_id",store_id);
      localStorage.setItem("imgs",imgs);
      localStorage.setItem("store_name",store_name);
      localStorage.setItem("address",address);
      localStorage.setItem("formatted_address",store_complete_address);
      localStorage.setItem("description",description);
      localStorage.setItem("store_contact_number",store_contact_number);
      localStorage.setItem("isAvailable",isAvailable);
      //window.location.reload();
    }


    handleImageChange = (e) => {
      this.setState({
        image: e.target.files[0],
        is_selected_image:false,
        is_selected:true,
        selectedImage:URL.createObjectURL(e.target.files[0]),
      })
    };

    handleImageChange1 = (e) => {
      this.setState({
        image1: e.target.files[0],
        is_selected_image1:false, 
        is_selected1:false,
        selectedImage1:URL.createObjectURL(e.target.files[0]),
      })
    };

 
    
   validate = () =>{
    let storeError = '';
    let addressError = '';
    let descriptionError = '';
    let imgError = ''; 
    let phoneError = '';

    if(this.state.is_selected_image){
      imgError = "Please select store image.";
    }

    if(!this.state.store_name){
      storeError = "Store name field can not be blank.";
    }

    if(!this.state.address){
      addressError = "Address field can not be blank.";
    }

    if(!this.state.description){
        descriptionError = "Description field can not be blank.";
    }

    if(!this.state.phone){
      phoneError = "Phone field can not be blank.";
    }


    if(phoneError || storeError || descriptionError || addressError || imgError){
        this.setState({phoneError,storeError,descriptionError,addressError,imgError});
        return false;
    } else {
        this.setState({phoneError,storeError,descriptionError,addressError,imgError});
        return true;
    }               
}            
   

   
  submitForm(e){  
    e.preventDefault(); 
    const isValid = this.validate();
    if(isValid){

    const X_ACCESS_TOKEN = CONSTANT.HEADER;  

    const headers = {
      'x-access-token': X_ACCESS_TOKEN
    }

    this.setState({  
      loading:true
    }) 

    const {store_name,address,description,phone,formatted_address} = this.state;  
    const user_id = localStorage.getItem("loggedInUserId");  
    const token   = localStorage.getItem("token");  

        const data = new FormData();
        data.append('user_id',user_id);
        data.append('isAvailable',1); 
        data.append('session_token',token);
        data.append('store_image', this.state.image, this.state.image.name); 
        data.append('store_name', store_name);
        data.append('store_description',description); 
        data.append('store_address',address); 
        data.append('store_complete_address',formatted_address); 
        data.append('store_latitude','28.604918');
        data.append('store_longitude','77.348991'); 
        data.append('store_contact_number',phone);
        data.append('store_complete_address',address);

        //store_contact_number & store_complete_address

        axios.post(CONSTANT.BASE_URL+'store/addStore',data,{
          headers: headers
        }).then(res => {
          this.setState({  
            loading:false,
            store_name:'',
            address:'',
            description:'',
            is_selected:false,
          }) 
          const user_id = localStorage.getItem("loggedInUserId");
          const session_token = localStorage.getItem("token");
          const store_id = localStorage.getItem("store_id");
          this.mystore(user_id,session_token,store_id);
          this.hideModal();
        }) 
     }
    }


 
    onChange(e){ 
		this.setState({
			[e.target.name] : e.target.value
		})

        if(e.target.name==="searching"){
            var searchKey = e.target.value; 
            var datas =  this.state.store_data_array.filter(function(el) { 
                return el.store_name.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1
            })



            if(datas.length>0){
              const store_id = localStorage.getItem("store_id");
                let storeData = datas.map((store,index) => {  
                  if(store_id){
                    if(store_id===store._id){
                      var classname = "my-store_bg";
                      var classname1 = "";
                    } else {
                      var classname = "my-store_bg my-store-unbg";
                      var classname1 = "store_electrical";
                    }
                  } else { 
                    localStorage.setItem("store_id",store._id);
                    window.location.reload();
                  } 
				  
				 
  
                  if(store.images[0]['ImageName']){
                    var imgs = CONSTANT.NSTABASE_URL+ '../images/store/'+ store.images[0]['ImageName'];
                  } else {
                    var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
                  }
                  

                  var clName = "";
                  if(store.isAvailable===1){
                    clName = "online-store";
                  } else {
                    clName = "online-store offline";
                  }

				  

                  return ( 
                   <div className="col col-xs-6">
                   <div className={classname}>
                     <div className="store_content">
                       <div className="my-store-img">
                         <div className={clName} />
                         <img src={imgs} />
                       </div>
                       <span className={classname1}>{store.store_name}</span>
                     </div>
                   </div>
                 </div>    
                  );    
                  })
                  this.setState({
                    store_data:storeData,  
                    empty:false,
                  })
                } else {
                    this.setState({
                        empty:true,
                    })
                }   
        }
    }
    


     isValid1(date, h1, m1, h2, m2) {
      var h = date.getHours();
      var m = date.getMinutes();
      return (h1 < h || h1 == h && m1 <= m) && (h < h2 || h == h2 && m <= m2);
    }



      mystore = async (user_id,session_token,store_id) => {   
        var i = 0;
        await getStoresList(user_id,session_token,store_id).then(data => { 
          if(data.response_invalid===1){
            this.setState({ 
              error_msg:data.response_msg,
            }, () => this.showError())
            return false;
          }
 
          if(data.response_data.store_list){
          if(data.response_data.store_list.length>0){
			  
			  var newArray = _.filter(data.response_data.store_list, { store_name : localStorage.getItem("store_name") });
			  
			  let storeData = newArray.map((store,index) => {  
                i++;
				
				if(localStorage.getItem("store_name") == store.store_name)
                if(store.images.length > 0){
                  var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ store.images[0]['ImageName'];
                } else {
                  var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
                }  

                let current_store_name = localStorage.getItem("store_name"); 
                if(i===1 && !current_store_name){
                  console.log("Ayaaa1111");
                  this.selectStore(store._id,imgs,store.store_name,store.store_address,store.store_description,store.store_contact_number,store.isAvailable,store.store_complete_address);
                  var store_id = store._id;
                } else {
                  var store_id = localStorage.getItem("store_id");
                }
				if(store_id){
					if(store_id===store._id){
						var classname = "my-store_bg";
						var classname1 = "";
					} else {
						var classname = "my-store_bg my-store-unbg";
						var classname1 = "store_electrical";
					}
				} else { 
					localStorage.setItem("store_id",store._id);
					window.location.reload();
				}


              var clName = "";
              if(store.isAvailable===1){

                var d = new Date();
                var currentDay = d.getDay(); 
                var calender = store.calender.filter(d => d.day ===currentDay);
                if(calender.length > 0){ 
                  var isBetween = this.isValid1(new Date(), calender[0]['utc_from_time_hours'], calender[0]['utc_from_time_min'], calender[0]['utc_to_time_hours'], calender[0]['utc_from_time_min']);
                  console.log(isBetween);
                  if(isBetween){
                    clName = "online-store";
                  }
                }

              } else {
                clName = "online-store offline";
              }
			  
              return ( 
                 <div className="col col-xs-6" key={Math.random()}>
                 <div className={classname}>
                   <div className="store_content">
                   <a href="#/" onClick={this.selectStore.bind(this,store._id,imgs,store.store_name,store.store_address,store.store_description,store.store_contact_number,store.isAvailable,store.store_complete_address)}>
                     <div className="my-store-img">
                       <div className={clName} />
                       <img src={imgs} />
                     </div>
                     <span className={classname1}>{store.store_name}</span>
                     </a>
                   </div>
                 </div>
               </div>       
                ); 
                })
                this.setState({    
                  store_data:storeData, 
                  store_data_array:data.response_data.store_list,
                  empty:false,
                })
              }
            } else {
                  this.setState({
                      empty:true,
                  })
              }
        });
      }
     
      
      async componentDidMount() { 

        const userData = {
          "session_token": localStorage.getItem("token"),
          "user_id": localStorage.getItem("loggedInUserId"),
          'store_name':localStorage.getItem("store_name")
        };
        
        //var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
          }
        }
          
         await axios.post(CONSTANT.STABASE_URL +'web/store/getStoreByName',userData, config).then(res => {
            
          if (res.data.response_status !== 0) {//console.log(res.data.response_data.store_data);
            //var filterData = _.filter(res.data.response_data.store_lists, {"store_name": localStorage.getItem("store_name")})
            // console.log("MMMMMMM")
            // console.log(filterData);
            // console.log("MMMMMMM")
          
           localStorage.setItem("store_id", res.data.response_data.store_data[0]._id);
            //this.productList(localStorage.getItem("loggedInUserId"),localStorage.getItem("token"),store_id);
            //this.productList(localStorage.getItem("loggedInUserId"),localStorage.getItem("token"),res.data.response_data.store_data[0]._id);
            
          }
        },
        (error) => { 
          this.setState({ 
            successmessage:"Please provide valid login credential."
          })
        });
        const user_id = localStorage.getItem("loggedInUserId");
        const session_token = localStorage.getItem("token");
        const store_id = localStorage.getItem("store_id");

        this.mystore(user_id,session_token,store_id);
      }

      

    render() {
        return (
          <div className="col-lg-4 col-md-12 col-xs-12"> 

             {
              this.state.loading ? (
              <Loader/>
              ) : (<></>)
            }


          <div className="job_post_wrapper"> 
            {/*<div className="datepicker_post_store">
			<span className="Find_store">Search Store</span>     
              <ul className="datepicker_list_wrp clearfix">
                <li className="datepicker_list_store">
                  <div className="find_store_location"> 
                    <input type="text" placeholder="Search Store" className="form-control" name="searching" id="searching" onChange={this.onChange}/>
                    <div className="search_store" />
                  </div>
                </li>
			</ul>
            </div>*/}
            <div className="my-store">
              <h2 className="my-store-head">My Stores</h2>               
              <div className="my_store_wrp">
                <div className="row">
                {
                    (this.state.empty) ? (
                      <p className="empty-message">Sorry no records found!</p>
                    ) : (this.state.store_data)
                }     
                </div>
              </div>
            </div>
             {/*<div className="manage_add_store">
            <NavLink to="/manage-order">
              <button type="button" className="back_bt jobs_post_bt">
                <span className="user_post_info manage-order">
                  <span className="search_user"><img src={process.env.PUBLIC_URL + "/assets/images/svg/clipboard.svg"} /></span>
                </span>Manage Orders</button>
            </NavLink> 
              <button type="button" className="save_bt" onClick={this.showModal.bind(this)}>Add Store</button>
            </div>*/}
          </div>

          




 

<Modal show={this.state.isOpen} onHide={this.hideModal.bind(this)} size="md" backdrop="static"> 
<Modal.Body>
<button type="button" className="close store post-close" onClick={this.hideModal.bind(this)}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
        <div className="row">
          <div className="col-md-12">
            <div className="list_view_category">
              <h2>Add Store</h2>
            </div>
          </div>
        </div>  
        <form onSubmit={this.submitForm} id="feedform">
        <div className="row">
          <div className="col-md-12">
            <div className="post-and-vedio_bt add_store clearfix">
              <div className="user_pic_upload clearfix">

                <ul className="multiple-selected popup_store_logo clearfix">
                {
                this.state.is_selected ? (
                  <li>
                  <output id="list"><span><img src={this.state.selectedImage}/></span></output>
                  </li>
                ) : (<></>)
                }
                  <li>  
                    <a href="#">
                      <div id="hide">
                        <label className="hand-cursor">
                          <input type="file" id="files" name="files" onChange={this.handleImageChange}/>
                          <span className="fa fa-camera" /> <span className="photo_text hidden-xs">Add Store Image</span> </label>
                      </div>
                    </a>  
                  </li> 
                </ul>

                 {/* <span className="error-msg">{this.state.imgError1}</span>
                  <ul className="multiple-selected popup_store_logo clearfix">
                  <li> 
                  {
                  this.state.is_selected1 ? (
                    <output id="list"><span><img src={this.state.selectedImage1}/></span></output>
                  ) : (<></>)
                  }
                  </li>
                  <li> 
                    <a href="#">
                      <div id="hide">
                        <label className="hand-cursor">
                          <input type="file" id="files1" name="files1" onChange={this.handleImageChange1}/>
                          <span className="fa fa-camera" /> <span className="photo_text hidden-xs">Add Store Logo</span> </label>
                      </div>
                    </a>  
                  </li> 
                </ul> 
                <span className="error-msg">{this.state.imgError}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Store Name <span className="required_mark">*</span></label>
              <input type="text" name="store_name" className="form-control" value={this.state.store_name} onChange={this.onChange}/>
              <span className="error-msg">{this.state.storeError}</span>
            </div>
          </div>
          
          <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Contact Number <span className="required_mark">*</span></label>
              <input type="number" name="phone" className="form-control" value={this.state.phone} onChange={this.onChange}/>
              <span className="error-msg">{this.state.phoneError}</span>
            </div>
          </div>

           
          <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Address <span className="required_mark">*</span></label>
              <div className="add_branch_search">
                {/* <input type="search" name="address" className="form-control manage-branch" value={this.state.address} onChange={this.onChange}/>  */}
                
                
                
                <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Address',
                className: 'location-search-input form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className="list-item-description">{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                <span className="error-msg">{this.state.addressError}</span>
              </div>
            </div>
          </div> 


        <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Store Complete Address <span className="required_mark">*</span></label>
              <textarea className="form-control job_add_dis" rows={4} placeholder="Store Complete Address" name="formatted_address" value={this.state.formatted_address} onChange={this.onChange}/>
              <span className="error-msg">{this.state.descriptionError}</span>
            </div>
        </div>



          <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Description <span className="required_mark">*</span></label>
              <textarea className="form-control job_add_dis" rows={4} placeholder="Write something about your store..." name="description" value={this.state.description} onChange={this.onChange}/>
              <span className="error-msg">{this.state.completeAddressError}</span>
            </div>
          </div>


        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="add_job_now">
              <button type="submit" className="save_bt" onClick={this.submitForm}>Submit</button>
            </div>
          </div>
        </div> 
        </form>

      </div>
      </Modal.Body>
      </Modal>


      <Modal show={this.state.isError} onHide={this.hideError.bind(this)} size="md"> 
      <Modal.Body>
      <button type="button" className="close store post-close" onClick={this.hideError.bind(this)}><span aria-hidden="true">×</span></button>
      <div className="main_detail_jobs"> 
      <h1 className="other_device_login">{this.state.error_msg}</h1>
      </div>
      </Modal.Body>
      </Modal>

      </div>
        );
    }
}

export default SidebarStore;