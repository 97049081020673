import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';

function Storefront() {
  return (
    <div className="fullheight">
      <Header />

      <section id="tabs" class="project-tab bdr-top innerheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="store-banner-sec">
                <img src="/assets/images/storebanner.png" alt="" />
                <h2>Apple Store<span>123 Lorem Streeet, NY</span></h2>
                <button> Manage Your Store </button></div>
            </div>
            <div className="col-md-12 feature-sec">
              <h2>Feature Products <span className="seeall"> See all </span></h2>
              <div className="row">
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product1.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title"> iPhone 11  <span> Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product2.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">Tablet  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product3.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 11  <span>Mobile Dream </span></div>
                      <div className="store-price">$254</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product4.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 12  <span>Phone World</span></div>
                      <div className="store-price">$321</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product5.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">TV  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-12 feature-sec">
              <h2>Popular Products <span className="seeall"> See all </span></h2>
              <div className="row">
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product1.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title"> iPhone 11  <span> Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product2.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">Tablet  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product3.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 11  <span>Mobile Dream </span></div>
                      <div className="store-price">$254</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product4.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 12  <span>Phone World</span></div>
                      <div className="store-price">$321</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product5.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">TV  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="col-md-12 feature-sec">
              <h2> Latest Products <span className="seeall"> See all </span></h2>
              <div className="row">
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product1.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title"> iPhone 11  <span> Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product2.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">Tablet  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product3.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 11  <span>Mobile Dream </span></div>
                      <div className="store-price">$254</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product4.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">iPhone 12  <span>Phone World</span></div>
                      <div className="store-price">$321</div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="store-product-sec">
                    <div className="store-img-sec">
                      <img src="/assets/images/feature-product5.png" alt="" />
                    </div>
                    <div className="store-des">
                      <div className="store-title">TV  <span>Jo Electrical </span></div>
                      <div className="store-price">$231</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}




export default Storefront;