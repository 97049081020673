import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import React, { Component } from 'react';
import { useHistory, Redirect, BrowserRouter } from "react-router-dom";
// import imageExists from 'image-exists';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../Loader';

// import React from "react";
import ReactDOM from "react-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ElementDemos from "../components/ElementDemos";
import CardForm from "../components/demos/CardForm";
import CreditCard from "../components/demos/CreditCard";
import FpxBankForm from "../components/demos/FpxBankForm";
import IbanForm from "../components/demos/IbanForm";
import IdealBankForm from "../components/demos/IdealBankForm";
import PaymentRequestForm from "../components/demos/PaymentRequestForm";
import SplitForm from "../components/demos/SplitForm";
import AfterpayClearpayMessage from "../components/demos/AfterpayClearpayMessage";
import CONSTANT from './../Constant'; 
import Metatitle from "../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const stripePromise = loadStripe(CONSTANT.loadStripeKey);

const demos = [
  {
    path: "/card-element",
    label: "CardElement",
    component: CardForm
  },
  {
    path: "/subscription",
    label: "Add Card Subscription",
    component: SplitForm
  },
  {
    path: "/payment-request-button-element",
    label: "PaymentRequestButtonElement",
    component: PaymentRequestForm
  },
  {
    path: "/ideal-bank-element",
    label: "IdealBankElement",
    component: IdealBankForm
  },
  {
    path: "/iban-element",
    label: "IbanElement",
    component: IbanForm
  },
  {
    path: "/fpx-bank-element",
    label: "FpxBankElement",
    component: FpxBankForm
  },
  {
    path: "/afterpay-clearpay-message",
    label: "AfterpayClearpayMessageElement",
    component: AfterpayClearpayMessage
  }
];

class addCard extends Component {
    constructor(props) {
        super(props);
		this.state = { newloader: false, getListData: [], getCreditData:[], default_card: "", card_id:"",creditType:'',credit_type_id:'',showMsg:false, sentMsg: false,isOpen2 : false,isOpen3 : false, credit: "", getCheckSubscription: "",
	quantity:'',package_type:'',hideAddCard:''
	};

    }
	
	async componentDidMount() {		
		this.setState({newloader: true});	
		var formData = {"session_token": localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/listCards', formData, config);
		
		if(response.data.response_data != null){
			this.setState({default_card: response.data.response_data.default_card, getListData: response.data.response_data.data, newloader: false});
		} else {
			this.setState({newloader: false});
		}
		await this.getCheckSubscriptionExist();
		//await this.getCreditType();
	}
	
	async getCreditType() {
		
		this.setState({newloader: true});	
		
		// var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}		
		// let response = await axios.post(CONSTANT.STABASE_URL+'store/checkCreditScore', formData, config);
		// this.setState({getCreditData: response.data.response_data.creditType, newloader: false});
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/subscriptionProductPrice', formData, config);
        
		if(response.data.response_data != null){
			this.setState({getCreditData: response.data.response_data, newloader: false});
		} else {
			this.setState({newloader: false});
		}
	}
	
	async getCheckSubscriptionExist(){
		
		if(localStorage.getItem("store_id") == undefined){
			this.props.history.push('/vendor/myStores')
			return false;
		}
		
		this.setState({newloader: true});	
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}
		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/checkSubscriptionExist', formData, config);
		
		if(response.data.response_data.status == false){
			this.setState({getCheckSubscription: 0, newloader: false});
		} else {
			this.setState({getCheckSubscription: 1, newloader: false});
		}
	}
	
	onChange = e => {
        this.setState({ credit_type_id: e.target.value, showMsg: false, "keyValue": e.target.key });
    };
	
	async setMakeDefaultPayment(e){
		
		if(this.state.credit_type_id == ""){
			
			this.setState({showMsg: true});
			
		} else {
			
			
			this.setState({newloader: true});
			
			// var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id"),"credit_type_id":this.state.credit_type_id}		
			// let response = await axios.post(CONSTANT.STABASE_URL+'store/paymentCreditScoreByCard', formData, config);
			
			var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id"),"price_id":this.state.credit_type_id,"quantity":this.state.quantity,"package_type":this.state.package_type}		
			
			//console.log('ayyyyyy');
			//console.log(formData);
			//return false;
			
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'store/addSubscription', formData, config);
			
			if(response.data.response_status == 1){
				const store_name = localStorage.getItem("store_name");
				this.setState({newloader: false, sentMsg: true, isOpen2: true});
				setTimeout(function(){ document.location.href = "/vendor/store/"+store_name; }, 3000);	
			} else {
				this.setState({newloader: false, sentMsg: true, isOpen3: true});				
			}				
		}			
	}
	
	async setMakeDefault(e){
		
		this.setState({newloader: true});		
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: e}		
		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/makecarddefault', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.setState({newloader: false});		
		}
		
	}
	
	async confirmCard(e){		
		this.setState({newloader: true, card_id: e});		
		this.setState({newloader: false});
		$("#showDeletePopup").css({"display":"block"})
	}
	
	async closeCardpopup(e){		
		this.setState({newloader: true});
		this.componentDidMount();
		$("#showDeletePopup").css({"display":"none"})
		this.setState({newloader: false});
	}
	
	async deleteCard(e){
		this.setState({newloader: true});	
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: this.state.card_id}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/deleteCard', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.closeCardpopup(1);
			this.setState({newloader: false});		
		}		
	}
	
	addCardModal = e =>{
		this.setState({
			isOpen3:true
		}) 
	}
	
	hidePopup(){
		this.setState({
			isOpen3:false,
		})
	}
		
handleChange = (q,p,e) => {
	// to find out if it's checked or not; returns true or false
	const checked = e.target.checked;
	console.log(q);
	// to get the checked value
	const checkedValue = e.target.value;
	
	// to get the checked name
	//const checkedName = e.target.name;
	const checkedName = p;
	
	//then you can do with the value all you want to d/o with it.
// 	console.log(checked);
// 	console.log('uuuuuuuuuuuuu');
// console.log(checkedValue);
// console.log('rrrrrrrrrrrrrrrrr');
// console.log(checkedName);
if(checked){

	this.setState({ credit_type_id: checkedValue, showMsg: false, hideAddCard:true, quantity: q,package_type:checkedName });
}else{
	this.setState({ credit_type_id: '', showMsg: false, quantity: '',package_type:'' });
}

	};
	render() {
		
		const { newloader = true, getListData, default_card, getCreditData, getCheckSubscription } = this.state;
		
		return (
			<>
			<Metatitle title="Store : Ecommerce Package | aBlueC" metaDescription="Store : Ecommerce Package" ogTitle="Store : Ecommerce Package" ogImage=""></Metatitle>
				
				{(this.state.newloader)? (<Loader />): ""}
				<Header data="inner-head"></Header>
				<div className="container-fluid bdr-top innerheight subscription-main">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-xs-12">
							<div className="categories-slide-sec main_store checkout-main container">
							<div className="checkout-sec">
								<div className="subscription-sec">
									
										<div className="row">							
											<div className="col-md-12">
												
												<div className="Demo">
													{(this.state.loading)? (<Loader />): ""}
													{(localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "" && getCheckSubscription == 0)?															
<div className="col-12">
<div className="card-deck mb-3 text-center">
<div className="card mb-4 box-shadow">
<div className="card-header">
<h4 className="my-0 font-weight-normal">Basic Starter</h4>
</div>
<div className="card-body">

<h1 className="card-title pricing-card-title">$19.95 <small className="text-muted">USD</small></h1>	
<p>Best for new ecommerce businesses with fewer than 100 unique products.</p>
<hr />
<p style={{"line-height": "1.2"}}><small>One time purchase to add 100 unique barcodes / products</small></p><hr />
<p>Easily add up to 100 Products to your store so that people can instantly find what you sell.</p><hr />
<span className='disclaimer' data-toggle="modal" data-target="#disclaimer"> <img	src="/assets/images/info.png" /> Bulk Load Update</span>
</div>
<div className="card-footer">
<div className="checkbox">
                    <input type="radio" id="checkbox" name="packages" defaultValue={19.95}  onChange={(e) => this.handleChange(100,'starter', e)} />
                    <label className="btn btn-outline-primary" htmlFor="checkbox"><span>Select </span></label>
                </div>
</div>
</div>
<div className="card mb-4 box-shadow">
<div className="card-header">
<h4 className="my-0 font-weight-normal">Standard</h4>
</div>
<div className="card-body">
<h1 className="card-title pricing-card-title">$69.95 <small className="text-muted">USD</small></h1>	
<p>Best for business selling up to 500 unique products. </p><hr />	
<p><small> One time purchase to add 500 unique barcodes / products </small></p><hr />
<p>Easily add up to 500 Products to your store so that people can instantly  find what you sell. </p> <hr />
<span className='disclaimer' data-toggle="modal" data-target="#disclaimer"> <img	src="/assets/images/info.png" /> Bulk Load Update</span>                
</div>
<div className="card-footer">
	<div className="checkbox"> 
                    <label onClick={() => this.props.history.push('/contacts')} className="btn btn-outline-primary" htmlFor="standard_pkg"><span>Contact Us </span></label>
                </div> 
	</div>
</div>
<div className="card mb-4 box-shadow">
<div className="card-header">
<h4 className="my-0 font-weight-normal">Advanced</h4>
</div>
<div className="card-body">
<h1 className="card-title pricing-card-title">$499.95 <small className="text-muted">USD</small></h1>	
<p> Best for retail ecommerce businesses with up to 25,000 products  </p>
<hr />
<p><small>One time purchase to add 25,000 unique barcodes </small> </p><hr />
<p>Easily add up to 25,000 Products to your store so that people can instantly find what you sell.</p><hr />
<span className='disclaimer' data-toggle="modal" data-target="#disclaimer"> <img	src="/assets/images/info.png" /> Bulk Load Update</span>
</div>
<div className="card-footer">  <div className="checkbox"> 
                    <label onClick={() => this.props.history.push('/contacts')} className="btn btn-outline-primary" htmlFor="standard_pkg"><span>Contact Us </span></label>
                </div>  </div> 
</div>
<div className="card mb-4 box-shadow">
<div className="card-header">
<h4 className="my-0 font-weight-normal">Enterprise</h4>
</div>
<div className="card-body">
<h4> Contact aBlueC </h4>	
<p>Best for large retailers</p>
<hr />
<p><small> One time purchase to add  unlimited unique barcodes </small></p><hr />
<p>Easily add Products to your store so that people can instantly find what you sell.</p><hr />
<span className='disclaimer' data-toggle="modal" data-target="#disclaimer"> <img	src="/assets/images/info.png" /> Bulk Load Update</span>
</div>
<div className="card-footer"> <div className="checkbox"> 
                    <label onClick={() => this.props.history.push('/contacts')} className="btn btn-outline-primary" htmlFor="standard_pkg"><span>Contact Us </span></label>
                </div>  </div>
</div>
</div>
<p style={{"color": "red"}}>{(this.state.showMsg)? (<>Please Select Your Package!!</>): (<></>)}</p>
</div>
:
(<></>)
}
													{(getListData.length != 0) ? 
															<>
																<div className="full_wrp">
																
																		<ul className="added-card-list">
																		{getListData.length > 0 && getListData.map((item) =>	
																			<li className="list-group-item People">
																			{(item.id.toString() == default_card.toString())?
																			(<>
																			<span className="brand_img"> 
																			<input type="radio" name="color" value="orange" checked />
																			{(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
																			</span>
																			</>)
																			:
																			(<><span className="brand_img"> <input type="radio" name="color" value="orange" onClick={() => this.setMakeDefault(item.id)} />
																			{(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
																			</span>
																			</>)
																			}
																			<span className="removecard" onClick={() => this.confirmCard(item.id)}>X</span>
																			<div className="cardnumber">
																			<label>Card : {'*************'+item.last4} <span className="cardlistStyle">{item.brand}</span> <span> Expires on :  {item.exp_month} / {item.exp_year} </span></label>
																			</div>
																			{(item.id.toString() == default_card.toString() && localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "" && getCheckSubscription == 0)?
																			<label>
																				{/* <select className="form-control" id='credit_type_id' value={this.state.credit_type_id} onChange={this.onChange}>
																					{/* <option value=''>Select Plan For Subscription</option>
																					{getCreditData.length > 0 && getCreditData.map((item) =>
																					<option key={(item.amount/100)} value={item.id}>{item.interval_count+' '+item.interval} - {"$"+item.amount/100+' '+item.currency.toUpperCase()}</option>
																					)}   */}
																					{/* <option value=''>Select Plan For Subscription</option>
																					{getCreditData.length > 0 && getCreditData.map((item) =>
																					<option key={(item.unit_amount/100)} value={item.id}>{"$"+item.unit_amount/100+' '+item.currency.toUpperCase()}</option>
																					)}  
																				</select>															 */} 

																				{/* <p style={{"color": "red"}}>{(this.state.showMsg)? (<>Plaese Select Subcription Plan First!!</>): (<></>)}</p> */}
																			</label>
																			:
																			(<></>)
																			}
																			{(item.id.toString() == default_card.toString() && localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "" && getCheckSubscription == 0)?
																			(<><div className="paynow"><button type="button" onClick={() => this.setMakeDefaultPayment(item.id)}>Pay Now</button></div></>)
																			:
																			(<></>)
																			}
																			</li>
																		)}
																	</ul>
																</div>
																<br/>
															</>
															: 
															<>
															  {/* <p>  No Data Found  </p>*/ }
															</> 
														}
											</div>
											
										</div>	
										<div className="col-md-12">
											
										<div className="checkout_bt mt-4 mb-0">         
												 
												 {(this.state.hideAddCard) ?
												  (<button type="button" className="btn btn-secondary" id="addCardM" onClick={() => this.addCardModal({"order_data":2222})} >AddCard</button>)
												 : (this.state.default_card) ?
												  (<button type="button" className="btn btn-secondary" id="addCardM" onClick={() => this.addCardModal({"order_data":2222})} >AddCard</button>)
												:<></>
												 }
												</div>
												</div>
												</div>
										
									</div>				
								</div>				
							
							</div>
						</div>
					</div>
				</div>
				<div className="overlay-addstore" id="showDeletePopup" style={{"display": "none"}}>
					<div className="addstore-main" style={{"background": "none", "margin": "1rem auto"}}>
						<div className="modal-dialog modal-dialog-centered" role="document">
						  <div className="modal-content confirmpopup">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeCardpopup(1)}>
							  <span aria-hidden="true" className="delete_yes_no">×</span>
							</button>
							<div className="modal-body sure_delete_wrp">
							  <h5 id="delete-title">Are you sure you want to delete it?</h5>
							  <div className="post_bt_public cancel_order">
								<ul>
								  <li>
									<button type="button" className="save_bt" onClick={() => this.deleteCard(1)}>YES</button>
								  </li>
								  <li>
									<button type="button" className="back_bt reject_bt" onClick={() => this.closeCardpopup(1)}>NO</button>
								  </li>
								</ul>  
							  </div>
							</div>
						  </div>
						</div>
					</div>
				</div>
				<Modal id="modal_body_clone_store" show={this.state.isOpen3} onHide={this.hidePopup.bind(this)} backdrop = "static"> 
					<Modal.Body>
						<button type="button" className="close store post-close" onClick={this.hidePopup.bind(this)} style={{"position":"relative"}}><span aria-hidden="true">×</span></button>
						<div className="manage-add-products"> 
							<div className="modal-body">
								<div className="card-header">    Add New card  </div> 
								<Elements stripe={stripePromise}>
									<ElementDemos demos={demos} />
								</Elements>
							</div>
						</div>
					</Modal.Body>
				</Modal>


				
<div className="modal fade" id="disclaimer" tabindex="-1" aria-labelledby="disclaimerLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Disclaimer</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
	  <div className="row">
<div className="col-md-3">
<img src="/assets/images/piggy-bank.png"/>
<p>
You pay a $100 USD deposit, which is also our minimum order amount.
</p>
<span className="icon-angle-right">&#x27A9;</span>
</div>
<div className="col-md-3">
<img src="/assets/images/email.png"/>
<p>
Email us your CSV or spreadsheet to be processed right away.
</p>
<span className="icon-angle-right">&#x27A9;</span>
</div>
<div className="col-md-3">
<img src="/assets/images/gear.png"/>
<p>
We search our database and retrieve all the product data we can find.
</p>
<span className="icon-angle-right">&#x27A9;</span>
</div>
<div className="col-md-3">
<img src="/assets/images/csv.png"/>
<p>
You receive a formatted CSV with all the data when the order balance is paid.
</p>
</div>
</div>
      </div>
    
    </div>
  </div>
</div>


				<Footer></Footer>
			</>
		);
    }
}
 
export default addCard;