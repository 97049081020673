import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
import Button from "./Button";
import { getCart, addRemoveCart, createOrder } from '../Api';
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant';
import Loader from '../../Loader';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect, NavLink } from 'react-router-dom';
import CartCount from "../User/CartCount";



const initialState = {
  status: 0,
  cartData: '',
  sum: 0,
  redirect: false,

}


class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

  }






  remove(product_id) {
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token");
    let totalQty = 0;
    this.setState({
      loading: true
    })
    addRemoveCart(user_id, session_token, product_id, totalQty).then(data => {

      //this.getCart();
      document.location.href = "/cart";
    });
    //document.location.href = "/cart";
  }





  addToCart(product_id, type) {
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");
    var totalQty = 1;
    // <CartCount incremet="1"></CartCount>
    if (this.state.cartItems) {
      this.setState({ loading: true })
      var productData = this.state.cartItems.map((cart, index) => {

        if (type === "minus") {
          totalQty = cart.qty - 1;
        } else {
          totalQty = cart.qty + 1;
        }

        //console.log(totalQty);
        return;
      })
    }
    // console.log(totalQty); return
    addRemoveCart(user_id, session_token, product_id, totalQty).then(data => {

      //  this.getCart();
      document.location.href = "/cart";
    });
  }

  newMethod = (props) => {
    console.log(props);
    return <CartCount incremet={props}></CartCount>

  }

  setStore = e => {
    localStorage.setItem("store_id", e.id);
    localStorage.setItem("store_name", e.store_name);
    document.location.href = "/store/" + e.store_name + "/" + e.product_id
  }


  getCart() {
    this.setState({
      loading: true,
    })
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");

    if (session_token == undefined || user_id == undefined) {
      return this.props.history.push('/login')
    }


    getCart(user_id, session_token).then(data => {
      console.log(data.response_data);
      if (data.response_data.cart_products.items.length > 0) {//console.log('uuuuuuuuuuuu')
        this.setState({
          cartItems: data.response_data.cart_products.items,
        })
        this.props.changeCart(data.response_data.cart_products.items, 'CART_UPDATE');
        var sum = 0;
        let cartData = data.response_data.cart_products.items.map((cart, index) => {
          var price = 0;
          if (cart.product.product_sale_price) {
            price = cart.product.product_sale_price;
          } else {
            price = cart.product.product_price;
          }
          var storeName = cart.store.store_name;

          if (cart.product.images[0] && cart.product.images[0] != undefined) {
            var imgs = CONSTANT.NSTABASE_URL + 'images/store/' + cart.product.images[0];
          } else if (cart.product.images[0] == undefined) {
            var imgs = cart.product.product_image
          } else {
            var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
          }

          var imgs1 = process.env.PUBLIC_URL + "Remove";
          // var total = (price * cart.qty).toFixed(2);
          //sum  = parseFloat(sum+total).toFixed(2);
          if (Number.isInteger(price)) {
            var total = (price * cart.qty);
            sum = sum + total;
          } else {
            var total = (price * cart.qty).toFixed(2);
            sum = parseFloat(sum + total).toFixed(2);
          }

          return (
            <tr key={Math.random()}>
              <td>
                <div className="product_name">
                  {/* <a href='#' className="media"  onClick={() => this.props.history.push("/store/"+storeName+"/"+cart.product._id)}><img src={imgs}/> <span className="media-body"> {cart.product.product_title}</span></a> */}
                  {/* <span>{CONSTANT.CURRENCY}{price}</span> */}
                  <a href='#' className="media" onClick={() => this.setStore({ "id": cart.store._id, "store_name": storeName, "product_id": cart.product._id })}><img src={imgs} /> <span className="media-body"> {cart.product.product_title}</span></a>




                </div>
              </td>
              <td>
                <div className="input-group product_add">
                  <input type="button" className="button-minus" data-field="quantity" defaultValue="-" onClick={this.decreament.bind(this, cart.product._id)} />
                  <input type="number" step={1} name="quantity" className="quantity-field" value={cart.qty} readOnly />
                  <input type="button" className="button-plus" data-field="quantity" defaultValue="+" onClick={this.increment.bind(this, cart.product._id)} />
                </div>
              </td>
              <td><span>{CONSTANT.CURRENCY}{total}</span></td>
              <td><a href="#/" className="btn btn-danger" onClick={this.remove.bind(this, cart.product._id)}>{imgs1} </a></td>
            </tr>

          );
        })
        this.setState({
          cartData: cartData,
          loading: false,
          sum: sum,
        })
      } else {
        this.setState({ loading: false })

        // document.location.href = "/cart";
      }
    });
  }


  increment(product_id) {
    this.newMethod('name');

    this.addToCart(product_id, 'add');

    //this.props.history.push('/cart')
    //document.location.href = "/cart";
    //window.location.reload()
  }


  decreament(product_id) {
    this.addToCart(product_id, 'minus');
    //this.props.history.push('/cart')
    //document.location.href = "/cart";
  }




  async componentDidMount() {
    this.getCart();

  }

  render() {

    var imgs2 = process.env.PUBLIC_URL + "/assets/images/svg/pin.svg";


    return (
      <>
        <Header />

        <section id="tabs" className="project-tab bdr-top innerheight">
          <div className="container">
            {/* <Search></Search>  */}
            {/* <Button></Button>     */}

            <div className="row">

              {
                this.state.loading ? (
                  <Loader />
                ) : (<></>)
              }



              <div className="col-lg-12 col-md-12 col-xs-12 cart-orders">



                <div className="card">
                  <div className="card-header">Shopping Cart</div>
                  <div className="card-body px-0">
                    {this.state.cartData.length > 0 ?

                      <table className="table table-borderless ">
                        <thead> <tr>  <th>Product</th> <th>Count</th> <th>Price</th> <th>&nbsp;</th> </tr> </thead>
                        <tbody>
                          {this.state.cartData}
                          <tr className="pd_top">
                            <td>&nbsp;</td>
                            <td>
                              <div className="total_wrp">
                                {/* <span className="taxes">Taxes IVA:</span> */}
                                <span className="total_rs">Total:</span>
                              </div>
                            </td>
                            <td>
                              <div className="total_wrp">
                                {/* <span className="taxes">$2.0</span> */}
                                <span className="total_rs">{CONSTANT.CURRENCY}{this.state.sum}</span></div></td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                      : <table className="table table-borderless checkout_table">

                        <tbody>
                          <tr className="card-empty">
                            <td>
                              <img src="assets/images/empty-cart.png" alt="" />
                              <h4 className="my-2"> Your Cart is Empty </h4>
                              <button onClick={() => this.props.history.push("/")} className="btn btn-primary my-2">Continue Shopping..</button>
                            </td>
                          </tr>
                        </tbody>

                      </table>
                    }
                  </div> </div>
                {this.state.cartData.length > 0 ?

                  <div className="checkout_bt">
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" style={{ "backgroundColor": "#1da1f2", "borderColor": "#1da1f2" }} className="btn btn-success text-left" onClick={() => this.props.history.push("/Search-result?q=&source=products")}>Continue Shopping</button>
                        <button type="button" style={{ "backgroundColor": "#1da1f2", "borderColor": "#1da1f2", "float": "right" }} className="btn btn-success text-right" onClick={() => this.props.history.push("/checkout")}>Checkout Now</button>
                      </div>
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}





const mapStateToProps = (state) => {
  return {
    store_id: state.CURRENT_STORE
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCart: (name, action) => { dispatch({ type: action, payload: name }) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
