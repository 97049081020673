import React, { Component } from 'react';
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../Constant'; 
import Loader from '../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
 FollowingList:[],
 
} 

class Following extends Component {
  constructor(props) {
    super(props);
	const token = localStorage.getItem("token");        
	this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
			this.props.history.push('/login');
    }
    this.setState({loading: true});	
    const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
    let getcombinedFriend = await axios.post(CONSTANT.STABASE_URL +'post/followinglist', {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"page":1,"row_count":15}	,config);
    if(getcombinedFriend.data.response_status === 1){
     this.setState({FollowingList:getcombinedFriend.data.response_data.followingUserList,loading: false});
    
    }  

  }


  
  
  addFollow = async e =>{
    e || e.preventDefault();
    this.setState({loading: true});	
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/follow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
  }	

  
  unFollow = async e =>{
		e || e.preventDefault();
    this.setState({loading: true});	
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		} else {
			
			const formData = {"session_token":localStorage.getItem("token"),"user_id": localStorage.getItem("loggedInUserId"),"to_user_id":e}		
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}		
			let response = await axios.post(CONSTANT.STABASE_URL+'post/unfollow', formData, config);
			if(response.data.response_status == 1){
				//localStorage.setItem("follow", true)
			}
			this.componentDidMount();
		}		
  }	

 


  
  followingLists =  (iFriend) => {
		const res = iFriend.length > 0 && iFriend.map((item) => {
		  item.email = item.user.display_name;
 if(item.profile_image != '' && item.profile_image != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';		
      }

      //console.log(item);

			return(	
        <li key={Math.random()}>
        
          <div className="product_name">
          <div className="friends-img" onClick={() => this.props.history.push('/people/'+item.email)}> <img src={profileImg} alt="img"  /> <p style={{"cursor":"pointer"}}>{item.user.name}</p></div> 
         
          <div className="friends-action">

          {(item.already_followed == undefined || item.already_followed == 0) ?
														(<button type="button" onClick={() => this.addFollow(item.to_user_id)} className="btn btn-primary">Follow</button>) :
														
														
														(<><button type="button" onClick={() => this.unFollow(item.to_user_id)} className="btn btn-primary">Unfollow</button></>)}
													

         </div>
          </div>         
      </li>
			)																		
		}) 	
		return <>{res}</>
	}
  


  



  render() {
	  
	  
   
    const { FollowingList} = this.state;
	
   
   //console.log('88888888888888888888888888888888888888');
  //console.log(friendList);
    return (
      <>
       <Metatitle title="Following | aBlueC" metaDescription="Following" ogTitle="Following" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
      {/* <Search></Search>  */}
      {/* <Button></Button>     */}
     
      <div className="row">  

       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    

      <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="categories-slide-sec main_store checkout-main container">
        <div className="row justify-content-center"> 
       
        <div className="col-lg-6 col-md-6 col-xs-12">
       
          <div className="checkout-sec mb-3"> 
          <div class="card">
  <div class="card-header">
  Following
  </div>
<div className="card-body">
<ul className="friends-list">

             {FollowingList.length > 0 ? this.followingLists(FollowingList):<>Not found</>} 
             </ul>
          
</div>

  </div>
        
          </div>
        
          
        </div>  

        
       
        </div>
        

         
         
        </div>
      </div>
    </div>





    
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default Following;