import React, { Component } from 'react'; 
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import Metatitle from "../../components/metaTitle";
import Button from "./Button"; 
//import Search from "../../../Search"; 
import {getAllTypeofProduct, getProductDetails, getSimilarProducts,addRemoveCart,getCart,addFavourite,removeFavourite } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant';
import Loader from '../../Loader';
import { NavLink,Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {connect} from 'react-redux';
import ReadMoreAndLess from 'react-read-more-less';
import _ from "lodash";


const initialState = {
    status:1,
    loading:false, 
    delivery_time:'',
    images :'',
    is_featured :'',
    is_shipping_available :'',
    product_category :'',
    product_description :'',
    product_price :'',
    product_quantity :'',
    product_sale_price :'',
    product_title :'',
    sku :'',
    product_id :'',
    user_id :'',
    unit :'',
    store_id :'',
    store_name :'',
    quantity:1,
    productData:'',
    sameProducts:'',
    is_similar:false,
    isOpen:false,
  } 



class Productdetails extends Component {
                                                  
	constructor(props) {
		super(props); 
		const token = localStorage.getItem("token");
		let loggedIn = true;
		if(token==null){
			loggedIn = false;
		}
		this.state = {
			loggedIn,
			status:1,
			loading:false, 
			delivery_time:'',
			images :'',
			is_featured :'',
			is_shipping_available :'',
			product_category :'',
			product_description :'',
			product_price :'',
			product_quantity :'',
			product_sale_price :'',
			product_title :'',
			sku :'',
			product_id :'',
			user_id :'',
			unit :'',
			store_id :'',
			store_name :'',
			quantity:1,
			productData:'',
			sameProducts:'',
			is_similar:false,
			isOpen:false,
			per_page: 12,
			current_page: 1,
			featuredProducts:[],
		};		

		let {name, id} = this.props.match.params
		localStorage.setItem("_id", id)
	}     
  

	componentDidMount() {
		const page = this.state.current_page;
		const per_page = this.state.per_page;
		const user_id = localStorage.getItem("loggedInUserId");
		const session_token = localStorage.getItem("token");
		const store_id = localStorage.getItem("store_id");
		var product_id = localStorage.getItem("_id");
		if(session_token){
			this.ProductDetailsData(session_token,product_id);
			this.featuredProduct(page,per_page,user_id,session_token,store_id,product_id);
		} else {
			this.props.history.push('/login')			
		}
	}
  
    featuredProduct = async (page,per_page,user_id,session_token,store_id,product_id) => { 
		getAllTypeofProduct(page,per_page,user_id,session_token,store_id).then(async data => {
			this.setState({loading:false})
			if(data.response_data){
				if(data.response_data.featuredProduct.length > 0){
					// var filterData = _.filter(data.response_data.featuredProduct, function(item) {
					// 	return item._id.toString() !== product_id.toString()
          // });
          var filterData =data.response_data.featuredProduct;
					// console.log("00000")
					// console.log(filterData)
					// console.log("88888888")
					// return false
					this.setState({featuredProducts:filterData})
					
				} else {
					this.setState({ featuredProducts:[]})
				}
			}  
		});
    }
	
	feature(){
		
		var products = this.props.data
		
		console.log("00000")
		console.log(this.props)
		console.log("88888888")
		return false
		
		const fres = products.length > 0 && products.map((item) => {
			if(item.images[0]){
				var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ item.images[0];
			} else if(item.product_image){
				var imgs = item.product_image;
			} else {
				var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
			}
			return(	
			<div className="product-sec-list">
			<div className="store-product-sec">   
			<a href onClick={() => this.setProduct(item)}>        
				 <div className="store-img-sec"> 
				   <img src={imgs} alt="" />               
				 </div> 
				 </a>
				 <div className="store-des"> 
				 <div className="store-title">{this.truncate(item.product_title,25)}</div>				
					{
					item.product_sale_price ? (
					<div className="store-price">{CONSTANT.CURRENCY}{item.product_sale_price}<span>{CONSTANT.CURRENCY}{item.product_price}</span></div>
					) : ( <></>)
					}	
				</div>
			   </div>
			</div>	
			)																		
		})
		return <>{fres}</>
	}
	
	truncate(source, size) {
		return source.length > size ? source.slice(0, size - 1) + "…" : source;
	}
	
	setProduct = (product) => { 
		var store_name = localStorage.getItem("store_name"); 
		localStorage.setItem("delivery_time",product.delivery_time);
		localStorage.setItem("images",product.images[0]);
		localStorage.setItem("product_image",product.product_image);
        localStorage.setItem("is_featured",product.is_featured);
        localStorage.setItem("is_shipping_available",product.is_shipping_available);
        localStorage.setItem("product_category",product.product_category);
        localStorage.setItem("product_description",product.product_description);
        localStorage.setItem("product_price",product.product_price);
        localStorage.setItem("product_quantity",product.product_quantity);
        localStorage.setItem("product_sale_price",product.product_sale_price);
        localStorage.setItem("product_title",product.product_title);
        localStorage.setItem("sku",product.sku);
        localStorage.setItem("slug",product.slug);
        localStorage.setItem("_id",product._id);
        localStorage.setItem("user_id",product.user_id);
        localStorage.setItem("unit",product.unit);
        localStorage.setItem("store_id",product.store_id);
		document.location.href = "/store/"+store_name+"/"+product._id;
	}
	
	productDetails(type,product){ 

		if(type===1){
          var delivery_time = localStorage.getItem("delivery_time");
          var images = localStorage.getItem("images");
          var product_image = localStorage.getItem("product_image");
          var is_featured = localStorage.getItem("is_featured");
          var is_shipping_available = localStorage.getItem("is_shipping_available");
          var product_category = localStorage.getItem("product_category");
          var product_description = localStorage.getItem("product_description");
          var product_price = localStorage.getItem("product_price");
          var product_quantity = localStorage.getItem("product_quantity");
          var product_sale_price = localStorage.getItem("product_sale_price");
          var product_title = localStorage.getItem("product_title");
          var sku = localStorage.getItem("sku"); 
          var product_id = localStorage.getItem("_id");
          var user_id = localStorage.getItem("user_id");
          var unit = localStorage.getItem("unit");
          var store_id = localStorage.getItem("store_id");
          var store_name =localStorage.getItem("store_name");
          var stock = localStorage.getItem("stock");  
          
          if(images && images != 'undefined'){
            var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ images;
          } else if(product_image){
            var imgs = product_image;
          }else {
            var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
          }

          this.setState({
            delivery_time:delivery_time,
            images :imgs,
            is_featured :is_featured,
            is_shipping_available :is_shipping_available,
            product_category :product_category,
            product_description :product_description,
            product_price :product_price,
            product_quantity :product_quantity,
            product_sale_price :product_sale_price,
            product_title :product_title,
            sku :sku,
            product_id :product_id,
            user_id :user_id,
            unit :unit,
            store_id :store_id,
            store_name :store_name,
            stock:stock
          })
    } else {

     // console.log(product.images[0]);
     
      if(product.images[0] && product.images[0] != undefined){
        var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ product.images[0];
      } else if(product.images[0] == undefined){
		  var imgs = product.product_image 
      } else {
        var imgs = CONSTANT.NSTABASE_URL  + "/assets/images/placeholder.png";
      }  


      this.setState({
        delivery_time:product.delivery_time,
        images :imgs,
        is_featured :product.is_featured,
        is_shipping_available :product.is_shipping_available,
        product_category :product.product_category,
        product_description :product.product_description,
        product_price :product.product_price,
        product_quantity :product.product_quantity,
        product_sale_price :product.product_sale_price,
        product_title :product.product_title,
        sku :product.sku,
        product_id :product.product_id,
        user_id :product.user_id,
        unit :product.unit,
        store_id :product.store_id,
        stock :product.stock,
      })
    } 
  }  
 
  showPopup(){
    this.setState({
      isOpen:true,
    })
  }

  hidePopup(){
    this.setState({
      isOpen:false,
    })
  }


  favourite(product_id){ 
    this.setState({
      loading:true
    })
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    addFavourite(user_id,session_token,product_id).then(data => { 
      let requestSegments = window.location.pathname.split('/');
      let cat_id = requestSegments[2]; 
      var store_id = localStorage.getItem("CURRENT_STORE"); 
      if(store_id){
        store_id = store_id;
      } else {
        store_id = this.props.store_id;
      }

      var session_token = localStorage.getItem("token");
      var product_id = localStorage.getItem("_id"); 
      this.similarProducts(session_token,product_id);
    });
  }

  remove_favourite(product_id){ 
    this.setState({
      loading:true
    })
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    removeFavourite(user_id,session_token,product_id).then(data => {  
      let requestSegments = window.location.pathname.split('/');
      let cat_id = requestSegments[2]; 
      var store_id = localStorage.getItem("CURRENT_STORE"); 
      if(store_id){
        store_id = store_id;
      } else {
        store_id = this.props.store_id;
      }
      var session_token = localStorage.getItem("token");
      var product_id = localStorage.getItem("_id"); 
      this.similarProducts(session_token,product_id);
    });
  }




  increment(){ 
     var quantity = this.state.quantity;
      this.setState({
        quantity:quantity+1
      })
  }

   
   decreament(){ 
    var quantity = this.state.quantity; 
    if(quantity-1 >0){
    this.setState({
      quantity:quantity-1
    })
  }
   } 
  
     
   ProductDetailsData = async (session_token,product_id) => { 
    this.setState({
		  loading:true
		}) 
    getProductDetails(session_token,product_id).then(data => { 

		this.setState({
        loading:false
      }) 
		this.productDetails(2,data.response_data.product_details)
    })
  }

  
  similarProducts = async (session_token,product_id) => { 
    let user_id = localStorage.getItem("loggedInUserId");
	if(user_id){
		// getSimilarProducts(session_token,product_id,user_id).then(data => {   
		  // var isSim = false;
		  // if(data.response_data.similar_products.length > 0){
			// var isSim = true;
		  // }
		  // this.setState({
			// sameProducts:data.response_data.similar_products,
			// is_similar:isSim,
			// loading:false
		  // })
		// })
	} else {
		window.location.href = "/login";
	}
    
  }

  
  remove(product_id){
  //   let user_id = localStorage.getItem("loggedInUserId");
  //   let session_token = localStorage.getItem("token");  
  //   let totalQty = 0;
  //   this.setState({
  //     loading:true
  //   })
  //   addRemoveCart(user_id,session_token,product_id,totalQty).then(data => { 
    
  //     this.getCart();
  //  }); 
  document.location.href = "/cart";
  }


  addToCart(parms){
//console.log(parms); return false;
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");  
    var totalQty = this.state.quantity;
    var product_id = localStorage.getItem("_id");
    var store_id = localStorage.getItem("store_id");

    this.setState({loading:true})
    getCart(user_id,session_token).then(data => {  
     if(data.response_data.cart_products.items.length >0){ 
     var resData= data.response_data.cart_products.items;
     const result = _.filter(resData, (o) => _.includes(o.store._id, store_id));
     //console.log(resData); return
     if(result.length > 0){
     
      var totalQtys;
     var filterArray = []

result.filter((item)=>{
if(item.product_id.toString() == product_id.toString())
filterArray.push(item)
});
var getData = Object.assign({},...filterArray)

if(getData.qty > 0){
  totalQtys = (getData.qty + totalQty);
}else{
  totalQtys =totalQty;
}

     addRemoveCart(user_id,session_token,product_id,totalQtys).then(data => {  
        this.getCart();
        if(parms !=1){
        document.location.href = "/cart";
        }else{
          document.location.href = "/checkout?qp=byNow";
          
        }
     }); 
     }else{
      this.setState({isOpen:true,loading:false })
      // setTimeout(()=> {			
      //   this.setState({loading:true})
      //   setTimeout(()=> {
      //     this.setState({loading:false})					
      //     document.location.href = "/store";
      //   }, 4000);
      // }, 2000);
      return false;
     }

     }else{
     addRemoveCart(user_id,session_token,product_id,totalQty).then(data => {  
      this.getCart();
      if(parms !=1){
        document.location.href = "/cart";
        }else{
          document.location.href = "/checkout?qp=byNow";
        }
	}); 
      
     }
    
    }); 
	}

  
  addToBuy(){
   this.addToCart(1);
  //  setTimeout(()=> {
  //  this.props.history.push("/checkout");
  // }, 4000);
  return false;
  }
  
  getCart(){
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");  
    getCart(user_id,session_token).then(data => {  
      this.setState({
        cartItems:data.response_data.cart_products.items,
        loading:false,
      })
      this.props.changeCart(data.response_data.cart_products.items,'CART_UPDATE');
    }); 
  }
 
  favourite(product_id){ 
    this.setState({
      loading:true
    })
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    addFavourite(user_id,session_token,product_id).then(data => { 
      let requestSegments = window.location.pathname.split('/');
      let cat_id = requestSegments[2]; 
      var store_id = localStorage.getItem("CURRENT_STORE"); 
      if(store_id){
        store_id = store_id;
      } else {
        store_id = this.props.store_id;
      }
      window.location.reload();
    });
  }

  
  remove_favourite(product_id){ 
    this.setState({
      loading:true
    })
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    removeFavourite(user_id,session_token,product_id).then(data => {  
      let requestSegments = window.location.pathname.split('/');
      let cat_id = requestSegments[2]; 
      var store_id = localStorage.getItem("CURRENT_STORE"); 
      if(store_id){
        store_id = store_id;
      } else {
        store_id = this.props.store_id;
      }
      window.location.reload();
    });
  }

 render() {
	  
	  const {featuredProducts} = this.state;
	  
      //console.log(this.state.product_price);
      //console.log(this.state.product_sale_price);
     // console.log(featuredProducts.length);
   // var path = "vendor/store-details/"+this.state.store_id;
   var path = "";
    return ( 
	<>
		  <Metatitle title={this.state.product_title+ " | aBlueC"} metaDescription={this.state.product_title} ogTitle={this.state.product_title} ogImage=""></Metatitle>
		  <Header/>
      <section id="tabs" className="project-tab bdr-top innerheight "> 
     <div className="container">
     {/* <Search></Search>   */}
     <div className="card product-details-sec"> 
                                       <div className="card-header" id="title" ><span className="title-head">  Product Details  </span>  </div>
						               <div className="card-body">   
      <div className="row">       
      {/* <Sidebar/>  */}


      {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }


        <div className="col-lg-12 col-md-12 col-xs-12">
        <div className=" main_store">
          <div className="header_catehory prdect_details"> 
            {/* <span><span className="home_categories"><a href="/">Home </a>&gt; <NavLink to="/category">Categories </NavLink></span></span>	  */}
          </div>
          <div className="prodect_wrap"> 
            <div className="row">
              <div className="col-md-4">
                <div className="prodect_main_details">
                  <img src={this.state.images} />
                  {/* <div className="heart-sec active"> <i className="fas fa-heart" /></div>  */}
                </div>
              </div>
              <div className="col-md-8">
                <div className="product_contant">
                  <h2>{this.state.product_title}</h2>
                  <span>


                    {/* {
                    this.state.product_sale_price ? (
                        this.state.product_price ? (
                         <>Price:<span className="regular_price"><strike>{CONSTANT.CURRENCY}{this.state.product_price}</strike></span></> 
                        ) : (<></>)
                    ) : (
                        this.state.product_price ? (
                         <>Price:<span className="regular_price">{CONSTANT.CURRENCY}{this.state.product_price}</span></> 
                        ) : (<></>)
                    )
                    }

                    {
                    this.state.product_sale_price ? (
                      <span className="sale_price">{CONSTANT.CURRENCY}{this.state.product_sale_price}</span>
                    ) : (<></>)
                    } */}
                    


                     <span className="prodect_prcie">

                    
						{
						this.state.product_sale_price ? (
						  <span className="regular_price strike"><strike>{CONSTANT.CURRENCY}{this.state.product_price}</strike></span>
						) : ( <span className="sale_price">{CONSTANT.CURRENCY}{this.state.product_price}</span>)
						}
						
						{
						this.state.product_sale_price ? (
						<span className="sale_price">{CONSTANT.CURRENCY}{this.state.product_sale_price}</span>
						) : (<></>)
						}
											
					

                    {/* {
                   this.state.product_sale_price !=='undefined' ? (
                      <span className="regular_price strike"><strike>{CONSTANT.CURRENCY}{this.state.product_price}</strike></span>
                    ) : ( <span className="sale_price">{CONSTANT.CURRENCY}{this.state.product_price}</span>)
                    }
                   

                    {
                    this.state.product_sale_price !=='undefined' ? (
                    <span className="sale_price">{CONSTANT.CURRENCY}{this.state.product_sale_price}</span>
                    ) : (<></>)
                    } */}
                    </span>
          
                  </span>
                </div>
				<div className="content_wrp">
                  <p>
                  <ReadMoreAndLess
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={150}
                readMoreText=" Read more "
                readLessText=" ...Read less "
            >
                 {this.state.product_description}
            </ReadMoreAndLess>

                 </p>
                </div>
              {/*  <span className="available_items">Available:{this.state.stock} Items</span>
                <div className="add_cart_wrp">
                <ul className="clearfix">
                    <li>
                      <div className="input-group">
                        <input type="button" className="button-minus" data-field="quantity" defaultValue="-" onClick={this.decreament.bind(this)}/>
                        <input type="number" step={1} name="quantity" className="quantity-field" value={this.state.quantity} readOnly/>
                        <input type="button" className="button-plus" data-field="quantity" defaultValue="+" onClick={this.increment.bind(this)}/>
                      </div>
                    </li>
                    <li><button type="button" className="add_cart_bt" onClick={this.addToCart.bind(this)}>Add To Cart</button></li> 
                    <li><button type="button" className="add_cart_bt blue-btn" onClick={this.addToBuy.bind(this)}>Buy Now</button></li> 
                    <li><a href={path}><button type="button" className="add_cart_bt yellow-btn" onClick={() => this.props.history.push("/store/"+localStorage.getItem("store_name"))}>Store Information</button></a></li>
                  </ul>
                </div> */}

                 <div className="add_cart_wrp">
                <ul className="clearfix">
                <li>
                 <a href={path}><button type="button" className="add_cart_bt yellow-btn" onClick={() => this.props.history.push("/store/"+localStorage.getItem("store_name"))}>Store Information</button></a>
             </li> 
        </ul>
         </div>
         
              </div>
            </div>
          </div>
          {/* <ul className="categories_view clearfix">
             {this.state.productData}
          </ul>  */} 

          



        </div>  
      </div>
	  
	  
	  
			{(featuredProducts.length > 0) ? 
			<div className="col-lg-12 col-md-12 col-xs-12">
				<div className=" main_store">
					<div className="row">
						<div className="col-lg-12">
							<div className="header_catehory prdect_details bdr-bottom"><h2>Feature Products</h2></div>
						</div>
					</div>
					<div className="prodect_wrap"> 
						<div className="row">
							<div className="col-lg-12">
								{featuredProducts.length > 0 && featuredProducts.map((item) => 
									<>
									<div className="product-sec-list" style={{"float": "left"}}>
									<div className="store-product-sec">   
									<a href onClick={() => this.setProduct(item)}>        
										 <div className="store-img-sec"> 
										   <img src={item.images[0] ? CONSTANT.NSTABASE_URL+'images/store/'+item.images[0] :item.product_image ? item.product_image : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} alt="" />               
										 </div> 
										 </a>
										 <div className="store-des"> 
										 <div className="store-title">{this.truncate(item.product_title,25)}</div>				
											{
											item.product_sale_price ? (
											<div className="store-price">{CONSTANT.CURRENCY}{item.product_sale_price}<span>{CONSTANT.CURRENCY}{item.product_price}</span></div>
											) : ( <></>)
											}	
										</div>
									   </div>
									</div>
									</>
								)}							
								{/*<feature data={featuredProducts} /> featuredProducts.length > 0 ? this.feature(featuredProducts):<>No Found!</>*/} 
							</div>
						</div>
					</div>
				</div>
			</div>
			:
			<></>
			}
	  
          </div>
          {/*end right section here*/} 
      </div> 
      
<Modal id="modal_store" show={this.state.isOpen} onHide={this.hidePopup.bind(this)} size="l"> 
<div class="card-header"><h5 class="modal-title">Another store's product Already in your cart.</h5>
</div> 
    <Modal.Body>     
      
    <p>Please complete purchase or remove existing product from cart.</p>         
     </Modal.Body>
     <div className="modal-footer">
     <button type="button" className="save_bt" onClick={this.remove.bind(this)}>Ok</button>
      </div>
    </Modal>
    </div></div>
    </section>
	    <Footer/>
		</>
    );
  }
}


 

const mapStateToProps = (state) => {
  return { 
    store_id:state.CURRENT_STORE
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCart: (name,action) => { dispatch({ type: action, payload: name }) }, 
  }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(Productdetails);


