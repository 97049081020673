import axios from "axios";
import Constant from '../Constant';
import {
    GET_ERRORS,
    SET_MESSAGE,
    GET_HOMEPAGES,
    GET_HEADER,
    GET_FOOTER,
    HOMEPAGES_LOADING
} from "./types";

export const getPagesContent = (sendData) => (dispatch) => {	
	dispatch(setLoading());
    axios.post(Constant.NSTABASE_URL+'web/search',sendData).then((res) => {
		var filter = res.data.response_data.languages
		dispatch({
			type: GET_HOMEPAGES,
			payload: filter
		});
	}).catch(err => {
		//window.location.href = "/login";
		
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login1";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getPagesMenu = (menuType) => (dispatch) => {
	dispatch(setLoading());
		if(menuType != undefined && menuType.type == 1){ 
				var URLk ='topNav/v2/menu';
		}else{
			var URLk ='storeNav/v2/menu';
		}
	axios.get(Constant.wordpressAPIURL+URLk).then((res) => {
		var filter = res.data
		dispatch({
			type: GET_HEADER,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const getFooterPagesMenu = () => (dispatch) => { 
	dispatch(setLoading());
    axios.get(Constant.wordpressAPIURL+'footerNavFirst/v2/menu').then((res) => {
		var filter = res.data
		dispatch({
			type: GET_FOOTER,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		}
	});		
};

export const setLoading = () => {
    return {
        type: HOMEPAGES_LOADING
    };
};

export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        payload: msg
    };
};

export const clearErrors = () => {
    return {
        type: GET_ERRORS,
        payload: {}
    };
}
