import React, { Component,useState } from 'react';
import Header from '../components/partials/Header'
import Footer from '../components/partials/Footer';
import{ Redirect,NavLink } from 'react-router-dom';
// import logoImage from "../assets/images/logo.png";
import closeRedImg from "../close-red.png"
import likeSvg from "../like.svg";
import unlikeSvg from "../unlike.svg";
import chatCommentSvg from "../chat_comment.svg";
import shareSvg from "../share.svg";

import post_btn_1 from "../post-btn-1.png";
import post_btn_2 from "../post-btn-2.png";
import logo_not_post from "../logo-not-post.png";
// import edit_start_postSvg from "../assets/images/svg/edit_start_post.svg";
// import cameraSvg from "../assets/images/svg/camera.svg";
// import Search from "../user/Search";
import axios from 'axios';
import qs from 'qs';
import CONSTANT from '../Constant';
import { Modal, Form } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';
import ShowMoreText from 'react-show-more-text';
import Loader from '../Loader';
import VideoThumbnail from 'react-video-thumbnail';
import TextareaAutosize from 'react-textarea-autosize';


// import { data } from 'jquery';

const initialState = {
  responsive: {

    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
} 

class Feed extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props){
        super(props)
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("loggedInUserId");
        const user_profile_thumb = localStorage.getItem("loggedInUser_thumb_profile_image");
        const user_name = localStorage.getItem("loggedInUserName");
        const user_profile_image = localStorage.getItem("loggedInUser_profile_image");
        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        let loggedIn = true;
        if (token == null) {
          loggedIn = false;
        }
        this.state = {
          loggedIn,
          session_token:token,
          post_time_zone:timezone,
          results:[],
          logged_in_user_id:user_id,
          logged_in_user_profile_thumb:user_profile_thumb,
          logged_in_user_name:user_name,
          friendConnectRequest:[],
          postType: 'Public',
          opt: initialState,
          user_profile_image: user_profile_image,
          postLoading:false,
          isOpen:false,
          post_id:''
        }
        this.handleScroll = this.handleScroll.bind(this);


    }

    isBottom(el) {
  return el.getBoundingClientRect().bottom <= window.innerHeight;
}

handleScroll() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {

    	 this.setState({
        postLoading:true
      })
    	console.log("ooooo");
    	//getFeedDataAks(); 

    	const existFeedPageCount = parseInt(localStorage.getItem("feedpage")) + parseInt(1);
		localStorage.setItem("feedpage", existFeedPageCount);

          axios.post(CONSTANT.STABASE_URL +'post/getpostLists',qs.stringify({
              session_token: this.state.session_token,
              post_time_zone: this.state.post_time_zone,
              page:existFeedPageCount,
          }),{
              headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(res => { 

              if (res.data.response_status === 0) {
                  this.setState({ 
                      successmessage:res.data.response_msg,
                      error:true,
                  })
              }else{

              

				 
                // console.log(res.data);
                if(res.data.response_data){

            		if(existFeedPageCount==2)
            		{
            			var a=this.state.resultsOne;
            			var b = res.data.response_data.postData;
                	   var results = [...a, ...b];
                	  

            		}
            		else{

            			var a=this.state.newarray;
            			var b = res.data.response_data.postData;
                	   var results = [...a, ...b];
                	   //var newarray = results;





            		}
            		this.setState({
                    //results:res.data.response_data.postData
                    newarray:results
                    
                  })
            		this.setState({
					        postLoading:false
					      })
            		 //var newarray = results;
                	//var a = this.state.resultsOne;
                	
                	//var newarray=results;
                	//if(existFeedPageCount>2)
                	//var results = [...results, ...a];

                	//var results = [...results1, ...a];
                	//var results= Object.assign(b, a, ...);
                  //this.setState({ results: [...this.state.resultsOne, ...res.data.response_data.postData] });
                  this.setState({
                    //results:res.data.response_data.postData
                    results:results
                    
                  })
                }else{
                  this.setState({
                    results:[]
                  })
                } 

              }
            this.setState({
              postLoading: false
            })
          },
          (error) => { 
              this.setState({ 
                  successmessage:"Something went wrong"
              })
          });

      this.setState({
        message:'bottom reached'
      });
    } else {
      this.setState({
        message:'not at bottom'
      });
    }
  }

    componentDidMount(){
    	    window.addEventListener("scroll", this.handleScroll);


      this.getFeedData();
      this.friendConnectReq();
    }
     componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
    showWarningModal =(e) => {
      this.setState({isOpen:true,post_id:e})
     };
   
     hideWarningModal =() =>{
       this.setState({isOpen:false})
     };

     getEmailUser = async e =>{ 
      e || e.preventDefault();
      if(localStorage.getItem("token") == undefined){
        return this.props.history.push('/login')
      } else {
       
        const formData = {"session_token":this.state.session_token,"user_id":e}   
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token':CONSTANT.HEADER
          }
        }   
        let response = await axios.post(CONSTANT.STABASE_URL+'users/getprofileInfofromuserid', formData, config);
        //console.log(response.data.response_data.getprofileInfofromuserid); return
        
        if(response.data.response_status == 1){
          //localStorage.setItem("follow", true)
          let email = response.data.response_data.getprofileInfofromuserid.email;
          window.location.href='/people/'+email.replace(/.com/g,'')
         // window.location.href='/people/'+display_name;
        }
        //this.componentDidMount();
      }   
    } 
    getFeedData(){
      // console.log(param);
      this.setState({
        postLoading:true
      })
     
     localStorage.setItem("feedpage", 1);
          axios.post(CONSTANT.STABASE_URL +'post/getpostLists',qs.stringify({
              session_token: this.state.session_token,
              post_time_zone: this.state.post_time_zone,
              page:1,
          }),{
              headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(res => { 

              if (res.data.response_status === 0) {
                  this.setState({ 
                      successmessage:res.data.response_msg,
                      error:true,
                  })
              }else{
                // console.log(res.data);
                if(res.data.response_data){
                  this.setState({
                    results:res.data.response_data.postData
                    
                  });
                  this.setState({
                    resultsOne:res.data.response_data.postData
                    
                  })
                }else{
                  this.setState({
                    results:[]
                  })
                }                
              }
            this.setState({
              postLoading: false
            })
          },
          (error) => { 
              this.setState({ 
                  successmessage:"Something went wrong"
              })
          });
  }




 

  _handleLike(post_id){
    this.setState({
      postLoading:true
    })
    axios.post(CONSTANT.STABASE_URL +'post/postLike',qs.stringify({
      session_token: this.state.session_token,
      user_name: this.state.logged_in_user_id,
      postId:post_id
    }),{
        headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res => { 

        if (res.data.response_status === 0) {
            this.setState({ 
                successmessage:res.data.response_msg,
                error:true,
            })
        } else {
          // console.log(res.data);
          this.getFeedData();
          this.setState({
            postLoading: false
          })
        }
    },
    (error) => { 
        this.setState({ 
            successmessage:"Something went wrong"
        })
    });
  }

  _handleKeyDownComment = (e,post_id) => {
    if (e.key === 'Enter') {
      // console.log(e+'------'+post_id)
            axios.post(CONSTANT.STABASE_URL +'post/addComments',qs.stringify({
              session_token: this.state.session_token,
              comment_user_id: this.state.logged_in_user_id,
              post_comment:e.target.value,
              post_id:post_id
            }),{
                headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => { 

                if (res.data.response_status === 0) {
                    this.setState({ 
                        successmessage:res.data.response_msg,
                        error:true,
                    })
                }else{
                  this.getFeedData();
                }
                

            },
            (error) => { 
                this.setState({ 
                    successmessage:"Something went wrong"
                })
            });
        }
  }

  friendConnectReq(){
    axios.post(CONSTANT.STABASE_URL +'friend/getFriendList',qs.stringify({
      session_token: this.state.session_token,
      page:1
    }),{
        headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res => { 

        if (res.data.response_status === 0) {
            this.setState({ 
                successmessage:res.data.response_msg,
                error:true,
            })
        }else{
          // console.log(res.data);
          this.setState({
            friendConnectRequest:res.data.response_data.getFriendListData
          })
          // this.getFeedData();
        }
        

    },
    (error) => { 
        this.setState({ 
            successmessage:"Something went wrong"
        })
    });
  }

  checkLikeUnlike(arr){
// console.log(arr);
    if(arr.length > 0){

      let obj = arr.find(o => o.user_id === this.state.logged_in_user_id);
      if(obj){
        return <img src={likeSvg} alt=""/>
      }else{
        return <img src={unlikeSvg} style={{height: 20, width: 20}} alt=""/>
      }
    }else{
      return <img src={unlikeSvg} style={{height: 20, width: 20}} alt=""/>
    }
    
  }

  acceptFriendReq(friend_id){
    axios.post(CONSTANT.STABASE_URL +'friend/confirmFriendInList',qs.stringify({
      session_token: this.state.session_token,
      confirm_friend_id:friend_id
    }),{
        headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res => { 

        if (res.data.response_status === 0) {
            this.setState({ 
                successmessage:res.data.response_msg,
                error:true,
            })
        }else{
          // console.log(res.data);
          // this.setState({
          //   friendConnectRequest:res.data.response_data.getFriendListData
          // })
          this.friendConnectReq();
        }
        

    },
    (error) => { 
        this.setState({ 
            successmessage:"Something went wrong"
        })
    });
  }
  cancleFriendReq(friend_id){
    axios.post(CONSTANT.STABASE_URL +'friend/deleteFriendInList',qs.stringify({
      session_token: this.state.session_token,
      delete_friend_id:friend_id
    }),{
        headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res => { 

        if (res.data.response_status === 0) {
            this.setState({ 
                successmessage:res.data.response_msg,
                error:true,
            })
        }else{
          // console.log(res.data);
          // this.setState({
          //   friendConnectRequest:res.data.response_data.getFriendListData
          // })
          this.friendConnectReq();
        }
        

    },
    (error) => { 
        this.setState({ 
            successmessage:"Something went wrong"
        })
    });
  }

  _handleDropDown(e){
    this.state.postType = e.target.value
    console.log(this.state.postType);
  
  }

  
  bindImages(data) {
      var returnData = 
        data.map((cat, index) => {

          if (cat.ImageType === "video/mp4") {
            var imgs = <video controls src={CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + cat.ImageName} ></video>;
          } else {
            var imgs = <a href={CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + cat.ImageName} target="_blank"> <img className="img-fluid" src={CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + cat.ImageName} alt=""></img></a>;
          }
          return (
           imgs
          );
        })
    return returnData;
  }

  
  deletePost = () => {
    const _this = this;
    _this.setLoader();
    
    
    axios.post(CONSTANT.STABASE_URL + 'post/deletePost', qs.stringify({
      session_token: this.state.session_token,
      post_id:this.state.post_id
    }), {
      headers: { "x-access-token": CONSTANT.HEADER, 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(res => {

      if (res.data.response_status === 0) {
        this.setState({
          successmessage: res.data.response_msg,
          error: true,
        })
      } else {
        this.getFeedData();
        this.setState({isOpen:false});
      }
      

    },
      (error) => {
        this.setState({
          successmessage: "Something went wrong"
        })
      });
    
}

  setLoader() {
    this.setState({
      postLoading:true
    })
  }

  removeLoader() {
    this.setState({
      postLoading:false
    })
  }
    
   render(){

    if(this.state.loggedIn === false){ 
      return <Redirect to="/login"/>   
 }

  
 const CommentSec = (props) => {
  const comment = props.results.map(r => (
                    <div key={r.comment_id} className="create_comment_details clearfix">
                      <div className="user_comment_pic">
                        
                      {(r.user_id == this.state.logged_in_user_id) ?
                        <NavLink to="/profile"><div className="user_profile_pic"> 
                     
                      {/* <img src={ CONSTANT.NSTABASE_URL+CONSTANT.PROFILE_IMAGE+r.profile_image} style={{width: '45px', height: '45px'}} alt="" /> */}
                      
                      {r.profile_image ?
                          <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.profile_image} style={{ height: 45, width: 45 }} /> :
                          <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
                      }

                      </div>
                      </NavLink>
                      :<a href ="#" onClick={() => this.getEmailUser(r.user_id)}><div className="user_profile_pic"> 
                     
                      {/* <img src={ CONSTANT.NSTABASE_URL+CONSTANT.PROFILE_IMAGE+r.profile_image} style={{width: '45px', height: '45px'}} alt="" /> */}
                      
                      {r.profile_image ?
                          <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.profile_image} style={{ height: 45, width: 45 }} /> :
                          <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
                      }

                      </div></a>}
                      </div>

                      <div className="main_section_comment">
        
                        < div class="user_comments_wrapper" >
                          <div class="user_comments_content">
                            <div class="user_info_comments">
                              <div class="left_user_name">{r.user_name}</div>
                              <div class="right_user_time">{new Date(r.comment_timestamp).toLocaleDateString()}</div>
                            </div>
                            <p>{r.user_comment}</p>
                          </div>
                        </div >
                      </div>
                    </div>
                  ))
              return comment;
 }
     
    //  const SliderImages = (props) => {
    //    const options = props.results.map(r => (
    //      {
    //        r.ImageType === "video/mp4" ?
    //      < img className = "img-fluid" src = { CONSTANT.NSTABASE_URL + CONSTANT.POST_IMAGE + r.ImageName } alt = "" ></img >:''
    //      }
    //    ))
    //    return options;
    //  }

 const FeedResult = (props) => {
  console.log("Called");
  console.log(props.results);
  const options = props.results.map(r => (
    
    // console.log(r)
    
    <div key={r._id} className="post_detels_wrapper ">
                    <div className="create_post row">
                      <div className="left_edit_user col-md-8 col-7">
                        <div className="create_img">
                        {(r.user_id == this.state.logged_in_user_id) ?
                        (<><NavLink to="/profile">
                          <div className="user_profile_pic">  
                          {r.post_user_profile_image ?
                                <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.post_user_profile_image}/> :
                                <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
                            }                          
                          </div>
                          <div className="user_info"> <span>{r.post_user_name}</span> <span></span> </div>
                          </NavLink></>)
                           : (<><NavLink to={`/people/${r.post_user_display_name}`}><div className="user_profile_pic">  
                           {r.post_user_profile_image ?
                                 <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + r.post_user_profile_image}/> :
                                 <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{ height: 45, width: 45 }}/>
                             }                          
                           </div>
                           <div className="user_info"> <span>{r.post_user_name}</span> <span></span> </div>
                           </NavLink></>)}
                        </div>
                      </div>
                      <div className="right_edit_user feeds-time col-md-4 col-5">
                        <span className="post_days">{r.post_time_status}</span>
                        {/* <span className="post_days"><button onClick={(e) => this.deletePost(r._id)}>Remove</button></span> */}
                        {(r.user_id == this.state.logged_in_user_id) ?
                        <span className="post-delete" onClick={(e) => this.showWarningModal(r._id)} > <img src="/assets/images/delete-post.png" alt="deletepost" /></span>
                        :null}
                      </div>
                    </div>
                    <div className="user_post_cont">
        <p>
          <ShowMoreText
            /* Default options */
            lines={3}
            more='Read more'
            less='Read less'
            anchorClass=''
            expanded={false}
            width={680}
          >
            {r.post_content}
          </ShowMoreText>
          
        </p>
      </div>
     
      <div className="post_img_detels"> 
        <OwlCarousel className="owl-theme" loop margin={10} nav items={1} responsive={this.state.opt.responsive} dots={false} autoplay={false} >

          {r.images.length > 0 ?
            // <SliderImages results={r.images}></SliderImages>
            this.bindImages(r.images)
            : ''}
        </OwlCarousel>
                      
                    </div>
                    <div className="user_views">
                      <ul className="clearfix">
                        <li><button type='button'><img src="/assets/images/sm_like.png" alt=""/> {r.totalCountLike}</button></li>
                        <li><button type='button'>{r.totalComments} Comments</button></li>
                        {/* <li><a href="#">3333 views</a></li> */}
                      </ul>
                    </div>
                    <div className="user_comments">
                      <div className="comment clearfix">
                        <ul>
                          <li><button type='button' onClick={e => this._handleLike(r._id)}>{this.checkLikeUnlike(r.like_users)}Like</button></li>
                          <li><button type='button'><img src={chatCommentSvg} alt=""/>Comment</button></li>
                          <li><button type='button'><img src={shareSvg} alt=""/>Share</button></li>
                        </ul>
                      </div>
                    </div>
                    {/*comment section here*/}
                    <div className="type_comments_wrapper clearfix">
                      <div className="type_comment_user">
                      <NavLink to="/profile">
                    <div className="user_profile_pic"> 
                    {/* <img alt="" src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + this.state.user_profile_image} style={{width: '45px', height: '45px'}}/> */}

                      {
                      this.state.logged_in_user_profile_thumb!=="undefined" ?
                      <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + this.state.logged_in_user_profile_thumb} style={{width: '45px', height: '45px'}} />:
                      <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{width: '49px', height: '49px'}} /> 
                      }   


                    </div>
                    </NavLink>
                      </div>
                      <div className="type_comment">
          <div className="textarea-write">
            <TextareaAutosize
              placeholder="Add Comment"
              onKeyPress={e => this._handleKeyDownComment(e, r._id)}
                // maxRows={}
                style={{
                  lineHeight: 2,
                  // fontSize: 10,
                  border: 0,
                  height:52,
                  boxSizing: 'border-box', 
                }}
              />
                          {/* <input type="text" name placeholder="Add Comment" name="addComment" onKeyPress={e => this._handleKeyDownComment(e,r._id)}/> */}
                           </div>
                           
                      </div>
                    </div> 
                    <CommentSec results = {r.comments}></CommentSec>
                    
                    {/*comment section here*/}
                  </div>
  ))
  return options
}


const FriendConnectSection = (props) => {

  const friend = props.results.map(r => (
      
      <div key={r._id} className="invited_listing clearfix">
        <div className="invited_left_section clearfix">
            <div className="user_invited_img">
            {r.profile_image ? 
                  <img src={CONSTANT.NSTABASE_URL+CONSTANT.PROFILE_IMAGE+r.profile_image} style={{width:45,height:45}} alt=""></img> :
                  <img src={ CONSTANT.NSTABASE_URL+CONSTANT.JOB_IMAGE+'no-image-icon.png'} style={{width:45,height:45}} alt=""></img>
                }
             </div>
                <span className="user_info_invited"> <span>{r.name}</span> <span></span> </span> </div>
        <div className="invited_right_section"> <span>
            <button type="button" className="connect_bt" onClick={e => this.acceptFriendReq(r._id)}>Connect</button>
            </span> <a href="#" className="cross_bt"><img alt="" src={closeRedImg} onClick={e =>this.cancleFriendReq(r._id)} /></a> </div>
        </div>
  ))
  return friend;
}




const PostFeed = (props) => {
  const [show, setShow] = useState(false);
  const logged_in_user_name = this.state.logged_in_user_name;
  const user_profile_pic = this.state.logged_in_user_profile_thumb;

  const [postImage, setPostImage] = useState([])
  const [postVideo, setPostVideo] = useState([]);
  const [postContent, setPostContent] = useState('')
  const [fileData, setFileData] = useState('')
  const [postContentError, setPostContentError] = useState('')

  var imageArr = [];

  const onImageChange = (e) => {

    setPostImage([]); 
    // imageArr = [];
    var imageThumb = [];
    var videoThumb = [];
    this.fileObj = []

      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        imageArr.push(URL.createObjectURL(this.fileObj[0][i]))
      }
    
    for (let i = 0; i < this.fileObj[0].length; i++) {
      // console.log(this.fileObj[0][i])
      if (this.fileObj[0][i].type != "video/mp4") {
        imageThumb.push(URL.createObjectURL(this.fileObj[0][i]))
      } else {
        videoThumb.push(URL.createObjectURL(this.fileObj[0][i]))
      }
      
    }
    
    setPostVideo(videoThumb);
    setPostImage(imageThumb);
    setFileData(e.target.files);

  }

  const onPostContentChnage = (e) => {
    setPostContent(e.target.value);
  }

  

  const postFeedData = () => {
    // this.setLoader();
    if (postContent) {
      const _this = this;
      _this.setLoader();

      var formData = new FormData();
      var is_private = 0;
      if (this.state.postType === "Private") {
        is_private = 1
      }

      for (const key of Object.keys(fileData)) {
        formData.append('post_images', fileData[key])
      }

      // formData.append('post_images', fileData);
      formData.append('post_content', postContent);
      formData.append('session_token', this.state.session_token);
      formData.append('is_private', is_private);

      // console.log(formData);

      axios.post(CONSTANT.STABASE_URL + 'post/addPost', formData, {
        headers: { "x-access-token": CONSTANT.HEADER, 'Content-Type': 'multipart/form-data' }
      }).then(res => {

        if (res.data.response_status === 0) {
          this.setState({
            successmessage: res.data.response_msg,
            error: true,
          })
        } else {
          this.getFeedData();
        }
        

      },
        (error) => {
          this.setState({
            successmessage: "Something went wrong"
          })
        });
      
    } else {
      setPostContentError('This field can not be blank')
    }
  }


  



  const handleClose = () => {
    setShow(false); 
    setPostImage([]); 
    setPostVideo([]);
    imageArr = [];
    this.fileObj = []
  }
  const handleShow = () => setShow(true);

  return (
  
  <>
  
      <input readOnly type="text" placeholder="Update or Post" data-toggle="modal" data-target="#exampleModal" defaultValue={""}
        onClick={handleShow} />
    <button onClick={handleShow}  className="left_post_write"></button> <button onClick={handleShow} className="camera_type" ></button> 
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal 
      dialogClassName="feed_popup"
      // aria-labelledby="example-custom-modal-styling-title"
       centered show={show} onHide={handleClose} backdrop="static">
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body >

        <button type="button" className="close store post-close" data-dismiss="modal" aria-label="Close" style={{"position":"relative","display":"block","margin":"0px"}} onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
            <div className="modal-body">
            
              <div id="post" className="post-inner_wrapper">
                <div className="user_profile clearfix">
                  <div className="user_connent">
                    <div className="user_left">
                      <div className="create_img user_profile_pic">
                      {
                      this.state.logged_in_user_profile_thumb!=="undefined" ?
                      <img src={CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + this.state.logged_in_user_profile_thumb} style={{width: '45px', height: '45px'}} />:
                      <img src={CONSTANT.NSTABASE_URL + '/assets/images/no-image-icon.png'} style={{width: '49px', height: '49px'}} /> 
                      }   
                      
                      {/* <img src={user_profile_pic !== 'undefined' ? CONSTANT.NSTABASE_URL + CONSTANT.PROFILE_IMAGE + this.state.user_profile_image : CONSTANT.NSTABASE_URL+CONSTANT.JOB_IMAGE+'no-image-icon.png'} alt="post_create" /> */}
                    
                    </div>
                    </div>
                      <div className="user_name_right">{logged_in_user_name}</div>
                  </div>
                  <div className="user_edit_info">
                    <div className="dropdown">
                    {/* <Form.Control as="select" custom onChange={e => this._handleDropDown(e)}>
                      <option >Public</option>
                      <option>Private</option>
                    </Form.Control>*/}
                      {/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Public</button>
                      <div className="dropdown-menu action_menu" id="#menu1" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#">Private</a>
                        
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className="textarea-write_popup clearfix">
                <textarea required placeholder="Write something here…" defaultValue={""} onChange={onPostContentChnage} />
                <small className="form-text text-muted_error_message">{postContentError}</small>
                </div>
                {/* <div className="add_hashtag clearfix">
                  <a href=""># Add Hashtag</a>
                </div> */}
              </div>
              <div className="add_post_video post-multiple-images">
                <h2>Upload Photo</h2>
              </div>
              <div className="post-and-vedio_bt clearfix">
                <div className="user_pic_upload clearfix">
                  <output id="list" >
                  
                  </output>
                <ul className="multiple-selected popup_store_logo clearfix">
                 
                {(postImage || []).map(url => (
                               <li key= {Math.random()}> <img src={url} alt="..." style={{height:112,width:127}}/></li>
                ))}
                  {(postVideo || []).map(url => (
                    <li key={Math.random()}>
                      <VideoThumbnail videoUrl={url} thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                      />
                    </li>
                  ))}
                   <li> 
                      <a href="#">
                        <div id="hide">
                            <label className="hand-cursor feed_add_wrp">
                            <input required type="file" id="files" name="files[]" accept=".mp4,.jpg,.png,.jepg" multiple nv-file-select="true" onChange={onImageChange} />
                            <span className="fa fa-plus" /> <br></br>
                            <span className="photo_text hidden-xs"> Add Photo/Video</span>
                            </label>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="post_bt"> 
                <span className="followers_text">
                  <button type="button" className="connect_bt followers follow_post" onClick={postFeedData}>Post</button>
                </span> 
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


 

    return (
        // <div>
    <>
    <Header />
          <div className="container-fluid bdr-top innerheight">
      
          {this.state.postLoading ? (<Loader />) : (<></>)}

        <div className="row">
        <div className="col-md-12">
        <div className="container"> 
        <div className="row">
        <div className="col-md-12">
        <div className="card" style={{"margin-top":"40px"}}>
        <div className="card-header"> Posts </div>
        <div className="card-body">
         <div className="post_create_new_user">
                    <div className="location_menu">
                      <div className="media_icon clearfix">
                        <div className="textarea-write">
                          <PostFeed/>
                        </div>
                      </div>
                      {/*start right button section*/} 
                      {/*end right button section*/} 
                    </div>
                  </div>
                  {this.state.results.length>0 ? 
                  <FeedResult results={this.state.results}></FeedResult> :
            // }           
            <div className="post_detels_wrapper not_yet_post">
              <div className="not-show-post clearfix">
                <div className="col-md-5 v-middle">
                  <img src={logo_not_post} />
                </div>
                <div className="col-md-6 v-middle">
                  <div className="app-download">
                    <strong>Start capturing and sharing your moments.</strong>
                    <br />Get the app to share your first photo or video.
                     </div>
                  <div className="app-download-btn">
                    <img src={post_btn_1} />  
                    <img src={post_btn_2} />
                  </div>
                </div>
              </div>
            </div>
            }
        </div>
        {/*<div><span onClick={this.getFeedDataAks}>Load More</span></div>*/}
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>



             
              
<Modal id="modal_body" show={this.state.isOpen} onHide={this.hideWarningModal}> 
    <Modal.Body> 
        <div className="modal-dialog" role="document">
          <div className="modal-content confirmpopup">
           
            
            <div class="card-header text-center">    Are you sure you want to delete it?  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideWarningModal}>
            <span aria-hidden="true">×</span>
            </button> </div>
            <div className="modal-body sure_delete_wrp">
             
              <div className="post_bt_public cancel_order">
                <ul>
                  <li>
                    <button type="button" className="save_bt" onClick={(e) => this.deletePost()}>YES</button>
                  </li>
                  <li>
                    <button type="button" className="back_bt reject_bt" onClick={this.hideWarningModal}>NO</button>
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div> 
    </Modal.Body>
    </Modal>
        </div>
        <Footer></Footer>
    </>
      );
   }
}

export default Feed;