import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

class Metatitle extends Component {
	constructor(props) {
        super(props);
    }		
	render() {
		return (
			<div className="wrapper">
				<MetaTags>
					<title>{this.props.title}</title>
					<meta id="meta-description" name="description" content={this.props.metaDescription} />
					<meta id="og-title" property="og:title" content={this.props.ogTitle} />
					<meta id="og-image" property="og:image" content={this.props.ogImage} />
				</MetaTags>
			</div>
		)
	}
}
export default Metatitle;