export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_RESET_PASSWORD ="GET_RESET_PASSWORD";
export const SET_MESSAGE ="SET_MESSAGE";
export const GET_ACCOUNT ="GET_ACCOUNT";
export const GET_ACCOUNT_INFO="GET_ACCOUNT_INFO";

export const GET_INNERPAGES = "GET_INNERPAGES";
export const GET_DATAACF = "GET_DATAACF";
export const INNERPAGES_LOADING = "INNERPAGES_LOADING";

export const GET_HOMEPAGES = "GET_HOMEPAGES";
export const GET_HEADER = "GET_HEADER";
export const GET_FOOTER = "GET_FOOTER";
export const HOMEPAGES_LOADING = "HOMEPAGES_LOADING";

export const GET_SEARCHRESULTDATA = "GET_SEARCHRESULTDATA";
export const GET_SEARCHRESULT = "GET_SEARCHRESULT";
export const GET_SEARCHPRODUCTRESULT = "GET_SEARCHPRODUCTRESULT";
export const GET_SEARCHJOBRESULT = "GET_SEARCHJOBRESULT";
export const GET_DISTANCE = "GET_DISTANCE";
export const GET_SEARCHPEOPLERESULT = "GET_SEARCHPEOPLERESULT";
export const GET_SEARCHTOPICSRESULT = "GET_SEARCHTOPICSRESULT";
export const GET_SEARCHSTORESRESULT = "GET_SEARCHSTORESRESULT";
export const SEARCHRESULT_LOADING = "SEARCHRESULT_LOADING";

export const GET_STORE = "GET_STORE";
export const GET_STORES = "GET_STORES";
export const GET_ALLSTORES = "GET_ALLSTORES";
export const GET_CHECKSTORE = "GET_CHECKSTORE";
export const GET_USTORELIST = "GET_USTORELIST";
export const STORE_LOADING = "STORE_LOADING";