import Header from './partials/Header'
import Footer from './partials/Footer';
import InnerPages from './Pages/InnerPages';
import React, { Component } from 'react';
// import imageExists from 'image-exists';
import Loader from '../Loader';
import CONSTANT from '../Constant'; 
import axios from 'axios';
import qs from 'qs';


class PeopleProfile extends Component {
    constructor(props) {
        super(props);		
		this.state = {
			profileData: {},
            skillData: [],
            academicsData: [],
			getprofileInfofromuserid:[],
			response_status: "",
			name:'',
            display_name:'',
            email:'',
            mobile:'',
            about:'',
			pageUrl: "",
			imageURL:'',
			images:'',
			showExperience:false,
			showSkill:false,
			
			};
		
		
    }
	
	async componentDidMount() {
		if(localStorage.getItem("token") == undefined){
			return this.props.history.push('/login')
		}
		this.setState({loading: true});	
		let {id} = this.props.match.params
		const user_id = localStorage.getItem("loggedInUserId");

		//console.log(this.props.match.id);
		//getData = await this.getData(name)	
		//var profileId = this.props.match.id;
		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':CONSTANT.HEADER
			}
		}	
		//'1630312292142'	//localStorage.getItem("token")
		let res = await axios.post(CONSTANT.STABASE_URL+'post/getPostListUserProfile',{session_token:localStorage.getItem("token"),post_time_zone:'Asia/Kolkata',page:1,'user_profile_id':id,'display_name':''},config);	
		console.log("sdfsdfsdfds");
		console.log(res.data.response_data.getUserInfo.availability_status);
		console.log("sdfsdfsdfds");

		if(res.data.response_data.getUserInfo.availability_status==1){
		this.setState({
			profileData:res.data.response_data.getprofileInfofromuserid, 
			// friendData: res.data.response_data.getFriendData,
			 skillData: res.data.response_data.getprofileInfofromuserid.addSkills,
            // experienceData: res.data.response_data.getprofileInfo.addExperiences,
			 academicsData: res.data.response_data.getprofileInfofromuserid.addAcademic,
			 loading: false
			
		});		

	}
	else{
		document.location.href = '/404'
	}
		
	}

	
	Address = (addParms) => {
		//console.log(addParms);
			 return (
				<div className="ex_inner_list">
				<ul>
                    <li>Flat: <span>{addParms.flatno}</span></li>                
                    <li>Street: <span>{addParms.street}</span></li>
                    <li>Country: <span>{addParms.country}</span></li>
                    <li>State: <span>{addParms.state}</span></li>
                    <li>City: <span>{addParms.city}</span></li>
                    <li>Zip: <span>{addParms.zip}</span></li>
                    </ul>
					
					
				</div>
			
			 )
	
	}


	 AcademicList = (acdParam) => {
		const options = acdParam.map(r => (
			<div className="col-md-6" key={Math.random()}>
				<div className="ex_inner_list">
					<div>
					<h2>{r.title}</h2>
					
					</div>
					<ul className="how-experience ex_list-view">
					<li>
						<div className="user-pro"><label className="address_user">College Name:</label><span>{r.college_or_school_name}</span></div>
					</li>
				</ul>
				<div className="left_diaply">	
				<ul className="how-experience ex_list-view">
					
						<li><div className="user-pro"><label className="address_user">Start Year:</label><span>{r.start_year}</span></div></li>
						<li><div className="user-pro"><label className="address_user">Pursuing:</label><span>{r.persuing ==1 ? 'Yes':'No'} </span></div></li>
				</ul>
				</div>
				<div className="right_diaply">
				<ul className="how-experience ex_list-view">							
						<li><div className="user-pro"><label className="address_user">End Year:</label><strong></strong><span>{r.end_year}</span></div></li>
						
					
				</ul>
				</div>
				  
				</div>
			</div>
		))
		return options;
	}

	

	 SkillsList = (skillParam) => { 
		const options = skillParam.map(r => (
			<div className="col-md-6" key={Math.random()}>
				<div className="ex_inner_list">
					<h2>{r.categoryName} </h2>
					<div>
					<h2>{r.title} </h2>
					
					</div>

					
					<ul className="how-experience">
						<li>
							<span>{(r.subCat[0].name=="Other") ? r.subCat[0].otherdata :r.subCat[0].name }</span>
						</li>
					</ul>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group-1 row">
								<label htmlFor="inputPassword" className="col-sm-7 col-form-label Service_label">
									Service Experience
								</label>
								<div>
									{r.subCat[0].experience}
								</div>
								{/* <div className="col-sm-5 pd-remove">
									<input
										type="password"
										className="form-control month-exp"
										id="inputPassword"
									/>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
		return options;
	}


	

	  
	render() {
		
		const {profileData,academicsData,skillData} = this.state;
//console.log(this.state.subcategoryOptionss);

if(profileData.thumb_profile_image == undefined && profileData.profile_image != undefined){
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+profileData.profile_image
}else if(profileData.thumb_profile_image != undefined && profileData.profile_image != undefined){
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+profileData.profile_image
}  
else {
	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
}

var dateJoin ='';
if(profileData._created_at != undefined)
dateJoin = new Date(profileData._created_at).toLocaleString();



        return (
        <>
        <Header></Header>
        <section className="bdr-top innerheight" >
	<div className="container">
		<div className="row">
		{
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
			<div className="col-12">
				<div className="profile_details profile_user">
				
						<div className="profile_area">						  
						  <div className="mx-md-5 profile_content">
						    <div className="profile-pic">  <img src="#" alt="img" src={profileImg} width="13400" height="134" /> <p><span> {profileData.name} </span> &nbsp; <span> {profileData.display_name} </span></p> <label>Joined on {dateJoin}</label>
							
							
                                            
							
							</div>							
						<div className="row">
							<div className="col-md-8">
							<p className="profile_text">
	<label>{profileData.post_count}</label><span>Posts</span>
										<label  onClick={() => this.props.history.push('/follower')}>{profileData.follow}</label><span style={{"cursor":"pointer"}} onClick={() => this.props.history.push('/follower')}>Followers</span>
										<label  onClick={() => this.props.history.push('/following')}>{profileData.following}</label><span style={{"cursor":"pointer"}} onClick={() => this.props.history.push('/following')}>Following</span>
										<label  onClick={() => this.props.history.push('/friend')}>{profileData.friends_count}</label><span style={{"cursor":"pointer"}} onClick={() => this.props.history.push('/friend')}>Friends</span>
									</p>
							</div>
						
						</div>
							
                                          
                                   
									

									


									<p className="profile-btn">
										{/* {(localStorage.getItem("friend") == undefined) ?
										(<a className="btn btn-primary mr-2" href="#" role="button" id="addFriend" onClick={this.handleLoginPage}>Add Friend</a>) : (<><div className="btn btn-primary mr-2" id="addFriend">Friend</div></>)}
										{(localStorage.getItem("follow") == undefined) ?
										(<a className="btn btn-primary" href="#" role="button" id="follow" onClick={this.handleLoginPage}>Follow</a>) : (<><div className="btn btn-primary" id="follow">Followed</div></>)}
									 */}
								
									

									
									</p>
							
								


								 <div className="user-experience">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
									<div className="col-md-6">
										<strong className="address_content">Contact</strong> 
				                        <div className="ex_inner_list" style={{"min-height": "100px"}}>
										<ul>
										<li>Mobile:	<span>{profileData.mobile} </span></li>
										<li>Email:	<span>{profileData.email}</span>	</li>
									 	</ul>
										 </div>


										</div>	


                                            </div>
                                        </div>
                                        {/* {(profileData.address != undefined && profileData.address != '') ? <>
                                        <div className="col-md-12" key={Math.random()}>
										 <strong className="address_content"> Address </strong> 	
										{this.Address(profileData.address)}
										</div></>
										: '' } */}
                                    </div>
                                    {/* <div className="user_exp_list academic_1">
                                        <div className="row">
                                             {/* <AcademicList results={this.state.academicsData}></AcademicList> */}

											{/* {(academicsData.length > 0) ? this.AcademicList(academicsData) :''}
                                        </div>
                                    </div> */}
                                </div>	  

                                 {/* {(skillData.length >0) ? <>
								<div className="user-experience">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <strong className="address_content">Skills</strong>
                                        </div>
                                    </div>
                                    <div className="user_exp_list">
                                        <div className="row">
                                          
											{(skillData.length > 0) ? this.SkillsList(skillData) :''}
                                        </div>
                                    
                                    </div>
                                </div>  </>
                                : '' } */}

								
								
					

</div></div>
						</div>
						  </div>
						

					
					
			
		</div>
	</div>

</section>
    <Footer></Footer>
    </>  );
    }
}
 
export default PeopleProfile;