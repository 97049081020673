import Header from '../partials/Header'
import Footer from '../partials/Footer';
import Metatitle from "../../components/metaTitle";
import React, { Component, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import PlacesAutocomplete, { geocodeByAddress,getLatLng,geocodeByPlaceId  } from 'react-places-autocomplete';
import {getStoresCheck, clearErrors} from "../../actions/storeActions";
import Loader from '../../Loader';
import CONSTANT from '../../Constant'; 
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

class addStorePages extends Component {
	// const [state, dispatch] = useReducer(reducer, initialState);
    constructor(props) {
        super(props);
		const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
        this.state = {
			loggedIn,
			loadingnew: false,
			getFilterData: [],
			newloader: false,
			profile: {},
			stripeConnect: "",
			country_code:"",
			store_apt_no:"",
			storeAddress:"",
			storeLat:'',
			storeLong:'',
			store_street:"",
			store_country:"",
			store_state:"",
			store_city:"",
			store_zip_code:"",
			otp:"",
			otpMsg:"",
			doneMsg:"",
			addStoreMsg:"",
			formValidS: "",
			formErrorss: {
				storeImg: "",
				storeName: "",
				storePhoneNo: "",
				storeDesc: "",
				storeAddress: "",
				store_apt_no:"",
				store_street:"",
				store_country:"",
				store_state:"",
				store_city:"",
				store_zip_code:"",
				country_code:""
			},
			files:[],
			file:[],
			images:"",
			getStoreData: {},
			storeresult: [],
			longitude: "77.3479261775324",
			latitude:"28.605331625541074"
		};
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
    }
	
	async componentDidUpdate() {
		var getLocationArray = []	
		navigator.geolocation.getCurrentPosition(location => {
			localStorage.setItem("longitude",location.coords.longitude);
			localStorage.setItem("latitude",location.coords.latitude);
		});
		const {getStoreData} = this.props.storeresult
		if (Object.keys(getStoreData).length === 0 && getStoreData.constructor === Object) {		  
		} else {
			localStorage.setItem("checkStoreStatus", getStoreData.response_status);
			return <Redirect push to={`/store/addStore`} />
		}
		// this.setState({"loadingnew": false})
		document.getElementById("storePhoneNo").className = "form-control"
		$('.PhoneInputCountryIcon').css({'width':'54px','height':'36px','border-radius':'.25rem'});
	}
	
	async componentDidMount() {
		var getLocationArray = []	
		navigator.geolocation.getCurrentPosition(location => {
			localStorage.setItem("longitude",location.coords.longitude);
			localStorage.setItem("latitude",location.coords.latitude);
		});
	}
	
	storePhoneNoChange = e => {
		if(e){			
			const phoneNumber = parsePhoneNumber(e)		
			if (phoneNumber) {
				localStorage.setItem("storePhoneNo",phoneNumber.nationalNumber);
				localStorage.setItem("country_code",phoneNumber.countryCallingCode);
			}			
		}	
	}
	
	handleUsernameChange = e => {			
		if (e.target.files && e.target.files[0]) {
			this.setState({
				imageURL: URL.createObjectURL(e.target.files[0]),
				images:e.target.files[0]
			});
		}		
		this.setState({ [e.target.id]: e.target.value});	
	}
	
	
	
  handleSelect = async (address,placeId) => {
      const results = await geocodeByAddress(address);
	  
	  //console.log(results);
	  const latLng = await getLatLng(results[0]);
	 const [place] = await geocodeByPlaceId(placeId);
	 const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
	 const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
	 const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
	const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
	const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
	const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
    const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
	const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
	var cityNames='';
	if(cityNameNeighbour) cityNames=cityNameNeighbour;else if(cityNameLocality)cityNames=cityNameLocality;else{cityNames=cityName;}

	this.setState({storeAddress: address,storeLat:latLng.lat,storeLong:latLng.lng,store_state:stateName,store_city:cityNames ,store_zip_code:postalCode,store_country:countryName,store_street:streetNumber+' '+routesName})
  };
		
	
   handleChange = (address) => {
    this.setState({ storeAddress:address });
   
  };
  

    handleKeyDown = e => {
    	console.log("dsfsd11111111111");
    	console.log(e.key);
    	    	console.log("dsfsd11111111111");

        if (e.key === " ") {
          e.preventDefault();
        }
      };


	checkLoggedIn = e => {
		e.preventDefault();
		if(this.state.loggedIn == true){			
				
		} else {			
			this.setState({loginData: "click",profileData:"", response_status: 0})
			e.target.setAttribute("id", "login")
			e.target.setAttribute("data-toggle", "modal")
			e.target.setAttribute("data-target", "#Login")			
		}	
	}
	
	verifyOtp = async e => {
		
		e.preventDefault();		
		this.setState({newloader: true});		
		var formData = new FormData();
		formData.append("store_image", this.state.images)
		formData.append("store_logo", this.state.images)
		formData.append("store_name", this.state.storeName)
		formData.append('country_code', localStorage.getItem("country_code"));
		formData.append('store_contact_number', localStorage.getItem("storePhoneNo"));
		formData.append('store_description', this.state.storeDesc);
		formData.append('store_address', this.state.storeAddress);
		formData.append('store_complete_address', this.state.storeAddress);
		formData.append('store_apt_no', this.state.store_apt_no);
		formData.append('store_street', this.state.store_street);
		formData.append('store_country', this.state.store_street);
		formData.append('store_state', this.state.store_state);
		formData.append('store_city', this.state.store_city);
		formData.append('store_zip_code', this.state.store_zip_code);
		formData.append('store_latitude', localStorage.getItem("latitude"));
		formData.append('store_longitude', localStorage.getItem("longitude"));
		formData.append('session_token', localStorage.getItem("token"));
		formData.append('user_id', localStorage.getItem("loggedInUserId"));
		formData.append('status', 0);
		formData.append('service_time', '');
		formData.append('service_time_unit', '');
		
		this.setState({
			errors:'Please wait...',
			errorstatus:true,
		})
		
		var validOTP = localStorage.getItem('otp');
		var enterOTP = this.state.otp;		
		
		if(validOTP == enterOTP){
			
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}	
			
			let response = await axios.post(CONSTANT.STABASE_URL+'store/addStore', formData, config);

			if(response.data.response_status != 0){
				document.getElementById('Otpb').click();
				localStorage.removeItem('otp');
				var doneMsg = 'Store added Sucessfully';
				this.setState({doneMsg:doneMsg,newloader: false})	
			}		
			

			if(localStorage.getItem("stripeConnect") == 0){	
			
				setTimeout(function(){
					//document.location.href = CONSTANT.stripeConnectLocalURL;
					document.location.href = CONSTANT.stripeConnectLiveURL;
					//document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_ASFmJ9wwpp5vcR4piWEW2M7OSArNBcUS&scope=read_write&redirect_uri=http://www.abluec.com:9024/stripeConnect/stripeConnectAuth&state=169#/'	

					// document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_ASFmJ9wwpp5vcR4piWEW2M7OSArNBcUS&scope=read_write&redirect_uri=http://localhost:7103/stripeConnect/stripeConnectAuth&state=169#/'					
									
				}, 1000);
				
			} else {
				
				document.location.href = "/vendor/myStores";	
			}
			
			
		} else {
			
			var otpMsg = 'Please Enter Valid OTP'
			this.setState({otpMsg:otpMsg})
		}
	}
	
	
	connectStripe = async e => {
		
		e || e.preventDefault();
		
		this.setState({newloader: true});
		
		if(this.state.storeName && this.state.storeDesc && this.state.storeAddress && this.state.store_street && this.state.store_country  && this.state.store_state  && this.state.store_city  && this.state.store_zip_code){
			
			var getStoresCheck = await this.props.getStoresCheck({store_name: this.state.storeName})
			
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => {
				var checkStoreStatus = localStorage.getItem("checkStoreStatus");
				if(checkStoreStatus == 1){
					
					var addStoreMsg = 'Store Name Already Exists!'
					this.setState({addStoreMsg:addStoreMsg})
					
				} else {
					
					// const configVerify = {
						// headers: {
							// 'Content-Type': 'application/json',
							//  
						// }
					// }
					
					// var verifyOtp = {
						// country_code: this.state.country_code,
						// mobile_number: this.state.storePhoneNo
					// };
					
					// let verifyOtpResponse = await axios.post(CONSTANT.STABASE_URL+'users/verifyOtp', verifyOtp, configVerify);
					
					// if(verifyOtpResponse.data.response_status != 0){
						document.getElementById('StoreForm').click();
						document.getElementById("StoreForm").className = "modal fade show";
						document.getElementById('StoreForm').click();
						document.getElementById('Otpb').click();
						document.getElementById("OtpForm").className = "modal fade1 show";
						this.setState({newloader: false});
						localStorage.setItem('otp', 1234);
						// localStorage.setItem('otp', verifyOtpResponse.data.response_data.OTP);
					// } else {
						// this.setState({addStoreMsg:"Please enter correct number"})
					// }
				}
			}, 10000);
			
		 } else {
			 
			let fieldValidationErrorss = this.state.formErrorss;
			let storeNameValid = this.state.storeName;
			let country_codeValid = this.state.country_code;
			let storePhoneNoValid = this.state.storePhoneNo;
			let storeDescValid = this.state.storeDesc;
			let storeAddressValid = this.state.storeAddress;
			// let store_apt_noSignValid = this.state.store_apt_no;
			let store_streetValid = this.state.store_street;
			let store_countryValid = this.state.store_country;
			let store_stateValid = this.state.store_state;
			let store_cityValid = this.state.store_city;
			let store_zip_codeValid = this.state.store_zip_code;		
			
			storeNameValid = this.state.storeName;
			fieldValidationErrorss.storeName = storeNameValid ? '' : 'Please enter name';
			
			
			var pattern = new RegExp(/^[0-9\b]+$/);
			country_codeValid = pattern.test(this.state.country_code);
			fieldValidationErrorss.country_code = country_codeValid ? '' : 'Not valid Code';
			
			var pattern = new RegExp(/^[0-9\b]+$/);
			storePhoneNoValid = pattern.test(localStorage.getItem('storePhoneNo'));
			fieldValidationErrorss.storePhoneNo = storePhoneNoValid ? '' : 'Please enter valid number!';
			
			storeDescValid = this.state.storeDesc;
			fieldValidationErrorss.storeDesc = storeDescValid ? '' : 'Please enter description!';
			
			storeAddressValid = this.state.storeAddress;
			fieldValidationErrorss.storeAddress = storeAddressValid ? '' : 'Please enter address!';
			
			// store_apt_noSignValid = this.state.store_apt_no;
			// fieldValidationErrorss.store_apt_no = store_apt_noSignValid ? '' : 'Please enter apt no!';
			
			store_streetValid = this.state.store_street;
			fieldValidationErrorss.store_street = store_streetValid ? '' : 'Please enter street name!';
			
			store_countryValid = this.state.store_country;
			fieldValidationErrorss.store_country = store_countryValid ? '' : 'Please enter country name!';
			
			store_stateValid = this.state.store_state;
			fieldValidationErrorss.store_state = store_stateValid ? '' : 'Please enter state name!';
			
			store_cityValid = this.state.store_city;
			fieldValidationErrorss.store_city = store_cityValid ? '' : 'Please enter city name!';
			
			store_zip_codeValid = this.state.store_zip_code;
			fieldValidationErrorss.store_zip_code = store_zip_codeValid ? '' : 'Please enter zip code!';
			
			this.setState({
				newloader: false,
				formErrorss: fieldValidationErrorss,
				storeNameValid: storeNameValid,
				country_codeValid: country_codeValid,
				storePhoneNoValid: storePhoneNoValid,
				storeDescValid: storeDescValid,
				storeAddressValid: storeAddressValid,
				// store_apt_noSignValid: store_apt_noSignValid,
				store_streetValid: store_streetValid,
				store_countryValid: store_countryValid,
				store_stateValid: store_stateValid,
				store_cityValid: store_cityValid,
				store_zip_codeValid: store_zip_codeValid,
			}, () => this.setState({formValidS: this.state.storeNameValid && this.state.country_codeValid && this.state.storePhoneNoValid && this.state.storeDescValid && this.state.storeAddressValid && this.state.store_apt_noSignValid && this.state.store_streetValid && this.state.store_countryValid && this.state.store_stateValid && this.state.store_cityValid && this.state.store_zip_codeValid}));
			 
			 
		 }
		
	}
	

	handleClose = e => {
		e.preventDefault();
		
		// document.getElementById("StoreForm").className = "modal fade1 show";
		// if(e.target.id == "StoreForm"){
			// e.target.setAttribute("id", "StoreForm")
			// e.target.setAttribute("data-toggle", "modal")
			// e.target.setAttribute("data-target", "#StoreForm")
		// } else
			// if(e.target.id == "storeImg"){
			// e.target.setAttribute("id", "StoreForm")
			// e.target.setAttribute("data-toggle", "modal")
			// e.target.setAttribute("data-target", "#StoreForm")
			// var input = document.createElement('input');
			// input.type = 'file';
			// input.onchange = e => { 
			   // this.setState({imageURL: URL.createObjectURL(e.target.files[0]),images:e.target.files[0]});
			// }
			// input.click();
		// } else if(e.target.id == "OtpForm"){
			// e.target.setAttribute("id", "OtpForm")
			// e.target.setAttribute("data-toggle", "modal")
			// e.target.setAttribute("data-target", "#OtpForm")
		// } else {
			// e.target.setAttribute("id", "StoreForm")
			// e.target.setAttribute("data-toggle", "modal")
			// e.target.setAttribute("data-target", "#StoreForm")
			// this.setState({ [e.target.id]: e.target.value});
			
		// }	
	}
		
	render() {
		
		if(this.state.loggedIn == false){ 
            return <Redirect to="/" />		
        }
		
		const { getFilterData, response_status, profileData, profile } = this.state;
		const {getStoreData} = this.props.storeresult
			
		
		
		var getData = Object.assign({},...getFilterData)
		
		var getStoreInfo = getData.getStoreInfo ? getData.getStoreInfo : 0
		
		return (		
			<>
				<Metatitle title="Add Store | aBlueC" metaDescription="Vendor Store" ogTitle="Vendor Store" ogImage=""></Metatitle>
				<Header data="inner-head"></Header>
				{/*<section className="sidebar-layout innerwave add-store">
				<section className="sidebar-layout">
						<div className="container">*/}
				<section className="sidebar-layout  add-store">
						<div className="container-fluid bdr-top">
							<div className="row">
															
								<div className="col-12">
									
									{ this.state.newloader ? <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} /> : "" }
									
									<div id="content" style={{"text-align": "center"}}>
									<h3 id="title">Manage Stores</h3>
									
										{/*<div style={{"color": "rgb(146,6,6)","background": "none"}}>Stripe Connected</div>*/}
								
										<img src="../assets/images/open.png" />
										
										{(getStoreInfo.length > 0) ?
										<>
											<br/><br/><button type="button" style={{"padding": "10px","width": "200px"}} className="btn btn-primary mr-1" data-toggle="modal" data-target="#StoreForm" onClick={this.checkLoggedIn}>Add Store</button><br/><br/>
										</>
										:
										<>
											<br/><br/><button type="button" style={{"padding": "10px","width": "200px"}} className="btn btn-primary mr-1" data-toggle="modal" data-target="#StoreForm" onClick={this.checkLoggedIn}>Open your first store</button><br/><br/>
										</>
										}									
									</div>									
								</div>
							</div>
						</div>
				</section>
				 
				<div className="modal fade" id="StoreForm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Add Store</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{"color":"white"}}>
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p style={{"color":"red","float": "left"}}>{(this.state.addStoreMsg != "") ? this.state.addStoreMsg : ""}</p>
								<form enctype="multipart/form-data" method="post" noValidate onSubmit={this.connectStripe}>
									<div className="form-row"> 
										<div className="form-group col-md-3"> 
											<span className="fileupload">
											<input type="file" className="upload-logo" id="storeImg" aria-describedby="emailHelp" placeholder="Store Image" name="storeImg" key="storeImg" value={this.state.storeImg} onChange={this.handleUsernameChange} /> </span>
										</div>
										{(this.state.images) ? 
										<div className="col-lg-9">
											<img src={this.state.imageURL} style={{width: '70px', height: '70px'}} alt={this.state.images}/>
										</div> 
										 :null}
									</div>
									<div className="form-row">
										<div className="form-group col-md-12">												
											<input type="text" className="form-control" id="storeName" aria-describedby="emailHelp" placeholder="Store Name" name="storeName" key="storeName" value={this.state.storeName} onChange={this.handleUsernameChange}required  />
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeName}</p>
										</div>
										{/*<div className="form-group col-md-3">
											<div className="input-group mb-2">
												<div className="input-group-prepend">
													<div className="input-group-text">+</div>
												</div>
												<input type="text" className="form-control" id="country_code" aria-describedby="country_codeHelp" name="country_code" value={this.state.country_code} onChange={this.handleUsernameChange} required />							
											</div>								
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' :this.state.formErrorss.country_code}</p>
										</div>*/}
										<div className="form-group col-md-12">
											<PhoneInput flags={flags} className="" defaultCountry="US" placeholder="Enter phone number" id="storePhoneNo" value={this.state.storePhoneNo} onChange={this.storePhoneNoChange}/>
											{/*<input type="text" className="form-control" id="storePhoneNo" placeholder="Store Phone No" name="storePhoneNo" value={this.state.storePhoneNo} onChange={this.handleUsernameChange} required />*/}
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storePhoneNo}</p>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-md-12">												
											<textarea className="form-control" id="storeDesc" placeholder="Description" rows="3" name="storeDesc" value={this.state.storeDesc} onChange={this.handleUsernameChange} required></textarea>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeDesc}</p>													
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-md-12">												
										{/*<input type="email" className="form-control" id="storeAddress" aria-describedby="emailHelp" placeholder="Address" name="storeAddress" value={this.state.storeAddress} onChange={this.handleUsernameChange} required/>*/}
										
	
<PlacesAutocomplete 
        value={this.state.storeAddress}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Address ...',
                className: 'form-control search_input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className="list-item-description">{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.storeAddress}</p>
										</div>
										<div className="form-group col-md-12">												
											<input type="text" className="form-control" id="store_apt_no" placeholder="Apt No/House No:/BuildingNo" name="store_apt_no" value={this.state.store_apt_no} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_apt_no}</p>
										</div>
										<div className="form-group col-md-12">												
											<input type="text" className="form-control" id="store_street" placeholder="Street" name="store_street" value={this.state.store_street} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_street}</p>
										</div>
										<div className="form-group col-md-6">												
											<input type="text" className="form-control" id="store_city" placeholder="City" name="store_city" value={this.state.store_city} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_city}</p>
										</div>
										<div className="form-group col-md-6">												
											<input type="text" className="form-control" id="store_state" placeholder="State" name="store_state" value={this.state.store_state} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_state}</p>
										</div>
										<div className="form-group col-md-6">												
											<input type="text" className="form-control" id="store_zip_code" placeholder="Zip Code" name="store_zip_code" value={this.state.store_zip_code} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_zip_code}</p>
										</div>
										<div className="form-group col-md-6">												
											<input type="text" className="form-control" id="store_country" placeholder="Country" name="store_country" value={this.state.store_country} onChange={this.handleUsernameChange} required/>
											<p style={{"color":"red","float": "left"}}>{this.state.formValidS ? '' : this.state.formErrorss.store_country}</p>
										</div>
										<div className="col-md-12 text-center">
											<button type="button" className="btn btn-primary my-2 px-5 py-3" onClick={this.connectStripe}>Save and Continue</button>
										</div>													
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<button type="hidden" class="btn btn-primary mr-1" id="Otpb" data-toggle="modal" data-target="#OtpForm" style={{"display":"none"}}>OTP</button>
				<div className="modal fade" id="OtpForm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  onClick={this.handleClose}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Please enter the otp</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{"color":"white"}}>
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form noValidate onSubmit={this.verifyOtp}>					
									<div className="form-row">
										<div className="form-group col-md-12">	
											<p style={{"color":"blue"}}>{(this.state.doneMsg != "") ? this.state.doneMsg : ""}</p>
											<input type="text" className="form-control" id="otp" aria-describedby="emailHelp" placeholder="Ex: 1234" name="otp" key="otp" value={this.state.otp} onChange={this.handleUsernameChange} required />
											<p style={{"color":"red"}}>{(this.state.otpMsg != "") ? this.state.otpMsg : ""}</p>
										</div>
									</div>
									<div className="col-md-12 text-center">
										<button type="button" className="btn btn-primary my-2 px-5 py-3" onClick={this.verifyOtp}>Save and Continue</button>
									</div>	
								</form>	
							</div>
						</div>
					</div>
				</div>
				<Footer></Footer>
			</>
		);
    }
}
 
addStorePages.propTypes = {
	auth: PropTypes.object.isRequired,
	storeresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getStoresCheck,clearErrors}
)(addStorePages);