import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import CONSTANT from '../../Constant';
import Loader from '../../Loader';
import Button from "./Button";
import Metatitle from "../../components/metaTitle";
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


async function checkImg(img) {
	await axios.get(img).then((response) => {
		if (response) {
			return response
		}
	})
}

function urlParam(name) {
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results == null) {
		return null;
	}
	return decodeURI(results[1]) || 0;
}

class StoreVendor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			smsg: "",
			emsg: "",
			profileData: "",
			pageUrl: "",
			profile: {},
			loading: false,
			openSl1: true,
			openSl2: false,
			store_id: '',
			formatted_address: '',
			store_apt_no: "",
			storeAddress: "",
			storeLat: '',
			storeLong: '',
			store_street: "",
			store_country: "",
			store_state: "",
			store_city: "",
			store_zip_code: "",
			storePhoneNo: "",
			longitude: "77.3479261775324",
			latitude: "28.605331625541074",
			store_data_array: '',
			formErrorss: {
				storeImg: "",
				storeName: "",
				storePhoneNo: "",
				storeDesc: "",
				storeAddress: "",
				store_apt_no: "",
				store_street: "",
				store_country: "",
				store_state: "",
				store_city: "",
				store_zip_code: "",
				country_code: ""
			},
			monday_start_time: '',
			monday_end_time: '',
			tuesday_start_time: '',
			tuesday_end_time: '',
			wednesday_start_time: '',
			wednesday_end_time: '',
			thursday_start_time: '',
			thursday_end_time: '',
			friday_start_time: '',
			friday_end_time: '',
			saturday_start_time: '',
			saturday_end_time: '',
			sunday_start_time: '',
			sunday_end_time: '',
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
			saturday: 0,
			sunday: 0,
			tableData: [1, 2, 3, 4, 5, 6, 0],
			banner_image: '',
			imageBannerURL: '',
			bannerLink: ''

		};
		//this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.reset = this.reset.bind(this);


	}

	async componentDidUpdate() {
		// document.getElementById("mobile").className = "form-control"
		$('.PhoneInputCountryIcon').css({ 'width': '54px', 'height': '36px', 'border-radius': '.25rem', 'border': '0' });
	}


	async componentDidMount() {
		this.setState({ loading: true });
		const onSuccess = (position) => {
			this.setState({ "latitude": position.coords.latitude, "longitude": position.coords.longitude })
		};
		const onError = (error) => {
			console.log(error.code + ' ' + error.message);
		}
		navigator.geolocation.getCurrentPosition(onSuccess, onError);


		if (localStorage.getItem("token") == undefined) {
			this.props.history.push('/login');
		}
		$('#storeImg').css({ 'position': 'absolute', 'width': '100%', 'height': '100%' });
		$("body").append('<style>.upload-logo::before{border: none;border-radius: none;content:none;}</style>');

		const user_id = localStorage.getItem("loggedInUserId");
		const session_token = localStorage.getItem("token");
		const store_id = localStorage.getItem("store_id");

		var formData = { "session_token": localStorage.getItem("token"), "user_id": localStorage.getItem("loggedInUserId"), "store_id": localStorage.getItem("store_id") }
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}
		let storeData = await axios.post(CONSTANT.STABASE_URL + 'web/store/getStores', formData, config);
		if (storeData.data.response_status === 1) {
			//console.log(storeData.data.response_data);
			//this.setState({profile:getProfile.data.response_data.profile, "response_status": getProfile.data.response_status,fname:getProfile.data.response_data.profile.name,femail:getProfile.data.response_data.profile.email,fnumber:getProfile.data.response_data.profile.mobile,dname:getProfile.data.response_data.profile.display_name, flatno:getProfile.data.response_data.profile.address.flatno, street:getProfile.data.response_data.profile.address.street, city:getProfile.data.response_data.profile.address.city, state:getProfile.data.response_data.profile.address.state, zip:getProfile.data.response_data.profile.address.zip, country:getProfile.data.response_data.profile.address.country, complete_address:getProfile.data.response_data.profile.address.complete_address, address:getProfile.data.response_data.profile.address.complete_address});
			//this.setState({loading:false});
			this.setState({
				store_data_array: storeData.data.response_data.store_list,
				store_id: storeData.data.response_data.store_list[0]._id,
				storeName: storeData.data.response_data.store_list[0].store_name,
				images: storeData.data.response_data.store_list[0].store_image,
				imageURL: (storeData.data.response_data.store_list[0].store_image) ? CONSTANT.NSTABASE_URL + '../images/store/' + storeData.data.response_data.store_list[0].store_image : CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
				storeDesc: storeData.data.response_data.store_list[0].store_description,
				storeAddress: storeData.data.response_data.store_list[0].store_address,
				store_street: storeData.data.response_data.store_list[0].store_street,
				store_country: storeData.data.response_data.store_list[0].store_country,
				store_state: storeData.data.response_data.store_list[0].store_state,
				store_city: storeData.data.response_data.store_list[0].store_city,
				store_zip_code: storeData.data.response_data.store_list[0].store_zip_code,
				storePhoneNo: storeData.data.response_data.store_list[0].store_contact_number,
				//storePhoneNo:storeData.data.response_data.store_list[0].country_code+storeData.data.response_data.store_list[0].store_contact_country,
				imageBannerURL: (storeData.data.response_data.store_list[0].banner_image) ? CONSTANT.NSTABASE_URL + '../images/store/' + storeData.data.response_data.store_list[0].banner_image : CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
				bannerLink: storeData.data.response_data.store_list[0].store_banner_link,
				loading: false
			})
		}

		(this.state.images) ?
			$("body").append('<style>.upload-logo::after{opacity:.4;background:white;padding-top: 12px;color: black;font-size: 0px;font-weight: bolder;}</style>') : $("body").append('<style>.upload-logo::after{opacity:.4;background:white;padding-top: 12px;color: black;font-size: 35px;font-weight: bolder;}</style>')


	}

	storePhoneNoChange = e => {
		if (e) {
			const phoneNumber = parsePhoneNumber(e)
			if (phoneNumber) {
				localStorage.setItem("storePhoneNo", phoneNumber.nationalNumber);
				localStorage.setItem("country_code", phoneNumber.countryCallingCode);
			}
		}
	}

	imageSize = (url) => {
		const img = document.createElement("img");

		const promise = new Promise((resolve, reject) => {
			img.onload = () => {
				// Natural size is the actual image size regardless of rendering.
				// The 'normal' `width`/`height` are for the **rendered** size.
				const width = img.naturalWidth;
				const height = img.naturalHeight;

				// Resolve promise with the width and height
				resolve({ width, height });
			};

			// Reject promise on error
			img.onerror = reject;
		});

		// Setting the source makes it start downloading and eventually call `onload`
		img.src = url;

		return promise;
	}


	handleUsernameChange = async e => {
		if (e.target.files && e.target.files[0]) {
			$('#storeImg').css({ 'position': 'absolute', 'width': '100%', 'height': '100%' });
			$("body").append('<style>.upload-logo::before{border: none;border-radius: none;content:none;}</style>');
			$("body").append('<style>.upload-logo::after{opacity:.4;background:white;padding-top: 12px;color: black;font-size: 0px;font-weight: bolder;}</style>');
			var imageDimensions = await this.imageSize(URL.createObjectURL(e.target.files[0]));
			console.log(imageDimensions);
			this.setState({
				imageURL: URL.createObjectURL(e.target.files[0]),
				images: e.target.files[0]
			});
		}
		this.setState({ [e.target.id]: e.target.value });
	}


	handleBannerChange = async e => {
		if (e.target.files && e.target.files[0]) {
			var imageDimensions = await this.imageSize(URL.createObjectURL(e.target.files[0]));
			console.log(imageDimensions);
			this.setState({
				imageBannerURL: URL.createObjectURL(e.target.files[0]),
				banner_image: e.target.files[0]
			});
		}
		this.setState({ [e.target.id]: e.target.value });
	}


	handleSelects = async (address, placeId) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const [place] = await geocodeByPlaceId(placeId);
		const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
		const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
		const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
		const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
		const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
		const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
		const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
		var cityNames = '';
		if (cityNameNeighbour) cityNames = cityNameNeighbour; else if (cityNameLocality) cityNames = cityNameLocality; else { cityNames = cityName; }
		this.setState({ storeAddress: address, storeLat: latLng.lat, storeLong: latLng.lng, store_state: stateName, store_city: cityNames, store_zip_code: postalCode, store_country: countryName, store_street: streetNumber + ' ' + routesName })
	};


	handleChanges = (address) => {
		this.setState({ storeAddress: address });
	};


	updateStore = async e => {
		e || e.preventDefault();

		this.setState({ newloader: true });
		//      console.log("jjjjjjjjj");
		// console.log(this.state.storeName); return


		if (this.state.storeName && this.state.storeDesc && this.state.storeAddress && this.state.store_street && this.state.store_country && this.state.store_state && this.state.store_city && this.state.store_zip_code) {

			//var getStoresCheck = await this.props.getStoresCheck({store_name: this.state.storeName})	

			// console.log("jjjjjjjjj")
			// console.log(getStoresCheck)
			// console.log("jjjjjjjjj")


			var checkStoreStatus = localStorage.getItem("checkStoreStatus");
			if (checkStoreStatus == 1) {

				var addStoreMsg = 'Store Name Already Exists!'
				this.setState({ addStoreMsg: addStoreMsg, newloader: false })
				$("#showStorePopup").scrollTop(0);

			} else {


				this.setState({ newloader: false });

				var formData = new FormData();
				formData.append("store_id", this.state.store_id)
				formData.append("store_image", this.state.images)
				formData.append("store_logo", this.state.images)
				formData.append("banner_image", this.state.banner_image)
				formData.append("store_name", this.state.storeName)
				formData.append('country_code', localStorage.getItem("country_code"));
				formData.append('store_contact_number', localStorage.getItem("storePhoneNo"));
				formData.append('store_description', this.state.storeDesc);
				formData.append('store_address', this.state.storeAddress);
				formData.append('store_complete_address', this.state.storeAddress);
				formData.append('store_apt_no', this.state.store_apt_no);
				formData.append('store_street', this.state.store_street);
				formData.append('store_country', this.state.store_street);
				formData.append('store_state', this.state.store_state);
				formData.append('store_city', this.state.store_city);
				formData.append('store_zip_code', this.state.store_zip_code);
				formData.append('store_latitude', localStorage.getItem("latitude"));
				formData.append('store_longitude', localStorage.getItem("longitude"));
				formData.append('session_token', localStorage.getItem("token"));
				formData.append('user_id', localStorage.getItem("loggedInUserId"));
				formData.append('status', 0);
				formData.append('service_time', '');
				formData.append('service_time_unit', '');
				formData.append("store_banner_link", this.state.bannerLink)


				this.setState({
					errors: 'Please wait...',
					errorstatus: true,
				})

				// var validOTP = localStorage.getItem('otp');
				// var enterOTP = this.state.otp;		

				// if(validOTP == enterOTP){

				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						'x-access-token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
					}
				}

				let response = await axios.post(CONSTANT.STABASE_URL + 'store/editStore', formData, config);

				// console.log("MMMMMMMMM")
				// console.log(response.data)
				// console.log("MMMMMMMMM")

				if (response.data.response_status != 0) {
					var doneMsg = 'Store updated Sucessfully';
					//this.setState({addStoreMsg:doneMsg,newloader: false})
					// document.location.href = "/addcard"
					// document.location.href = "/vendor/myStores"
					this.setState({ loading: false, smsg: response.data.response_msg, openSl2: false, openSl1: true });

				} else {
					this.setState({ loading: false, emsg: response.data.response_msg, openSl2: false, openSl1: true });
				}
				await this.componentDidMount();
			}


		} else {

			let fieldValidationErrorss = this.state.formErrorss;
			let storeNameValid = this.state.storeName;
			let country_codeValid = this.state.country_code;
			let storePhoneNoValid = this.state.storePhoneNo;
			let storeDescValid = this.state.storeDesc;
			let storeAddressValid = this.state.storeAddress;
			// let store_apt_noSignValid = this.state.store_apt_no;
			let store_streetValid = this.state.store_street;
			let store_countryValid = this.state.store_country;
			let store_stateValid = this.state.store_state;
			let store_cityValid = this.state.store_city;
			let store_zip_codeValid = this.state.store_zip_code;

			storeNameValid = this.state.storeName;
			fieldValidationErrorss.storeName = storeNameValid ? '' : 'Please enter name';


			var pattern = new RegExp(/^[0-9\b]+$/);
			country_codeValid = pattern.test(this.state.country_code);
			fieldValidationErrorss.country_code = country_codeValid ? '' : 'Not valid Code';

			var pattern = new RegExp(/^[0-9\b]+$/);
			storePhoneNoValid = pattern.test(localStorage.getItem('storePhoneNo'));
			fieldValidationErrorss.storePhoneNo = storePhoneNoValid ? '' : 'Please enter valid number!';

			storeDescValid = this.state.storeDesc;
			fieldValidationErrorss.storeDesc = storeDescValid ? '' : 'Please enter description!';

			storeAddressValid = this.state.storeAddress;
			fieldValidationErrorss.storeAddress = storeAddressValid ? '' : 'Please enter address!';

			// store_apt_noSignValid = this.state.store_apt_no;
			// fieldValidationErrorss.store_apt_no = store_apt_noSignValid ? '' : 'Please enter apt no!';

			store_streetValid = this.state.store_street;
			fieldValidationErrorss.store_street = store_streetValid ? '' : 'Please enter street name!';

			store_countryValid = this.state.store_country;
			fieldValidationErrorss.store_country = store_countryValid ? '' : 'Please enter country name!';

			store_stateValid = this.state.store_state;
			fieldValidationErrorss.store_state = store_stateValid ? '' : 'Please enter state name!';

			store_cityValid = this.state.store_city;
			fieldValidationErrorss.store_city = store_cityValid ? '' : 'Please enter city name!';

			store_zip_codeValid = this.state.store_zip_code;
			fieldValidationErrorss.store_zip_code = store_zip_codeValid ? '' : 'Please enter zip code!';

			this.setState({
				newloader: false,
				formErrorss: fieldValidationErrorss,
				storeNameValid: storeNameValid,
				country_codeValid: country_codeValid,
				storePhoneNoValid: storePhoneNoValid,
				storeDescValid: storeDescValid,
				storeAddressValid: storeAddressValid,
				// store_apt_noSignValid: store_apt_noSignValid,
				store_streetValid: store_streetValid,
				store_countryValid: store_countryValid,
				store_stateValid: store_stateValid,
				store_cityValid: store_cityValid,
				store_zip_codeValid: store_zip_codeValid,
			}, () => this.setState({ formValidS: this.state.storeNameValid && this.state.country_codeValid && this.state.storePhoneNoValid && this.state.storeDescValid && this.state.storeAddressValid && this.state.store_apt_noSignValid && this.state.store_streetValid && this.state.store_countryValid && this.state.store_stateValid && this.state.store_cityValid && this.state.store_zip_codeValid }));


		}

	}
	reset(day) {
		if (day === 1) {
			this.setState({
				monday_start_time: '',
				monday_end_time: '',
			})
		}

		if (day === 2) {
			this.setState({
				tuesday_start_time: '',
				tuesday_end_time: '',
			})
		}

		if (day === 3) {
			this.setState({
				wednesday_start_time: '',
				wednesday_end_time: '',
			})
		}

		if (day === 4) {
			this.setState({
				thursday_start_time: '',
				thursday_end_time: '',
			})
		}

		if (day === 5) {
			this.setState({
				friday_start_time: '',
				friday_end_time: '',
			})
		}

		if (day === 6) {
			this.setState({
				saturday_start_time: '',
				saturday_end_time: '',
			})
		}

		if (day === 0) {
			this.setState({
				sunday_start_time: '',
				sunday_end_time: '',
			})
		}
	}

	async editProfile(event) {
		if (event == 'edit')
			this.setState({ openSl1: false, openSl2: true })
		else
			this.setState({ openSl1: true, openSl2: false })
	}

	handleProfileChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	}



	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}




	render() {

		//console.log(this.state.storePhoneNo)

		return (
			<>
				<Metatitle title="Store calender | aBlueC" metaDescription="Store front" ogTitle="Store calender" ogImage=""></Metatitle>
				{this.state.loading ? (<Loader />) : (<></>)}
				<Header data="inner-head" ></Header>
				<section class="sidebar-layout bdr-top innerheight">

					<div className="container pt-4">
						<div className="header_catehory">
							<h2 className="jobs_heading mb-2 ">Store Front</h2> </div>
						<div className="card">
							<div className="card-header">  <Button></Button>  </div>
							<div className="card-body">
								<div className="row">
									<div className="col-lg-3 col-md-3 col-xs-12">
										<div className="card">
											<div className="card-body">
												<div className="d-flex flex-column align-items-center text-center">
													<img src={this.state.imageURL} alt="Admin" className="store-front-img" />
													<div className="mt-3">
														<h4>{this.state.storeName}</h4>


														{/*<button className="btn btn-outline-primary">Message</button>*/}
													</div>
												</div>
											</div>
										</div>
									</div>


									{/*For Show Profile*/}
									<div className={this.state.openSl1 ? 'col-lg-9 col-md-9 col-xs-12 pShow' : 'col-lg-9 col-md-9 col-xs-12 pHide'}>
										<div className="card mb-3">

											<div className="card-body">
												{(this.state.smsg != "") ?
													<>
														<div className="row">
															<div className="col-sm-12"><h6 className="mb-0" style={{ "color": "green" }}>Store updated successfully!</h6></div>
														</div>
														<hr />
													</>
													:
													<>
													</>
												}
												{(this.state.emsg != "") ?
													<>
														<div className="row">
															<div className="col-sm-12"><h6 className="mb-0" style={{ "color": "red" }}>Store not updated successfully!</h6></div>
														</div>
														<hr />
													</>
													:
													<>
													</>
												}
												<div className="row">
													<div className="col-sm-3"><h6 className="mb-0">Store Name</h6></div>
													<div className="col-sm-9 text-secondary">{this.state.storeName}</div>
												</div>
												<hr />
												<div className="row">
													<div className="col-sm-3"><h6 className="mb-0">Store Address</h6></div>
													<div className="col-sm-9 text-secondary">{this.state.storeAddress}</div>
												</div>
												<hr />
												<div className="row">
													<div className="col-sm-3"><h6 className="mb-0">Store Contact Number</h6></div>
													<div className="col-sm-9 text-secondary">{this.state.storePhoneNo}</div>
												</div>
												<hr />

												<div className="row">
													<div className="col-sm-12"> <button className="btn btn-primary " onClick={() => this.editProfile('edit')}>Edit</button></div>
												</div>

											</div>
										</div>
									</div>



									{/*For Edit Profile*/}
									<div className={this.state.openSl2 ? 'col-md-8 pShow' : 'col-md-8 pHide'}>
										<div className="card mb-3">
											<div className="card-body">

												<div className="form-row">
													<div className="form-group col-md-3">
														<span className="fileupload">
															<input type="file" type="file" className="upload-logo" id="storeImg" aria-describedby="emailHelp" accept="image/*" placeholder="Store Image" name="storeImg" key="storeImg" value={this.state.storeImg} onChange={this.handleUsernameChange} />

															{(this.state.images) ?
																<img src={this.state.imageURL} style={{ width: '105px', height: '76px' }} alt={this.state.images} />
																: <img src={this.state.imageURL} style={{ width: '105px', height: '76px' }} alt={this.state.images} />}
														</span>
													</div>
												</div>
												<div className="form-row">
													<div className="form-group col-md-12">
														<input type="text" className="form-control" id="storeName" aria-describedby="emailHelp" placeholder="Store Name" name="storeName" key="storeName" value={this.state.storeName} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.storeName}</p>
													</div>
													<div className="form-group col-md-12 phone-inpt">
														{/* <PhoneInput flags={flags} className="" defaultCountry="US" placeholder="Enter phone number" id="storePhoneNo" value={store_data_array.store_contact_number} onChange={this.storePhoneNoChange}/>
										 */}
														<PhoneInput flags={flags} className="" defaultCountry="US" placeholder="Enter phone number" id="storePhoneNo" value={this.state.storePhoneNo} onChange={this.storePhoneNoChange} />

														{/*<input type="text" className="form-control" id="storePhoneNo" placeholder="Store Phone No" name="storePhoneNo" value={this.state.storePhoneNo} onChange={this.handleUsernameChange} required />*/}
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.storePhoneNo}</p>
													</div>
												</div>
												<div className="form-row">
													<div className="form-group col-md-12">
														<textarea className="form-control" id="storeDesc" placeholder="Store Description" rows="3" name="storeDesc" value={this.state.storeDesc} onChange={this.handleUsernameChange} required></textarea>
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.storeDesc}</p>
													</div>
												</div>
												<div className="form-row">
													<div className="form-group col-md-12">
														<PlacesAutocomplete
															value={this.state.storeAddress}
															onChange={this.handleChanges}
															onSelect={this.handleSelects}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<div>
																	<input
																		{...getInputProps({
																			placeholder: 'Search Address ...',
																			className: 'form-control search_input',
																		})}
																	/>
																	<div className="autocomplete-dropdown-container">
																		{loading && <div>Loading...</div>}
																		{suggestions.map(suggestion => {
																			const className = suggestion.active
																				? 'suggestion-item--active'
																				: 'suggestion-item';
																			// inline style for demonstration purpose
																			const style = suggestion.active
																				? { backgroundColor: '#fafafa', cursor: 'pointer' }
																				: { backgroundColor: '#ffffff', cursor: 'pointer' };
																			return (
																				<div
																					{...getSuggestionItemProps(suggestion, {
																						className,
																						style,
																					})}
																				>
																					<span className="list-item-description">{suggestion.description}</span>
																				</div>
																			);
																		})}
																	</div>
																</div>
															)}
														</PlacesAutocomplete>
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.storeAddress}</p>
													</div>
													<div className="form-group col-md-12">
														<input type="text" className="form-control" id="store_apt_no" placeholder="Apt No/House No:/BuildingNo" name="store_apt_no" value={this.state.store_apt_no} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_apt_no}</p>
													</div>
													<div className="form-group col-md-12">
														<input type="text" className="form-control" id="store_street" placeholder="Street" name="store_street" value={this.state.store_street} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_street}</p>
													</div>
													<div className="form-group col-md-6">
														<input type="text" className="form-control" id="store_city" placeholder="City" name="store_city" value={this.state.store_city} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_city}</p>
													</div>
													<div className="form-group col-md-6">
														<input type="text" className="form-control" id="store_state" placeholder="State" name="store_state" value={this.state.store_state} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_state}</p>
													</div>
													<div className="form-group col-md-6">
														<input type="text" className="form-control" id="store_zip_code" placeholder="Zip Code" name="store_zip_code" value={this.state.store_zip_code} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_zip_code}</p>
													</div>
													<div className="form-group col-md-6">
														<input type="text" className="form-control" id="store_country" placeholder="Country" name="store_country" value={this.state.store_country} onChange={this.handleUsernameChange} required />
														<p style={{ "color": "red", "float": "left" }}>{this.state.formValidS ? '' : this.state.formErrorss.store_country}</p>
													</div>

												</div>

												{/* <div className="store_calender">
            <h2>Weekdays</h2>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox"> 
                    <input type="checkbox" id="checkbox" name="monday" defaultValue={1}  defaultChecked="checked"  onChange={this.onChange1} />
                    <label htmlFor="checkbox"><span>Monday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                

                <input type="time" placeholder="From" className="form-control" value={this.state.monday_start_time} name="monday_start_time" onChange={this.onChange}/>
              
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.monday_end_time} name="monday_end_time" onChange={this.onChange}/>
              
                <a href="javascript:void(0)" onClick={this.reset.bind(this,1)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-2" name="tuesday" defaultValue={2} defaultChecked="checked"  onChange={this.onChange1} />
                    <label htmlFor="checkbox-2"><span>Tuesday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.tuesday_start_time} name="tuesday_start_time" onChange={this.onChange}/>
               
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.tuesday_end_time} name="tuesday_end_time" onChange={this.onChange} />
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,2)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-3" name="wednesday" defaultValue={3} defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-3"><span>Wednesday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.wednesday_start_time} name="wednesday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.wednesday_end_time} name="wednesday_end_time" onChange={this.onChange}/>
                
                <a href="javascript:void(0)" onClick={this.reset.bind(this,3)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-4" name="thursday" defaultValue={4} defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-4"><span>Thursday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.thursday_start_time} name="thursday_start_time" onChange={this.onChange}/>
              
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.thursday_end_time} name="thursday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,4)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-5" name="friday" defaultValue={5} defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-5"><span>Friday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.friday_start_time} name="friday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.friday_end_time} name="friday_end_time" onChange={this.onChange}/>
              
                <a href="javascript:void(0)" onClick={this.reset.bind(this,5)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom last-days">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-6" name="saturday" defaultValue={6} defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-6"><span>Saturday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.saturday_start_time} name="saturday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.saturday_end_time} name="saturday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,6)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom last-days">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-7" name="sunday" defaultValue={0} defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-7"><span>Sunday </span></label>
                </div>
                </div> 
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.sunday_start_time} name="sunday_start_time" onChange={this.onChange}/>
               
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.sunday_end_time} name="sunday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,0)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div> */}
												<div className="form-row">
													<div className="form-group col-md-3">
														Upload Banner Image <span className="fileupload">
															<input type="file" type="file" aria-describedby="emailHelp" name="store_banner" accept="image/*" onChange={this.handleBannerChange} />

															{(this.state.banner_image) ?
																<img src={this.state.imageBannerURL} style={{ width: '105px', height: '76px' }} alt={this.state.banner_image} />
																: <img src={this.state.imageBannerURL} style={{ width: '105px', height: '76px' }} alt={this.state.banner_image} />}
														</span>
													</div>
													{/* <div className="form-group col-md-3">
														Upload Banner Link <span className="fileupload">
															<input id="bannerLink" type="text" aria-describedby="emailHelp" name="bannerLink" value={this.state.bannerLink} onChange={this.handleUsernameChange} />


														</span>
													</div>
												*/}
												</div>


												<div className="col-md-12 text-center">
													<button type="button" className="btn btn-primary mr-3" onClick={this.updateStore}>Update</button>
													<button className="btn btn-primary" onClick={() => this.editProfile('back')}>Back</button>
												</div>


											</div>
										</div>
									</div>

								</div>
							</div></div></div>
				</section>
				<Footer></Footer>
			</>
		);
	}
}
export default StoreVendor;