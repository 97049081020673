import Header from '../partials/Header'
import Footer from '../partials/Footer';
import Metatitle from "../../components/metaTitle";
import React, { Component, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../../Loader';
import CONSTANT from '../../Constant';
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {getStores,clearErrors} from "../../actions/storeActions";

class myStores extends Component {
    constructor(props) {
        super(props);
		const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
        this.state = {
			loggedIn,
			getFilterData: [],
			newloader: false,
			stripeConnect: "",
			getStoreData: {},
			storeresult: [],
			getListData: []
		};
    }
	
	async componentDidUpdate() {		
	}
	
	async componentDidMount() {
		this.setState({newloader: true});	
		const userData = {
			"session_token": localStorage.getItem("token"),
			"user_id": localStorage.getItem("loggedInUserId")
		};	
		var data = await this.props.getStores(userData);
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		// let response = await axios.post(CONSTANT.STABASE_URL+'store/listCards', formData, config);
		// if(response.data.response_status == 1){
			// this.setState({getListData: response.data.response_data.data, newloader: false});
			this.setState({newloader: false});
		// }
	}
	
	componentWillUnmount() {
		this.props.clearErrors();
	}
	
	setStore = e => {
		localStorage.setItem("store_id", e.id);
		localStorage.setItem("store_name", e.store_name);
		document.location.href = "/vendor/store/"+e.store_name
	}

	stripeConnect = e => {
		e.preventDefault();
		//document.location.href = 'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_ASFm4qvJ5XkC60W0pUzGMCzm8yxsdjer&scope=read_write&redirect_uri=http://www.abluec.com:9024/stripeConnect/stripeConnectAuth&state=169#/'	
		document.location.href = CONSTANT.stripeConnectLiveURL;
					//document.location.href = CONSTANT.stripeConnectLocalURL;
	}
	
	render() {
		
		if(this.state.loggedIn == false){ 
            return <Redirect to="/" />		
        }		
		const {getStoreListData} = this.props.storeresult
		const {getListData} = this.state
		
		// console.log("MMMMMMMMMMMM")
		// console.log(getStoreListData)
		// console.log("MMMMMMMMMMMM")
		
		return (		
			<>
				<Metatitle title="My Stores | aBlueC" metaDescription="Vendor Store" ogTitle="Vendor Store" ogImage=""></Metatitle>
				<Header data="inner-head"></Header>
				<section className="sidebar-layout bdr-top innerheight ">
						<div className="container pt-4">
							<div className="row">															
								<div className="col-12">
									{(this.state.newloader)? (<Loader />): ""}
										{/*<h3 id="title" className="mt-5 ml-3">My Stores {(getListData.length == 0) ? <><a href="/split-card-elements">+ Add Credit Card</a></> : <a href="/manageactivity?showStorePopup=yes">+ New Store</a>}</h3>*/}
										
										<div className="card"> 
                                       <div className="card-header" id="title" >
										   <span className="title-head">  My Stores  </span>  
										   <span className="float-right">
										   
										    {/* {((localStorage.getItem('stripeConnect') == 0) || (localStorage.getItem('stripeConnect') == undefined)) ?
									<>
									<div className="stripe-img"> <a href='' onClick={this.stripeConnect}> <img src="../assets/images/stripe-not-connected.png" alt="" /></a></div>
									</>
									:
									<>
									<div className="stripe-img">  <a href='' onClick={this.stripeConnect}><img src="../assets/images/stripe-connected.png" alt="" /></a></div>
									</>
									}  */}
										   <a className="btn btn-primary" href="/manageactivity?showStorePopup=yes">+ New Store</a>
										   
										   </span>
										   </div>
						               <div className="card-body px-0">

									   <div id="content" className="mystore">
											{(getStoreListData.length != 0) ? 
												<>
													{getStoreListData.length > 0 && getStoreListData.map((item ,i) =>
														
														<div className="store-sec" key={i}>
															<span className="store-img">
																{/* <input type="radio" id="kwik" name="jo" value="kwik" />
																<label for="kwik"> </label> */}
																<a href="#" onClick={() => this.setStore({"id":item._id,"store_name":item.store_name})}>
									<img src={(item.store_image != undefined && item.store_image != "") ? CONSTANT.NSTABASE_URL+'images/store/'+item.store_image : CONSTANT.NSTABASE_URL+'images/profile/shop-default.png'} alt="" />
									</a>
															</span>
															<p>{item.store_name}</p>
														</div>
													)}																		
												</>
												: 
												<>
													<br/><hr/><br/>No Store Found<br/><br/>
												</> 
											}																		
									</div>	



									  </div>  
									  </div>
																
								</div>
							</div>
						</div>
				</section>
				<Footer></Footer>
			</>
		);
    }
}
 
myStores.propTypes = {
	auth: PropTypes.object.isRequired,
	storeresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getStores, clearErrors}
)(myStores);