import React, { Component } from 'react';
import Header from '../partials/Header';
import Button from "./Button";
import Footer from '../partials/Footer';
import CONSTANT from '../../Constant';
import Loader from '../../Loader';
import Metatitle from "../metaTitle";
import { setStoreCalender } from '../Api';
import axios from 'axios';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;



function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

class StoreCalendar extends Component {
    constructor(props) {
        super(props);		
		this.state = {
			smsg:"",
			emsg:"",
			profileData: "",
			pageUrl: "",
			profile: {},
			loading: false,
			openSl1: false,
			openSl2: true,
			store_id:'',
			formatted_address:'',
			store_apt_no:"",
			storeAddress:"",
			storeLat:'',
			storeLong:'',
			store_street:"",
			store_country:"",
			store_state:"",
			store_city:"",
			store_zip_code:"",
			storePhoneNo: "",
			longitude: "77.3479261775324",
			latitude:"28.605331625541074",
			store_data_array:'',
			monday_start_time:'',
			  monday_end_time:'',
			  tuesday_start_time:'',
			  tuesday_end_time:'',
			  wednesday_start_time:'',
			  wednesday_end_time:'',
			  thursday_start_time:'',
			  thursday_end_time:'',
			  friday_start_time:'',
			  friday_end_time:'',
			  saturday_start_time:'',
			  saturday_end_time:'',
			  sunday_start_time:'',
			  sunday_end_time:'',
			  monday:0,
			  tuesday:0,
			  wednesday:0,
			  thursday:0,
			  friday:0,
			  saturday:0,
			  sunday:0,
			  tableData:[1,2,3,4,5,6,0],
			
		};
		//this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.reset = this.reset.bind(this); 
		
		
    }
	
	async componentDidMount() {
		this.setState({loading:true});
		// const onSuccess = (position) => {
		// 	this.setState({"latitude":position.coords.latitude,"longitude":position.coords.longitude})
		// };		
		// const onError = (error) => {
		// 	console.log(error.code+' '+error.message);
		// }
		// navigator.geolocation.getCurrentPosition(onSuccess, onError);


		if(localStorage.getItem("token") == undefined){
			this.props.history.push('/login');
		}
	
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),"store_id":localStorage.getItem("store_id")}	
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
			let storeData = await axios.post(CONSTANT.STABASE_URL+'web/store/getStores',formData,config );
			if(storeData.data.response_status === 1){
			
                var calender = storeData.data.response_data.store_list.filter(cal => cal._id ===localStorage.getItem("store_id"));
        
                this.setState({storeName:storeData.data.response_data.store_list[0].store_name,imageURL:(storeData.data.response_data.store_list[0].store_image) ? CONSTANT.NSTABASE_URL+ '../images/store/'+ storeData.data.response_data.store_list[0].store_image:CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
            });
                if(calender[0]['calender'].length>0){
                 var monday_start_time = "";
                 var monday_end_time = "";
                 var tuesday_start_time = "";
                 var tuesday_end_time = "";
                 var wednesday_start_time = "";
                 var wednesday_end_time = "";
                 var thursday_start_time = "";
                 var thursday_end_time = "";
                 var friday_start_time = "";
                 var friday_end_time = "";
                 var saturday_start_time = "";
                 var saturday_end_time = "";
                 var sunday_start_time = "";
                 var sunday_end_time = "";
     
                 var monday    = 0;
                 var tuesday   = 0;
                 var wednesday = 0;
                 var thursday  = 0;
                 var friday    = 0;
                 var saturday  = 0;
                 var sunday    = 0;
     
                 calender[0]['calender'].forEach(cal =>{
                     var day = cal.day;
     
                     if(this.state.tableData.indexOf(day) === -1){
                        // this.state.tableData.push(day)
                     }  
     
     
                     
                     if(day===1){
                          monday_start_time = cal.utc_from_time;
                          monday_end_time   = cal.utc_to_time;
                          monday = true;
                     } if(day===2){
                          tuesday_start_time = cal.utc_from_time;
                          tuesday_end_time   = cal.utc_to_time;
                          tuesday = 1;
                     } if(day===3){
                          wednesday_start_time = cal.utc_from_time;
                          wednesday_end_time   = cal.utc_to_time;
                          wednesday = 1;
                     } if(day===4){
                          thursday_start_time = cal.utc_from_time;
                          thursday_end_time   = cal.utc_to_time;
                          thursday = 1;
                     } if(day===5){
                          friday_start_time = cal.utc_from_time;
                          friday_end_time   = cal.utc_to_time;
                          friday = 1;
                     } if(day===6){
                          saturday_start_time = cal.utc_from_time;
                          saturday_end_time   = cal.utc_to_time;
                          saturday = 1;
                     } if(day===0){ 
                          sunday_start_time = cal.utc_from_time;
                          sunday_end_time   = cal.utc_to_time;
                          sunday = 1;
                     }
     
                     // if(this.state.tableData.indexOf(day) === -1){
                     //     var day1 = day.toString();
                     //     this.state.tableData.push(day1)
                     // } 
     
     
                 })
     
                 
                 
     
     
                 this.setState({
                     monday_start_time:monday_start_time,
                     monday_end_time:monday_end_time,
                     tuesday_start_time:tuesday_start_time,
                     tuesday_end_time:tuesday_end_time,
                     wednesday_start_time:wednesday_start_time,
                     wednesday_end_time:wednesday_end_time,
                     thursday_start_time:thursday_start_time,
                     thursday_end_time:thursday_end_time,
                     friday_start_time:friday_start_time,
                     friday_end_time:friday_end_time,
                     saturday_start_time:saturday_start_time,
                     saturday_end_time:saturday_end_time,
                     sunday_start_time:sunday_start_time,
                     sunday_end_time:sunday_end_time,
                     sunday1:sunday,
                     monday1:monday,
                     tuesday1:tuesday,
                     wednesday1:wednesday,
                     thursday1:thursday,
                     friday1:friday,
                     saturday1:saturday,
                     storeName:storeData.data.response_data.store_list[0].store_name,
				 	 images:storeData.data.response_data.store_list[0].store_image,
				 	 loading:false
                 })
     
                 
               }else{

                this.setState({loading:false})
               }
             


			}				
		
       
	}

    savecalender(){ 
        this.setState({
            loading:true,
        })
        const obj = [];
        var start_time = "";
        var end_time   = "";

       
        this.state.tableData.forEach(day =>{ 
            if(day===1){
                start_time = this.state.monday_start_time;
                end_time   = this.state.monday_end_time;
            } if(day===2){ 
                start_time = this.state.tuesday_start_time;
                end_time   = this.state.tuesday_end_time;
            } if(day===3){
                start_time = this.state.wednesday_start_time;
                end_time   = this.state.wednesday_end_time;
            } if(day===4){
                start_time = this.state.thursday_start_time;
                end_time   = this.state.thursday_end_time;
            } if(day===5){
                start_time = this.state.friday_start_time;
                end_time   = this.state.friday_end_time;
            } if(day===6){
                start_time = this.state.saturday_start_time;
                end_time   = this.state.saturday_end_time;
            } if(day===0){
                start_time = this.state.sunday_start_time;
                end_time   = this.state.sunday_end_time;
                day = 0;
            }

             

            var user_id = localStorage.getItem("loggedInUserId"); 
            var store_id = localStorage.getItem("store_id"); 

            var objData = {
                "store_id" : store_id,
                "user_id" : user_id,
                "day" : parseInt(day),
                "from_time" : start_time,
                "to_time" : end_time,
                "utc_from_time":start_time,
                "utc_to_time": end_time,
                "time_zone": "Asia/Kolkata"
                 }  
            obj.push(objData);     
        });
 
        var session_token = localStorage.getItem("token");
        const finalObj = {
            "session_token":session_token,
            "calender_data":obj
        }
       // console.log(finalObj);
       this.saveCalender(finalObj);
    } 

    saveCalender = async (obj) => {  
        await setStoreCalender(obj).then(data => {   
            console.log(data);
            this.setState({
                loading:false,smsg:data.response_msg,
            })
           //window.location.reload();
          
        });
        await this.componentDidMount();
      }
  
	  reset(day){ 
        if(day===1){
            this.setState({
                monday_start_time:'',
                monday_end_time:'',
            })
        }

        if(day===2){
            this.setState({
                tuesday_start_time:'',
                tuesday_end_time:'',
            })
        }

        if(day===3){
            this.setState({
                wednesday_start_time:'',
                wednesday_end_time:'',
            })
        }

        if(day===4){
            this.setState({
                thursday_start_time:'',
                thursday_end_time:'',
            })
        }

        if(day===5){
            this.setState({
                friday_start_time:'',
                friday_end_time:'',
            })
        }

        if(day===6){
            this.setState({
                saturday_start_time:'',
                saturday_end_time:'',
            })
        }

        if(day===0){
            this.setState({
                sunday_start_time:'',
                sunday_end_time:'',
            })
        }
    }
	
	
	
	
	onChange(e){
        this.setState({[e.target.name] : e.target.value})
    }  
	
	
	
	
	render() {
		
		//console.log(this.state.storePhoneNo)

		return(
			<>
			<Metatitle title="Store calender | aBlueC" metaDescription="Store calender" ogTitle="Store calender" ogImage=""></Metatitle>
			{this.state.loading ? (<Loader/>) : (<></>)}
			<Header data="inner-head" ></Header>
				<section class="sidebar-layout bdr-top innerheight">
					
                <div className="container pt-4">                    
                <div className="header_catehory">
                <h2 className="jobs_heading mb-2 ">Store Calendar</h2> </div>
                <div className="card">
                <div className="card-header">  <Button></Button>  </div>
                <div className="card-body">
                  <div className="row">
                  <div className="col-lg-3 col-md-3 col-xs-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-column align-items-center text-center">
												<img src={this.state.imageURL} alt={this.state.imageURL}  />
												<div className="mt-3">
													<h4>{this.state.storeName}</h4>
												
													
													{/*<button className="btn btn-outline-primary">Message</button>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

                                <div className={this.state.openSl2 ? 'col-lg-9 col-md-9 col-xs-12 pShow' : 'col-lg-9 col-md-9 col-xs-12 pHide'}> 
									<div className="card mb-3">
										<div className="card-body">
												
                                        
											{(this.state.smsg != "") ?
											<>
												<div className="alert alert-success">Calendar updated successfully!</div>
											
											
											</>
											:
											<>
											</>
											}
											{(this.state.emsg != "") ?
											<>
											
												<div className="alert alert-danger">Calendar not updated successfully!</div>
											
											</>
											:
											<>
											</>
											}

								 <div className="store_calender">
            <h2>Weekdays</h2>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox"> 
                    <input type="checkbox" id="checkbox" name="monday" defaultValue={1} defaultChecked="checked"  onChange={this.onChange1} />
                    <label htmlFor="checkbox"><span>Monday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                

                <input type="time" placeholder="From" className="form-control" value={this.state.monday_start_time} name="monday_start_time" onChange={this.onChange}/>
              
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.monday_end_time} name="monday_end_time" onChange={this.onChange}/>
              
                <a href="javascript:void(0)" onClick={this.reset.bind(this,1)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-2" name="tuesday" defaultValue={2}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-2"><span>Tuesday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.tuesday_start_time} name="tuesday_start_time" onChange={this.onChange}/>
               
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.tuesday_end_time} name="tuesday_end_time" onChange={this.onChange} />
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,2)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-3" name="wednesday" defaultValue={3}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-3"><span>Wednesday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.wednesday_start_time} name="wednesday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.wednesday_end_time} name="wednesday_end_time" onChange={this.onChange}/>
                
                <a href="javascript:void(0)" onClick={this.reset.bind(this,3)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-4" name="thursday" defaultValue={4}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-4"><span>Thursday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.thursday_start_time} name="thursday_start_time" onChange={this.onChange}/>
              
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.thursday_end_time} name="thursday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,4)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-5" name="friday" defaultValue={5}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-5"><span>Friday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.friday_start_time} name="friday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.friday_end_time} name="friday_end_time" onChange={this.onChange}/>
              
                <a href="javascript:void(0)" onClick={this.reset.bind(this,5)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom last-days">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-6" name="saturday" defaultValue={6}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-6"><span>Saturday </span></label>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.saturday_start_time} name="saturday_start_time" onChange={this.onChange}/>
                
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.saturday_end_time} name="saturday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,6)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
            <div className="date-bottom last-days">
                <div className="checkbox_inner">
                <div className="checkbox">
                    <input type="checkbox" id="checkbox-7" name="sunday" defaultValue={0}  defaultChecked="checked" onChange={this.onChange1} />
                    <label htmlFor="checkbox-7"><span>Sunday </span></label>
                </div>
                </div> 
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="From" className="form-control" value={this.state.sunday_start_time} name="sunday_start_time" onChange={this.onChange}/>
               
            </div>
            </div>
            <div className="col-md-4">
            <div className="date-bottom">
                <input type="time" placeholder="To" className="form-control" value={this.state.sunday_end_time} name="sunday_end_time" onChange={this.onChange}/>
               
                <a href="javascript:void(0)" onClick={this.reset.bind(this,0)} className="timeslot">Reset Timeslot</a>
            </div> 
            </div>
            </div> 
            
           
			<div className="col-md-12 text-center">
										<button type="button" className="btn btn-primary" onClick={this.savecalender.bind(this)}>Update</button>
									</div>
               
							
										</div>
									</div>
								</div>

                                   
                  </div>  
                    
                </div>
                </div>
                </div>   
				</section>
			<Footer></Footer>
			</>
		);
    } 
}
export default StoreCalendar;