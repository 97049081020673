import Header from '../partials/Header'
import Footer from '../partials/Footer';
// import InnerPages from '../components/InnerPages';
import React, { Component } from 'react';
// import "../../customStyleProfile.css";
// import imageExists from 'image-exists';
import axios from 'axios';
import CONSTANT from '../../Constant';
// import $ from "jquery";
// window.jQuery = $;
// window.$ = $;
// global.jQuery = $;


async function getData(name) {	
	const config = {
		headers: {
		  'Content-Type': 'application/x-www-form-urlencoded'
		}
	}
	let response = await axios.post(CONSTANT.NSTABASE_URL+'web/getFilterData',{name: name});	
	return response.data.response_data
}

async function checkImg(img) {	
	await axios.get(img).then((response) => {
		if(response){
			return response
		}		
	})	
}

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

class Profile extends Component {
    constructor(props) {
        super(props);		
		this.state = {
			getFilterData : [],
			response_status: "",
			profileData: "",
			pageUrl: "",
			profile: {},
		};
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
    }
	
	async componentDidMount() {
		let {name} = this.props.match.params
		getData = await getData(name)
		this.setState({getFilterData:getData, loginData: "", pageUrl: name});
		const response_status = localStorage.getItem('token');
		const profileData = response_status ? localStorage.getItem('profileData') : '';
				
		
		// if(response_status != ""){
			
			// document.addEventListener('contextmenu', function(e) {
			  // e.preventDefault();
			// });
			
		// } else {
			
			let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:localStorage.getItem('token')});
			this.setState({profile:getProfile.data.response_data.profile, "response_status": response_status});
			
			var filterUserName = this.state.profile.email.replace(/.com/g,'');
			if(name != filterUserName && response_status == ""){
				document.location.href = `../profile/${filterUserName}`
			}
			
		// }
		
		const stripeConnect = localStorage.getItem('stripeConnect');
		
		if(stripeConnect != undefined || stripeConnect == '')
		{
			this.setState({stripeConnect: stripeConnect});
		} else {
			this.setState({stripeConnect: stripeConnect});
		}
		
		if(urlParam('clicked') === "store"){
			document.getElementById('pills-contact-tab').click();
		} else if(urlParam('stripe') === "connected"){
			
			const storeData = {
				session_token: this.state.profile.session_token,
				user_id: this.state.profile._id,
				status:1
			}
			const config = {
				headers: {
					'Content-Type': 'application/json','x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}
			
			let response = await axios.post(CONSTANT.STABASE_URL+'store/updateStore', JSON.stringify(storeData), config);
			
			if(response.data.response_status != 0){				
				let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:this.state.profile.session_token});
				var filterUserName = getProfile.data.response_data.profile.email.replace(/.com/g,'');
				document.location.href = `../${filterUserName}`+"?clicked=store&stripe=connected"			
			}			
		}
	}
	
	handleImageError = e => {
		// imageExists(e, function(exists) {
			// if (exists) {
				// return e;
			// }
		// });		
	}
	
	handleUsernameChange = e => {
		
		if (e.target.files && e.target.files[0]) {
			this.setState({
				imageURL: URL.createObjectURL(e.target.files[0]),
				images:e.target.files[0]
			});
		}
		
		this.setState({ [e.target.id]: e.target.value});
		
	}
	
	handleLoginPage = e => {		
		e || e.preventDefault();
		
		const response_status = localStorage.getItem('response_status') == 1;
		const profileData = response_status ? localStorage.getItem('profileData') : '';		
		
		if(response_status == true && e.target.id == 'postImg'){	
		
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "/feeds/comingSoon"
			
		} else if(response_status == true && e.target.id == 'addFriend'){
			
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "#"
			
		} else if(response_status == true && e.target.id == 'follow'){
			
			this.setState({profileData:profileData, response_status: response_status});
			document.location.href = "#"
			
		} else if((response_status == true && e.target.id == 'pills-profile-tab') || (response_status == true && e.target.id == 'pills-contact-tab')){
			
			this.setState({profileData:profileData, response_status: response_status});			
			document.location.href = "#"
			
		} else {
			
			this.setState({loginData: "click",profileData:"", response_status: 0})
			e.target.setAttribute("id", "login")
			e.target.setAttribute("data-toggle", "modal")
			e.target.setAttribute("data-target", "#Login")			
		}		
	}
	
	render() {
		
		const { getFilterData, response_status, profileData, pageUrl, profile, stripeConnect } = this.state;
		
		var getData = Object.assign({},...getFilterData)
		
		var friendData = getData.userFriends ? getData.userFriends.friends : 0
		var postsList = getData.postsList ? getData.postsList : 0
		var userPProfile = getData.userProfesionalProfile ? getData.userProfesionalProfile : 0
		var categoryList = userPProfile.categoryList ? userPProfile.categoryList : 0
		var getStoreInfo = getData.getStoreInfo ? getData.getStoreInfo : 0
		
		if(getData.thumb_profile_image == undefined && getData.profile_image != undefined){
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+getData.profile_image
		} else if(getData.thumb_profile_image != undefined && getData.profile_image == undefined){
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+getData.thumb_profile_image
		} else if(getData.thumb_profile_image != undefined && getData.profile_image != undefined){
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+getData.thumb_profile_image
		} else {
			var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
		}
		
		if(postsList.length > 0){
			
			postsList = postsList.length > 0 && postsList.filter((item) => {			
				if(item.images != undefined && item.images.length > 0){
					return item;
				}			
			});
			
		} else {
			postsList = []
		}
		
		
		var dateObj = new Date(getData._created_at);
		var month = dateObj.getUTCMonth('MMM') + 1; //months from 1-12
		var day = dateObj.getUTCDate();
		var year = dateObj.getUTCFullYear();
		var date = year + "-" + day + "-" + month;		
		var d = new Date(date.split("-")[0]+'-'+date.split("-")[2]+'-'+date.split("-")[1]).toLocaleString('en', { month: 'short', year: 'numeric' })
		
		var subItems = []
		if(categoryList.length > 0){
			
			categoryList.map((item) => {			
				item.subCat.map((subItem) => {			
					subItems.push(subItem.name)
				});
			});
			
		} else {
			subItems = []
		}
		
		// if(pageUrl == "about" || pageUrl == 'terms' || pageUrl == 'privacy' || pageUrl == 'user-agreement'){			
			// return <InnerPages data={pageUrl}  />
		// }
		
		console.log("MMMMMMMMM")
		console.log(getData)
		console.log("MMMMMMMMM")

        return (
        <div>
        <Header data="inner-head" loginData={this.state.loginData}></Header>
        <section>
	<div className="container">
		<div className="row">
			<div className="col-12">
				<div className="profile_details">
						<div className="profile_area">						  
						  <div className="mx-md-5 profile_content">
						    <div className="profile-pic">  <img src={profileImg} alt="img" width="134" height="134" /> <p>{getData.name}</p> <label>Joined on {d}</label></div>							
							{(getData.email == 'connect@abluec.com') ? 
								<>
								<p className="profile-btn" style={{"display": "block", "padding": 0, "clear": "both"}}>
									<a style={{"display": "none"}} className="btn" href="#" role="button" id="addFriend">&nbsp;</a>
									<a style={{"display": "none"}} className="btn" href="#" role="button" id="follow">&nbsp;</a>
								</p>
								</>
								: 
								<>
									<p className="profile_text">
										<label>{postsList.length}</label><span>Posts</span>
										<label>{getData.followUserListRowCount}</label><span>Followers</span>
										<label>{getData.followingUserListRowCount}</label><span>Following</span>
										<label>{friendData.length}</label><span>Friends</span>
									</p>
									<p className="profile-btn">
										<a className="btn btn-primary mr-1" href="#" role="button" id="addFriend" onClick={this.handleLoginPage}>Add Friend</a>
										<a className="btn btn-primary" href="#" role="button" id="follow" onClick={this.handleLoginPage}>Follow</a>
									</p>
								</>
							}					    
						   
						  </div>
						</div>

						<div className="tab-sec">
<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#posts" role="tab" aria-controls="pills-home" aria-selected="true">Posts</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pr" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={this.handleLoginPage}>PR</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#store" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={this.handleLoginPage}>STORE</a>
  </li>
</ul>
<div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="pills-home-tab">
  	<div className="row postImg">		
		{postsList.length > 0 && postsList.map((item) => 
			<div className="col-md-4"><img onError={this.handleImageError(CONSTANT.NSTABASE_URL+"images/post/"+item.images[0].thumbImage)} src={item.images[0] ? CONSTANT.NSTABASE_URL+"images/post/"+item.images[0].thumbImage : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} alt="" id="postImg" onClick={this.handleLoginPage} /></div> 
		)}
  	</div>

  </div>
  <div className="tab-pane fade" id="pr" role="tabpanel" aria-labelledby="pills-profile-tab">
	{/*<div className="row">
  		<div className="col-md-4"><img src="../assets/img/gall-5.png" alt="" /></div>
  		<div className="col-md-4"><img src="../assets/img/gall-3.png" alt="" /></div>
  		<div className="col-md-4"><img src="../assets/img/gall-2.png" alt="" /></div>
  		<div className="col-md-4"><img src="../assets/img/gall-1.png" alt="" /></div>
  		<div className="col-md-4"><img src="../assets/img/gall-4.png" alt="" /></div>
  		<div className="col-md-4"><img src="../assets/img/gall-6.png" alt="" /></div>
  	</div>*/}
	<div className="row justify-content-center">
			<div className="col-md-8">
				<h5>Academic</h5>
				<div className="list-sec">
					<ul>
						<li>  Graduate in {userPProfile.college_or_school_name ? userPProfile.college_or_school_name : ""}</li>
					</ul>
				</div>
				<h5>Skills</h5>
				<div className="list-sec">
					<ul>
						<li>Academic</li>
						{categoryList.length > 0 && categoryList.map((items) => 
							<li>{items.categoryName ? items.categoryName : ""}</li>
						)}
						{subItems.length > 0 && subItems.map((item) => 
							<li>{item ? item : ""}</li>
						)}
					</ul>
				</div>
				<h5>Experience</h5>
				<div className="list-sec">
					<ul>
						<li>  {parseFloat(userPProfile.end_year - userPProfile.start_year)} Years in Academic </li>
					</ul>
				</div>

				<div className="resume d-flex justify-content-left">
					<div className="mr-2">
						<h5>Resume</h5>
						<img src="../assets/img/resume.png" alt="resume" />
						</div>
						<div className="mr-3">
						<h5>Cover Letter</h5>
						<img src="../assets/img/cover-letter.png" alt="Cover Letter" />
					</div>
				</div>
			</div>
	 </div>
  </div>
  <div className="tab-pane fade" id="store" role="tabpanel" aria-labelledby="pills-contact-tab">				
				<div className="store-sec-main">
					{(getStoreInfo.length != 0) ? 
								<>
									{getStoreInfo.length > 0 && getStoreInfo.map((item) =>
										
										<div className="store-sec">
											<span className="store-img">
												<input type="radio" id="kwik" name="jo" value="kwik" />
												<label for="kwik"> </label>
												<img src={item.store_logo ? "https://abluec.com/images/store/"+item.store_logo : "https://abluec.com/images/profile/noimage.png"} style={{width:90, height:78}} alt="" />
											</span>
											<p>{item.store_name}</p>
										</div>
									)}																		
								</>
								: 
								<>
									<br/><hr/><br/>No Store Found<br/><br/>
								</> 
							}
														
							
				
							{/*<div className="store-sec">
								<span className="store-img">
									<input type="radio" id="kwik" name="jo" value="kwik" />
									<label for="kwik"> </label>
									<img src="../assets/img/kwik.png" alt="" /> </span>
								<p>Kwik Plumb Parts</p>
							</div>
							<div className="store-sec">
								<span className="store-img">
									<input type="radio" id="jo" name="jo" value="jo" />
									<label for="jo"> </label>
									<img src="../assets/img/jo.png" alt="" /> </span>
								<p> Jo Electrical </p>
							</div>
							*/}
				</div>
			  </div>
			</div>


						</div>
					
				</div>
			</div>
		</div>
	</div>
</section>
    <Footer></Footer>
    </div>  );
    }
}
 
export default Profile;