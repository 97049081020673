import React, { Component } from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import CONSTANT from '../../Constant';
import Button from "./Button";  
import Loader from '../../Loader';
import Metatitle from "../metaTitle";
import { getActivity } from '../Api';
import axios from 'axios';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;



class StoreActivity extends Component {
    constructor(props) {
        super(props);		
		this.state = {
            activities:'',
            per_page: '',
            current_page: 1,
            flage:true,
            getCheckSubscription:'',
            storeName:'',
            imageURL:''
			
		};
	
		this.onChange = this.onChange.bind(this);
		
		
		
    }
	
	async componentDidMount() {
		//this.setState({loading:true});
	    if(localStorage.getItem("token") == undefined){
			this.props.history.push('/login');
		}else if(localStorage.getItem("store_id") == undefined || localStorage.getItem("store_id") ==''){
            this.props.history.push('/404');
        }
	
        const user_id = localStorage.getItem("loggedInUserId");
		const session_token = localStorage.getItem("token"); 
		const store_id = localStorage.getItem("store_id");
	
    
    var formData = {"session_token":session_token,"user_id":user_id,"store_id":store_id}	
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
    }		
        let storeData = await axios.post(CONSTANT.STABASE_URL+'web/store/getStores',formData,config );
        if(storeData.data.response_status === 1){
            if(storeData.data.response_data.store_list.length > 0){
        this.setState({storeName:storeData.data.response_data.store_list[0].store_name,imageURL:(storeData.data.response_data.store_list[0].store_image) ? CONSTANT.NSTABASE_URL+ '../images/store/'+ storeData.data.response_data.store_list[0].store_image:CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
             
        });
            }else{
                this.setState({storeName:'',imageURL:''});
            }  
        }
        await this.getCheckSubscriptionExist();

		this.productList(user_id,session_token,store_id); 
			
	} 

  
    async getCheckSubscriptionExist(){
		
        if(localStorage.getItem("store_id") == undefined){
          this.props.history.push('/vendor/myStores')
          return false;
        }
        
        
        var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
          }
        }		
        let response = await axios.post(CONSTANT.STABASE_URL+'store/checkActivitySubscriptionExist', formData, config);
        console.log(response.data.response_data);
        if(response.data.response_data.status == false){
          this.setState({getCheckSubscription: 0,per_page:''});
        } else {
          this.setState({getCheckSubscription: 1,per_page:response.data.response_data.subs.quantity});
        }
      }
      
      truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
      }
	
	onChange(e){
        this.setState({[e.target.name] : e.target.value})
    }  
	
	
    productList = async (user_id,session_token,store_id) => { 
        this.setState({
          loading:true
        }) 
        let page = this.state.current_page;
        let per_page = this.state.per_page;
    if(this.state.getCheckSubscription){
        getActivity(page,per_page,user_id,session_token,store_id).then(data => {   
         this.setState({
            loading:false
          }) 
    
          if(data.response_data.activity){
          if(data.response_data.activity.length>0){
            this.setState({rawList:data.response_data.activity});
              let activityData = data.response_data.activity.map((activityS,index) => {   
    
                if(activityS.images[0]){
                  var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ activityS.images[0];
                } else if(activityS.product_image){
                    var imgs = activityS.product_image;
                } else {
                  var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
                }
                
                // console.log(activityS);
                return (                   
                    <> 
                    <tr> 

                     <td><span>{activityS.user_name}</span></td>
                     <td><span>{activityS.mobile}</span></td> 
                     <td><span>{activityS.store_name}</span></td>
                  <td>
                  <span> <img src={imgs} alt="" /></span>
                    </td> 
                <td><span> {this.truncate(activityS.product_title,25)}</span></td> 
                <td><span>{activityS.counter}</span></td> 
                <td><span> {new Date(activityS.add_on).toDateString()} </span></td> 
                </tr> 
                    </>
                  ); 
                })  
                this.setState({
                  activities:activityData, 
                  loading:false,
                 // total:data.response_data.total_count,
                })
              } else {
                this.setState({ 
                  activities:[], 
                  flage:false,
                  loading:false,
                })
              }
            }else{
                this.setState({ 
                    activities:[], 
                    flage:false,
                    loading:false,
                  })
            }  
        });
    }else{
        this.setState({ 
            activities:[], 
            flage:false,
            loading:false,
          })
    }
      }
	
	
	render() {
		
		//console.log(this.state.storePhoneNo)

		return(
			<>
			<Metatitle title="Store Activity | aBlueC" metaDescription="Store activity" ogTitle="Store activity" ogImage=""></Metatitle>
			{this.state.loading ? (<Loader/>) : (<></>)}
			<Header data="inner-head" ></Header>
				<section class="sidebar-layout bdr-top innerheight">
					<div className="container pt-4">
          <div class="header_catehory"> <h2 class="jobs_heading mb-2 ">Store Activity</h2> </div>
						<div className="card">
              
            <div className="card-header">  
            <Button/>
            </div>
            <div className="card-body"> 
             <div className="row">
							{/* <div className="col-lg-2 col-md-2 col-xs-12">
              <div className="card">
              <div className="card-body">
              <div className="d-flex flex-column align-items-center text-center">
              <span class="store-img">	<img src={this.state.imageURL} alt={this.state.imageURL}  /> </span>
												<div className="mt-3">
													<h4>{this.state.storeName}</h4>											
												</div>
											</div>
                      </div>
											</div>
                 </div> */}
              <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="categories-slide-sec main_store">  
              {/* <ul className="categories_view">{this.state.flage ? this.state.activities: 'Not found'} </ul> */}
              <div class="store-activity-sec table-responsive-md">

              <table className="table" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Store</th>
                                            <th>Product</th>
                                            <th>Product Name</th>
                                            <th>Visit</th>
                                            <th>Date</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.flage ? this.state.activities: 'Not found'} 
                                    </tbody>
                                    </table>
              </div>
              
               </div>
              </div>
             </div>
            </div>    
						</div>
					</div>
				</section>
			<Footer></Footer>
			</>
		);
    } 
}
export default StoreActivity;