import React, { Component } from 'react';
import { NavLink, } from "react-router-dom";
import { deleteStore,getCart,creditScore,checkSubscription } from '../Api';
import axios from "axios";
import CONSTANT from '../../Constant';
//import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import Modal from "react-bootstrap/Modal";
import {connect} from 'react-redux';
import _ from "lodash";


const initialState = {  
  cartcount:0, 
  cartItems:[],
  creditScoreVal:'',
  getCheckSubscription:'',
  isOpen:false,
} 

class Button extends Component {

  constructor(props) {
    super(props); 
    this.state = initialState; 
  } 

  

  
  getCart(){
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");  
    getCart(user_id,session_token).then(data => {  
      //console.log(data);
      if(data.response_invalid===1){

      } else {
      this.setState({
        cartItems:data.response_data.cart_products.items
      })
      this.props.changeCart(data.response_data.cart_products.items,'CART_UPDATE');
    }
    }); 
  }


    creditScore(){
    
      const store_id = localStorage.getItem("store_id");    
      if(store_id != null){
      
      const user_id = localStorage.getItem("loggedInUserId");
      const session_token = localStorage.getItem("token");      
      checkSubscription(session_token,store_id,user_id).then(data => {
        
        // console.log(data);
        if(data.response_invalid===1){
          
          this.setState({creditScoreVal:0})
          
        } else {
          
          if(data.response_data.status === false){
            
            this.setState({creditScoreVal:"No"})
            
          }else{
            
            this.setState({creditScoreVal: "Yes"})
            
          }
        }
      });     
      }
    }
  
    

  async componentDidMount() {
    
    //this.getCart(); 
    //this.creditScore();
    //await this.getCheckSubscriptionExist();
    if(localStorage.getItem("store_id") == undefined){
      this.props.history.push('/vendor/myStores')
      return false;
    }
    
    
    var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
    let response = await axios.post(CONSTANT.STABASE_URL+'store/checkActivitySubscriptionExist', formData, config);
    
    if(response.data.response_data.status == false){
      this.setState({getCheckSubscription: 0});
    } else {
      this.setState({getCheckSubscription: 1});
    }
  }

  async getCheckSubscriptionExist(){
    
    if(localStorage.getItem("store_id") == undefined){
      this.props.history.push('/vendor/myStores')
      return false;
    }
    
    
    var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id: localStorage.getItem("store_id")}
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
    let response = await axios.post(CONSTANT.STABASE_URL+'store/checkActivitySubscriptionExist', formData, config);
    
    if(response.data.response_data.status == false){
      this.setState({getCheckSubscription: 0});
    } else {
      this.setState({getCheckSubscription: 1});
    }
  }
  
  /*onClick(e){ 
    e || e.preventDefault();
    if(e == 'StoreList')
    document.location.href = '/vendor/myStores'
    else if(e == 'Orders')
    document.location.href = '/vendor/store/store-order'    
    else if(e == 'StoreName')
    document.location.href = '/vendor/store/'+localStorage.getItem('store_name')  
    else if(e == 'ManageStorefront')
    document.location.href = '/vendor/store/store-front'
    else if(e == 'ManageStorehour')
    document.location.href = '/vendor/store/store-calendar' 
    else if(e == 'ManageStoreinvent')
    document.location.href = '/vendor/store/store-inventory'
    else if(e == 'StoreActivity'){
      if(this.state.getCheckSubscription){
        document.location.href = '/vendor/store/store-activity'
      }else{
        document.location.href = '/activity-subscription'
      }
    }
  }*/


  showWarningModal() {
    this.setState({
      isOpen:true,    
    })
   };
 
   hideWarningModal() {
     this.setState({
      isOpen:false,
     })
   };
   deleteStore(){
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token"); 
    const store_id = localStorage.getItem("store_id");  
    deleteStore(user_id,session_token,store_id).then(data => {   
    //this.mystore(user_id,session_token,store_id,1);
    //window.location.reload();    
    //this.props.history.push('/vendor/store')
    document.location.href = '/vendor/myStores'
   })
  }
    render() {  
    
    
      var url = window.location.pathname;
      var res = url.split("/");
      var classVendor = "";
      var classCustomer = "";
      var storeURLVendor = "";
      var vendorURL= false;
      if(res[1]==="vendor"){
        classVendor = "buy_bt mange_store active";
        vendorURL=true;
      } else {
        classVendor = "buy_bt mange_store";
      }

      if(res[1]==="customer" || res[1]==="products" || res[1]==="product-details" || res[1]==="checkout" || res[1]==="manage-order"){
        classCustomer = "buy_bt active";
      } else {
        classCustomer = "buy_bt";
      }

      var cartcount = 0;

      if(this.props.cart){
        var productData = this.props.cart.map((cart,index) => {    
              cartcount = parseInt(cartcount) + parseInt(cart.qty); 
        }) 
      } else {
        var productData = this.state.cartItems.map((cart,index) => {    
          cartcount = parseInt(cartcount) + parseInt(cart.qty); 
        }) 
      }
      
     

        return (
         <div className="store-list-sec">
         <div className="row">
         <div className="col-md-10">

       
          
      
      <ul className="store-list">
           {/* <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('StoreName')}>{localStorage.getItem('store_name')} </a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('ManageStoreinvent')}>Inventory </a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('Orders')}>Orders </a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('ManageStorefront')}>Manage Store front </a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('ManageStorehour')}>Manage Store hour </a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('StoreList')}>Store List</a></li>
           <li><a href="#" className="btn btn-primary" onClick={()=> this.onClick('StoreActivity')}>Store Activity</a></li> */}

           <li><NavLink to={`/vendor/store/${localStorage.getItem('store_name')}`} className="btn btn-primary" activeClassName="active" exact={true}> Products </NavLink> </li>
            <li><NavLink to='/vendor/store/store-inventory' className="btn btn-primary" activeClassName="active"> Inventory </NavLink> </li>
            {/* <li><NavLink to='/vendor/store/store-order' className="btn btn-primary" activeClassName="active"> Orders </NavLink> </li> */}
            <li><NavLink to='/vendor/store/store-front' className="btn btn-primary" activeClassName="active"> Manage Store front </NavLink> </li>
            <li><NavLink to='/vendor/store/store-calendar' className="btn btn-primary" activeClassName="active"> Manage Store hours </NavLink> </li>
            <li><NavLink to='/vendor/myStores' className="btn btn-primary" activeClassName="active"> Store List </NavLink> </li>
            <li><NavLink to='/vendor/package-transaction' className="btn btn-primary" activeClassName="active"> Ecommerce Package </NavLink> </li>
            {/* <li><NavLink to='/vendor/store/product-bulkupload' className="btn btn-primary" activeClassName="active"> Upload Bulk Product</NavLink> </li> */}
            {/* <li><NavLink to={this.state.getCheckSubscription ? '/vendor/store/store-activity':'/activity-subscription'} className="btn btn-primary" activeClassName="active"> Store Activity </NavLink> </li> */}
      </ul>
         
        
        </div>
        
        
              <div className="col-md-2">
               
              <span className="float-right">
                  {/* <a href="/vendor/myStores" className="jobs_heading btn btn-primary">Manage Store</a> */}
        {(vendorURL) ? <>  <button className="jobs_heading btn btn-secondary" onClick={this.showWarningModal.bind(this)}>Delete Store</button></>:''}
        </span>
                </div>
              </div>



              <Modal id="modal_body" show={this.state.isOpen} onHide={this.hideWarningModal.bind(this)}> 
    <Modal.Body> 
        <div className="modal-dialog" role="document">
          <div className="modal-content confirmpopup">
           
            
            <div class="card-header text-center">    Are you sure you want to delete it?  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideWarningModal.bind(this)}>
            <span aria-hidden="true">×</span>
            </button> </div>
            <div className="modal-body sure_delete_wrp">
             
              <div className="post_bt_public cancel_order">
                <ul>
                  <li>
                    <button type="button" className="save_bt" onClick={this.deleteStore.bind(this)}>YES</button>
                  </li>
                  <li>
                    <button type="button" className="back_bt reject_bt" onClick={this.hideWarningModal.bind(this)}>NO</button>
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div> 
    </Modal.Body>
    </Modal>


            </div>
        
        );
  
    }
}

 


const mapStateToProps = (state) => {
  return { 
    cart:state.cart_data
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    changeCart: (name,action) => { dispatch({ type: action, payload: name }) }, 
  }
}

 
export default connect(mapStateToProps,mapDispatchToProps)(Button);

