import React, { Component } from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import CONSTANT from '../../Constant';
import Button from "./Button";  
import Loader from '../../Loader';
import Metatitle from "../metaTitle";
import { getProductList } from '../Api';
import axios from 'axios';
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;



class StoreInventory extends Component {
    constructor(props) {
        super(props);		
		this.state = {
            products:'',
            per_page: 12,
            current_page: 1,
            flage:true,
            storeName:'',
            imageURL:'',
            isChecked:'',
            feature:false,
            abc:true,
            prod_id:''
			
		};
	
		this.onChange = this.onChange.bind(this);
		
		
		
    }
	
	async componentDidMount() {
		//this.setState({loading:true});
	    if(localStorage.getItem("token") == undefined){
			this.props.history.push('/login');
		}else if(localStorage.getItem("store_id") == undefined || localStorage.getItem("store_id") ==''){
            this.props.history.push('/404');
        }
	
        const user_id = localStorage.getItem("loggedInUserId");
		const session_token = localStorage.getItem("token"); 
		const store_id = localStorage.getItem("store_id");
	
    
    var formData = {"session_token":session_token,"user_id":user_id,"store_id":store_id}	
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
    }		
        let storeData = await axios.post(CONSTANT.STABASE_URL+'web/store/getStores',formData,config );
        if(storeData.data.response_status === 1){
            if(storeData.data.response_data.store_list.length > 0){
        this.setState({storeName:storeData.data.response_data.store_list[0].store_name,imageURL:(storeData.data.response_data.store_list[0].store_image) ? CONSTANT.NSTABASE_URL+ '../images/store/'+ storeData.data.response_data.store_list[0].store_image:CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png",
             
        });
            }else{
                this.setState({storeName:'',imageURL:''});
            }  
        }

		this.productList(user_id,session_token,store_id); 
			
	}



handleCheckboxChange = async (id,e) => {

  var X_ACCESS_TOKEN = CONSTANT.HEADER;
  var headers = {'x-access-token': X_ACCESS_TOKEN}
  this.setState({
    loading:true
  }) 
  // this.setState({
  //   isChecked: e.target.checked,prod_id:id
  // })
  //console.log(this.state.prod_id);

  var checkData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),prod_id:id,checkFeature:e.target.checked}
  let featureData = await axios.post(CONSTANT.STABASE_URL+'web/store/makeFeatured',checkData,{headers:headers} );
    if(featureData.data.response_status === 1){
      this.setState({
        loading:false
      }) 
      //this.componentDidMount();
    }
    
}

	onChange(e){
        this.setState({[e.target.name] : e.target.value})
    }  
	
	
    productList = async (user_id,session_token,store_id) => { 
        this.setState({
          loading:true
        }) 
        let page = this.state.current_page;
        let per_page = this.state.per_page;
    
        getProductList(page,per_page,user_id,session_token,store_id).then(data => {   
          //console.log("===========");
          //console.log(data.response_data.total_count);
          //console.log("===========");
    
          this.setState({
            loading:false
          }) 
    
          if(data.response_data.product_listing){
          if(data.response_data.product_listing.length>0){
            this.setState({rawList:data.response_data.product_listing});
              let productData = data.response_data.product_listing.map((product,index) => {   
    
                if(product.images[0]){
                  var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ product.images[0];
                } else if(product.product_image){
                    var imgs = product.product_image;
                } else {
                  var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
                }
                
                if(product.is_featured == 1){
                  this.setState({isChecked:true});
                }else{
                  this.setState({isChecked:false});
                }
                // console.log(product);
                return (   
                  <li key={Math.random()}>
                  <div className="product-sec">  
                    <div className="prd-img-sec"><span className="img_wrp"><img src={imgs} alt="" /></span>

                {/* { (product.is_featured == 1) ? ( <input type="checkbox" name='isChecked' checked={true} onChange={this.handleCheckboxChange} />):( ( <input type="checkbox" name='isChecked' checked={false} onChange={this.handleChange} />))
                
                
                }  */}
                 <input type="checkbox"  defaultChecked={this.state.isChecked} onChange={(e) => this.handleCheckboxChange(product._id, e)}/><span style={{'paddingLeft':'3px'}}>Featured</span>

                    </div>
                    <div className="title-sec clearfix"> 
                      <div className="left_section">
                        
                        <span className="product_name">{product.product_title}</span>
                        {/* <span className="prodect_prcie">{CONSTANT.CURRENCY}{product.product_price}</span>  */}
                        {/* <span className="prodect_sale_prcie">{CONSTANT.CURRENCY}{product.product_sale_price}</span> */}
                        {/* <div className="price-sec"><span className="regular_price"><strike>{CONSTANT.CURRENCY}{product.product_price}</strike></span> <span className="sale_price">{CONSTANT.CURRENCY}{product.product_sale_price}</span></div> */}
                        <span className="inventory-price">Available Items: <label> {product.stock} </label> </span>
                        <span className="prodect_prcie">
                        
                        {
                        product.product_sale_price ? (
                          <span className="regular_price strike"><strike>{CONSTANT.CURRENCY}{product.product_price}</strike></span>
                        ) : ( <span className="sale_price">{CONSTANT.CURRENCY}{product.product_price}</span>)
                        }
                        
                        {
                        product.product_sale_price ? (
                        <span className="sale_price">{CONSTANT.CURRENCY}{product.product_sale_price}</span>
                        ) : (<></>)
                        }
                                            
                        </span>
                       
                      </div> 
                    </div>
                  </div>
                  </li>  
                  ); 
                })  
                this.setState({
                  products:productData, 
                  loading:false,
                  total:data.response_data.total_count,
                })
              } else {
                this.setState({ 
                  products:[], 
                  flage:false,
                  loading:false,
                })
              }
            }else{
                this.setState({ 
                    products:[], 
                    flage:false,
                    loading:false,
                  })
            }  
        });
      }
	
	
	render() {
		
		//console.log(this.state.storePhoneNo)

		return(
			<>
			<Metatitle title="Store Inventory | aBlueC" metaDescription="Store inventory" ogTitle="Store inventory" ogImage=""></Metatitle>
			{this.state.loading ? (<Loader/>) : (<></>)}
			<Header data="inner-head" ></Header>
				<section className="sidebar-layout bdr-top innerheight">
					<div className="container pt-4">
          <div className="header_catehory"> <h2 className="jobs_heading mb-2 ">Store Inventory</h2> </div>
						<div className="card">
              
            <div className="card-header">  
            <Button/>
            </div>
            <div className="card-body"> 
             <div className="row">
							<div className="col-lg-3 col-md-3 col-xs-12">
              <div className="card">
              <div className="card-body">
              <div className="d-flex flex-column align-items-center text-center">
              <span className="store-img">	<img src={this.state.imageURL} alt={this.state.imageURL}  /> </span>
												<div className="mt-3">
													<h4>{this.state.storeName}</h4>	
                         						
												</div>
                        <div className="mt-3">
                        <a href={`/vendor/store/${this.state.storeName}`} className="btn btn-primary">Add product</a>
                         						
												</div>
                       		
											</div>
                      </div>
											</div>
                 </div>
              <div className="col-lg-9 col-md-9 col-xs-12">
              <div className="categories-slide-sec main_store">  <ul className="categories_view">{this.state.flage ? this.state.products: 'Not found'} </ul> </div>
              </div>
             </div>
            </div>    
						</div>
					</div>
				</section>
			<Footer></Footer>
			</>
		);
    } 
}
export default StoreInventory;