import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import React, { Component } from 'react'; 
import{ Redirect } from 'react-router-dom';
import CONSTANT from '../../Constant';
import axios from 'axios';
import qs from 'qs'; 
import Loader from '../../Loader';


class ForgotPassword extends Component {
       
    constructor(props){
        super(props);
        const token = localStorage.getItem("token");        
        let loggedIn = true;
        if(token==null){
            loggedIn = false;
        }
        this.state={
            email:'',
			otp: '',
        }
		this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
		this.handleUsernameChange = this.handleUsernameChange.bind(this);
       }

	handleUsernameChange = e => {
		this.setState({[e.target.id]: e.target.value});
	}
	
    onChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    
    componentDidMount(){ 
    }
	
	
	resetPassword = async e => {		
		e.preventDefault();	
		const isValid1 = this.validate1();
        if(isValid1){			
			var validOTP = localStorage.getItem('otp');
            var email = localStorage.getItem('email');
			var enterOTP = this.state.otp
			if(validOTP != enterOTP){
				var otpMsg = 'Please Enter Valid OTP'
				this.setState({otpMsg:otpMsg})
				return false
			} else if(validOTP == enterOTP){
				this.setState({otpMsg:otpMsg})
                localStorage.setItem('emailnew', email);
               // this.setState({email:email})



             //  return false;
				///document.location.href = "/changePassword";	
                this.props.history.push('/changePassword')   
			}
		}
	}
	validate1 = (e) =>{
		if(this.state.otp == ''){
			var otpMsg = 'Please Enter Valid OTP'
			this.setState({otpMsg:otpMsg, otp: ''})
			return false;
        } else {
            return true;
        }
    }
	
	
    submitForm(e){
        e.preventDefault();
        const isValid = this.validate();
        if(isValid){
			
			this.setState({ 
                successmessage:"Please wait..."
            }) 
            
            const {email} = this.state; 
            axios.post(CONSTANT.STABASE_URL +'users/forgetpassword',qs.stringify({
                email: email,
            }),{
                headers: { "x-access-token": CONSTANT.HEADER,'Content-Type': 'application/x-www-form-urlencoded' }
              }).then(res => { 

                if (res.data.response_status === 0) {
					var otpMsg = 'Please Enter Valid Email'
					this.setState({emailError:otpMsg,successmessage:''})
                }else{
					//document.getElementById('Otpb').click();
                    document.getElementById('OtpForm').style.display = "block";
					localStorage.setItem('otp', res.data.response_data.OTP);					
					localStorage.setItem('email', email);
                }
            },
            (error) => { 
                this.setState({ 
                    successmessage:"Please provide valid login credential."
                })
            });
        }
    }
 

    // validate = () =>{
    //     let emailError = '';
        
    //     if(!this.state.email){
    //         emailError = "Email field can not be blank.";
    //     }
    //     if(emailError){
    //         this.setState({
    //             emailError,
    //             cls:"alert alert-danger", 
    //         });
    //         return false;
    //     } else {
    //         this.setState({
    //             emailError,
    //             cls:"alert alert-success", 
    //         });
    //         return true;
    //     }
    // }
    
    validate = () =>{
        let emailError = '';
        let emailInvError = '';
        
        if(!this.state.email){
            emailError = "Email field can not be blank.";
        }
         if (!/\S+@\S+\.\S+/.test(this.state.email)) {
            emailInvError = 'Email is invalid format';
        }
        if(emailError){
            this.setState({emailError:emailError,cls: "alert alert-danger"}); 
            return false;
        }
        if(emailInvError){
            this.setState({emailError:emailInvError,cls: "alert alert-danger"}); 
            return false;
        }
        this.setState({emailError:'',cls: "alert alert-success"});
        return true;
    }

    render() { 
  
        // if(this.state.loggedIn){ 
             // return <Redirect to="/search"/>   
        // }

        return ( 
			<>
            <Header />
            <section id="login"> 
            <div id="feed_wrapper_section" className="register_wrapper">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <div className="login-user-wrapper">
                        <div className="login-box">
                        <h5>Forgot password?</h5>
                        <form className="flp" onSubmit={this.submitForm} noValidate>
                            {this.state.successmessage?<p className={this.state.cls}>{this.state.successmessage}</p>:''}
                            <div className="form-group">
                            <input type="email" placeholder="Email" className="form-control" name="email" id="email" placeholder="Email" onChange={this.onChange}/>
                            <small id="emailHelp" className="form-text text-muted_error_message">{this.state.emailError}</small>  
                            </div>
                            <div className="post_bt_public register_login"> 
                            <ul>
                                <li>
                                <button type="submit" className="save_bt">Reset</button></li>
                            </ul>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
			<div className="shape-bottom"><svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg"
			 className="svg replaced-svg">
					<title>sApp Shape</title>
					<desc>Created with Sketch</desc>
					<defs></defs>
					<g id="sApp-Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
							<path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
								id="sApp-v1.0">
								</path>
						</g>
					</g>
				</svg>
			</div>
			{/* <button type="hidden" class="btn btn-primary mr-1" id="Otpb" data-toggle="modal" data-target="#OtpForm" style={{"display":"none"}}>OTP</button> */}
			{/* <div className="modal fade" id="OtpForm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  onClick={this.handleClose}>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Please enter the otp</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{"color":"white"}}>
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form noValidate onSubmit={this.resetPassword}>					
								<div className="form-row">
									<div className="form-group col-md-12">	
										<p style={{"color":"blue"}}>{(this.state.doneMsg != "") ? this.state.doneMsg : ""}</p>
										<input type="text" className="form-control" id="otp" aria-describedby="emailHelp" placeholder="Ex: 1234" name="otp" key="otp"
										onChange={this.handleUsernameChange} required />
										<p style={{"color":"red"}}>{(this.state.otpMsg != "") ? this.state.otpMsg : ""}</p>
									</div>
								</div>
								<div className="col-md-12 text-center">
									<button type="submit" className="btn btn-primary my-2 px-5 py-3">Save and Continue</button>
								</div>	
							</form>	
						</div>
					</div>
				</div>
			</div> */}

<div className="overlay-addstore" id="OtpForm" style={{"display":"none"}}>
					<div className="addstore-main">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Please enter verification code</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{"color":"white"}}>
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form noValidate onSubmit={this.resetPassword}>					
									<div className="form-row">
										<div className="form-group col-md-12">	
											<p style={{"color":"blue"}}>{(this.state.doneMsg != "") ? this.state.doneMsg : ""}</p>
											<input type="text" className="form-control" id="otp" aria-describedby="emailHelp" placeholder="Ex: 1234" name="otp" key="otp" onChange={this.handleUsernameChange} required />
											<p style={{"color":"red"}}>{(this.state.otpMsg != "") ? this.state.otpMsg : ""}</p>
										</div>
									</div>
									<div className="col-md-12 text-center">
										<button type="submit" className="btn btn-primary my-2 px-5 py-3">Verify</button>
									</div>	
								</form>	
							</div>
						</div>
					</div>
				</div>
            </section>
			<Footer />
			</>
        );
    }

}

  export default ForgotPassword;