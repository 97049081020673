import React, { Component } from 'react';
import Header from '../partials/Header'
import Footer from '../partials/Footer';
import Button from "./Button";  
import { deleteProduct,addZeroes,getCategories,getProductList,getBarCodeProductInfo,otherCategory,getStoreParentCategories,otherCategories,getProductDetails } from '../Api'; 
import Sidebar from './Sidebar'; 
import CONSTANT from '../../Constant';
import { NavLink, } from "react-router-dom"; 
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Loader from '../../Loader';
import Metatitle from "../../components/metaTitle";
import _ from "lodash"
import $ from 'jquery';  

 
const initialState = {  
  status:0,
  categories:'',
  is_selected_image:true, 
  is_selected:false,
  selected_img:'',
  is_featured:0,
  is_checked:false,
  taxable:1,
  shipping:1,
  products:'',
  catButtonData:'',
  isOpen1:false,
  isOpen3:false,
  isOpen6:false,
  sku1:'',
  product_name_disabled: false,
  sku_disabled:false,
  description_disabled:false,
  category_disabled:false,
  total: 0,
  per_page: 12,
  rawList:[],
  current_page: 1,
  prod_id:'',
  select_img:''
}

class Product extends Component {

  constructor(props) {
    super(props); 
    this.state = initialState; 
    this.onChangeform = this.onChangeform.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitForm1 = this.submitForm1.bind(this);
    this.editsubmitForm=this.editsubmitForm.bind(this);
    this.storeNameUrl1=localStorage.getItem("store_name");
    this.storeNameUrl=this.props.match.params.name;

    
  } 





  showModal() {
    this.setState({
      isOpen:true,
    })
   };
 

   hideModal() {
     this.setState({
       isOpen:false,
        catError : '',
        imgError : '', 
        skuError : '',
        productError : '',
        salepriceError:'',
        descriptionError : '',
        stockError : '',
        unitError : '',
        priceError : '',
        sku:'',
        product_name:'',
        price:'',
        sale_price:'',
        description:'',
        startDate:'',
        startDate1:'',
        taxable:'',
        shipping:'',
        is_featured:'',
        category:'',
        stock:'',
        unit:'',
        is_selected_image:true, 
        is_selected:false,
        selected_img:'', 
		showError: false,
    isOpen2:false,
    
     })
   };
   
   showPopup(){
    this.setState({
      isOpen2:true,
    })
  }

  hidePopup(){
    this.setState({
      isOpen2:false,
    })
  }

  onChangeform(e){
    if(e.target.name==="sku"){  
      const re = /^\S*$/; 
      if (re.test(e.target.value)) {
        this.setState({[e.target.name] : e.target.value})
      }

    } else {
    this.setState({[e.target.name] : e.target.value})
    }
  } 

  state = {
    startDate  : new Date(),
    startDate1 : new Date()
  };

  confirmDelete(product_id){
    //console.log(product_id);
    this.setState({
      deletedProductId:product_id
    })
    this.showWarning();
  } 


  showWarning(){
    this.setState({
      isOpen3:true,
    })
  }

  hideWarning(){
    this.setState({
      isOpen3:false,
    })
  }

  deleteProduct(){
    let session_token = localStorage.getItem("token"); 
    let store_id = localStorage.getItem("store_id"); 
    let product_id = this.state.deletedProductId;
    this.setState({
      loading:true
    })
    deleteProduct(store_id,product_id,session_token).then(data => {  
    //  this.storeParentCategory();   
    this.setState({
      loading:false
    })  
    let cat_id='';
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    let store_id = localStorage.getItem("store_id");  
    this.productList(user_id,session_token,store_id,cat_id);  
      this.hideWarning();
    });
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleChange1 = date => {
    this.setState({
      startDate1: date
    });
  };
  
   handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  
  // addProductStep1() {
    // this.setState({
      // isOpen1:true,
    // })
   // };

   
  showEditProdModal =(id) => {
    this.setState({
      isOpen6:true,
    })

if(this.state.rawList.length > 0){
   let req = _.filter(this.state.rawList, { _id: id});
let product = Object.assign({},...req);

//console.log(product);
   this.setState({
     prod_id:product._id,
     product_name:product.product_title,
     price:addZeroes(product.product_price),
     description:product.product_description,
     sale_price:addZeroes(product.product_sale_price),
     stock:product.stock,
     sku:product.sku,
     is_featured:product.is_featured,
     is_checked:(product.is_featured == 1) ? true:false,
     product_image:product.images[0],
     taxable:product.is_taxable,
     shipping:product.is_shipping_available,
     selected_img:product.product_image,
     select_img:product.product_image,
     is_selected:true,
     product_name_disabled: true,
     sku_disabled:true,
     description_disabled:true,
     category_disabled:true,
     category:product.product_category
   })

}
  }

  
  hideEditProd = (e) => { 
    this.setState({
      isOpen6:false,
    })
  }
   
	addProductStep1() {

		var X_ACCESS_TOKEN = CONSTANT.HEADER;
		var headers = {'x-access-token': X_ACCESS_TOKEN}
		
		 var checkDatas = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id:localStorage.getItem("store_id")}
		 axios.post(CONSTANT.STABASE_URL+'store/checkCreditScore',checkDatas,{headers: headers}).then( async rest => {
      if(rest.data.response_msg != 'No credit score available.')
      {
        this.setState({
          isOpen1:true,isOpen2:false
        })
    
    
      }else{
			
		/*var checkData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),store_id:localStorage.getItem("store_id")}
		axios.post(CONSTANT.STABASE_URL+'store/checkSubscriptionExist',checkData,{headers: headers}).then( async res => {
			if(res.data.response_data.status != false){
				this.setState({isOpen1:true,isOpen2:false})
			} else {
				this.setState({isOpen1:false,isOpen2:true })
				setTimeout(()=> {			
					this.setState({loading:true})
					setTimeout(()=> {
						this.setState({loading:false})					
						document.location.href = "/subscription";
					}, 4000);
				}, 2000);
				return false;
			}
    });*/
    this.setState({isOpen1:false,isOpen2:true })
    setTimeout(()=> {			
      this.setState({loading:true})
      setTimeout(()=> {
        this.setState({loading:false})					
        document.location.href = "/subscription";
      }, 4000);
    }, 2000);
    return false;
  }
  });

	};

	closeProductStep1() {
		this.setState({isOpen1:false, sku1:''})
	};


   skip(){
    this.closeProductStep1();
    this.showModal();
    this.otherCategoryList();
    this.setState({
      product_name_disabled: false,
      sku_disabled:false,
      description_disabled:false,
      category_disabled:false,
    })
  }

  
  skipWithoutDisabled(){
    this.closeProductStep1();
    this.showModal();
    this.otherCategoryList(); 
  }
  
  storeParentCategory(){ 
    let session_token = localStorage.getItem("token"); 
    let store_id = localStorage.getItem("store_id"); 
    getStoreParentCategories(session_token,store_id).then(data => {   

		// console.log("MMMMMMMMMM")
		// console.log(data.response_data)
		// console.log("MMMMMMMMMM")
      
      if(data.response_data){
      if(data.response_data.allCategory){
      var i = 0;  
      var istCat = '';
      let catButtonData = data.response_data.allCategory.map((store,index) => {   
        i++;
        var clName = "";
        if(i===1){
          let user_id = localStorage.getItem("loggedInUserId");
          let session_token = localStorage.getItem("token"); 
          let store_id = localStorage.getItem("store_id"); 
          let cat_id =  store._id;
          this.productList(user_id,session_token,store_id,cat_id);
          istCat = cat_id;
          clName = "active";
        }    
        if(store.name !=='undefined'){ 
        return (    
           <li key={Math.random()} className={clName}><a href="#/" onClick={this.categorywise.bind(this,store._id)}>{store.name}</a></li>
        );  
        } 
        }) 
        this.setState({ 
          catButtonData:catButtonData,
          current_cat:istCat
        })
      }
      }
    });
  }

  

 pagination(page){
  // this.setState({
  //   current_page:page
  // })

  this.setState({
    current_page:page
  }, () => {
    let cat_id = this.state.current_cat;
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token"); 
    let store_id = localStorage.getItem("store_id");  
    this.productList(user_id,session_token,store_id,cat_id); 

    //this.productList();
  }) 
 }

  productList = async (user_id,session_token,store_id,cat_id) => { 
    this.setState({
      loading:true
    }) 
    let page = this.state.current_page;
    let per_page = this.state.per_page;

    getProductList(page,per_page,user_id,session_token,store_id,cat_id).then(data => {   
      //console.log("===========");
      //console.log(data.response_data.total_count);
      //console.log("===========");

      this.setState({
        loading:false
      }) 

      if(data.response_data.product_listing){
      if(data.response_data.product_listing.length>0){
        this.setState({rawList:data.response_data.product_listing});
          let productData = data.response_data.product_listing.map((product,index) => {   

			if(product.images[0]){
              var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ product.images[0];
			} else if(product.product_image){
				var imgs = product.product_image;
            } else {
              var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
            }
            
            // console.log(product);
            return (   
              <li key={Math.random()}>
              <div className="product-sec">  
                <div className="prd-img-sec"><span className="img_wrp"><img src={imgs} alt="" /><div onClick={this.confirmDelete.bind(this,product._id)} className="cross_bt_red" /></span>
                </div>
                <div className="title-sec clearfix"> 
                  <div className="left_section">
                    <span className="product_name">{product.product_title}</span>
                    {/* <span className="prodect_prcie">{CONSTANT.CURRENCY}{product.product_price}</span>  */}
                    {/* <span className="prodect_sale_prcie">{CONSTANT.CURRENCY}{product.product_sale_price}</span> */}
                    {/* <div class="price-sec"><span class="regular_price"><strike>{CONSTANT.CURRENCY}{product.product_price}</strike></span> <span class="sale_price">{CONSTANT.CURRENCY}{product.product_sale_price}</span></div> */}
                    <span className="prodect_prcie lft-sec-edit">
                    
                    {
                    product.product_sale_price ? (
                      <span className="regular_price strike"><strike>{CONSTANT.CURRENCY}{addZeroes(product.product_price)}</strike></span>
                    ) : ( <span className="sale_price">{CONSTANT.CURRENCY}{addZeroes(product.product_price)}</span>)
                    }
                    
                    {
                    product.product_sale_price ? (
                    <span className="sale_price">{CONSTANT.CURRENCY}{addZeroes(product.product_sale_price)}</span>
                    ) : (<></>)
                    }
                                        
                    </span>
                    <span className="edit-icon" onClick={() => this.showEditProdModal(product._id)}> <img src="/assets/images/edit.png" /></span>
                
                  </div> 
                  {/* <button onClick={() => this.showEditProdModal(product._id)}>Edit</button>  */}
                </div>
              </div>
              </li>  
              ); 
            })  
            this.setState({
              products:productData, 
              loading:false,
              total:data.response_data.total_count,
            })
          } else {
            this.setState({ 
              products:[], 
              loading:false,
            })
          }
        }  
    });
  }


  categorywise(cat_id){
    this.setState({
      current_cat:cat_id
    })
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token"); 
    const store_id = localStorage.getItem("store_id");  
    this.productList(user_id,session_token,store_id,cat_id); 
  }

  handleImageChange = (e) => { 
    this.setState({
      image: e.target.files[0],
      is_selected_image:false, 
      is_selected:true,
      selected_img:URL.createObjectURL(e.target.files[0]),
    })
  };


  isNumber(evt) {    
    var iKeyCode = (evt.which) ? evt.which : evt.keyCode
    if (iKeyCode != 46 && iKeyCode > 31 && (iKeyCode < 48 || iKeyCode > 57))
        return false;
    return true;
  }  
  
  validate = () =>{
	  
	// console.log("MMMMMMMMMMMM")
	// console.log(this.state.is_selected_image)
	// console.log(this.state.image)
	// console.log(this.state.image.name)

    let catError = '';
    let imgError = ''; 
    let skuError = '';
    let productError = '';
    let salepriceError='';
    let descriptionError = '';
    let stockError = '';
    let unitError = '';
    let priceError = '';
    let priceError1 = '';
    let schedultDateError='';

    if(!this.state.selected_img){
      imgError = "Please select product image.";
    }

    if(!this.state.sku){
      skuError = "Please enter sku number.";
    }

    if(!this.state.product_name){
      productError = "Please enter product name.";
    }

    if(!this.state.category){
      catError = "Category field can not be blank.";
    }
    
    // if(!this.state.sale_price){
    //   salepriceError = "Sale price field can not be blank.";
    // }

    if(!this.state.description){
      descriptionError = "Sale price field can not be blank.";
    }

    if(!this.state.stock){
      stockError = "Stock field can not be blank.";
    }

    // if(!this.state.unit){
      // unitError = "Unit field can not be blank.";
    // }

    if(!this.state.price){
      priceError = "Price field can not be blank.";
    }

    
    if(parseInt(this.state.price) < parseInt(this.state.sale_price)){
      priceError1 = "Sale price should be smaller than price.";
    }

    // if(this.state.startDate > this.state.startDate1){
    //   schedultDateError = "End date should be greater or equal of start date.";
    // }

    if(priceError1 || catError || imgError || skuError || productError || catError  || descriptionError || stockError || unitError || priceError){
        this.setState({priceError1,catError,imgError,skuError,productError,catError,descriptionError,stockError,unitError,priceError});
        return false;
    } else {
        this.setState({priceError1,catError,imgError,skuError,productError,catError,descriptionError,stockError,unitError,priceError});
        return true;
    }               
}            
   


validateSkuInfo(){
  let sku1Error = '';
  if(!this.state.sku1){
    sku1Error = "Please enter a valid UPC, EAN, ISBN Number";
  } 
  if(this.state.sku1){
    var result = _.filter(this.state.rawList, { sku: this.state.sku1 });
   if(result.length > 0){
    this.setState({sku1Error:"Cannot duplicate product UPC, EAN, ISBN Number in your store."});
    return false;
   }
    }
    
  if(sku1Error){ 
      this.setState({sku1Error});
      return false;
  } else { 
      this.setState({sku1Error});
      return true;
  } 
}  


otherCategoryList(){ 
  let session_token = localStorage.getItem("token");
  otherCategory(session_token).then(data => {  
    if(data.response_data.otherCategories){
    let catData = data.response_data.otherCategories.map((store,index) => {          
      return (   
         <option value={store._id} key={Math.random()}>{store.name}</option>
        );  
      })
      this.setState({
        categories:catData
      })
    }
  });
}

submitForm1(e){ 
  e.preventDefault(); 
 
 
			const isValid = this.validateSkuInfo(); 
		  if(isValid){
			this.setState({loading:true})
			let session_token = localStorage.getItem("token");
			const {sku1} = this.state;  
			getBarCodeProductInfo(sku1,session_token).then(data => {	
			  if(data.response_data){
				//data.response_data.getBarCodeProductInfo.product_category = 0;
				var is_cat = true;
				if(data.response_data.getBarCodeProductInfo.product_category===0){
				  this.otherCategoryList();
				  var is_cat = false;
				}
				
				this.setState({
				  product_name:data.response_data.getBarCodeProductInfo.product_title,
				  description:data.response_data.getBarCodeProductInfo.product_description,
				  price:data.response_data.getBarCodeProductInfo.product_price,
				  sku:this.state.sku1,
				  is_selected:true,
				  selected_img:data.response_data.getBarCodeProductInfo.images,
				  product_name_disabled: true,
				  sku_disabled:true,
				  description_disabled:true,
				  loading:false,
				  category_disabled:is_cat,
				  category:data.response_data.getBarCodeProductInfo.product_category,
				})		
				this.skipWithoutDisabled();
				this.setState({loading:false})
			  } else {
				this.setState({loading:false, sku1Error: "Product Not Found! Please enter a valid UPC, EAN, ISBN Number"})
			  }      
			});
		  }
		
		
		
		// let cat_id = this.state.current_cat;
		// if(cat_id){
		// const user_id = localStorage.getItem("loggedInUserId");
		// const session_token = localStorage.getItem("token"); 
		// const store_id = localStorage.getItem("store_id");  
		// this.productList(user_id,session_token,store_id,cat_id);  
		// } else {
		// this.storeParentCategory();
		// }
		// this.hideModal();
     
}  


submitForm(e){   
	e.preventDefault(); 
	const isValid = this.validate();
	if(isValid){
	var  X_ACCESS_TOKEN = CONSTANT.HEADER;  
	var headers = {
	'x-access-token': X_ACCESS_TOKEN
	}

    this.setState({                
      loading:true    
    }) 
      
    const {sku,product_name,price,sale_price,description,startDate,startDate1,taxable,shipping,is_featured,category,stock,unit} = this.state;  
    const user_id = localStorage.getItem("loggedInUserId");  
    const session_token = localStorage.getItem("token");
    const store_id = localStorage.getItem("store_id");  
     
     
        const data = new FormData();
        data.append('store_id',store_id);
        data.append('product_title',product_name);
        data.append('product_price',price); 
        data.append('product_category',category);
        data.append('product_description',description);
        data.append('delivery_time',10);   
        data.append('is_featured',is_featured);
        data.append('session_token',session_token);
        data.append('product_sale_price',sale_price);
        // data.append('schedule_from_date','2020-08-23');
        // data.append('schedule_to_date','2020-08-28');
        data.append('is_taxable',taxable);
        data.append('is_shipping_available',shipping);
        data.append('stock',stock);
        data.append('unit',"");
        data.append('sku',sku);
        data.append('time_zone','Asia/Kolkata');
        // data.append('iso_schedule_from_date','2020-08-23T00:00:00.000+05:30');
        // data.append('iso_schedule_to_date','2020-08-25T00:00:00.000+05:30');
        data.append('product_image', this.state.selected_img);

		axios.post(CONSTANT.STABASE_URL+'store/addProduct',data,{
          headers: headers
        }).then(res => { 
		
		// console.log("In Valid");
		// console.log(res);
		// console.log("In Valid");
          
          let cat_id = this.state.current_cat;
          // if(cat_id){
          // const user_id = localStorage.getItem("loggedInUserId");
          // const session_token = localStorage.getItem("token"); 
          // const store_id = localStorage.getItem("store_id");  
          // this.productList(user_id,session_token,store_id,cat_id);  
          // } else {
          //  this.storeParentCategory();
          // }
           const user_id = localStorage.getItem("loggedInUserId");
           const session_token = localStorage.getItem("token"); 
           const store_id = localStorage.getItem("store_id");  
           this.productList(user_id,session_token,store_id,cat_id);  
          this.hideModal();
        }) 
      } else {
        console.log("In Valid");
        return false;
      }
    }



    onChangeChecbox = (e) => {
 this.setState({
        is_featured: (e.target.checked) ? 1:0
      })
    }


    validateEditProd = () =>{
	  
      // console.log("MMMMMMMMMMMM")
      // console.log(this.state.is_selected_image)
      // console.log(this.state.image)
      // console.log(this.state.image.name)
    
        let catError = '';
        let imgError = ''; 
        let skuError = '';
        let productError = '';
        let salepriceError='';
        let descriptionError = '';
        let stockError = '';
        let unitError = '';
        let priceError = '';
        let priceError1 = '';
        let schedultDateError='';
    
        if(!this.state.selected_img){
          imgError = "Please select product image.";
        }
    
        if(!this.state.sku){
          skuError = "Please enter sku number.";
        }
    
        if(!this.state.product_name){
          productError = "Please enter product name.";
        }
    
        if(!this.state.category){
          catError = "Category field can not be blank.";
        }
        
        // if(!this.state.sale_price){
        //   salepriceError = "Sale price field can not be blank.";
        // }
    
        if(!this.state.description){
          descriptionError = "Sale price field can not be blank.";
        }
    
        if(!this.state.stock){
          stockError = "Stock field can not be blank.";
        }
    
        // if(!this.state.unit){
          // unitError = "Unit field can not be blank.";
        // }
    
        if(!this.state.price){
          priceError = "Price field can not be blank.";
        }
    
        
        if(parseInt(this.state.price) < parseInt(this.state.sale_price)){
          priceError1 = "Sale price should be smaller than price.";
        }
    
        // if(this.state.startDate > this.state.startDate1){
        //   schedultDateError = "End date should be greater or equal of start date.";
        // }
    
        if(priceError1 || skuError || productError || descriptionError || stockError || unitError || priceError){
            this.setState({priceError1,skuError,productError,descriptionError,stockError,unitError,priceError});
            return false;
        } else {
            this.setState({priceError1,skuError,productError,descriptionError,stockError,unitError,priceError});
            return true;
        }               
    }


    editsubmitForm(e){   
      e.preventDefault(); 
      const isValid = this.validateEditProd();
      if(isValid){
      var  X_ACCESS_TOKEN = CONSTANT.HEADER;  
      var headers = {
      'x-access-token': X_ACCESS_TOKEN
      }
    
        this.setState({                
          loading:true    
        }) 
          
        const {sku,product_name,price,sale_price,description,startDate,startDate1,taxable,shipping,is_featured,category,stock,unit,prod_id} = this.state;  
        const user_id = localStorage.getItem("loggedInUserId");  
        const session_token = localStorage.getItem("token");
        const store_id = localStorage.getItem("store_id");  
         
         
            const data = new FormData();
             
            data.append('product_id',prod_id);
            data.append('store_id',store_id);
            data.append('product_title',product_name);
            data.append('product_price',price); 
            data.append('product_category',category);
            data.append('product_description',description);
            data.append('delivery_time',10);   
            data.append('is_featured',is_featured);
            data.append('session_token',session_token);
            data.append('product_sale_price',sale_price);
            // data.append('schedule_from_date','2020-08-23');
            // data.append('schedule_to_date','2020-08-28');
            data.append('is_taxable',taxable);
            data.append('is_shipping_available',shipping);
            data.append('stock',stock);
            data.append('unit',"");
            data.append('sku',sku);
            data.append('time_zone','Asia/Kolkata');
            // data.append('iso_schedule_from_date','2020-08-23T00:00:00.000+05:30');
            // data.append('iso_schedule_to_date','2020-08-25T00:00:00.000+05:30');
            data.append('product_image', this.state.selected_img);
    
        axios.post(CONSTANT.STABASE_URL+'store/editProduct',data,{
              headers: headers
            }).then(res => { 
        
        // console.log("In Valid");
        // console.log(res);
        // console.log("In Valid");
              
              let cat_id = this.state.current_cat;
              // if(cat_id){
              // const user_id = localStorage.getItem("loggedInUserId");
              // const session_token = localStorage.getItem("token"); 
              // const store_id = localStorage.getItem("store_id");  
              // this.productList(user_id,session_token,store_id,cat_id);  
              // } else {
              //  this.storeParentCategory();
              // }
               const user_id = localStorage.getItem("loggedInUserId");
               const session_token = localStorage.getItem("token"); 
               const store_id = localStorage.getItem("store_id");  
               this.productList(user_id,session_token,store_id,cat_id);  
              this.hideEditProd();
            }) 
          } else {
            console.log("In Valid");
            return false;
          }
        }

    async componentDidMount() {
	  
		const user_id = localStorage.getItem("loggedInUserId");
		const session_token = localStorage.getItem("token"); 
		const store_id = localStorage.getItem("store_id");
		//this.categoryList(user_id,session_token,store_id); 
	//	this.storeParentCategory();
		this.productList(user_id,session_token,store_id); 

		// $(document).ready(function(){
		//   $('.product_category').on('click', 'li', function() {
		// 	$('.product_category li.active').removeClass('active');
		// 	$(this).addClass('active');
		// });
		// });
   }

  render() {


     // var storeName = params.get('store');
  console.log(this.storeNameUrl1);
  console.log(this.storeNameUrl);
 // return false;

 if(this.storeNameUrl1!=this.storeNameUrl){

  this.props.history.push('/vendor/myStores');
 }


console.log(this.state.selected_img);
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }
    }


    var lastEle = 0;
    const renderPageNumbers = pageNumbers.map(number => {
      let classes = this.state.current_page == number ? "active" : '';
      lastEle = number;
      return (
        <span className={classes} key={number} onClick={() => this.pagination(number)}>{number}</span>
      );
    });



    if (this.state.total) {
      var lastelement = this.state.total / this.state.per_page;
      lastelement = lastEle;
    }

	// console.log(this.state.products)

    return (
	  <>
	  <Metatitle title={localStorage.getItem('store_name').split('/').pop().substr(0,1).toUpperCase()+localStorage.getItem('store_name').split('/').pop().substr(1)+' | aBlueC'} metaDescription="Stores" ogTitle="Stores" ogImage=""></Metatitle>
	  <Header />
	  <section class="sidebar-layout bdr-top innerheight">
      <div className="container pt-4">
    <div class="header_catehory"> <h2 className="jobs_heading mb-2 ">Stores: {localStorage.getItem("store_name")}</h2> </div>
      <div class="card">       
      <div class="card-header">  <Button></Button>  </div>
      <div class="card-body">    

      <div className="row">  
      <Sidebar/>
      {
      this.state.loading ? (
      <Loader/>
      ) : (<></>)
      }

      <div className="col-lg-9 col-md-9 col-xs-12">
        <div className="categories-slide-sec main_store">
          <div className="header_catehory">
            {/* <span style={{"color": "#000 !important"}}><span className="home_categories"><NavLink  to="/vendor/store">Home</NavLink> </span> &gt; Manage Products</span>   */}
            <h2>Manage Products</h2> 
          </div>
          {/*<div className="manage_product">
          <ul className="product_category clearfix">
          {this.state.catButtonData} 
          </ul>
          </div>*/}
          <ul className="categories_view clearfix">
          {this.state.products}
          <li>
              <div className="product-sec">
                <div className="prd-img-sec">
                  <button type="button" className="add_bt_category manage-product" onClick={this.addProductStep1.bind(this)}><i className="fa fa-plus"> + </i> Add Product</button>
                </div>
                <div className="title-sec clearfix">
                  <div className="left_section">
                    <span className="product_name">&nbsp;</span>
                    <span className="prodect_prcie">&nbsp;</span>
                    <span className="prodect_prcie">&nbsp;</span>
                  </div> 
                </div>
              </div>
            </li>
          </ul>
        
       {/*
        this.state.products.length > 0 ? (
          <div className="col-md-12">
          <div  className=" pagination-sec clearfix">
          <div className="pagination-section">
          <label className="first-arrow" onClick={() => this.pagination(1)}>&laquo;</label>
          {renderPageNumbers}
          <label className="last-arrow" onClick={() => this.pagination(lastelement)}>&raquo;</label>
          </div> 
          </div> 
          </div>
		  
        ) : (<></>)
        */}

      
        </div>
      </div>
      </div>

{/* <Modal show={this.state.isOpen3} onHide={this.hideWarning.bind(this)} size="md"> 
<Modal.Body>
<button type="button" className="close store post-close" onClick={this.hideWarning.bind(this)}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
        <div className="row">
          <div className="col-md-12">
            <div className="list_view_category">
              <h2 className="confirm-msg">Are you sure want to delete it?</h2>
            </div>
          </div>
        </div>    
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="add_job_now confirms-btn">
              <button type="submit" className="save_bt" onClick={this.hideWarning.bind(this)}>Cancel</button> &nbsp;&nbsp;
              <button type="button" className="save_bt" onClick={this.deleteProduct.bind(this)}>Delete</button>
            </div>
          </div>
        </div>  
      </div>
      </Modal.Body>
      </Modal> */}

<Modal id="modal_credit_body" show={this.state.isOpen2} onHide={this.hidePopup.bind(this)} size="l"> 
    <Modal.Body> Need to add an E-Commerce package to add additional products to your store</Modal.Body>
    </Modal>

<Modal id="modal_body" show={this.state.isOpen3} onHide={this.hideWarning.bind(this)}> 
    <Modal.Body> 
        <div className="modal-dialog" role="document">
          <div className="modal-content confirmpopup">
           
            
            <div class="card-header text-center">    Are you sure you want to delete it?  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideWarning.bind(this)}>
            <span aria-hidden="true">×</span>
            </button> </div>
            <div className="modal-body sure_delete_wrp">
             
              <div className="post_bt_public cancel_order">
                <ul>
                  <li>
                    <button type="button" className="save_bt" onClick={this.deleteProduct.bind(this)}>YES</button>
                  </li>
                  <li>
                    <button type="button" className="back_bt reject_bt" onClick={this.hideWarning.bind(this)}>NO</button>
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div> 
    </Modal.Body>
    </Modal>
	
	
      
<Modal show={this.state.isOpen1} onHide={this.closeProductStep1.bind(this)} size="md"> 
<Modal.Body>
<button type="button" className="close store post-close" onClick={this.closeProductStep1.bind(this)}><span aria-hidden="true">×</span></button>
<div className="main_detail_jobs">
        <div className="row">
          <div className="col-md-12">
            <div className="list_view_category">
              <h2>Add Product</h2>
            </div>
          </div>
        </div>  
     
        <form onSubmit={this.submitForm1} id="feedform" method="post"> 
        <div className="row"> 
          <div className="col-md-12">
            <div className="form-group">
              <label className="label_title">Enter UPC, EAN, ISBN Number <span className="required_mark">*</span></label>
              <input type="text" name="sku1" placeholder="Enter a barcode number or product name" className="form-control" value={this.state.sku1} onChange={this.onChangeform} onKeyDown={this.handleKeyDown}/>
              <span className="error-msg">{this.state.sku1Error}</span>
            </div>
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="add_job_now">
              <button type="submit" className="save_bt">Next</button> &nbsp;&nbsp;
              {/*<button type="button" className="save_bt" onClick={this.skip.bind(this)}>Skip</button>*/}
            </div>
          </div>
        </div> 
        </form>
      </div>
      </Modal.Body>
      </Modal>
      
    <Modal show={this.state.isOpen} onHide={this.hideModal.bind(this)} size="lg" backdrop="static"> 
    <Modal.Body>
    <button type="button" className="close store post-close" onClick={this.hideModal.bind(this)} style={{"position":"relative"}}><span aria-hidden="true">×</span></button>
    <div className="manage-add-products"> 
        <div className="modal-body">
        <form onSubmit={this.submitForm} id="addform" method="post" novalidate>
          <div className="row"> 
          <div className="col-md-12">
          <div className="post-and-vedio_bt add_store clearfix">
              <div className="user_pic_upload output_pic clearfix">
                <ul className="multiple-selected category_images clearfix">
                 
                  {
                  this.state.is_selected ? (
                    <li><output id="list"><span><img src={this.state.selected_img}/></span></output></li>
                  ) : (<></>)
                  }
              
                  <li> <a href="#">
                      <div id="hide">
                        <label className="hand-cursor add_image_category">
                          <input type="file" id="files" name="files" onChange={this.handleImageChange}/>
                          <span className="picture"><span className="fa fa-camera" /></span> <span className="photo_text hidden-xs">Product Image</span> </label>
                      </div>
                    </a>  
                  </li>
                  
                </ul>
                <span className="error-msg">{this.state.imgError}</span>
              </div>
            </div>
              </div>

          <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">SKU Number</label>
                    <div className="select_wrp">
                    <input type="text" disabled = {(this.state.sku_disabled)? "disabled" : ""} className="form-control" name="sku" value={this.state.sku} onChange={this.onChangeform}/>
                    <span className="error-msg">{this.state.skuError}</span>
                    </div>
                  </div>
                </div>    
              </div>
            </div>


          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Product Name</label>
                    <input type="text" disabled = {(this.state.product_name_disabled)? "disabled" : ""} className="form-control" name="product_name" value={this.state.product_name} onChange={this.onChangeform} />
                    <span className="error-msg">{this.state.productError}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Description</label>
                    <textarea disabled = {(this.state.description_disabled)? "disabled" : ""} className="form-control job_add_dis" rows={4} placeholder="Write something about your product."  name="description" value={this.state.description} onChange={this.onChangeform}/>
                    <span className="error-msg">{this.state.descriptionError}</span>
                  </div>
                </div>
                <div className="col-md-12 clearfix">
                  <div className="form-group">
                    <div className="right_radio_bt provider featured-product">
                      <label className="customcheck">
                        <input type="checkbox" name="is_featured" value="1" onChange={this.onChangeform}/>
                        <span className="checkmark" />
                        <span className="mIles_provider featured">Featured Product</span>
                      </label>
                    </div>
                  </div>
                </div>
                {
                  (this.state.category_disabled)? 
                  "" : 
                  <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Category</label>
                    <div className="select_wrp">
                      <select disabled = {(this.state.category_disabled)? "disabled" : ""}  className="form-control select_category" name="category" value={this.state.category} onChange={this.onChangeform}>
                        <option defaultValue={''}>Select Category</option>
                        {this.state.categories}
                      </select>
                      <i className="fas fa-chevron-down drop-down-chevron" />
                      <span className="error-msg">{this.state.catError}</span>
                    </div>
                  </div>
                </div>
                } 

           

              </div>
            </div>
            <div className="col-md-6">   
              <div className="row">
                  
              <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label bold-label">Retail Price</label>
                    {/* <input type="number" className="form-control" name="price" value={this.state.price} onChange={this.onChangeform} onKeyPress={this.isNumber.bind(this)}/> */}
                    <input type="text" className="form-control" name="price" value={this.state.price} onChange={this.onChangeform} onKeyPress={(event) => {if (!/^\d*\.?\d*$/.test(event.key)) {event.preventDefault();}}}/>
                    <span className="error-msg">{this.state.priceError}</span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label bold-label">Price</label>
                    {/* <input type="number" className="form-control" name="sale_price" value={this.state.sale_price} onChange={this.onChangeform} /> */}
                    <input type="text" className="form-control" name="sale_price" onKeyPress={(event) => {if (!/^\d*\.?\d*$/.test(event.key)) {event.preventDefault();}}} value={this.state.sale_price} onChange={this.onChangeform} />
                    <span className="error-msg">{this.state.salepriceError}{this.state.priceError1}</span>
                  </div>
                </div>
              
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label bold-label">Schedule</label>  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="calendar_month">
                          <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          minDate={new Date()}
                          placeholder="DD/MM/YYYY"
                          /> 
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="calendar_month">
                        <DatePicker
                          selected={this.state.startDate1}
                          onChange={this.handleChange1}
                          minDate={new Date()}
                          placeholder="DD/MM/YYYY"
                          /> 
                          <span className="error-msg">{this.state.schedultDateError}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <div className="form-group shipping">
                    <div className="row">
                      <div className="col col-md-3">
                        <label className="product_label bold-label">Taxable:</label>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test1" name="taxable" defaultChecked value="1" onChange={this.onChangeform}/>
                          <label htmlFor="test1">Yes</label>
                        </div>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test2" name="taxable" value="0" onChange={this.onChangeform}/>
                          <label htmlFor="test2">No</label>
                      </div> 
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group shipping">
                    <div className="row">
                      <div className="col col-md-3">
                        <label className="product_label bold-label">Shipping:</label>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test3" name="shipping" defaultChecked />
                          <label htmlFor="test3">Yes</label>
                        </div>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test4" name="shipping" />
                          <label htmlFor="test4">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label bold-label">Stock:</label>  
                    <div className="row">
                      <div className="col-md-6"><input type="text" className="form-control" name="stock" onKeyPress={(event) => {if (!/^[0-9]*$/.test(event.key)) {event.preventDefault();}}} value={this.state.stock} onChange={this.onChangeform} />
                      <span className="error-msg">{this.state.stockError}</span>
                      </div>
                      <div className="col-md-6">
                        <div className="select_wrp">
							{/*<select className="form-control select_category" name="unit" onChange={this.onChangeform}>
                            <option defaultValue={''}>&nbsp;</option>
                            <option value="kg">Kg</option>
                            <option value="gm">Gm</option>
                            <option value="pcs">Pcs</option>
							</select>*/}
                          <i className="fas fa-chevron-down drop-down-chevron" />
                          <span className="error-msg">{this.state.unitError}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="post_bt_public request_bt add_now_product">
            <ul>
              <li>
                <button type="submit" className="save_bt">Add Now</button>
              </li>
            </ul>
          </div>
          </form>
        </div>
      </div>
    </Modal.Body>
    </Modal>

    <Modal show={this.state.isOpen6} onHide={this.hideEditProd} size="lg"> 
    <Modal.Body>
    <button type="button" className="close store post-close" onClick={this.hideEditProd} style={{"position":"relative"}}><span aria-hidden="true">×</span></button>

    {/* <button type="button" className="close store post-close" onClick={this.hideEditProd} style={{"position":"relative"}}><span aria-hidden="true">×</span></button> */}
    <div className="manage-add-products"> 
        <div className="modal-body">
        <form onSubmit={this.editsubmitForm} id="feedform" method="post">
          <div className="row"> 
          <div className="col-md-12">
          <div className="post-and-vedio_bt add_store clearfix">
              <div className="user_pic_upload output_pic clearfix">
                <ul className="multiple-selected category_images clearfix">
               
<li><output id="list"><span><img src={this.state.product_image ? CONSTANT.NSTABASE_URL+'images/store/'+this.state.product_image :this.state.select_img ? this.state.select_img : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'} className="img-rounded search-list-img" alt="" /></span></output></li> 
              
                  {/* <li> <a href="#">
                      <div id="hide">
                        <label className="hand-cursor add_image_category">
                          <input type="file" id="files" name="files" onChange={this.handleImageChange}/>
                          <span className="picture"><span className="fa fa-camera" /></span> <span className="photo_text hidden-xs">Product Image</span> </label>
                      </div>
                    </a>  
                  </li>
                   */}
                </ul>
                <span className="error-msg">{this.state.imgError}</span>
              </div>
            </div>
              </div>

          <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">SKU Number</label>
                    <div className="select_wrp">
                    <input type="text" disabled = {(this.state.sku_disabled)? "disabled" : ""} className="form-control" name="sku" value={this.state.sku} onChange={this.onChangeform}/>
                    <span className="error-msg">{this.state.skuError}</span>
                    </div>
                  </div>
                </div>    
              </div>
            </div>


          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Product Name</label>
                    <input type="text" disabled = {(this.state.product_name_disabled)? "disabled" : ""} className="form-control" name="product_name" value={this.state.product_name} onChange={this.onChangeform} />
                    <span className="error-msg">{this.state.productError}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Description</label>
                    <textarea disabled = {(this.state.description_disabled)? "disabled" : ""} className="form-control job_add_dis" rows={4} placeholder="Write something about your product." name="description" value={this.state.description} onChange={this.onChangeform}/>
                    <span className="error-msg">{this.state.descriptionError}</span>
                  </div>
                </div>
                <div className="col-md-12 clearfix">
                  <div className="form-group">
                    <div className="right_radio_bt provider featured-product">
                      <label className="customcheck">
                        <input type="checkbox" name="is_featured" defaultChecked={this.state.is_checked}  onChange={this.onChangeChecbox}/>
                        <span className="checkmark" />
                        <span className="mIles_provider featured">Featured Product</span>
                      </label>
                    </div>
                  </div>
                </div>
                {
                  (this.state.category_disabled)? 
                  "" : 
                  <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Category</label>
                    <div className="select_wrp">
                      <select disabled = {(this.state.category_disabled)? "disabled" : ""}  className="form-control select_category" name="category" value={this.state.category} onChange={this.onChangeform}>
                        <option defaultValue={''}>Select Category</option>
                        {this.state.categories}
                      </select>
                      <i className="fas fa-chevron-down drop-down-chevron" />
                      <span className="error-msg">{this.state.catError}</span>
                    </div>
                  </div>
                </div>
                } 

           

              </div>
            </div>
            <div className="col-md-6">   
              <div className="row">
                  
              <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label bold-label">Retail Price</label>
                    {/* <input type="number" className="form-control" name="price" value={this.state.price} onChange={this.onChangeform} onKeyPress={this.isNumber.bind(this)}/> */}
                    <input type="text" className="form-control" name="price" value={this.state.price} onChange={this.onChangeform} onKeyPress={(event) => {if (!/^\d*\.?\d*$/.test(event.key)) {event.preventDefault();}}}/>
                    <span className="error-msg">{this.state.priceError}</span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label bold-label">Price</label>
                    {/* <input type="number" className="form-control" name="sale_price" value={this.state.sale_price} onChange={this.onChangeform} /> */}
                    <input type="text" className="form-control" name="sale_price" onKeyPress={(event) => {if (!/^\d*\.?\d*$/.test(event.key)) {event.preventDefault();}}} value={this.state.sale_price} onChange={this.onChangeform} />
                   
                    
                    <span className="error-msg">{this.state.salepriceError}{this.state.priceError1}</span>
                  </div>
                </div>
              
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label bold-label">Schedule</label>  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="calendar_month">
                          <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          minDate={new Date()}
                          placeholder="DD/MM/YYYY"
                          /> 
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="calendar_month">
                        <DatePicker
                          selected={this.state.startDate1}
                          onChange={this.handleChange1}
                          minDate={new Date()}
                          placeholder="DD/MM/YYYY"
                          /> 
                          <span className="error-msg">{this.state.schedultDateError}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <div className="form-group shipping">
                    <div className="row">
                      <div className="col col-md-3">
                        <label className="product_label bold-label">Taxable:</label>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test1" name="taxable" checked={(this.state.taxable == 1) ?true : ''} value="1" onChange={this.onChangeform}/>
                          <label htmlFor="test1">Yes</label>
                        </div>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test2" name="taxable" checked={this.state.taxable == 0 ? true:'' } value="0" onChange={this.onChangeform}/>
                          <label htmlFor="test2">No</label>
                      </div> 
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <div className="form-group shipping">
                    <div className="row">
                      <div className="col col-md-3">
                        <label className="product_label bold-label">Shipping:</label>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test3" name="shipping" checked={(this.state.shipping == 1) ?true : ''} value="1" onChange={this.onChangeform}/>
                          <label htmlFor="test3">Yes</label>
                        </div>
                      </div>
                      <div className="col col-md-3">
                        <div className="right_radio_bt yes-no-radio">
                          <input type="radio" id="test4" name="shipping" checked={(this.state.shipping == 0) ?true : ''} value="0" onChange={this.onChangeform}/>
                          <label htmlFor="test4">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label bold-label">Stock:</label>  
                    <div className="row">
                      <div className="col-md-6"><input type="text" className="form-control" name="stock" onKeyPress={(event) => {if (!/^[0-9]*$/.test(event.key)) {event.preventDefault();}}} value={this.state.stock} onChange={this.onChangeform} />
                      <span className="error-msg">{this.state.stockError}</span>
                      </div>
                      <div className="col-md-6">
                        <div className="select_wrp">
							{/*<select className="form-control select_category" name="unit" onChange={this.onChangeform}>
                            <option defaultValue={''}>&nbsp;</option>
                            <option value="kg">Kg</option>
                            <option value="gm">Gm</option>
                            <option value="pcs">Pcs</option>
							</select>*/}
                          <i className="fas fa-chevron-down drop-down-chevron" />
                          <span className="error-msg">{this.state.unitError}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="post_bt_public request_bt add_now_product">
            <ul>
              <li>
                <button type="submit" className="save_bt">Update Now</button>
              </li>
            </ul>
          </div>
          </form>
        </div>
      </div>
    </Modal.Body>
    </Modal>

    </div>
    </div>
    </div>
    </section>
	<Footer />
	</>
    );  
  }
}

export default Product;