import React, { useMemo } from "react";
import axios from 'axios';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import Loader from '../../Loader';
import CONSTANT from '../../Constant'; 

import useResponsiveFontSize from "../../useResponsiveFontSize";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const FormCredit = () => {
	const stripe = useStripe();
	const elements = useElements();
	const options = useOptions();
	const [checkLog] = React.useState(false)
	const [error, setError] = React.useState("")
	const [cardNumber, setCardNumber] = React.useState("")
	const [cardExpDate, setCardExpDate] = React.useState("")
	const [cardCVV, setcardCVV] = React.useState("")
	
	const handleChange = (event) => {
		if(event.complete != false && event.elementType == "cardNumber"){
			setError(event.error ? event.error.message : "")
			setCardNumber(1)
		} else if(event.complete != false && event.elementType == "cardExpiry"){
			setError(event.error ? event.error.message : "")
			setCardExpDate(1)
		} else if(event.complete != false && event.elementType == "cardCvc"){
			setError(event.error ? event.error.message : "")
			setcardCVV(1)
		}
	}
	
	const handleSubmit = async event => {
		event.preventDefault();
		if(cardNumber == ''){
			setError("Your card number is blank!")
			return false;
		} else if(cardExpDate == ''){
			setError("Your card expire date is blank!")
			return false;			
		} else if(cardCVV == ''){
			setError("Your card cvv number date is blank!")
			return false;
		} else {			
			setError("")			
			$("#loader").css({"display":"block"})		
			if (!stripe || !elements) {
			  return;
			}
			const card = elements.getElement(CardNumberElement);
			const payload = await stripe.createToken(card);
			if(payload.error){
				setError("Please fill the correct details!")
				$("#loader").css({"display":"none"})
				return false
			}
			var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),"stripe_token":payload.token.id}
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}			
			let response = await axios.post(CONSTANT.STABASE_URL+'store/addcard', formData, config);
			
			console.log("MMM")
			console.log(response.data)
			console.log("MMM")
			
			if(response.data.response_status == 1){
				var cardStatus = response.data.response_msg
				$("#loader").css({"display":"none"})
				setTimeout(function () {
					document.location.href = '/activity-subscription';
				}, 2000);
			} else {
				$("#loader").css({"display":"none"})
				setError("Please fill the correct details!")
				$("#loader").css({"display":"none"})
				return false
			}
		}
	};
	
	return (
		<>
		<p id="loader" style={{"display":"none"}}><Loader/></p>
		{(error != "") ? (<p style={{"color":"red","background": "white","border": "1px solid #eee","padding": "10px","background-color": "transparent"}}>{error}</p>) : ""}
		{(checkLog)? (<p style={{"color":"green","background": "white","border": "1px solid #eee","padding": "10px","background-color": "transparent"}}>Card added successfully</p>) : ""}
		<div className="addnewcard"> <form  onSubmit={handleSubmit}>
		  <label>
			Card number
			<CardNumberElement
			  options={options}
			  value={cardNumber}
			  onReady={() => {
				// console.log("CardNumberElement [ready]");
			  }}
			  onChange={event => {
				handleChange(event)
			  }}
			  onBlur={() => {
				// console.log("CardNumberElement [blur]");
			  }}
			  onFocus={() => {
				// console.log("CardNumberElement [focus]");
			  }}
			/>
		  </label>
		  <label>
		   Expiry Date
			<CardExpiryElement
			  options={options}
			  value ={cardExpDate}
			  onReady={() => {
				// console.log("CardNumberElement [ready]");
			  }}
			  onChange={event => {
				handleChange(event)
			  }}
			  onBlur={() => {
				// console.log("CardNumberElement [blur]");
			  }}
			  onFocus={() => {
				// console.log("CardNumberElement [focus]");
			  }}
			/>
		  </label>
		  <label>
		  CVV No
			<CardCvcElement
			  options={options}
			  value ={cardCVV}
			  onReady={() => {
				console.log("CardNumberElement [ready]");
			  }}
			  onChange={event => {
				handleChange(event)
			  }}
			  onBlur={() => {
				// console.log("CardNumberElement [blur]");
			  }}
			  onFocus={() => {
				// console.log("CardNumberElement [focus]");
			  }}
			/>
		  </label>
		  <div className="paynow"> <button type="submit" disabled={!stripe}>Add Card</button> </div>
		</form> </div>
		</>
  );
};

export default FormCredit;