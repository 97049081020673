import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component } from 'react';
import { Provider } from "react-redux";
import Home from './components/Home';
import FeedsPages from './components/FeedsPages';
import ManagePages from './components/ManagePages';
import addCard from './components/addCard';
import stripeConnectAuth from './components/stripeConnectAuth.js';
import SearchResult from "./components/Search-result";
import InnerPages from "./components/Pages/InnerPages";
import SellPages from "./components/Pages/SellPages";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import addStorePages from "./components/User/addStorePages";
import Product from "./components/User/Product";
import ForgotPassword from "./components/User/ForgotPassword";
import changePassword from "./components/User/changePassword";
import myStores from "./components/User/myStores";
import allStores from "./components/allStores";
import storeProductList from "./components/User/storeProductList";
import Profile from "./components/User/Profile";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import Productdetails from "./components/User/Productdetails";
import Checkout from "./components/User/Checkout";
import Cart from "./components/User/Cart";
import OrderStore from "./components/User/OrderStore";
import CustomerOrder from "./components/User/CustomerOrder";
import Feed from "./components/Feed";
import PeopleProfile from './components/PeopleProfile';
import Storefront from "./components/User/Storefront";
import StoreVendor from "./components/User/StoreVendor";
import StoreCalendar from "./components/User/StoreCalendar";
import StoreInventory from "./components/User/StoreInventory";
import StoreActivity from "./components/User/StoreActivity";
import ActivitySubscription from "./components/User/ActivitySubscription";
import NoFound from './components/404Page';
import Profiles from './components/Profile';
import AppSubscription from './components/AppSubscription';
import Friends from "./components/Friends";
import ProfileUser from "./components/ProfileUser";
import Followers from "./components/Followers";
import Following from "./components/Following";
import ServiceProvider from "./components/Job/ServiceProvider";
// added by AKS
import Jobs from "./components/Job/Jobs";
import JobsListing from "./components/Job/JobsListing";
import JobsDetails from "./components/Job/JobsDetails";
import AppliedJobs from "./components/Job/AppliedJobs";
import AddJob from "./components/Job/AddJob";
import PackageTransaction from "./components/User/PackageTransaction";
import EditJob from "./components/Job/EditJob";
import Contactpackage from "./components/Contactpackage";
import Contact from "./components/Contactquery";
//import Emailer from "./components/Emailer";
//import Importsku from "./components/User/Importsku";









import { setCurrentUser, logoutUser } from "./actions/authActions";

if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
	//console.log(token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./";
    }
}

class App extends Component {
    render () {
		return (
			<Provider store={store}>
				<Router>
					<div className="App">
						<Switch>
							<Route exact path='/' component={Home}/>
							<Route path="/404" component={NoFound} />
							<Route exact path='/people/:name' component={Profiles}  />
							<Route exact path='/appsubsription/:session_token/:store_id/:store_name' component={AppSubscription}  />
							<Route exact path='/people-profile/:id' component={PeopleProfile}/> 
							<Route exact path='/profile' component={ProfileUser}/>
							<Route exact path='/follower' component={Followers} />
							<Route exact path='/following' component={Following} />
							<Route exact path='/vendor/store/store-order' component={OrderStore}/>
							<Route exact path='/vendor/store/store-front' component={StoreVendor}/>
							<Route exact path='/vendor/store/store-calendar' component={StoreCalendar}/>
							<Route exact path='/vendor/store/store-inventory' component={StoreInventory}/>
							<Route exact path='/vendor/store/store-activity' component={StoreActivity}/>
							<Route exact path='/activity-subscription' component={ActivitySubscription}/>
							<Route exact path='/custumer/order' component={CustomerOrder}/>
							<Route exact path='/store/:name/:id' component={Productdetails} />
							<Route exact path='/feeds' component={FeedsPages} />
							<Route exact path='/subscription' component={addCard} />
							<Route exact path='/manageactivity' component={ManagePages} />
							<Route exact path='/stripeConnect/stripeConnectAuth' component={stripeConnectAuth}/>
							<Route exact path='/vendor/myStores' component={myStores} />
							<Route exact path='/store/:name' component={storeProductList} />
							<Route exact path='/store-front' component={Storefront} />
							<Route exact path='/friend' component={Friends} />
							{/*<Route exact path='/vendor/store/product-bulkupload' component={Importsku}  /> */}



							<Route exact path='/store' component={allStores} />
							<Route exact path='/vendor/store/:name' component={Product} />
							<Route exact path='/vendor/addStore' component={addStorePages} />
							<Route exact path='/Search-result' component={SearchResult}/>
							<Route exact path='/?status=ok' component={Home}/>							
							<Route exact path='/login' component={Login}/>
							<Route exact path='/forgot-password' component={ForgotPassword}/>
							<Route exact path='/changePassword' component={changePassword}/>
							<Route exact path='/signup' component={Register}/>
							<Route exact path='/checkout' component={Checkout}/>
							<Route exact path='/cart' component={Cart}/>
							<Route path="/feed" component={Feed}/>
							<Route exact path='/profile/:name' component={Profile}  />
							<Route exact path='/profile-user' component={ProfileUser}  />
							<Route exact path='/service-provider' component={ServiceProvider}  />
							<Route exact path='/contacts' component={Contactpackage}  />
							<Route exact path='/contact' component={Contact}  />
							{/*<Route  path='/emailer' component={Emailer}/>*/}
							<Route exact path='/jobs' component={Jobs}  />
							<Route exact path='/jobs-listing' component={JobsListing}  />
							<Route exact path='/add-job' component={AddJob}  />
							<Route exact path='/applied-jobs' component={AppliedJobs}  />
							 <Route exact path='/edit-jobs/:id' component={EditJob}  /> 
							<Route exact path='/jobs-details/:id' component={JobsDetails}/> 
							<Route exact path='/vendor/package-transaction' component={PackageTransaction}/> 
							 <Route  exact path='/:name' component={InnerPages}/>
							<Route  exact path='/ecommerce/:name' component={SellPages}/>
							<Route  exact path='/mobile/:name' component={SellPages}/>

						


							
						</Switch>
					</div>
				</Router>
			</Provider>
		);
    }
}

export default App;
