import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  friendList: [],
  completeList:[],
  isOpen:false,
  currentJobId:''
 
} 

class JobsListing extends Component {
  constructor(props) {
    super(props);
  const token = localStorage.getItem("token");        
  this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    this.setState({loading: true}); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
   
    
    var formData = {"session_token":localStorage.getItem("token"),"status":"1"}   
  
    let response = await axios.post(CONSTANT.STABASE_URL +'users/getjoblisting', formData, config);
    console.log(response.data.response_msg);
    if(response.data.response_msg!='Already logged on other device'){
   
    if(response.data.response_data.getjoblistingList != null && response.data.response_data.getjoblistingList!=''){
      this.setState({friendList: response.data.response_data.getjoblistingList[0].data,loading: false});
    } else {
      this.setState({loading: false});
      this.setState({friendList: [],loading: false});
    }
  }
  else{
     this.props.history.push('/login');
  }
  

 var formData1 = {"session_token":localStorage.getItem("token"),"from_date":"","to_date":"","status":4}   
  
    let response1 = await axios.post(CONSTANT.STABASE_URL +'users/getjoblisting', formData1, config);
    console.log("fffff");
   console.log(response1.data);
 console.log("11111111");
 if(response1.data.response_msg!='Already logged on other device'){
    if(response1.data.response_data.getjoblisting != null && response1.data.response_data.getjoblisting!=''){
      this.setState({completeList: response1.data.response_data.getjoblisting[0].data,loading: false});
    } else {
      this.setState({loading: false});
    }
  }
  else{

    localStorage.removeItem("token");
    localStorage.removeItem("store_id");
    localStorage.removeItem("imgs");
    localStorage.removeItem("store_name");
    localStorage.removeItem("address");
    localStorage.removeItem("description");
    localStorage.removeItem("store_contact_number");    
    localStorage.removeItem("loggedInUserId");    
    localStorage.removeItem("email");   
    localStorage.removeItem("loggedInUser_thumb_profile_image");    
    localStorage.removeItem("loggedInUserName");    
    localStorage.removeItem("checkStoreStatus");    
    localStorage.removeItem("otp");   
    localStorage.removeItem("mobile");    
    localStorage.removeItem("longitude");   
    localStorage.removeItem("latitude");    
    localStorage.removeItem("storePhoneNo");    
    localStorage.removeItem("country_code");    
    this.setState({loggedIn:false, showing: true})
    document.location.href = '/login'

    //this.props.history.push('/login');
  }
  
}
  

  timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

setDetail = e => { console.log(e)
  localStorage.setItem("stat",e.stat);
  //localStorage.setItem("store_name", e.store_name);
  document.location.href = "/jobs-details/"+e.job_id
}


showWarningModal() {
    this.setState({
      isOpen:true
    })
   };
 
   hideWarningModal() {
     this.setState({
      isOpen:false,
     })
   };


removeJob11 = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {
  
  this.state.currentJobId=e;
  this.setState({
      isOpen:true
    });

    }
  };


removeJob = async e =>{
    e || e.preventDefault();
    if(localStorage.getItem("token") == undefined){
      return this.props.history.push('/login')
    } else {

//       console.log("e232242");
//       console.log(e);
// console.log("e232242");
// //     // var jobIDselected=e;
// //return false;

//  // showWarningModal();

//      this.setState({
//       isOpen:true
//     });


// return false;
  this.setState({
      isOpen:false,
     })

console.log(this.state.currentJobId);

      const formData = {"session_token":localStorage.getItem("token"),"job_id":this.state.currentJobId}   
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      }   
      let response = await axios.post(CONSTANT.STABASE_URL+'users/deletejobUser', formData, config);
      if(response.data.response_status == 1){
        //localStorage.setItem("follow", true)
      }
      this.componentDidMount();
    }   
  } 






completeJob = async e =>{
  e || e.preventDefault();
  if(localStorage.getItem("token") == undefined){
    return this.props.history.push('/login')
  } else {

    console.log("eerfee");
    console.log(e);
    console.log("45464645");
    //var status = e.target.value;
    //var status = e.target.value;

    // if(status==="1"){
    //  status = "0";
    //   } else {
    //  status = "1";
    //   }

      //console.log(e.target.value); return "job_id":e
   // const formData = {"session_token":localStorage.getItem("token"),"job_id":this.state.joobdetailsId}    
   const formData = {"session_token":localStorage.getItem("token"),"job_id":e}   
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
      }
    }   
    let response = await axios.post(CONSTANT.STABASE_URL+'users/completeRequestApi', formData, config);
    // if(response.data.response_status == 1){
    //   localStorage.setItem("stat", 4)
    //   document.location.href = '/jobs-listing'
    //   //localStorage.setItem("follow", true)
    //   //localStorage.setItem("stat", 4)
    //   //this.componentDidMount();
    // }
    this.componentDidMount();
  }   
}


  friendsList =  (cFriend) => {
    const res = cFriend.length > 0 && cFriend.map((item) => {
     if(item.job_image[0] != '' && item.job_image[0] != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/store/'+item.job_image[0]
     
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }
    
    if(item.job_status==1)  var jobstatus="Active";
    if(item.job_status==5)  var jobstatus="Inactive";
    var timeago=this.timeSince(new Date(item.updated_at));


      return( 
        <li key={Math.random()}>
        
          <div className="product_name">

         {/* <a href="" onClick={() => this.props.history.push('/jobs-details/'+item.job_id)}> 
         <a href="" onClick={() => this.props.history.push( {pathname: '/jobs-details/'+item.job_id,
            state: { 'jstatus':item.job_status }})}>*/}
          <a style={{"cursor":"pointer"}} onClick={() => this.setDetail({'job_id':item.job_id,'stat':item.job_status})}>

          <div className="friends-img" style={{'max-width': '300px'}}> <img src={profileImg} alt="img" /> 

          <p>{item.job_title} <br/><span>Status : {jobstatus}</span><br/><span>{item.applied_job_users_count}  {item.applied_job_users_count > 1 ? "Applicants" : "Applicant"} </span></p><br/>
        

          <div className="status-sec"></div>

          </div>        

          </a>
          
           <div className="friends-action"> 
          <small> {timeago}    </small>   
           {/* <button type="button" class="btn btn-light" onClick={() => this.removeJob(item.job_id)}>Remove</button>    */}
            <button type="button" class="btn btn-light" onClick={() => this.completeJob(item.job_id)}>Pause the job</button>

          {/* <button type="button" class="btn btn-light" onClick={this.showWarningModal.bind(this)}>Remove</button> */}
          <button type="button" class="btn btn-light" onClick={() => this.removeJob11(item.job_id)}>Remove</button> 
            {/*<button type="button" class="btn btn-light" onClick={() => this.props.history.push('/edit-jobs/'+item.job_id)}>Edit</button> */}
           </div>                
          </div>
        
       
      
      </li>
      )                                   
    })  
    return <>{res}</>
  }
  


completedsList =  (cFriend1) => {
    const res1 = cFriend1.length > 0 && cFriend1.map((item) => {
        //var email = item.provider_data.email.replace(/.com/g,'');
        var email = item.provider_data.display_name;
     
     var providerDataImage=item.provider_data.thumb_profile_image;

     console.log(providerDataImage);
          console.log(providerDataImage);

     console.log(providerDataImage);


     if(providerDataImage!='' && providerDataImage!=undefined){
    var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+providerDataImage;

     }else{
          var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';

     }
    
    if(item.job_status==4)  var jobstatus="Pause Job Listing";
    var timeago=this.timeSince(new Date(item.updated_at));
     var completeusername=item.provider_data.name;


      return( 
        <li key={Math.random()}>
        
          <div className="product_name">

          <a style={{"cursor":"pointer"}} onClick={() => this.setDetail({'job_id':item.job_id,'stat':item.job_status})}>


          <div className="friends-img" style={{"max-width":"100%"}}> <img src={profileImg} alt="img" /> 

          <p style={{textTransform: 'capitalize'}}>{item.job_title}<br/><span className="status-sec">Status : {jobstatus}</span></p>
          
          </div>
          </a>
          
        <br/>
         <div className="friends-action" style={{"text-align" : "right"}}> 
           {timeago}      
           <br/>
           <span style={{textTransform: 'capitalize'}}>Completed By <a href="" onClick={() => this.props.history.push('/people/'+email)}>{completeusername}</a> </span>   
           
           </div>  
        
          
         
                        
          </div>
        
       
      
      </li>
      )                                   
    })  
    return <>{res1}</>
  }

  

  


  
  



  render() {
    
    
   
    const { friendList} = this.state;
  const {completeList}= this.state;
  
    return (
      <>
       <Metatitle title="Job Listing | aBlueC" metaDescription="Job Listing" ogTitle="Job Listing" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
        <div className="container">  
       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
      <div className="row">
        <div className="col-md-12 my-2">
        <a className="btn btn-primary float-right" href="/add-job">Add Job</a>
        </div>
      </div>
    
     
          <div className="row justify-content-center"> 

          <div className="col-md-6">
          <div className="checkout-sec mb-3"> 
            <div class="card">
                  <div class="card-header">
                My Posted Job
                  </div>
                  <div className="card-body">
                          <ul className="friends-list posted"> 
                            {friendList.length > 0 ? this.friendsList(friendList):<> <li>No Current Job Found</li></>} 
                      </ul>
          
                </div>

          </div>
        </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-6">  
        <div className="checkout-sec mb-3"> 
            <div class="card">
                  <div class="card-header">
                Found Applicants / Paused Job Listing
                  </div>
                  <div className="card-body">
                          <ul className="friends-list completed"> 
                           {completeList.length > 0 ? this.completedsList(completeList):<><li>No Jobs Paused</li></>} 
                      </ul>
          
                </div>

          </div>
        </div>
  </div>

           </div>
     



    

 <Modal id="modal_body" show={this.state.isOpen} onHide={this.hideWarningModal.bind(this)}> 
    <Modal.Body> 
        <div className="modal-dialog" role="document">
          <div className="modal-content confirmpopup">
           
            
            <div class="card-header text-center">    Are you sure you want to delete it?  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideWarningModal.bind(this)}>
            <span aria-hidden="true">×</span>
            </button> </div>
            <div className="modal-body sure_delete_wrp">
             
              <div className="post_bt_public cancel_order">
                <ul>
                  <li>
                                      <button type="button" className="save_bt" onClick={this.removeJob.bind(this)}>YES</button>

                  </li>
                  <li>
                    <button type="button" className="back_bt reject_bt" onClick={this.hideWarningModal.bind(this)}>NO</button>
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div> 
    </Modal.Body>
    </Modal>








</div> 
    
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default JobsListing;