import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { Redirect, NavLink } from "react-router-dom";
import axios from 'axios';
import Loader from "react-loader-spinner";
import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import Modal from "react-bootstrap/Modal";
import '../autosearch.css';
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import CONSTANT from '../Constant';
import {getPagesContent, clearErrors} from "../actions/HomepagesActions";
import Metatitle from "../components/metaTitle";

if ("geolocation" in navigator) {
	navigator.geolocation.getCurrentPosition(function(position) {
	  localStorage.setItem("latitude_home",position.coords.latitude);
	  localStorage.setItem("longitude_home",position.coords.longitude);	
	},
	function(error) {
		console.log("222222");
	  if (error.code == error.PERMISSION_DENIED)
	  localStorage.setItem("latitude_home","42.658140");
	  localStorage.setItem("longitude_home","-84.488590");
	});
  } else {
	localStorage.setItem("latitude_home","42.658140");
	  localStorage.setItem("longitude_home","-84.488590");
  }

async function getSuggestions(value) {
	const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim();
	this.setState({searchfilterdata: escapedValue});
	if (escapedValue === '') {
		return [];
	}
	const regex = new RegExp('^' + escapedValue, 'i');	
	if(escapedValue.split('').length > 2)
	return await this.props.getPagesContent({search_txt: escapedValue,latitude: this.state.position.latitude,longitude: this.state.position.longitude})
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
	if(suggestion.getTitle == "products"){
		//var images = suggestion.product_image
		var images =suggestion.images[0] ? CONSTANT.NSTABASE_URL+'images/store/'+suggestion.images[0] :suggestion.product_image ? suggestion.product_image : CONSTANT.NSTABASE_URL+'images/profile/noimage.png'
		
		var source = 'products'
	} else if(suggestion.getTitle == 'stores'){

		var images1 = suggestion.store_image

			if(images1!=''){
				var images = CONSTANT.NSTABASE_URL+'images/store/'+suggestion.store_image;
			}else{
				var images = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
			}


		//var images = suggestion.images
		var source = 'stores'
	} else if(suggestion.getTitle == 'pages'){
		var images = suggestion.images
		var source = 'pages'
	} else if(suggestion.getTitle == 'jobs'){
	
		if(suggestion.images != undefined){
			var images = CONSTANT.NSTABASE_URL+'images/store/'+suggestion.images;
		}else{
			var images = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
		}

		var source = 'jobs'
	}else {
		var images = suggestion.images
		var source = 'people'
	}
	return (<div><a href={"Search-result?q="+suggestion.name+"&source="+source}><span><img src={images} rel={suggestion.getTitle} width="50px" height="50px"/> </span> <span>{suggestion.name}</span></a></div>);
	// return (<div><span> <img src={suggestion.images} width="50px" height="50px"/> </span> <span>{suggestion.name}</span></div>);
}

class Home extends Component {


		
	constructor(props) {
		super(props);
		this.state = {
			newloader: false,
			value: '',
			suggestions: [],
			pages: [],
			email: "",
			password: "",
			position:{
				"latitude": localStorage.getItem("latitude_home"),
				"longitude": localStorage.getItem("longitude_home")
			},
			showmessage:'',
			profileData: "",
			response_status: "",
			profile: {},
			getStoreInfo: {},
			stripeConnect: "",
			searchfilterdata:'',
			status: '',
			isOpen1: true
        };  

		//console.log(navigator.geolocation);
		getSuggestions = getSuggestions.bind(this);
		this.handleUsernameLoginChange = this.handleUsernameLoginChange.bind(this);
    }
	
	async componentDidMount() {
		this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />});
		document.getElementById("Search").addEventListener("keyup", async (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
				this.props.history.push(`/Search-result?q=${this.state.value}&source=people`);
			}
		});
	}

	onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {    
		var filterUserName = suggestion.name;
		if(this.state.searchfilterdata != ""){
			this.setState({"status": "ok","searchfilterdata": filterUserName});
		} else {
			this.setState({"status": "not"});
		}
	};
	
	onChange = (event, { newValue, method }) => {
		this.setState({newloader: <Loader type="Puff" className="loaderSpinner" color="#00BFFF"  height={30} width={30} timeout={3000} />,value: newValue});
	};
    
	onSuggestionsFetchRequested = async ({ value }) => {
		this.setState({newloader: <Loader type="Puff" color="#00BFFF" className="loaderSpinner"  height={30} width={30} timeout={3000} />,suggestions: await getSuggestions(value) });
	};
  
    onSuggestionsClearRequested = () => {
		this.setState({
		  suggestions: []
		});
    };
	
	handleUsernameLoginChange = e => {
		this.setState({ [e.target.id]: e.target.value});
	}
	searchData = e => {		
		if(this.state.searchfilterdata != ""){
			this.setState({"status": "ok"});
		} else {
			this.setState({"status": "not"});
		}	
	}
	
	clickButton(e){
		if(e === 1)
		this.props.history.push("/")
		else
		this.props.history.push("/custumer/order")			
	}
	
	render(){
		
		if(this.state.status == "ok"){
			var filterUserName = this.state.searchfilterdata;
            return <Redirect push to={`/Search-result?q=${filterUserName}&source=people`} />
        }
		
		const { value,  newloader, pages, profileData, response_status, profile, stripeConnect } = this.state;
		const {getDataPage} = this.props.homepages
		const inputProps = {
			id: 'Search',
			placeholder: 'Search',
			value,
			onChange: this.onChange
		};
		
		
		var newUserFilterList = []
	    var newProductsFilterList = []
	    var newStoreFilterList = []
		var newJobFilterList = []
		getDataPage.length > 0 && getDataPage.map((items) => {
			if(items.title == "Users")
				newUserFilterList.push(items.languages)
			else if(items.title == "Products")
				newProductsFilterList.push(items.languages)
			else if(items.title == "Jobs")
				newJobFilterList.push(items.languages)
			else
				newStoreFilterList.push(items.languages)
		});
		
		newUserFilterList = newUserFilterList[0] ? newUserFilterList[0] : []
		newProductsFilterList = newProductsFilterList[0] ? newProductsFilterList[0] : []
		newStoreFilterList = newStoreFilterList[0] ? newStoreFilterList[0] : []
		newJobFilterList = newJobFilterList[0] ? newJobFilterList[0] : []
		
		var getTitle
		newUserFilterList.length > 0 && newUserFilterList.map((items) => {
			items.getTitle = "users"
		});
		newProductsFilterList.length > 0 && newProductsFilterList.map((items) => {
			items.getTitle = "products"
		});
		newStoreFilterList.length > 0 && newStoreFilterList.map((items) => {
			items.getTitle = "stores"
		});
		newJobFilterList.length > 0 && newJobFilterList.map((items) => {
			items.getTitle = "jobs"
		});
		
		var getDataFilterResult = [...newUserFilterList,...newProductsFilterList,...newStoreFilterList,...newJobFilterList]
		
		return (
			<>
			<Metatitle title="Home | aBlueC" metaDescription="" ogTitle="" ogImage=""></Metatitle>
			<div className="home-bg">
				<Header/>
					<div className="container">
						<div className="layer_section">
							<div className="layer_logo">
								<a href="#"><img src="../assets/images/logo-white.png" alt="Layer"/></a>
							</div>
							<div className="search_wrp">
								<Autosuggest
								suggestions={getDataFilterResult.slice(0,10)}
								onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
								onSuggestionsClearRequested={this.onSuggestionsClearRequested}
								getSuggestionValue={getSuggestionValue}
								renderSuggestion={renderSuggestion}
								onSuggestionSelected={this.onSuggestionSelected}
								onChange={this.handleOnInputChange}
								inputProps={inputProps} />
								<img src="../assets/images/search.png" onClick={this.searchData} className="main_search_img"/>
							</div>
							<div className="dropdown">
								<div style={{ width: 400 }}></div>
							</div>
						</div>
					</div>
				<Footer />
				<>
				<Modal id="modal_body_clone_store" show={this.state.isOpen1 == false} backdrop = "static"> 
					<Modal.Body>
						<div className="modal-content modal_bg">
						  <div className="modal-header head_thanks">
							<div className="row">
							  <div className="col-md-12">
								<div className="tick_img">
								  <img src="/assets/images/svg/tick.svg" alt="" />
								</div>
							  </div>
							</div>
						  </div>
						  <div className="content_body">
							<div className="row">
							  <div className="col-md-12">
								<h2 className="order_head">Thank You for your Order</h2>
							  </div>
							</div>
							<div className="row">
							  <div className="col-md-12 text-center">
								<button type="button" className="save_bt mr-4" onClick={() => this.props.history.push("/")}>Shopping</button>
								<button type="button" className="save_bt" onClick={() => this.props.history.push("/custumer/order")}>Orders</button>
							  </div>
							</div>
						  </div>
					  </div>
					</Modal.Body>
				</Modal>
				</>
			</div>
			</>
		);
	}
}

Home.propTypes = {
	auth: PropTypes.object.isRequired,
	homepages: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    homepages: state.homepages,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getPagesContent, clearErrors}
)(Home);