import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
//import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import Metatitle from "../../components/metaTitle";
import {getAllCategory,getSubCategory} from '../../components/Api'; 

import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
  categoryOptions:'',
  category_id:'',
  sub_category_id:[],
  sub_cat_id:'',
  subcategoryOptionss:'',
  serviceProviderList:[],
  loading:false,
  flags:false
} 

class ServiceProvider extends Component {
  constructor(props) {
    super(props);
       
  this.state = initialState;
  } 


  async componentDidMount() { 
    if(localStorage.getItem("token") == undefined){
      this.props.history.push('/login');
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        localStorage.setItem("latitude_sp",position.coords.latitude);
        localStorage.setItem("longitude_sp",position.coords.longitude);	
      },
      function(error) {
        console.log("222222");
        if (error.code == error.PERMISSION_DENIED)
        localStorage.setItem("latitude_sp","42.658140");
        localStorage.setItem("longitude_sp","-84.488590");
      });
      } else {
      localStorage.setItem("latitude_home","42.658140");
        localStorage.setItem("longitude_home","-84.488590");
      }

    this.setState({loading: true}); 
    

    // const onSuccess = (position) => {
    //  this.setState({"latitude":position.coords.latitude,"longitude":position.coords.longitude})
    // };   
    // const onError = (error) => {
    //  console.log(error.code+' '+error.message);
    // }
    // navigator.geolocation.getCurrentPosition(onSuccess, onError);

    await this.categoryList({'session_token':localStorage.getItem("token")});
   
  }

  categoryList = async (token) => { 
    await getAllCategory(token).then(data => {
    if(data.response_data.length>0){
        let catData = data.response_data.map((cat,index) => {  
        return (   
        <option value={cat._id}>{cat.name}</option>
            ); 
        })
        this.setState({
          categoryOptions:catData, loading: false
          })  
        
        } else {
        this.setState({
          categoryOptions:'', loading: false
          
        })   
        }
    });
    }
  
  
    onChangeCategory = async e => {
      
      this.setState({'category_id':e.target.value,loading: true});
        
      const catData = {
          "category_id": e.target.value,
          "session_token":localStorage.getItem("token")
        };
       await getSubCategory(catData).then(data => {
          if(data.response_data.subCategories.length>0){
      
                     this.setState({
                       subcategoryOptionss:data.response_data.subCategories, loading: false
                    
                   }) 
                } else {
                  this.setState({
                      subcategoryOptionss:[], loading: false
                    
                  })   
                }
              
          });
  };
  
  onChangeSubCat = async e =>{
    var filterArray = []
    
    filterArray.push(e.target.value)
    
    this.setState({loading: true,sub_category_id:filterArray,sub_cat_id:e.target.value});

    await this.onChangeProviderList({'sub_cat_id':e.target.value,'category_id':this.state.category_id})
    }

    onChangeProviderList = async (e) => {

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
        }
      } 
      var formData = {"session_token":localStorage.getItem("token"),"search_txt":'',"subCategoryId":e.sub_cat_id,"categoryId":e.category_id,"lat":localStorage.getItem("latitude_sp"), "long":localStorage.getItem("longitude_sp"),"page":1,"row_count":20}    
    
      let servicePUsers = await axios.post(CONSTANT.STABASE_URL +'users/getserviceprovider', formData,config);
      if(servicePUsers.data.response_status === 1){
       this.setState({serviceProviderList:servicePUsers.data.response_data.getServiceProvider,loading: false,flags:true});
      }  
      
  };

  serviceProviderLists =  (sProvider) => { 
    if(this.state.flags && sProvider.length > 0){
    const res = sProvider.length > 0 && sProvider.map((item) => {
      if(item.profile_image != '' && item.profile_image != undefined){
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
       //var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';
      // } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      // } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
      //   var profileImg = CONSTANT.NSTABASE_URL+'/images/profile/'+item.thumb_profile_image
      } else {
        var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png';    
      }
    
    //var skillsname=item.userSkills.addSkills.subCat;

  
   var skillsname=item.userSkills.addSkills.subCat;
   

   
    //  if(skillname==null)
    //  var skillname1= value.name;
    // else
    //  var skillname1= value.otherdata;




      return( 
        <li key={Math.random()}>
        
          <div className="product_name">
          <a href="" onClick={() => this.props.history.push('/people-profile/'+item._id)}> <div className="friends-img" style={{"max-width": "100%"}}> <img src={profileImg} alt="img" /> 
          
          <p>{item.name}<br/> <span> {skillsname[0].otherdata !='' ?  skillsname[0].otherdata : ''} </span></p>
          
          </div>
          
          </a>
                          
          </div>
      
      </li>
      )                                   
    })  
    return <>{res}</>
  }else{
return( <><li>Not Found</li></>)
  }
  }



  render() {
    
    
   
    const { serviceProviderList} = this.state;
  //console.log('88888888888888888888888888888888888888');
  //console.log(this.state.flags);
    return (
      <>
       <Metatitle title="ServiceProvider | aBlueC" metaDescription="ServiceProvider" ogTitle="ServiceProvider" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
      {/* <Search></Search>  */}
      {/* <Button></Button>     */}
     
      <div className="row">  

       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    

      <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="categories-slide-sec main_store checkout-main container">
        <div className="row justify-content-center"> 
       
        <div className="col-lg-6 col-md-6 col-xs-12">
       
          <div className="checkout-sec mb-3"> 
          <div class="card">
  <div class="card-header">
  Search Service Provider
  </div>
<div className="card-body">
<div className="row">
<div className="col-md-12">
                  <div className="form-group">
                    <label className="label_title">Category</label>
                    

          <select className="form-control select_category" name="category_id" value={this.state.category_id} onChange={this.onChangeCategory}>
                <option defaultValue="">Select category</option> 
                {this.state.categoryOptions}
              </select> 
                    <span className="error-msg">{this.state.catError}</span>
                  </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                    <label className="label_title">Sub Category</label>
          {/* <Multiselect
                         options={(this.state.subcategoryOptionss) ? this.state.subcategoryOptionss:[]} // Options to display in the dropdown
                        //selectedValues={this.state.categoryIds} // Preselected value to persist in dropdown
                        onSelect={this.onSelect} // Function will trigger on select event
                        onRemove={this.onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                      />  */}
            <select className="form-control select_category" name="sub_category_id" value={this.state.sub_category_id} onChange={this.onChangeSubCat}>
                <option defaultValue="">Select Sub category</option> 
                {/* {this.state.subcategoryOptionss} */}

             
{this.state.subcategoryOptionss.length > 0 && this.state.subcategoryOptionss.map((item) =>
  <option key={item._id} value={item._id}>{item.name}</option>
 )}



              </select>
                    <span className="error-msg">{this.state.subCatError}</span>
                  </div>
          
                    </div>

                    </div>
</div>

  </div>
        
          </div>
        
          
        </div> 

        <div className="col-lg-6 col-md-6 col-xs-12">
      <div class="card">
  <div class="card-header">
   Service Provider
  </div>
<div className="card-body">
<ul className="friends-list service-pro-list">

             {/* {this.serviceProviderLists(serviceProviderList)}  */}
             {(this.state.flags) ? this.serviceProviderLists(serviceProviderList) :<> No Service Provider found</>}
             </ul>
          
</div>
</div>
  
        </div>
      
     
        </div>
        

         
         
        </div>
      </div>
    </div>





    
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

 
export default ServiceProvider;