import React, { Component } from 'react';
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
//import Search from "../User/Search"; 
import Button from "./Button";   
import { getCart,addRemoveCart,createOrder } from '../Api'; 
//import Sidebar from '../customer/common/Sidebar';  
import CONSTANT from '../../Constant'; 
import Loader from '../../Loader';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { Redirect,NavLink } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CreditCard from "../../components/demos/CreditCard";
import Metatitle from "../../components/metaTitle";
import $ from "jquery";
const stripePromise = loadStripe(CONSTANT.loadStripeKey);
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const initialState = { 
	status:0, 
	cartData:'',
	sum:0,
	isOpen:false,
	payment_method:'card',
	delivery_method:'delivery',
	redirect:false,
	isOpen1:false,
	isOpen3:false,
	isOpen4:false,
  getListData: [],
  profile: {},
  shipAddressError:'',
  complete_address:'',
  storeAddress:''
} 

class Checkout extends Component {
  constructor(props) {
    super(props);
	const token = localStorage.getItem("token");        
	let loggedIn = true;
	if(token==null){
		loggedIn = false;
	}
    this.state = { 
		loggedIn, 
		status:0, 
		cartData:'',
		sum:0,
		isOpen:false,
		payment_method:'card',
		delivery_method:'delivery',
		redirect:false,
		isOpen1:false,
		isOpen3:false,
		isOpen4:false,
		getListData: [],
	}; 
    this.onChangeform = this.onChangeform.bind(this);
    this.submitForm = this.submitForm.bind(this);
    
  } 


  onChangeform(e){
	  
	// console.log("MMMMMMM")  
	// console.log(e.target.name)  
	// console.log(e.target.value)  
	// console.log("MMMMMMM")  
	  
	var radiosCheck = document.getElementById('test2').checked;
	if(e.target.id == 'test2'){
		document.getElementById('addCardM').style.display = 'none' 
		document.getElementById('demoL').style.display = 'none' 
	} else if(e.target.id == 'test' && radiosCheck == true){
		document.getElementById('addCardM').style.display = 'none' 
		document.getElementById('demoL').style.display = 'none'
	} else if(e.target.id == 'tests' && radiosCheck == true){
		document.getElementById('addCardM').style.display = 'none' 
		document.getElementById('demoL').style.display = 'none'
	} else if(e.target.id == 'test3' && radiosCheck == true){
		document.getElementById('addCardM').style.display = 'none' 
		document.getElementById('demoL').style.display = 'none'	
		
	} else {
		document.getElementById('addCardM').style.display = 'block'
		document.getElementById('demoL').style.display = 'block'
	}
	this.setState({[e.target.name] : e.target.value})
  }
  
  handleSelects = async (address, placeId) => {
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const [place] = await geocodeByPlaceId(placeId);
		const { long_name: streetNumber = '' } = place.address_components.find(c => c.types.includes('street_number')) || {};
		const { long_name: routesName = '' } = place.address_components.find(c => c.types.includes('route')) || {};
		const { long_name: postalCode = '' } = place.address_components.find(c => c.types.includes('postal_code')) || {};
		const { long_name: cityName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
		const { long_name: cityNameNeighbour = '' } = place.address_components.find(c => c.types.includes('neighborhood')) || {};
		const { long_name: cityNameLocality = '' } = place.address_components.find(c => c.types.includes('locality')) || {};
		const { long_name: stateName = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: countryName = '' } = place.address_components.find(c => c.types.includes('country')) || {};
		var cityNames = '';
		if (cityNameNeighbour) cityNames = cityNameNeighbour; else if (cityNameLocality) cityNames = cityNameLocality; else { cityNames = cityName; }
		this.setState({ storeAddress: address, storeLat: latLng.lat, storeLong: latLng.lng, state: stateName, city: cityNames, zip: postalCode, country: countryName, street: streetNumber + ' ' + routesName,flat:'' })
	};

  
	handleChanges = (address) => {
		this.setState({ storeAddress: address });
	};

  checkout1(){
	  
	const { getListData  } = this.state;
	var radiosCheck = document.getElementById('test2').checked;
  if(this.state.street===""){
   // alert("Please provide shipping address.")
   var shipAddressError = 'Please provide shipping address.';
   this.setState({shipAddressError});
  }else{
  
	if(getListData.length > 0  || radiosCheck == true){
		
		let user_id = localStorage.getItem("loggedInUserId");
		let session_token = localStorage.getItem("token");

		this.setState({
		  loading:true,
		})

		var {flat,street,city,state,zip,country,instruction,delivery_method,payment_method} = this.state;  
		if(!flat){ flat = localStorage.getItem("flat"); }
		if(!street){ street = localStorage.getItem("street"); }
		if(!city){ city = localStorage.getItem("city"); }
		if(!state){ state = localStorage.getItem("state"); }
		if(!zip){ zip = localStorage.getItem("zip"); }
		if(!country){ country = localStorage.getItem("country"); }

		var lat = "28.541397";
		var long = "77.397034";
		var complete_address = flat+','+street+','+city+','+state+','+zip+','+country;

		//var payment_method = 'cash'

		createOrder(session_token,user_id,flat,street,city,state,zip,country,lat,long,complete_address,instruction,delivery_method, payment_method).then(data => {  
		   this.setState({
			 loading:false,
		   })
		  this.showModal1();
		}); 
		
	} else {
		
		this.setState({
		 loading:false,
		 isOpen3: true
	    })
		
	}   
  }
  }
  
  validate = () =>{

    let flatError=''; 
    let streetError = '';
    let cityError = ''; 
    let stateError = '';
    let zipError = '';
    let countryError='';
    let deliveryError='';
    let storeAddressError
    
    if(!this.state.flat){
      flatError = "Please enter flat no.";
    }

    if(!this.state.street){
      streetError = "Please enter street.";
    }

    if(!this.state.city){
      cityError = "Please enter city.";
    }

    if(!this.state.state){
      stateError = "Please enter state.";
    }

    if(!this.state.zip){
      zipError = "Please enter zip code.";
    }
    
    if(!this.state.country){
      countryError = "Please enter country.";
    }
    if(!this.state.delivery_method){
      deliveryError = "Please select delivery options.";
    }
    if(!this.state.storeAddress){
      storeAddressError = "Please enter address.";
    }
    
    if(streetError || cityError || stateError || zipError || countryError || deliveryError || storeAddressError){
        this.setState({streetError,cityError,stateError,zipError,countryError,deliveryError,storeAddressError});
        return false;
    } else {
        this.setState({streetError,cityError,stateError,zipError,countryError,deliveryError,storeAddressError});
        return true;
    }               
}   

  
submitForm(e){   
  e.preventDefault();
  const isValid = this.validate();
  if(isValid){	  
		this.setState({  
		  loading:true
		})		
		var {flat,street,city,state,zip,country,shipUserId} = this.state;  
        if(!flat){flat = localStorage.getItem("flat");}
        if(!street){ street = localStorage.getItem("street"); }
        if(!city){ city = localStorage.getItem("city"); }
        if(!state){ state = localStorage.getItem("state"); }
        if(!zip){ zip = localStorage.getItem("zip"); }
        if(!country){ country = localStorage.getItem("country"); }
        localStorage.setItem("shipUserId",localStorage.getItem("loggedInUserId"));
        localStorage.setItem("flat",flat);
        localStorage.setItem("street",street);  
        localStorage.setItem("city",city);  
        localStorage.setItem("state",state);  
        localStorage.setItem("zip",zip);  
        localStorage.setItem("country",country);		
		this.hideModal();
      }
      this.setState({  
        loading:false,
      })      
      this.hideModal1();
    }


  remove(product_id){
    let user_id = localStorage.getItem("loggedInUserId");
    let session_token = localStorage.getItem("token");  
    let totalQty = 0;
    this.setState({
      loading:true
    })
    addRemoveCart(user_id,session_token,product_id,totalQty).then(data => {    
      this.getCart();
   }); 
  }


  showModal() {
    this.setState({
      isOpen:true,
    })
   };
 
   hideModal() {
     this.setState({
       isOpen:false,
     })
   };


   showModal1() {
    this.setState({
      isOpen1:true,
    })
   };
 
   hideModal1() {
     this.setState({
       isOpen1:false,
     })
   };


   showPopup(){
    this.setState({
      isOpen3:true,
    })
  }

  hidePopup(){
    this.setState({
      isOpen3:false,
    })
  }    

	showConfirmModal = e => {
    this.setState({
      isOpen4:true
  }) 
  }

  
	hideConfirmModal = e => {
    this.setState({
      isOpen4:false
  }) 
  }
   
  addCardModal = e =>{

    this.setState({
      isOpen3:true
  }) 
  }

  addToCart(product_id,type){
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");  
    var totalQty = 1;
    if(this.state.cartItems){
      this.setState({loading:true})
      var productData = this.state.cartItems.map((cart,index) => {   
    
            if(type==="minus"){
              totalQty = cart.qty-1;
            } else {
            totalQty = cart.qty+1;
            }

            console.log(totalQty);
            return; 
      }) 
    }  
    
    addRemoveCart(user_id,session_token,product_id,totalQty).then(data => {  
       //this.getCart();
       document.location.href = "/checkout";
    }); 
  }

  getCart(){
    this.setState({
      loading:true,
    })
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token"); 

	if(session_token == undefined || user_id == undefined){ 
		return this.props.history.push('/login')
	}
	
    getCart(user_id,session_token).then(data => {  
      if(data.response_data.cart_products.items.length > 0 ){
        this.setState({
          cartItems:data.response_data.cart_products.items, 
        })
        this.props.changeCart(data.response_data.cart_products.items,'CART_UPDATE');
        var sum = 0;
        let cartData = data.response_data.cart_products.items.map((cart,index) => {  
         var price = 0;
         if(cart.product.product_sale_price){
           price = cart.product.product_sale_price;
         } else {
          price = cart.product.product_price;
         }

         if(cart.product.images[0]){
          var imgs = CONSTANT.LIVE_BASE_URL+ 'images/store/'+ cart.product.images[0];
        } else {
          var imgs = process.env.PUBLIC_URL + "/assets/images/placeholder.png";
        }

        var imgs1 = process.env.PUBLIC_URL + "Remove";
         
        //var total = price * cart.qty;
        
        //sum  = sum+total;
        if(Number.isInteger(price)){
          var total = (price * cart.qty);
          sum  = sum+total;
          }else{
              var total = (price * cart.qty).toFixed(2);
          sum  = parseFloat(sum+total).toFixed(2);
          }
         
        return (
            <tr key={Math.random()}>
            <td>
              <div className="product_name">
                <p>{cart.product.product_title}</p>                
              </div>
            </td>
            
            <td>
              <div className="input-group product_add">
                <input type="button" className="button-minus" data-field="quantity" defaultValue="-" onClick={this.decreament.bind(this,cart.product._id)}/>
                <input type="number" step={1} name="quantity" className="quantity-field" value={cart.qty} readOnly/>
                <input type="button" className="button-plus" data-field="quantity" defaultValue="+" onClick={this.increment.bind(this,cart.product._id)}/>
              </div>
            </td>
            <td><span>{CONSTANT.CURRENCY}{price}</span> </td>
            <td><span>{CONSTANT.CURRENCY}{total}</span></td>
            <td><a href="#/"  onClick={this.remove.bind(this,cart.product._id)}> <button type="button" className="btn btn-danger">{imgs1}</button></a></td>
          </tr>
            ); 
          })
          this.setState({
            cartData:cartData,
            loading:false,
            sum:sum,
          })
       }else{
        document.location.href = "/";
       } 
    }); 
  }


  increment(product_id){ 
    this.addToCart(product_id,'add');  
  }

   
   decreament(product_id){ 
    this.addToCart(product_id,'minus');
   }   

  async componentDidMount() {

    let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:localStorage.getItem("token")});
    if(getProfile.data.response_status === 1){
      if(getProfile.data.response_data.profile.address == undefined){
        getProfile.data.response_data.profile.address = {
          "flatno" : "",
          "street" : "",
          "city" : "",
          "state" : "",
          "zip" : "",
          "country" : "",
          "complete_address" : ""
        }
      }
      this.setState({profile:getProfile.data.response_data.profile, "response_status": getProfile.data.response_status,fname:getProfile.data.response_data.profile.name,femail:getProfile.data.response_data.profile.email,fnumber:getProfile.data.response_data.profile.mobile,dname:getProfile.data.response_data.profile.display_name, flat:getProfile.data.response_data.profile.address.flatno, street:getProfile.data.response_data.profile.address.street, city:getProfile.data.response_data.profile.address.city, state:getProfile.data.response_data.profile.address.state, zip:getProfile.data.response_data.profile.address.zip, country:getProfile.data.response_data.profile.address.country, complete_address:getProfile.data.response_data.profile.address.complete_address, address:getProfile.data.response_data.profile.address.complete_address});
    }  
this.getCart();
    this.setState({loading: true});	
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/listCards', formData, config);
		
		if(response.data.response_data != null){
			this.setState({default_card: response.data.response_data.default_card, getListData: response.data.response_data.data, loading: false});
		} else {
			this.setState({loading: false});
		}
  }

  async setMakeDefault(e){
		
		this.setState({loading: true});		
		
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: e}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/makecarddefault', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.setState({loading: false});		
		}
		
  }
  
  async deleteCard(e){
		this.setState({loading: true});	
		var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId"),card_id: this.state.card_id}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		let response = await axios.post(CONSTANT.STABASE_URL+'store/deleteCard', formData, config);

		if(response.data.response_status == 1){
			this.componentDidMount();
			this.setState({loading: false,isOpen4:false});		
		}		
	}


	async confirmCard(e){		
		this.setState({loader: true, card_id: e,isOpen4:true});		
		//this.setState({loader: false,isOpen4:false});
	
	}

  render() {
	  
	  //console.log(this.state.street);
	  
	if(!this.state.loggedIn){ 
		return <Redirect to="/login" />		
	}
   
    var imgs2 = process.env.PUBLIC_URL + "/assets/images/svg/pin.svg";

    if(this.state.redirect){
			return <Redirect to="/customer/thank-you"/> 

		}
    const { getListData, default_card} = this.state;
	
	// console.log("checkout")
	// console.log(this.state.complete_address)
	// console.log("checkout")

	
    return (
      <>
       <Metatitle title="Checkout | aBlueC" metaDescription="Product Checkout" ogTitle="Checkout" ogImage=""></Metatitle>
      <Header/> 
      <div className="container-fluid bdr-top innerheight"> 
      {/* <Search></Search>  */}
      {/* <Button></Button>     */}
     
      <div className="row">  

       {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }

    

      <div className="col-lg-12 col-md-12 col-xs-12">
        <div className="categories-slide-sec main_store checkout-main container">
        <div className="row">
          <div className="col-12"><div className="header_catehory"><h2 className="jobs_heading mb-2 ">Checkout</h2> </div></div>
        <div className="col-lg-8 col-md-8 col-xs-8">
          <div className="checkout-sec">          
          <div className="card">
  <div className="card-header">
  Your Order
  </div>
<div className="card-body">
<table className="table table-borderless checkout_table">
        
            <thead><tr className="pd_bot"> <td> Product </td>   <td> Quantity </td> <td> Price </td> <td> Total </td> <td> &nbsp; </td></tr>  </thead>
             <tbody>
               {this.state.cartData}
              <tr className="pd_top">
              <td colspan="2" className="text-right"> &nbsp;  </td>
                <td>
                  <div className="total_wrp">
                    {/* <span className="taxes">Taxes IVA:</span> */}
                    <span className="total_rs">Total:</span>
                  </div>
                </td>
                <td>
                  <div className="total_wrp">
                  {/* <span className="taxes">$2.0</span> */}
                  <span className="total_rs">{CONSTANT.CURRENCY}{this.state.sum}</span></div></td>
               
              </tr>
            </tbody>
          </table>
</div>

  </div>
  </div>

  <div className="row">
  <div className="col-md-6 my-4"> 
          <div className="card"><div className="card-header">Delivery Address</div>
          <div className="card-body">
          <div className="address_map clearfix mt-3">
              <div className="map_icon"><img src={imgs2} /></div>
              <div className="address_contact">
              
              {

(this.state.complete_address != '' ) ? (
  <>
  <span>{(this.state.street )},{this.state.city},{this.state.state},{this.state.zip},{this.state.country}</span>
    <span><a href="#/" onClick={this.showModal.bind(this)}>Change Address</a></span>
  </>
  ):

              (this.state.street && this.state.city && this.state.state && this.state.zip && this.state.country) || localStorage.getItem("street") ? (
              <>
                
                {(localStorage.getItem("shipUserId") == localStorage.getItem("loggedInUserId")) ?
              <><span>{(this.state.street) ? ( this.state.street ) : ( localStorage.getItem("street") )}, {(this.state.city) ? ( this.state.city ) : ( localStorage.getItem("city") )},{(this.state.state) ? ( this.state.state ) : ( localStorage.getItem("state"))},{(this.state.zip) ? ( this.state.zip ) : ( localStorage.getItem("zip") )},{(this.state.country) ? ( this.state.country ) : ( localStorage.getItem("country") )}</span>
              <span><a href="#/" onClick={this.showModal.bind(this)}>Change Address</a></span></>
              : <><button type="button" className="checkout_address btn btn-secondary" onClick={this.showModal.bind(this)}>Add Address</button> </>
              
              }
                
                
              </>
              ) : (
              <>  
              <button type="button" className="checkout_address btn btn-secondary" onClick={this.showModal.bind(this)}>Add Address</button>
              </>
              )
              } 
              
              </div>
              <span className="error-msg">{this.state.shipAddressError}</span>
            </div>
            <div className="discription">
              <div className="form-group">
                <label className="payment">Instructions</label>
                <textarea className="form-control" placeholder="Comments..." name="instruction" onChange={this.onChangeform}/>
              </div>
            </div>
          </div>
          </div>
          
          </div>





          <div className="col-md-6 my-4"> 
          <div className="card"><div className="card-header">Delivery Options</div>
          <div className="card-body">
          <div className="delivery_method">              
              <div className="card_wrapper clearfix">
                <div className="right_radio_bt card_checkout">
                  <input type="radio" id="test" name="delivery_method" value="shipping" onChange={this.onChangeform}/>
                  <label htmlFor="test">Shipping</label>
                </div>
                <div className="right_radio_bt card_checkout">
                  <input type="radio" id="tests" name="delivery_method" defaultChecked value="delivery" onChange={this.onChangeform}/>
                  <label htmlFor="tests">Delivery</label>
                </div>
                <div className="right_radio_bt card_checkout">
                  <input type="radio" id="test3" name="delivery_method" value="pickup" onChange={this.onChangeform}/>
                  <label htmlFor="test3">Pick Up</label>
                </div>
                
              </div>
              <span className="error-msg">{this.state.deliveryError}</span>
            </div>

            <div className="payment_method">
              <label className="card_label mt-4">Your Payments Method</label>
              <div className="card_wrapper clearfix">
                <div className="right_radio_bt card_checkout">
                  <input type="radio" id="test1" name="payment_method" defaultChecked value="card" onChange={this.onChangeform}/>
                  <label htmlFor="test1">Card</label>
                </div>
                <div className="right_radio_bt card_checkout">
                  <input type="radio" id="test2" name="payment_method" value="cash" onChange={this.onChangeform}/>
                  <label htmlFor="test2">Cash</label>
                </div>
              </div>
              <div className="checkout_bt mt-4 mb-0">         
              <button type="button" className="btn btn-secondary" id="addCardM" onClick={() => this.addCardModal({"order_data":2222})} >AddCard</button>                          
            </div>

            <div className="Demo" id="demoL">
										{(this.state.loading)? (<Loader />): ""}
										{(getListData.length != 0) ? 
												<>
													<div className="full_wrp">
															<ul className="added-card-list">
															{getListData.length > 0 && getListData.map((item) =>	
																<li className="list-group-item People">{console.log(item)}
																	{(item.id.toString() == default_card.toString())?
																	(<>
																	<span className="brand_img"> 
                                  <input type="radio" name="color" value="orange" checked />  
																	
                                  {(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
                                  
																	</span>
																	
																	</>)
																	:
																	(<><span className="brand_img"> <input type="radio" name="color" value="orange" onClick={() => this.setMakeDefault(item.id)} />
                                  {(item.brand == 'Visa') ? <img src="../assets/images/visa.png"/> :(item.brand == 'Mastercard') ? <img src="../assets/images/master.png"/>  :(item.brand == 'American Express') ? <img src="../assets/images/american.png"/> :''}
                                  </span>
																	</>)
																	}
																	
                                  
																	<span className="removecard" onClick={() => this.confirmCard(item.id)}>X</span>
																	<div className="cardnumber">
																		<label>Card : {'*************'+item.last4} <span className="cardlistStyle">{item.brand}</span> <span> Expires on :  {item.exp_month} / {item.exp_year} </span></label>
																																																	
																	</div>
																	{/*(item.id.toString() == default_card.toString() && localStorage.getItem("store_id") != undefined && localStorage.getItem("store_id") != "")?
																	(<><div className="paynow"><button type="button" onClick={() => this.setMakeDefaultPayment(item.id)}>Pay Now</button></div></>)
																	:
																	(<></>)
                                */}
																</li>
															)}
														</ul>
													</div>
													<br/>
												</>
												: 
												<>
												  {/* <p>  No Data Found  </p>*/ }
												</> 
											}
									</div>
            </div>
            </div>
          </div>
          
          </div> 




          
  </div>

          </div>

          <div className="col-lg-4 col-md-4 col-xs-12">
          <div className="delevery_section">
          <div className="row">
          <div className="col-md-12"> 
          <div className="card"><div className="card-header">Order Summaary</div>
          <div className="card-body summaary-sec">
          <div className="row">
                                <div className="col-md-12">
                                    <big>Items</big>
                                    <div className="float-right"><span>{CONSTANT.CURRENCY}</span><span>{this.state.sum}</span></div>
                                </div>
                                <div className="col-md-12">
                                    <big>Tax</big>
                                    <div className="float-right"><span>$</span><span>0</span></div>
                                </div>
                                <div className="col-md-12">
                                    <big>Shipping &amp; Handling </big>
                                    <div className="float-right"><span>$</span><span>0</span></div>
                                    <hr />
                                </div>
                                <div className="col-md-12">
                                    <strong>Order Total</strong>
                                    <div className="float-right"><span>{CONSTANT.CURRENCY}{this.state.sum}</span></div>
                                    
                                </div>
                                
                               
                                
                        </div>
          </div>
          </div>
          </div>  
          
          </div>
          

           

          
            
            
            
            <div className="checkout_bt">
              <div className="row">
                <div className="col-md-12">
                  <button type="button" className="btn btn-success blue-btn btn-block" style={{"backgroundColor": "#1da1f2","borderColor": "#1da1f2"}} onClick={this.checkout1.bind(this)}>Proceed to Checkout</button>
                </div>
              </div>
            </div>
          </div></div>
          </div>
        </div>
      </div>
    </div>



    <Modal id="modal_body_clone_store" show={this.state.isOpen1} onHide={this.hideModal1.bind(this)} backdrop = "static"> 
					<Modal.Body>
						<div className="modal-content modal_bg">
						  <div className="modal-header head_thanks">
							<div className="row">
							  <div className="col-md-12">
								<div className="tick_img">
								  <img src="/assets/images/svg/tick.svg" alt="" />
								</div>
							  </div>
							</div>
						  </div>
						  <div className="content_body">
							<div className="row">
							  <div className="col-md-12">
								<h2 className="order_head">Thank You for your Order</h2>
							  </div>
							</div>
							<div className="row">
							  <div className="col-md-12 text-center">
								<button type="button" className="save_bt mr-4" onClick={() => this.props.history.push("/")}>Shopping</button>
								<button type="button" className="save_bt" onClick={() => this.props.history.push("/custumer/order")}>Orders</button>
							  </div>
							</div>
						  </div>
					  </div>
					</Modal.Body>
				</Modal>



    <Modal show={this.state.isOpen} onHide={this.hideModal.bind(this)} backdrop = "static"> 
    <div className="modal-header card-header"><h5 className="modal-title">Add Delivery Address</h5><button type="button" className="close store post-close" onClick={this.hideModal.bind(this)} style={{"position":"relative"}}><span aria-hidden="true">×</span></button> </div>
           
    
        <form onSubmit={this.submitForm} id="feedform">
        <Modal.Body>
          <div className="row">

          <div className="col-md-12">
              <div className="row">
             
              <div className="form-group col-md-12">
              <label className="product_label">Address</label>
														<PlacesAutocomplete
															value={this.state.storeAddress}
															onChange={this.handleChanges}
															onSelect={this.handleSelects}
														>
															{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
																<div>
																	<input
																		{...getInputProps({
																			placeholder: 'Search Address ...',
																			className: 'form-control search_input',
																		})}
																	/>
																	<div className="autocomplete-dropdown-container">
																		{loading && <div>Loading...</div>}
																		{suggestions.map(suggestion => {
																			const className = suggestion.active
																				? 'suggestion-item--active'
																				: 'suggestion-item';
																			// inline style for demonstration purpose
																			const style = suggestion.active
																				? { backgroundColor: '#fafafa', cursor: 'pointer' }
																				: { backgroundColor: '#ffffff', cursor: 'pointer' };
																			return (
																				<div
																					{...getSuggestionItemProps(suggestion, {
																						className,
																						style,
																					})}
																				>
																					<span className="list-item-description">{suggestion.description}</span>
																				</div>
																			);
																		})}
																	</div>
																</div>
															)}
														</PlacesAutocomplete>
                            
                            <span className="error-msg">{this.state.storeAddressError}</span>
													</div>



               <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Flat No</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="flat" name="flat" value={this.state.flat} onChange={this.onChangeform} placeholder="Flat No"/>
                    <span className="error-msg">{this.state.flatError}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Street</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="street" name="street" value={this.state.street} onChange={this.onChangeform} placeholder="Street"/>
                    <span className="error-msg">{this.state.streetError}</span>
                    </div>
                  </div>
                </div>
                 <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">City</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="city" name="city" value={this.state.city} onChange={this.onChangeform} placeholder="City"/>
                    <span className="error-msg">{this.state.cityError}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label">State</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="state" name="state" value={this.state.state} onChange={this.onChangeform} placeholder="State"/>
                    <span className="error-msg">{this.state.stateError}</span>
                    </div>
                  </div>
                </div>
                 <div className="col-md-6">
                  <div className="form-group">
                    <label className="product_label">Zip</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="zip" name="zip" value={this.state.zip} onChange={this.onChangeform} placeholder="zip"/>
                    <span className="error-msg">{this.state.zipError}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="product_label">Country</label>
                    <div className="select_wrp">
                    <input type="text" className="form-control" id="country" name="country" value={this.state.country} onChange={this.onChangeform} placeholder="Country"/>
                    <span className="error-msg">{this.state.countryError}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          
          {/* <div className="post_bt_public request_bt add_now_product">
            <ul>
              <li>
                <button type="submit" className="save_bt">Add Address</button>
              </li>
            </ul>
          </div> */}
         
         
       
    </Modal.Body>
    <div className="modal-footer"> <button type="submit" className="save_bt">Add Addres</button> </div>
    </form>
    </Modal>


    <Modal id="modal_body_clone_store" show={this.state.isOpen3} onHide={this.hidePopup.bind(this)} backdrop = "static"> 
					<Modal.Body>
					 <button type="button" className="close store post-close" onClick={this.hidePopup.bind(this)} style={{"position":"relative"}}><span aria-hidden="true">×</span></button>
    <div className="manage-add-products"> 
        <div className="modal-body">
        <div className="card-header">    Add New card  </div> 
        <Elements stripe={stripePromise}>
									<CreditCard />
								</Elements>
        </div>
      </div>


					</Modal.Body>
				</Modal>


        <Modal id="modal_body_delete_card" show={this.state.isOpen4} onHide={this.hideConfirmModal.bind(this)} backdrop = "static"> 
        <Modal.Body>
        <button type="button" className="close store post-close" onClick={this.hideConfirmModal.bind(this)}><span aria-hidden="true">×</span></button>
					
						  <div className="modal-content confirmpopup">
							<div className="modal-body sure_delete_wrp">
							  <h5 id="delete-title">Are you sure you want to delete it?</h5>
							  <div className="post_bt_public cancel_order">
								<ul>
								  <li>
									<button type="button" className="save_bt" onClick={() => this.deleteCard(1)}>YES</button>
								  </li>
								  <li>
									<button type="button" className="back_bt reject_bt" onClick={this.hideConfirmModal.bind(this)}>NO</button>
								  </li>
								</ul>  
							  </div>
							</div>
						  </div>
						


				
          </Modal.Body>
				</Modal>
        </div> 
    <Footer/>
   
   
  </>
    );
  }
} 
 

const mapStateToProps = (state) => {
  return { 
    store_id:state.CURRENT_STORE
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCart: (name,action) => { dispatch({ type: action, payload: name }) }, 
  }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(Checkout);