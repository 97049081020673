import axios from "axios";
import Constant from '../Constant';
import {
    GET_ERRORS,
    SET_MESSAGE,
    GET_ALLSTORES,
    GET_STORES,
    GET_STORE,
    GET_CHECKSTORE,
    GET_USTORELIST,
    STORE_LOADING
    } from "./types";
	
export const getAllStores = (storeData) => (dispatch) => {
	dispatch(setLoading());
	axios.post(Constant.STABASE_URL+"store/getStoresNearByWeb",storeData,{headers: {'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'}}).then((res) => {
		if(res.data.response_data.near_buy_stores.length > 0){
			var filter = res.data.response_data.near_buy_stores
		} else {
			var filter = []
		}
		dispatch({
			type: GET_ALLSTORES,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
			type: GET_ERRORS,
			payload: err.response.data
		})
		}
	});
};
	
export const getStoresCheck = (storeData) => (dispatch) => {
   dispatch(setLoading());
    axios.post(Constant.STABASE_URL+"store/checkStore",storeData,{headers: {'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'}}).then((res) => {
		var filter = res.data
		dispatch({
			type: GET_CHECKSTORE,
			payload: filter
		});
	}).catch(err => {
		if(err.response.data=="Unauthorized"){
			window.location.href = "/login";
		}else{
			dispatch({
			type: GET_ERRORS,
			payload: err.response.data
		})
		}
	});
};


export const getStores = (sendData) => (dispatch) => {
   dispatch(setLoading());
	axios.post(Constant.STABASE_URL+"store/getStores",sendData,{headers: { "x-access-token": Constant.HEADER}}).then((res) => {            
			if(res.data.response_status == 1){
				var filter = res.data.response_data.store_list
			} else {
				var filter = []				
			}
			dispatch({
                type: GET_USTORELIST,
                payload: filter
            });
        })
        .catch(err => {
            if(err.response.data=="Unauthorized"){
                window.location.href = "/login";
            }else{
                dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            }
        });
};

// export const getStore = (storeData, history) => dispatch => {
//     dispatch(setLoading());
//  axios
//         .post(Constant.STABASE_URL+"/store/getStore", storeData)
//         .then((res) =>{
//            // history.push('/store')
//            // dispatch(setMessage(res.data.message))
//         var filter = res.data
//            var i;
//            var j;
//            for(i in filter){
//             for(j in filter[i].coordinates){
//                 filter.storeLong =filter[i].coordinates[0];
//                 filter.storeLat =filter[i].coordinates[1];
//                j++
//                }
//            }
//             dispatch({
//                 type: GET_STORE,
//                 payload: filter,
//             })
//         }).catch(err => {
//             if(err.response.data=="Unauthorized"){
//                 window.location.href = "/login";
//             }else{
//              dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             })
//             }
//         });
// }
export const getStore = (storeData, history) => dispatch => {
    dispatch(setLoading());
    axios
        .post(Constant.STABASE_URL+"/store/getStore", storeData)
        .then((res) => {
			// history.push('/store')
			// dispatch(setMessage(res.data.message))
			var filter = res.data          
            dispatch({
                type: GET_STORE,
                payload: filter,
            })
        }).catch(err => {
            if(err.response.data=="Unauthorized"){
                window.location.href = "/login";
            }else{
                dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
            }
        });
}

export const addStore = (storeData, history) => dispatch => {
    const form = Object.keys(storeData).reduce((f, k) => {
        f.append(k, storeData[k]);
        return f;
      }, new FormData());
    axios
        .post(Constant.STABASE_URL+"/store/addStore", form)
        .then((res) =>{
            history.push('/stores')
            dispatch(setMessage(res.data))
            // dispatch({
            //     type: STORE_ADD,
            //     payload: res,
            // })
        }).catch(err => {
            if(err.response.data=="Unauthorized"){
                window.location.href = "/login";
            }else{
                dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            }
        });
}

export const updateStore = (storeData, history) => dispatch => {
    const form = Object.keys(storeData).reduce((f, k) => {
        f.append(k, storeData[k]);
        return f;
      }, new FormData());
    axios
        .post(Constant.STABASE_URL+"/store/updateStore", form)
        .then((res) =>{
            history.push('/stores')
            // window.location = '/stores';
            dispatch(setMessage(res.data))
            // dispatch({
            //     type: DRESS_UPDATE,
            //     payload: res,
            // })
        }).catch(err => {
            if(err.response.data=="Unauthorized"){
                window.location.href = "/login";
            }else{
                dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            }
        });
}

export const setLoading = () => {
    return {
        type: STORE_LOADING
    };
};

export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        payload: msg
    };
};

export const clearErrors = () => {
    return {
        type: GET_ERRORS,
        payload: {}
    };
}
