import {
    GET_ERRORS,
    SET_MESSAGE,
    GET_ALLSTORES,
    GET_STORES,
    GET_STORE,
    GET_CHECKSTORE,
    GET_USTORELIST,
    STORE_LOADING
   
} from "../actions/types";

const initialState = {
    statec: {},
    getStoreData: {},
    getAllStoreData: {},
    getStoreListData: {},
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {		
		case GET_ALLSTORES:
            return {
                ...state,
                getAllStoreData: action.payload,
                loading: false
                
            };
		case GET_USTORELIST:
            return {
                ...state,
                getStoreListData: action.payload,
                loading: false
                
            };
		case GET_CHECKSTORE:
            return {
                ...state,
                getStoreData: action.payload,
                loading: false
                
            };
		case STORE_LOADING:
			return {
				...state,
				loading: true
			};              
        default:
        return state;
    }
}
