import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import Metatitle from "../../components/metaTitle";
import Button from "./Button"; 
//import InnerSearch from "../User/InnerSearch";
import React, { Component, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import {getAllTypeofProduct } from '../Api'; 
import SidebarStore from './SidebarStore'; 
import CONSTANT from '../../Constant';
import { NavLink, } from "react-router-dom";
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {clearErrors} from "../../actions/storeActions";
import Loader from '../../Loader';
import ReactStars from "react-rating-stars-component";
import _ from "lodash";
import $ from 'jquery'; 



const initialState = {  
	status:0,
	categories:'',
	is_selected_image:true, 
	is_selected:false,
	selected_img:'',
	is_featured:0,
	taxable:1,
	shipping:1,
	products:'',
	catButtonData:'',
	isOpen1:false,
	isOpen3:false,
	sku1:'',
	product_name_disabled: false,
	sku_disabled:false,
	description_disabled:false,
	category_disabled:false,
	total: 0,
	per_page: 12,
	current_page: 1,
	featuredProduct:[],
	popularProduct:[],
	latestProduct:[],
	storeInfo:[],
	monday_start_time:'',
    monday_end_time:'',
    tuesday_start_time:'',
    tuesday_end_time:'',
    wednesday_start_time:'',
    wednesday_end_time:'',
    thursday_start_time:'',
    thursday_end_time:'',
    friday_start_time:'',
    friday_end_time:'',
    saturday_start_time:'',
    saturday_end_time:'',
    sunday_start_time:'',
	sunday_end_time:'',
	currentLatitude:'',
	currentLongitude:'',
	imageBannerURL:''


  }
  
  function mergeByProperty(arr1, arr2, prop) {
	    _.each(arr2, function(arr2obj) {
			var arr1obj = _.find(arr1, function(arr1obj) {
			return arr1obj[prop] === arr2obj[prop];
			});
			arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
		});
	}

	async function requestPosition() {

		// additionally supplying options for fine tuning, if you want to
		var options = {
		  enableHighAccuracy: true,
		  timeout:    5000,   // time in millis when error callback will be invoked
		  maximumAge: 0,      // max cached age of gps data, also in millis
		};
	  
		return new Promise(function(resolve, reject) {
		  navigator.geolocation.getCurrentPosition(
			position => { resolve({'currentLat':position.coords.latitude,'currentLong':position.coords.longitude}); }, 
			err => { reject (err); }, 
			options);
		});
	  }

	

class storeProductList extends Component {
		constructor(props) {
			super(props); 
			this.state = initialState;			
			let {name} = this.props.match.params
			localStorage.setItem("store_name", name)
		}
	
	 pagination(page){
	  // this.setState({
	  //   current_page:page
	  // })
	
	  this.setState({
		current_page:page
	  }, () => {
		let cat_id = this.state.current_cat;
		let user_id = localStorage.getItem("loggedInUserId");
		let session_token = localStorage.getItem("token"); 
		let store_id = localStorage.getItem("store_id");  
		//this.productList(user_id,session_token,store_id,cat_id); 
	
		//this.productList();
	  }) 
	 }

	  tConv24(time24) {
		var ts = time24;
		var H = +ts.substr(0, 2);
		var h = (H % 12) || 12;
		h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
		var ampm = H < 12 ? " AM" : " PM";
		ts = h + ts.substr(2, 3) + ampm;
		return ts;
	  };


 
	setProduct = (product) => { 
		var store_name = localStorage.getItem("store_name"); 
		localStorage.setItem("delivery_time",product.delivery_time);
		localStorage.setItem("images",product.images[0]);
		localStorage.setItem("product_image",product.product_image);
        localStorage.setItem("is_featured",product.is_featured);
        localStorage.setItem("is_shipping_available",product.is_shipping_available);
        localStorage.setItem("product_category",product.product_category);
        localStorage.setItem("product_description",product.product_description);
        localStorage.setItem("product_price",product.product_price);
        localStorage.setItem("product_quantity",product.product_quantity);
        localStorage.setItem("product_sale_price",product.product_sale_price);
        localStorage.setItem("product_title",product.product_title);
        localStorage.setItem("sku",product.sku);
        localStorage.setItem("slug",product.slug);
        localStorage.setItem("_id",product._id);
        localStorage.setItem("user_id",product.user_id);
        localStorage.setItem("unit",product.unit);
        localStorage.setItem("store_id",product.store_id);
		document.location.href = "/store/"+store_name+"/"+product._id;
	}
	
	async componentDidMount() { 
		
		//let position = await requestPosition();
		//console.log(position);
		// this.setState({
		// 	currentLatitude:position.currentLat,currentLongitude:position.currentLong
		//   }) 
		const onSuccess = (position) => {
			this.setState({"currentLatitude":position.coords.latitude,"currentLongitude":position.coords.longitude})
		};		
		const onError = (error) => {
			console.log(error.code+' '+error.message);
		}
		navigator.geolocation.getCurrentPosition(onSuccess, onError);

		
		this.setState({
			loading:true
		  }) 
			const userData = {
				"session_token": localStorage.getItem("token"),
				"user_id": localStorage.getItem("loggedInUserId"),
				'store_name':localStorage.getItem("store_name")
			};   
			
			//var formData = {"session_token":localStorage.getItem("token"),"user_id":localStorage.getItem("loggedInUserId")}
			
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
				}
			}
				
			 await axios.post(CONSTANT.STABASE_URL +'web/store/getStoreByName',userData, config).then(res => {
					
				if (res.data.response_status !== 0) {//console.log(res.data.response_data.store_data);
					//var filterData = _.filter(res.data.response_data.store_lists, {"store_name": localStorage.getItem("store_name")})
					// console.log("MMMMMMM")
					// console.log(filterData);
					// console.log("MMMMMMM")
				if(res.data.response_data.store_data.length > 0){


				 localStorage.setItem("store_id", res.data.response_data.store_data[0]._id);
				// this.setState({calenderData:res.data.response_data.store_data[0].calender})
				this.storeCal(res.data.response_data.store_data[0].calender);
				}else{
					localStorage.setItem("store_id", '');
					this.props.history.push('/404')
				}
					//this.productList(localStorage.getItem("loggedInUserId"),localStorage.getItem("token"),store_id);
					//this.productList(localStorage.getItem("loggedInUserId"),localStorage.getItem("token"),res.data.response_data.store_data[0]._id);
					
				}
			},
			(error) => { 
				this.setState({ 
					successmessage:"Please provide valid login credential."
				})
			});
			
			const user_id = localStorage.getItem("loggedInUserId");
			const session_token = localStorage.getItem("token"); 
			const store_id = localStorage.getItem("store_id");
			//setTimeout(function(){ localStorage.getItem("store_id", res.data.response_data.store_data[0]._id); }, 3000);

			//console.log('yyyyyyyyyyyy');
			//console.log(store_id);
			//this.categoryList(user_id,session_token,store_id); 
			this.productList(user_id,session_token,store_id);
			//this.storeParentCategory();
			
		
			if(!user_id){
				window.location.href = "/login";
			}
			
	   }

	    truncate(source, size) {
		return source.length > size ? source.slice(0, size - 1) + "…" : source;
	  }
	   ratingChanged = (newRating) => {
		console.log(newRating);
	  };
	   
	  
	//   var res = truncate('Truncate text to fit in 3 lines', 14);
	//   console.log(res);

	   productList = async (user_id,session_token,store_id) => { 
		this.setState({
		  loading:true
		}) 
		let page = this.state.current_page;
		let per_page = this.state.per_page;
	
		getAllTypeofProduct(page,per_page,user_id,session_token,store_id).then(data => {   
		  // console.log("===========");
		   //console.log(data.response_data);
		  // console.log("===========");
		  // return
	
		  this.setState({
			loading:false
		  }) 
	
		  if(data.response_data){
		  if(data.response_data.featuredProduct.length>0){
			 
				this.setState({
					featuredProduct:data.response_data.featuredProduct, 
				  
				 // total:data.response_data.total_count,
				})
			  } else {
				this.setState({ featuredProduct:[]})
			  }
			  if(data.response_data.popularProduct.length>0){
			 
				this.setState({
					popularProduct:data.response_data.popularProduct, 
				 // loading:false,
				 // total:data.response_data.total_count,
				})
			  } else {
				this.setState({ popularProduct:[]})
			  }
			  if(data.response_data.latestProduct.length>0){
			 
				this.setState({
					latestProduct:data.response_data.latestProduct, 
				  //loading:false,
				 // total:data.response_data.total_count,
				})
			  } else {
				this.setState({ latestProduct:[]})
			  }

			  if(data.response_data.storeInfo.length>0){
			 
				this.setState({
					storeInfo:data.response_data.storeInfo, 
				  //loading:false,
				 // total:data.response_data.total_count,
				})
			  } else {
				this.setState({ storeInfo:[]})
			  }

			}  
		});
	  }

	   popualr =  (product) => {
		const res = product.length > 0 && product.map((item) => {
			if(item.images[0]){
				var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ item.images[0];
			} else if(item.product_image){
				var imgs = item.product_image;
			} else {
				var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
			}	
			return(	
			<div className="product-sec-list">
			<div className="store-product-sec">  
			<a href onClick={() => this.setProduct(item)}>        
				 <div className="store-img-sec"> 
				   <img src={imgs} alt="" />               
				 </div> 
				 </a>
				 <div className="store-des"> 
				 <div className="store-title">{this.truncate(item.product_title,25)}</div> 
				  {/* <div className="store-price">${item.product_price} <span> $12 </span></div> */}
				
						
				  {
						item.product_sale_price ? (
							<div className="store-price">{CONSTANT.CURRENCY}{item.product_sale_price}<span>{CONSTANT.CURRENCY}{item.product_price}</span></div>
						) : ( <></>)
						}
						
						{/* {
						item.product_sale_price ? (
						<span className="sale_price">{CONSTANT.CURRENCY}{item.product_sale_price}</span>
						) : (<></>)
						} */}
											
						
				 </div>
			   </div>
			</div>	
			)																		
		}) 	
		return <>{res}</>
	}

	feature =  (products) => {
		const fres = products.length > 0 && products.map((item) => {
			if(item.images[0]){
				var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ item.images[0];
			} else if(item.product_image){
				var imgs = item.product_image;
			} else {
				var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
			}	
			return(	
			<div className="product-sec-list">
			<div className="store-product-sec">   
			<a href onClick={() => this.setProduct(item)}>        
				 <div className="store-img-sec"> 
				   <img src={imgs} alt="" />               
				 </div> 
				 </a>
				 <div className="store-des"> 
				 <div className="store-title">{this.truncate(item.product_title,25)}</div> 
				  {/* <div className="store-price">${item.product_price} <span> $12 </span> </div> */}
				
				  {
						item.product_sale_price ? (
							<div className="store-price">{CONSTANT.CURRENCY}{item.product_sale_price}<span>{CONSTANT.CURRENCY}{item.product_price}</span></div>
						) : ( <></>)
						}
						{/* {
						item.product_sale_price ? (
						  <div><strike>{CONSTANT.CURRENCY}{item.product_price}</strike></div>
						) : ( <div className="sale_price">{CONSTANT.CURRENCY}{item.product_price}</div>)
						}
						
						{
						item.product_sale_price ? (
						<div className="sale_price">{CONSTANT.CURRENCY}{item.product_sale_price}</div>
						) : (<></>)
						} */}
											
						
				 </div>
			   </div>
			</div>	
			)																		
		}) 	
		return <>{fres}</>
	}


	latest =  (lproduct) => {
		const lres = lproduct.length > 0 && lproduct.map((item) => {
			if(item.images[0]){
				var imgs = CONSTANT.NSTABASE_URL+ 'images/store/'+ item.images[0];
			} else if(item.product_image){
				var imgs = item.product_image;
			} else {
				var imgs = CONSTANT.NSTABASE_URL + "/assets/images/placeholder.png";
			}	
			return(	
			<div className="product-sec-list">
			<div className="store-product-sec">  
			<a href onClick={() => this.setProduct(item)}>           
				 <div className="store-img-sec"> 
				   <img src={imgs} alt="" />               
				 </div> 
				 </a>
				 <div className="store-des"> 
				 <div className="store-title">{this.truncate(item.product_title,25)}</div> 
				  {/* <div className="store-price">${item.product_price} <span> $12 </span></div> */}
				
				  {
						item.product_sale_price ? (
							<div className="store-price">{CONSTANT.CURRENCY}{item.product_sale_price}<span>{CONSTANT.CURRENCY}{item.product_price}</span></div>
						) : ( <></>)
						}
						{/* {
						item.product_sale_price ? (
						  <div><strike>{CONSTANT.CURRENCY}{item.product_price}</strike></div>
						) : ( <div className="sale_price">{CONSTANT.CURRENCY}{item.product_price}</div>)
						}
						
						{
						item.product_sale_price ? (
						<div className="sale_price">{CONSTANT.CURRENCY}{item.product_sale_price}</div>
						) : (<></>)
						} */}
											
						
				 </div>
			   </div>
			</div>	
			)																		
		}) 	
		return <>{lres}</>
	}
	
	storeCal =  (calData) => {
		if(calData.length>0){
            var monday_start_time = "";
            var monday_end_time = "";
            var tuesday_start_time = "";
            var tuesday_end_time = "";
            var wednesday_start_time = "";
            var wednesday_end_time = "";
            var thursday_start_time = "";
            var thursday_end_time = "";
            var friday_start_time = "";
            var friday_end_time = "";
            var saturday_start_time = "";
            var saturday_end_time = "";
            var sunday_start_time = "";
            var sunday_end_time = "";

          

            calData.forEach(cal =>{
                var day = cal.day;
				if(day===1){
                     monday_start_time = this.tConv24(cal.from_time);
                     monday_end_time   = this.tConv24(cal.to_time);
                   
                } if(day===2){
                     tuesday_start_time = this.tConv24(cal.from_time);
                     tuesday_end_time   = this.tConv24(cal.to_time);
                    
                } if(day===3){
                     wednesday_start_time = this.tConv24(cal.from_time);
                     wednesday_end_time   = this.tConv24(cal.to_time);
                    
                } if(day===4){
                     thursday_start_time = this.tConv24(cal.from_time);
                     thursday_end_time   = this.tConv24(cal.to_time);
                   
                } if(day===5){
                     friday_start_time = this.tConv24(cal.from_time);
                     friday_end_time   = this.tConv24(cal.to_time);
                    
                } if(day===6){
                     saturday_start_time = this.tConv24(cal.from_time);
                     saturday_end_time   = this.tConv24(cal.to_time);
                    
                } if(day===0){ 
                     sunday_start_time = this.tConv24(cal.from_time);
                     sunday_end_time   = this.tConv24(cal.to_time);
                     
                }

            })

            this.setState({
                monday_start_time:monday_start_time,
                monday_end_time:monday_end_time,
                tuesday_start_time:tuesday_start_time,
                tuesday_end_time:tuesday_end_time,
                wednesday_start_time:wednesday_start_time,
                wednesday_end_time:wednesday_end_time,
                thursday_start_time:thursday_start_time,
                thursday_end_time:thursday_end_time,
                friday_start_time:friday_start_time,
                friday_end_time:friday_end_time,
                saturday_start_time:saturday_start_time,
                saturday_end_time:saturday_end_time,
                sunday_start_time:sunday_start_time,
                sunday_end_time:sunday_end_time,
               
            })

            
          }
		
		}

	  
	
	  render() {
       // console.log('calenderDatayyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy');  
		//console.log(this.state.calenderData);
		const {featuredProduct,popularProduct,latestProduct,storeInfo,monday_start_time,monday_end_time,tuesday_start_time,tuesday_end_time,wednesday_start_time,wednesday_end_time,thursday_start_time,thursday_end_time,
			friday_start_time,friday_end_time,saturday_start_time,saturday_end_time,sunday_start_time,sunday_end_time} = this.state;

	
		const pageNumbers = [];
		if (this.state.total !== null) {
		  for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
			pageNumbers.push(i);
		  }
		}
	
	
		var lastEle = 0;
		const renderPageNumbers = pageNumbers.map(number => {
		  let classes = this.state.current_page == number ? "active" : '';
		  lastEle = number;
		  return (
			<span className={classes} key={number} onClick={() => this.pagination(number)}>{number}</span>
		  );
		});
	
	
	
		if (this.state.total) {
		  var lastelement = this.state.total / this.state.per_page;
		  lastelement = lastEle;
		}
	
		if(storeInfo.length > 0){
			var storeData = Object.assign({},...storeInfo);
		//var storeLat = storeData.loc.coordinates[1];
		//var storeLong = storeData.loc.coordinates[0];

		if(storeData.banner_image != undefined || storeData.banner_image != ''){
			var bannerImg = CONSTANT.NSTABASE_URL+'images/store/'+storeData.banner_image
		}  else {
			var bannerImg = ''	;		
		}
			
		}else{
			var storeData ={};
		}

		// var dataGG = [];
		// daysArray.length > 0 && daysArray.map(async (item) => {
			// let fData =  await calenderData.filter((citem) => {
				// if(citem.day == item.day){
					
				// }
				// citem.index = item.index

				// if(fData.length > 0){
					// dataGG.push(Object.assign({},...fData))
				// }else{
					// dataGG.push(item)
				// }
			
			// })
			
		// });
		// console.log(dataGG);


		return (
		  <>
		  <Metatitle title={localStorage.getItem('store_name').split('/').pop().substr(0,1).toUpperCase()+localStorage.getItem('store_name').split('/').pop().substr(1)+' | aBlueC'} metaDescription="Stores" ogTitle="Stores" ogImage=""></Metatitle>
		  <Header/>
		  {/* <InnerSearch/> */}
		  <section id="tabs" className="project-tab bdr-top innerheight"> 

		 <div className="container-fluid">
		<div className="row">
		<div className="col-md-12">
         <div className="store-banner-sec" style={{'background-image': `url(${bannerImg})`}}>		 	 
           {/* <img src="/assets/images/storebanner.png" alt="" /> */}
		   <div className="container">
		<div className="row">
		<div className="col-md-12">
		   <div className="storebanner-txt-sec"> 
           <h2>{storeData.store_name} </h2>
		   {/* <a href={`http://maps.google.com/?q=${storeData.store_address}`} target="_blank"> */}
		   {/* <a href={`https://maps.google.com/?saddr=My+Location&daddr=${storeData.store_address}`} target="_blank">  */}
		   {/* <a href={`https://www.google.com/maps?saddr=${localStorage.getItem("latitude")},${localStorage.getItem("longitude")}&daddr=${storeData.store_address}`} target="_blank">  */}
		   
		   <ul className="store-address-sec">
			   <li>
				    <a href={`https://www.google.com/maps/dir/?api=1&origin=${this.state.currentLatitude},${this.state.currentLongitude}&destination=${storeData.store_address}`} target="_blank"> 
				   {/* <a href={`https://maps.google.com/?saddr=My+Location&daddr=${storeData.store_address}`} target="_blank">  */}
			  <img src="/assets/images/location.png" alt="" /> 
			  <span className="store-address">{storeData.store_address}</span>
			  </a>
			  </li>
			  {(storeData.store_contact_number && storeData.store_contact_number != 'null') ? (<li><a href={`tel:${storeData.store_contact_number}`}><img src="/assets/images/phone.png" alt="" /> <span> {storeData.store_contact_number} </span></a></li>) :''}
		   </ul>
			 
		   
		   
           {/* <button> Manage Your Order </button> */}
		   
		   {/* <p><big>Timings:</big> 09:20 AM - 08:00 PM</p> */}

		  

		     
		{/* <p><big>Monday:</big> {monday_start_time} - {monday_end_time}</p> */}
		
		
		  <ul className="store-time">
			<li> 
			<span><big>Mon:</big> {(monday_start_time && monday_end_time) ? (<>{monday_start_time} - {monday_end_time}</>) :'Closed'}</span>
			<span><big>Tue:</big>{(tuesday_start_time && tuesday_end_time) ? (<>{tuesday_start_time} - {tuesday_end_time}</>) :'Closed'}</span>
		     </li>  
			<li>
			<span><big>Wed:</big> {(wednesday_start_time && wednesday_end_time) ? (<>{wednesday_start_time} - {wednesday_end_time}</>) :'Closed'}</span>	
			<span><big>Thu:</big> {(thursday_start_time && thursday_end_time) ? (<>{thursday_start_time} - {thursday_end_time}</>) :'Closed'}</span>	
		    </li> 
			 <li>
			 <span><big>Fri:</big> {(friday_start_time && friday_end_time) ? (<>{friday_start_time} - {friday_end_time}</>) :'Closed'}</span>
		     <span><big>Sat:</big> {(saturday_start_time && saturday_end_time) ? (<>{saturday_start_time} - {saturday_end_time}</>) :'Closed'}</span>
			</li> 
			<li><span><big>Sun:</big>{(sunday_start_time && sunday_end_time) ? (<>{sunday_start_time} - {sunday_end_time}</>) :'Closed'}</span> </li>
		  </ul>
		
		  
		   </div>
		   {/* //    <p><big>Monday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Tuesday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Wednesday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Thursday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Friday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Saturday:</big> 09:20 AM - 08:00 PM</p>
		//    <p><big>Sunday:</big> 09:20 AM - 08:00 PM</p> */}
		   
		   {/* <h2>Rating:**** <ReactStars
    count={5}
    onChange={this.ratingChanged}
    size={24}
    activeColor="#ffd700"
  />  </h2> */}

		  
		   </div>   
         
		   </div> </div> </div>
		 </div>
    
		</div>	 
	    </div>	



     <div className="container">
	 {
		  this.state.loading ? (
		  <Loader/>
		  ) : (<></>)
		  }
       <div className="row"> 
           <div className="col-md-12 feature-sec">
         {featuredProduct.length > 0 ? <h2>Feature Products 
			 {/* <span className="seeall"> See all </span> */}
		 
		 </h2>
		 :<></>}
         <div className="row">
        

		 {featuredProduct.length > 0 ? this.feature(featuredProduct):<></>} 

        </div>
         </div>


      <div className="col-md-12 feature-sec">
	  {popularProduct.length > 0 ?
         <h2>Popular Products 
			 {/* <span className="seeall"> See all </span> */}
			 
			 </h2>:<></>}
         <div className="row">
        
		{popularProduct.length > 0 ? this.popualr(popularProduct):<></>} 
	
		</div>
         </div>
	<div className="col-md-12 feature-sec">
		 {latestProduct.length > 0 ?
         <h2> Latest Products 
			 {/* <span className="seeall"> See all </span> */}
		 
		 </h2>:<></>}
         <div className="row">
		 {latestProduct.length > 0 ? this.latest(latestProduct):<></>} 

         
         </div>
         </div>    


       </div>
       </div>
       </section>
		<Footer />
		</>
		);  
	  }
	}
 
storeProductList.propTypes = {
	auth: PropTypes.object.isRequired,
	storeresult: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    { clearErrors}
)(storeProductList);