import React, { Component } from 'react';
import axios from 'axios';
import { useHistory, Redirect, NavLink, BrowserRouter, Route, Link, Switch } from "react-router-dom";
import _ from "lodash";
import "../../menu.css";
import menuImage from "../../menu.png";
import feedsImage from "../../feeds.png";
import contactUsImage from "../../contact-us.png";
import aboutUsImage from "../../about_us.png";
import downloadImage from "../../download.png";
import settingsImage from "../../settings.png";
import jobMenu from "../../job-menu.png";
import manageStore from "../../manage-store.png";
import ihome from "../../home.png";
import ifeeds from "../../feeds.png";
import istore from "../../istore.png";
import storeMenu from "../../store-menu.png";
import Login from "../../components/User/Login";
import Register from "../../components/User/Register";
import Home from "../../components/Home";
import CartCount from "../User/CartCount";
import CONSTANT from '../../Constant'; 
// New One
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {getPagesMenu, clearErrors} from "../../actions/HomepagesActions";
import {getStores} from "../../actions/storeActions";
import { geolocated } from "react-geolocated";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


class Header extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggedIn:"",
			pages:[],
			clickLogin: false,
			clickSignup: false,
			addStore: false,
			myStores: false,
			search: "",
			latitude: 28.541397,
            longitude: 77.397034,
			getLocationArray: [],
			proFileName: '',
			getProfile: {},
			stripeConnect: 0,
			initialNotifs:'',
			notifCount:'',
			render: ''
		}
		this.logout = this.logout.bind(this);
	}
	

	//aks
	// async componentDidUpdate() {
	// 	if(this.props.auth.user.token == 'yes'){			
	// 		// if (this.timeout) clearTimeout(this.timeout);
	// 		// this.timeout = setTimeout(() => {
	// 		// 	window.location.reload();
	// 		// }, 100);	
	// 		window.location.href = "./";			

		
	// 	}
	// }
	
	async componentDidMount() {
		if((document.location.pathname == '/ecommerce' || document.location.pathname.match(/(ecommerce).[-a-zA-Z0-9]/)))
		{
		let posts = await this.props.getPagesMenu({'type':2})
		}else{
			let posts = await this.props.getPagesMenu({'type':1})
		}
		const token = localStorage.getItem("token");
		const user_profile_thumb = localStorage.getItem("loggedInUser_thumb_profile_image");
		const user_profile_image = localStorage.getItem("loggedInUser_profile_image");
		const userId = localStorage.getItem("loggedInUserId");
		var stripeConnect = localStorage.setItem("stripeConnect", 0);
		let loggedIn = true;
		if (token == null) {
			loggedIn = false;			
		} else {
			let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:localStorage.getItem("token")});
			if(getProfile.data.response_status != 0){
			var stripeConnect = localStorage.setItem("stripeConnect", getProfile.data.response_data.profile.stripe_connect_status);
			}
		}	
		
		this.setState({loggedIn,logged_in_user_profile_thumb: user_profile_thumb,user_profile_image: user_profile_image,userId: userId, stripeConnect: stripeConnect, showing: true});
		var getLocationArray = []
		navigator.geolocation.getCurrentPosition(function(position) {
			getLocationArray.push({latitude: position.coords.latitude,longitude: position.coords.longitude})
		});
		
		if(loggedIn == true){
			this.setState({getLocationArray: getLocationArray});
		} else {
			this.setState({getLocationArray: getLocationArray});
		}
		const userData = {
			"session_token": localStorage.getItem("token"),
			"user_id": localStorage.getItem("loggedInUserId")
		};	
		var data = await this.props.getStores(userData);

		var formData = {"session_token":localStorage.getItem("token")}
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
          }
        }		
        let response = await axios.post(CONSTANT.STABASE_URL+'post/getNotificationList', formData, config);
        
		      

       

		if(response.data.response_status != 0){
          this.setState({initialNotifs: response.data.response_data.notificationData,notifCount:response.data.response_data.count});
        } else {
          this.setState({initialNotifs: ''});
           if(response.data.response_msg=='Already logged on other device'){

        let response = await axios.post(CONSTANT.STABASE_URL+'post/getNotificationList', formData, config);



        	localStorage.removeItem("token");
		localStorage.removeItem("store_id");
		localStorage.removeItem("imgs");
		localStorage.removeItem("store_name");
		localStorage.removeItem("address");
		localStorage.removeItem("description");
		localStorage.removeItem("store_contact_number");		
		localStorage.removeItem("loggedInUserId");		
		localStorage.removeItem("email");		
		localStorage.removeItem("loggedInUser_thumb_profile_image");		
		localStorage.removeItem("loggedInUserName");		
		localStorage.removeItem("checkStoreStatus");		
		localStorage.removeItem("otp");		
		localStorage.removeItem("mobile");		
		localStorage.removeItem("longitude");		
		localStorage.removeItem("latitude");		
		localStorage.removeItem("storePhoneNo");		
		localStorage.removeItem("country_code");
		this.setState({initialNotifs: ''});		
		this.setState({loggedIn:false, showing: true})
		//document.location.href = '/login'
		

        }

		}

		//HIDE Notification click on outside
		$(document).mouseup(function (e){
  		var container = $("#show-notif"); 
		if (!container.is(e.target)
      	&& container.has(e.target).length === 0) 
  		{
			container.hide();
			
  		}
		});
	
	}
	
	publicProfile = async (e) => {
		e.preventDefault();
		const response_status = localStorage.getItem("token");
		const profileData = response_status ? localStorage.getItem('email') : '';
		let getProfile = await axios.post(CONSTANT.NSTABASE_URL+'web/webloginData', {session_token:response_status});
		var filterUserName = getProfile.data.response_data.profile.email.replace(/.com/g,'');
		document.location.href = `../profile/${filterUserName}`
	}	
	
	onboarding = async (e) => {
		
		e.preventDefault();
		
		var profileData = localStorage.getItem("token")
		
		var sentData = {"session_token": profileData}
		var config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}
		let response = await axios.post(CONSTANT.STABASE_URL+'store/stripeConnectOnboarding', sentData, config);
		if(response.data.response_status != 0){
			var redirectUrl = response.data.response_data.accountLinks.url
			localStorage.setItem("onboarding", 1)
			document.location.href = redirectUrl
		}
	}
	
	checkLandingView = e => {
		if(e.target.id == 'home')
		this.setState({"home": true})
		else if(e.target.id == 'feeds')
		this.setState({"feeds": true})
		else if(e.target.id == 'manageactivity')
		this.setState({"manageactivity": true})
		else if(e.target.id == 'clickLogin')
		this.setState({"clickLogin": true})
		else if(e.target.id == 'addStore')
		this.setState({"addStore": true})
		else if(e.target.id == 'myStores')
		this.setState({"myStores": true})
		else if(e.target.id == 'profile')
		this.setState({"mprofile": true})
		else if(e.target.id == 'custumerorder')
		this.setState({"custumerorder": true})
		else if(e.target.id == 'friend')
		this.setState({"mfriend": true})
		else if(e.target.id == 'serviceprovider')
		this.setState({"serviceprovider": true})
		//code added by AKS
		else if(e.target.id == 'jobs')
		this.setState({"jobs": true})
		else if(e.target.id == 'appliedjobs')
		this.setState({"appliedjobs": true})

		else
		this.setState({"clickSignup": true})
	}
	
	 showNotificationPopup = async (e) =>{
e.preventDefault();
$('.notification-sec > ul').toggle();

var formData = {"session_token":localStorage.getItem("token")}
        
const config = {
  headers: {
	'Content-Type': 'application/json',
	'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
  }
}		
let response = await axios.post(CONSTANT.STABASE_URL+'post/readNotification', formData, config);
if(response.data.response_status != 0){
await this.componentDidMount();
}

	}

	renderRedirect = () => {
		if(this.state.clickLogin){
			return <Redirect push to="/login" />	
		} else if(this.state.clickSignup){
			return <Redirect push to="/signup" />
		} else if(this.state.addStore){
			return <Redirect push to="/vendor/addStore" />
		} else if(this.state.myStores){
			return <Redirect push to="/vendor/myStores" />
		} else if(this.state.mprofile){
			return <Redirect push to="/profile" />
		} else if(this.state.home){
			return <Redirect push to="/" />
		} else if(this.state.feeds){
			return <Redirect push to="/feed" />
		} else if(this.state.custumerorder){
			return <Redirect push to="/custumer/order" />
		} else if(this.state.mfriend){
			return <Redirect push to="/friend" />
		}else if(this.state.manageactivity){
			return <Redirect push to="/manageactivity" />
		}else if(this.state.serviceprovider){
			return <Redirect push to="/service-provider" />
		}
		// added by AKS
		else if(this.state.jobs){
			return <Redirect push to="/jobs" />
		}
		else if(this.state.appliedjobs){
			return <Redirect push to="/applied-jobs" />
		}
	}
	
	handleClick(compName, e){
        // console.log(compName);
        this.setState({render:compName});        
    }
	
	logout(){
		localStorage.removeItem("token");
		localStorage.removeItem("store_id");
		localStorage.removeItem("imgs");
		localStorage.removeItem("store_name");
		localStorage.removeItem("address");
		localStorage.removeItem("description");
		localStorage.removeItem("store_contact_number");		
		localStorage.removeItem("loggedInUserId");		
		localStorage.removeItem("email");		
		localStorage.removeItem("loggedInUser_thumb_profile_image");		
		localStorage.removeItem("loggedInUserName");		
		localStorage.removeItem("checkStoreStatus");		
		localStorage.removeItem("otp");		
		localStorage.removeItem("mobile");		
		localStorage.removeItem("longitude");		
		localStorage.removeItem("latitude");		
		localStorage.removeItem("storePhoneNo");		
		localStorage.removeItem("country_code");		
		this.setState({loggedIn:false, showing: true})
		document.location.href = '/login'
	}

	 setDetail = async e => { 

	 	console.log(e)
		// return false;

		  var config = {
							headers: {
								'Content-Type': 'application/json',
								'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
							}
						}

				//var redirectValue="/jobs-details/"+e.job_id;
				var formData = {"session_token":localStorage.getItem("token"),"service_id":e.job_id};
  
   			    let response =   await axios.post(CONSTANT.STABASE_URL +'users/getjobIdDetails', formData, config);
   			       			    	console.log("responseaks");

   			    	//console.log(response.data.response_data.getjobIdDetails.status);
   			    	localStorage.setItem("stat",response.data.response_data.getjobIdDetails.status);

   			    	//return false;

   			    	 //localStorage.setItem("stat",5);


		  //localStorage.setItem("store_name", e.store_name);
		  document.location.href = "/jobs-details/"+e.job_id
		}


	notificationList =  (notifdata) => {
		const res = notifdata.length > 0 && notifdata.map((item ,index) => {

			//console.log("adasdas");
			//console.log(item.job_id);

			var job_id=item.job_id;
			var redirectUrl=item.post_comment;
			var splitValue=redirectUrl.slice(redirectUrl.length - 29);
			var splitValue2=redirectUrl.slice(redirectUrl.length - 50);

			//console.log(redirectUrl);

			var flag=false;
			if(redirectUrl=='Posted a new post.')
			{
				var redirectValue="/feed";
			}
			else if(splitValue=='accepted your friend request.') {

				var redirectValue="/friend";
			}
			else if(redirectUrl=='Has sent you a friend request') {

				var redirectValue="/friend";
			}
			else if(redirectUrl=='Applied for Job') {

				var flag=true;
				var redirectValue="/jobs-details/"+job_id;
				//var floag=1;
				//this.setDetail(job_id);

			}
			else{

				var redirectValue="/feed";

			}

			if(item.thumb_profile_image == undefined && item.profile_image != ''){
				var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.profile_image
			// } else if(item.thumb_profile_image != undefined && item.profile_image == undefined){
			// 	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.thumb_profile_image
			// } else if(item.thumb_profile_image != undefined && item.profile_image != undefined){
			// 	var profileImg = CONSTANT.NSTABASE_URL+'images/profile/'+item.thumb_profile_image
			 } else {
				var profileImg = CONSTANT.NSTABASE_URL+'images/profile/noimage.png'	;		
			}
			return(	
				<li key={index}>

						
		      {flag ? (
		       <a onClick={() => this.setDetail({'job_id':item.job_id})}  >
				<div className="media">
				<img src={profileImg} className="mr-3" alt="user profile"/>
				<div className="media-body">
					<h5 className="mt-0">{item.name}</h5>
					<p>{item.post_comment}</p>
				</div>
				</div>	
				</a>
		      ) : (
		       <a href={redirectValue} >
				<div className="media">
				<img src={profileImg} className="mr-3" alt="user profile"/>
				<div className="media-body">
					<h5 className="mt-0">{item.name}</h5>
					<p>{item.post_comment}</p>
				</div>
				</div>	
				</a>
		      )}
 

			</li>
			)																		
		}) 	
		return <>{res}</>
	}

	render(){
		
		// if(this.state.myStores){
			// return <Redirect to="/vendor/myStores" />
			// return <Link to='/vendor/myStores' />
			
			// return this.props.history.push("/vendor/myStores");
		// }
		
		const { value, suggestions, newloader, profileData, response_status, profile, stripeConnect,initialNotifs,notifCount } = this.state;
		const {getHeaderData} = this.props.homepages
		const {getStoreListData} = this.props.storeresult
		const inputProps = {
		  placeholder: 'Search',
		  value,
		  onChange: this.onChange
		};
		
		if(getHeaderData.length > 0){
		  
		  var newMainArray = [];
		  var newSubArray = [];
		  var newPages = getHeaderData.length > 0 && getHeaderData.filter((item) => {     
			if(item.menu_item_parent == "0"){
			  var url = (item.url).split("/")
			  if(url[3] == ""){
				item.slug = ""
			  } else {
				item.slug = url[3]
			  }
			  newMainArray.push(item);
			} else {
			  var url = (item.url).split("/")
			  if(url[3] == ""){
				item.slug = ""
			  } else {
				item.slug = url[3]
			  }
			  newSubArray.push(item);
			}
		  });
		  
		} else {
		  var newMainArray = []
		  var newSubArray = []
		}   
		newMainArray.map(async (current) => {
		  current.subCat = _.filter(newSubArray, { menu_item_parent: current.ID.toString() });
		  if(current.subCat.length > 0){
			return current;
		  } else {
			current.subCat = undefined
			return current;
		  }   
		});
		
		return (
			<>
<div className="about_wrapper clearfix">
				<div className="left-logo-sec">
<nav className="navbar navbar-expand-lg navbar-light ">
                         {(document.location.pathname != '/' && document.location.pathname != '/Search-result')?
							<>
								<a className="navbar-brand" href={"/"}><img src="../../../assets/images/logo.png" alt="" /></a>
							</>
							:
							<>
							</>
						}

  
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">      
	  {newMainArray.length > 0 && newMainArray.map((item,i) =>
						<li className="nav-item dropdown" key={i}>
							{(item.subCat == undefined || item.subCat == '') ? 
							(document.location.pathname == '/ecommerce' || document.location.pathname.match(/(ecommerce).[-a-zA-Z0-9]/)) ?
							<a className="nav-link" href={item.slug ? '/ecommerce/'+item.slug : '/ecommerce'}>{item.title}</a>:
							<a className="nav-link" href={item.slug ? '/'+item.slug : document.location.origin}>{item.title}</a>
							:
							
							<>
							{(document.location.pathname == '/ecommerce' || document.location.pathname.match(/(ecommerce).[-a-zA-Z0-9]/)) ?
	<a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" href={item.slug ? '/ecommerce/'+item.slug : '#'}>{item.title}</a> :<a href={item.slug ? '/'+item.slug : document.location.origin} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">{item.title}</a> }              
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<ul>{item.subCat.length > 0 && item.subCat.map((item) =>

									
									<li>
										{(document.location.pathname == '/ecommerce' || document.location.pathname.match(/(ecommerce).[-a-zA-Z0-9]/)) ?
										
										<a className="dropdown-item" href={item.slug ? '/ecommerce/'+item.slug : document.location.origin}>{item.title}</a>:

										<a className="dropdown-item" href={item.slug ? '/'+item.slug : document.location.origin}>{item.title}</a>
										
								}
									</li>	
									
								)}</ul>
							</div>
							</> 
							}               
						</li>
						)}   





      
    </ul>
    
  </div>
</nav>		
</div>
<div className="right-logo-sec">
				{this.state.loggedIn ?
				<div className="notification-sec">
				<a className ="show-notification" onClick={this.showNotificationPopup}><img src="/assets/images/notification-bell.png"/>
						<span className="cart_mess" >{notifCount}</span>
				</a>
				<ul id="show-notif" className="class1">
					{/* {initialNotifs.length > 0 && initialNotifs.map((item) =>
				<li>
					<div className="media">
					<img src="/assets/images/user_profile.png" className="mr-3" alt="user profile"/>
					<div className="media-body">
						<h5 className="mt-0">{item.name}</h5>
						<p>{item.post_comment}</p>
					</div>
					</div>	
				</li>
				)} */}
				{initialNotifs.length > 0 ? this.notificationList(initialNotifs):<></>} 
				
				</ul>	
				</div>	
	:<></>}			


				 	{/* cart menu aks {this.state.loggedIn ?<CartCount></CartCount> :<></>} */}
					<ul className="user_info about_abluce">
						{this.state.loggedIn ? 
						<>
						{this.renderRedirect()}
						<li>
							<a href="#" className="menu-list-sec">
								<img src={menuImage} alt="" />
							</a>
							<ul className="menu_listing">
							 <li><a href="" id="home" onClick={this.checkLandingView}> <span><img src={ihome} alt="" /></span>Home</a></li>
								
								 {/*<li><a href="" id="serviceprovider" onClick={this.checkLandingView}><span><img src={ihome} alt="" /></span>Find a Service Provider</a>
									{/*<ul className="menu_listing">
										{/* <li><a href="">Notifications</a></li> */}
										{/*<li><a href="">Stores</a></li>
										{/* <li><a href="">Jobs</a></li> */}
									{/*</ul>
								</li> */}
								{/* <li><a href="" id="serviceProfessional" onClick={this.checkLandingView}><span><img src={ihome} alt="" /></span>Service Professional</a>
									<ul className="menu_listing">
										<li><a href="">My Orders</a></li>
										{/* <li><a href="">Favourite Product</a></li> */}
										{/* <li><a href="">Jobs</a></li> */}
									{/*</ul>								
								</li> */}
								{/* <li><a href="" id="settings" onClick={this.checkLandingView}> <span><img src={ihome} alt="" /></span>Settings</a></li> */}
								{/* <li><a href="" id="shareaBlueC" onClick={this.checkLandingView}> <span><img src={ihome} alt="" /></span>Share aBlueC</a></li> */}
								<li><a href="" id="feeds" onClick={this.checkLandingView}> <span><img src={ifeeds} alt="" /></span>Posts</a></li>
								<li><a href="" id="manageactivity" onClick={this.checkLandingView}> <span><img src={manageStore} alt="" /></span>Stores</a></li>
								<li><a href="" id="serviceprovider" onClick={this.checkLandingView}><span><img src="/assets/images/search-service-provider.svg" alt="" /></span>Find a Service Provider</a></li>
								<li><a href="" id="jobs" onClick={this.checkLandingView}><span><img src="/assets/images/search-jobs.svg" alt="" /></span>Jobs</a></li>
								<li><a href="" id="appliedjobs" onClick={this.checkLandingView}><span><img src="/assets/images/search-jobs.svg" alt="" /></span>Jobs Applied For</a></li>

								<li><a href="" id="profile" onClick={this.checkLandingView}> <span><img src="/assets/images/menu-profile.png" alt="" /></span>Profile</a></li>
								<li><a href="" id="friend" onClick={this.checkLandingView}> <span><img src="/assets/images/menu-friends.png" alt="" /></span>Friends</a></li>	
								{/*<li><a href="" id="custumerorder" onClick={this.checkLandingView}> <span><img src="/assets/images/menu-orders.png" alt="" /></span>My Orders</a></li> */}
								<li><a href="#" id="custumerorder" onClick={this.logout}> <span><img src="/assets/images/menu-logout.png" alt="" /></span>Logout</a></li>
							</ul>
						</li></> :
						<>
						{this.renderRedirect()}
						<li style={{ display: (this.state.showing ? 'block' : 'none') }}><a href="" id="clickLogin" onClick={this.checkLandingView}>Login</a></li>
						<li style={{ display: (this.state.showing ? 'block' : 'none') }}><a href="" id="clickSignup" className="regster" onClick={this.checkLandingView}>Sign up</a></li>						
						</>
						}
						{/* {this.state.loggedIn ? 
						<li><a href="#" onClick={this.logout}>Logout</a></li> : ""
						} */}
					</ul>
				</div>
</div>



			
			</>			
		);
	}
}

Header.propTypes = {
	auth: PropTypes.object.isRequired,
	homepages: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired    
};
const mapStateToProps = state => ({
    auth: state.auth,
    homepages: state.homepages,
	storeresult: state.storeresult,
    errors: state.errors   
});
export default connect(
    mapStateToProps,
    {getPagesMenu, getStores, clearErrors}
)(Header);