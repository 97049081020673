import React, { Component } from 'react';
import { NavLink, } from "react-router-dom";
import { getCart,} from '../Api';
import {connect} from 'react-redux';
import _ from "lodash";


const initialState = {  
  cartcount:0, 
  cartItems:[],
  creditScoreVal:''
} 

class CartCount extends Component {

  constructor(props) {
    super(props); 
    this.state = initialState; 
  } 

  

  
  getCart(){
    const user_id = localStorage.getItem("loggedInUserId");
    const session_token = localStorage.getItem("token");  
    getCart(user_id,session_token).then(data => {  
      //console.log(data);
      if(data.response_invalid===1){

      } else {
      this.setState({
        cartItems:data.response_data.cart_products.items
      })
      this.props.changeCart(data.response_data.cart_products.items,'CART_UPDATE');
    }
    }); 
  }


   


  async componentDidMount() {
		
    this.getCart(); 
    
  }


    render() {	
		
		
      var url = window.location.pathname;
      var res = url.split("/");
      var classVendor = "";
      var classCustomer = "";
      var storeURLVendor = "";
      var vendorURL= false;
      var cartIconVisible =''; 
      var cartCountIcon= true;
      var query = window.location.search.substring(1);
      //console.log(query)

    //   if(res[1]==="vendor"){
    //     classVendor = "buy_bt mange_store active";
    //     vendorURL=true;
    //   } else {
    //     classVendor = "buy_bt mange_store";
    //   }

      if(res[1]==="customer" || res[1]==="products" || res[1]==="product-details" || res[1]==="checkout" || res[1]==="manage-order"){
        classCustomer = "buy_bt active";
      } else {
        classCustomer = "buy_bt";
      }

      if(query == 'qp=byNow'){
        cartCountIcon = false;
      }

      var cartcount = 0;

      if(this.props.cart){
        var productData = this.props.cart.map((cart,index) => {    
              cartcount = parseInt(cartcount) + parseInt(cart.qty); 
        }) 
      } else {
        var productData = this.state.cartItems.map((cart,index) => {    
          cartcount = parseInt(cartcount) + parseInt(cart.qty); 
        }) 
      }
      
     

        return (
         <div className="store_wrap count-sec">
      
      {cartCountIcon ?        
       <NavLink to="/cart">
                          <img src={process.env.PUBLIC_URL + "/assets/images/shopping.png"}/>
                          <span className="cart_mess">{cartcount}</span>
                        </NavLink>
         
      :<></>}
       
      
          </div>
        );
    }
}

 


const mapStateToProps = (state) => {
  return { 
    cart:state.cart_data
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    changeCart: (name,action) => { dispatch({ type: action, payload: name }) }, 
  }
}

 
export default connect(mapStateToProps,mapDispatchToProps)(CartCount);

