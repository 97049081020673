export const AUTH_CONFIG = {
    BASE_URL: "https://www.abluec.com/api/",
    NEWBASE_URL: "https://www.abluec.com/api/",
    STABASE_URL: "https://www.abluec.com/api/",
    NSTABASE_URL: "https://www.abluec.com/",
	SHOW_USER_PIC_URL:"http://localhost:7103/uploads/user/",
	SHOW_CATEGORY_PIC_URL:"http://localhost:7103/uploads/category/",
	SHOW_STORE_PIC_URL:"http://localhost:7103/uploads/store/",
	SHOW_SERVICE_PIC_URL:"http://localhost:7103/uploads/service/",
	SHOW_POST_PIC_URL: "http://localhost:7103/uploads/post/",
	SHOW_DELIVERY_PIC_URL: "http://localhost:7103/uploads/deliveryBoy/",
	SHOW_PRODUCT_PIC_URL: "http://localhost:7103/uploads/product/",
	SHOW_ORDER_PIC_URL: "http://localhost:7103/uploads/order/",
	SHOW_ADVERTISEMENT_PIC_URL: "http://localhost:7103/uploads/advertisement/",
	SHOW_FLAG_PIC_URL: "http://localhost:7103/uploads/flag/",
	SHOW_OFFER_PIC_URL: "http://localhost:7103/uploads/offer/",
	NO_IMAGE: "http://localhost:7103/uploads/service/download.png",
	NO_IMAGE: "http://localhost:7103/uploads/category/download.png",
	NO_IMAGE: "http://localhost:7103/uploads/product/download.png",
	HEADER: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg",
	JOB_IMAGE:"images/store/",
    POST_IMAGE:"/images/post/",
    PROFILE_IMAGE:"images/profile/",
    NO_IMAGE_ICON: "no-image-icon.png",
    API_URL:"http://localhost:3000/",
	CATEGORY_IMAGE:"images/categorynew/",
	CURRENCY:"$",
	loadStripeKeyTest: "pk_test_VwUzOee1ZurYI6fxgX7wFQeG",
	loadStripeKey: "pk_live_gk7OpXU8nNlkFcHsLJATxdLt00DyQXAIxK",
	wordpressAPIURL: "https://abluec.com:8443/wp-json/wp/",
	stripeConnectLiveURL:"https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_Eaog1YFoxc7zgGy9AXcbWItNPvsylYH7&scope=read_write&redirect_uri=http://www.abluec.com:9024/stripeConnect/stripeConnectAuth",
	stripeConnectLocalURL:"https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_Eaog1YFoxc7zgGy9AXcbWItNPvsylYH7&scope=read_write&redirect_uri=http://localhost:7103/stripeConnect/stripeConnectAuth"

};
export default AUTH_CONFIG;

