import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import Constant from '../Constant';
import {
    GET_ERRORS,
    SET_CURRENT_USER,
	GET_ACCOUNT_INFO,
    USER_LOADING,
    SET_MESSAGE
} from "./types";

export const registerUser = (userData, history) => dispatch => {
    axios.post(Constant.STABASE_URL+"users/register", userData, {headers: { "x-access-token": Constant.HEADER }}).then(res => {		
		if (res.data.response_status === 0) {
			dispatch(setCurrentUser({successmessage:res.data.response_msg,registered:false}));
			setTimeout(function(){ 
				window.location.href = "/login?register=0";
			}, 500);
		} else {
			dispatch(setCurrentUser({successmessage:"Registerd successfully",registered: true, cls:"alert alert-success"}));
			setTimeout(function(){ 
				window.location.href = "/login?register=1";
			}, 500);
		}
		
	}).catch(err =>
		dispatch({
			type: GET_ERRORS,
			payload: err.response.data
		})
	);
};

//aks
// export const loginUser = (userData,history) => dispatch => {
//     axios.post(Constant.STABASE_URL+"users/login", userData, {headers: { "x-access-token": Constant.HEADER }}).then(res => {	

//     console.log("asdsadas11111");
//     console.log(res.data);

//         console.log("asdsadas111");


//     //return false;	
// 		if(res.data.response_status == 0){
// 			window.location.href = "/login?token=no";
// 		} else {
// 			localStorage.setItem("token",res.data.response_data.session_token);
// 			localStorage.setItem("email",res.data.response_data.profile.email);
// 			localStorage.setItem("loggedInUserId",res.data.response_data.profile._id);
// 			localStorage.setItem("loggedInUser_thumb_profile_image",res.data.response_data.profile.profile_image);
// 			localStorage.setItem("loggedInUserName",res.data.response_data.profile.name);
// 			const decoded = {"token":"yes"}
// 			dispatch(setCurrentUser(decoded));			
// 		}
		
// 	}).catch(err =>
// 		dispatch({
// 			type: GET_ERRORS,
// 			payload: err.response.data
// 		})
// 	);
// };


export const loginUser = (userData,history) => dispatch => {
    axios.post(Constant.STABASE_URL+"users/login", userData, {headers: { "x-access-token": Constant.HEADER }}).then(res => {        
        if(res.data.response_status == 0){
            window.location.href = "/login?token=no";
        } else {
            localStorage.setItem("token",res.data.response_data.session_token);
            localStorage.setItem("email",res.data.response_data.profile.email);
            localStorage.setItem("loggedInUserId",res.data.response_data.profile._id);
            localStorage.setItem("loggedInUser_thumb_profile_image",res.data.response_data.profile.profile_image);
            localStorage.setItem("loggedInUserName",res.data.response_data.profile.name);
            //const decoded = {"token":"yes"}
            //dispatch(setCurrentUser(decoded));    
            window.location.reload()        
        }
        
    }).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const forgetpasswordUser = (userData,history) => dispatch => {
    axios
        .post(Constant.STABASE_URL+"/users/forgetpassword", userData)
        .then(res=>{
           // console.log("nnnnnnnnppppppppppppppppp");console.log(res.data);
            //dispatch(setMessage(res.data));
            //history.push("/forgetpassword")

            //const {msg} = res.data.messages;
            dispatch(setMessage(res.data));
            
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const passwordReset = (userData,history) => (dispatch) => {
    
    
    axios
        .post(Constant.STABASE_URL+"/users/updatepassword",userData)
        .then((res) => {
            //dispatch(clearErrors());
           dispatch(setMessage(res.data));
            
        })
        .catch((err) => {
            //dispatch(clearMessage());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const accountUpdate = (userData,history) => (dispatch) => {
    
    
    axios
        .post(Constant.STABASE_URL+"/users/updateaccount",userData)
        .then((res) => {
            //dispatch(clearErrors());
           dispatch(setMessage(res.data));
            
        })
        .catch((err) => {
            //dispatch(clearMessage());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const getAccountInfo = (userID) => (dispatch) => {
    //console.log("aya");
    // dispatch(setLoading());
    axios
        .post(Constant.STABASE_URL+"/users/getaccount",userID)
        .then((res) => {
            dispatch({
                type: GET_ACCOUNT_INFO,
                payload: res.data.userdata
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};


export const logoutUser = () => dispatch => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("store_name");
    localStorage.removeItem("store_id");
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("loggedInUserName");
    localStorage.removeItem("stripeConnect");
    localStorage.removeItem("email");
    localStorage.removeItem("loggedInUser_thumb_profile_image");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};

export const setMessage = msg => {
    return {
        type: SET_MESSAGE,
        payload: msg
    };
};

export const clearMessage = () => {
    return {
        type: SET_MESSAGE,
        payload: {}
    };
};

export const clearErrors = () => {
    return {
        type: GET_ERRORS,
        payload: {}
    };
}
