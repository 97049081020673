import React, { Component } from 'react';
import { getOrderList } from '../Api'; 
import Button from "./Button";  
//import  OrderItem  from './OrderItem'; 
//import Search from "../../user/Search"; 
//import Button from "../../user/Button"; 
import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer'; 
import Loader from '../../Loader';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import CONSTANT from '../../Constant';
 

const initialState = {  
    status:1,
    pending:'',
    accepted:'',
    completed:'',
    current_product:'',
    order_id:'',
    order_date:'',
    customer_name:'',
    customer_address:'',
    instruction:'',
    order_total:0,
    items:'',
    orderData:'',
    isOpen:false,
    order_status:'',
    product_name:'',
    custom_order_id:'',
    store_name:'',
 } 

class OrderStore extends Component {

    constructor(props) {
        super(props); 
        this.state = initialState;    
    } 


    componentDidMount() { 
      const user_id = localStorage.getItem("loggedInUserId");
      const session_token = localStorage.getItem("token"); 
      const store_id = localStorage.getItem("store_id"); 
      this.orderList(session_token,store_id,1);  
     // this.orderList(session_token,store_id,2);  
     // this.orderList(session_token,store_id,3);  
  }

    
   showPopup(){
    this.setState({
      isOpen:true,
    })
  }

  hidePopup(){
    this.setState({
      isOpen:false,
    })
  }

  
  onChange = e => {

    this.setState({[e.target.name] : e.target.value})
  };

    orderDetails(order){
      //console.log(order.product);
        let items = order.product.map((item,index) => { 
              return ( 
                <tr>
                <td>
                <div className="product_name">
                <h2>{item.qty}x{item.product_title}</h2>
                </div>
                </td>
                <td>
                &nbsp;
                </td>
                <td>
                &nbsp;
                </td>
              <td><span>{CONSTANT.CURRENCY}{item.price}</span></td>
                </tr>
            ); 
        })  
              



      var orderDate = new Date( Date.parse(order.createdAt));
      this.setState({
        order_id:order._id, 
        order_date:orderDate.toLocaleString(),
        customer_name:order.customer.name,
        customer_address:order.address.complete_address,
        instruction:order.instruction,
        order_total:order.order_total,
        items:items,
      })
    }
    
    orderList = async (session_token,store_id,status) => { 
      this.setState({loading:true})
    getOrderList(session_token,store_id,status).then(data => { 
		data.response_data.Order_Info.payment_type = (data.response_data.Order_Info.payment_type != undefined) ? data.response_data.Order_Info.payment_type : ""
      var i = 0;  
      this.setState({loading:false})
      this.setState({orderData:data.response_data.Order_Info});
      

         
    });
  }  




	viewOrder = e => {
	this.setState({
	isOpen:true
	})

	if(e.order_data){
      this.setState({
        custom_order_id:e.order_data.custom_order_id,
        customer_name:e.order_data.customer.name,
        order_total:e.order_data.order_total,
        product_name:(e.order_data.product.length > 0) ? e.order_data.product[0].product_title :'',
        order_date:e.order_data.createdAt,
        order_status:e.order_data.status,
        order_id:e.order_data._id,
        store_name:e.order_data.store.store_name,
        address:e.order_data.address.complete_address,
		 payment_type:e.order_data.payment_type,

    })
    }else{
      this.setState({
        custom_order_id:'',
        customer_name:'',
        order_total:'',
        product_name:'',
        order_date:'',
        order_status:'',
        order_id:'',
        store_name:''
    })
    }
   
	}
 
  

  submitForm = (e) => {  
    e.preventDefault(); 
    const isValid = this.validate();

   
    if(isValid){
    const X_ACCESS_TOKEN = CONSTANT.HEADER;  

    const headers = {
      'x-access-token': X_ACCESS_TOKEN
    }

    this.setState({  
      loading:true
    }) 

    const {order_id,order_status} = this.state;  
    const user_id = localStorage.getItem("loggedInUserId");  
    const token   = localStorage.getItem("token");  

       
        var data = {"session_token":token,"user_id":user_id,"order_id":order_id,"status":order_status}

        axios.post(CONSTANT.STABASE_URL+'store/changeOrderStatus',data,{
          headers: headers
        }).then(res => {
          this.setState({  
            loading:false
           
          }) 

          window.location.reload();
         
        }) 
     }
    }

 
    validate = () =>{
      let statusError = '';
      if(!this.state.order_status){
        statusError = "Please select order status";
      }  
      if(statusError){ 
          this.setState({statusError});
          return false;
      } else { 
          this.setState({statusError});
          return true;
      } 
    }  


  
    
  

    render() {
      var imgs1 = process.env.PUBLIC_URL + "/assets/images/svg/pin.svg"; 
      var imgs2 = process.env.PUBLIC_URL + "/assets/images/svg/visa.svg"; 
      let {orderData}=this.state;

      orderData.length > 0 && orderData.map(
        (item) => {

item.createdAt = new Date(item.createdAt).toDateString();
        })

      // console.log(orderData);
        return (
          <>
          <Header/> 
           
            {/* <Search></Search> 
            <Button></Button>   */}

           {/* {console.log(this.state.orderData)}  */}
          <section id="tabs" className="project-tab bdr-top innerheight">
            <div className="container">
                <div className="row">
                {
        this.state.loading ? (
        <Loader/>
        ) : (<></>)
      }
                    <div className="col-md-12">
                      
                        
                            <div className="tab-pane fade show active order-main" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div class="header_catehory"> <h2 class="jobs_heading mb-2 ">Store Orders</h2> </div> 
                            <div className="card">
                            <div className="card-header"><Button></Button></div>
                            <div className="card-body px-0">
                                <table className="table" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Store</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    { orderData.length > 0 ?
          orderData.length > 0 && orderData.map(
              (c,i) => 
                                         <tr key={i}>
                                            <td><a href="#">{c.custom_order_id}</a></td>
          <td>{c.customer.name}</td>
          <td>{c.store.store_name}</td>
                                            <td>{(c.product.length > 0) ? c.product[0].product_title :''}</td>
                                            <td>{c.order_total}</td>
                                            <td>{c.createdAt}</td>
                                            <td>{(c.status == 1) ? 'Pending':(c.status == 2) ? 'Inprocessed':(c.status == 3) ? 'Completed':(c.status == 4) ? 'Cancel':''}</td>
                                            <td><button type="button" onClick={() => this.viewOrder({"order_data":c})} className="btn btn-primary">Details</button></td>
                                        </tr>
                                      
                                      
                                        )
                                        :<tr><td colSpan="8"> <br /> <br /> No order found</td></tr>}


                                    </tbody>
                                </table>
                            </div>
                            </div>
                            </div>
                           
                        
                    </div>
                </div>

                <Modal show={this.state.isOpen} onHide={this.hidePopup.bind(this)} backdrop="static"> 
    
                <div class="modal-header card-header"><h5 class="modal-title">Order Details</h5> <button type="button" className="close store post-close" onClick={this.hidePopup.bind(this)}><span aria-hidden="true">×</span></button></div> 
    
    

    
   
   
        
        <form onSubmit={this.submitForm} id="feedform" method="post">
        <Modal.Body>
        <ul className="list-group order-list">
   <li className="row"> <div className="label_title col-md-4">Order Id :</div>  <div className="col-md-8">  {this.state.custom_order_id} </div> </li>
  <li className="row"> <div className="label_title col-md-4">Customer Name :</div> <div className="col-md-8">{this.state.customer_name}</div></li>
  <li className="row"><div className="label_title col-md-4">Store Name :</div>  <div className="col-md-8">{this.state.store_name}</div></li>
  <li className="row"><div className="label_title col-md-4">Address :</div>  <div className="col-md-8">{(this.state.address != "null,null,null,null,null,null") ? this.state.address : ""}</div></li>
  <li className="row"><div className="label_title col-md-4">Product Name :</div> <div className="col-md-8">{this.state.product_name}</div></li>
  <li className="row"><div className="label_title col-md-4">Payment Type :</div> <div className="col-md-8">{this.state.payment_type}</div></li>
  <li className="row"><div className="label_title col-md-4">Order Total Amount :</div> <div className="col-md-8">{this.state.order_total}</div></li>
  <li className="row"><div className="label_title col-md-4">Order Date :</div>  <div className="col-md-8">{this.state.order_date}</div></li>
  <li className="row">
  <div className="label_title col-md-4">Status :</div>
  {/* <div className="col-md-8">{(this.state.order_status == 1) ? 'Pending':(this.state.order_status == 2) ? 'Inprocessed':(this.state.order_status == 3) ? 'Completed':(this.state.order_status == 4) ? 'Cancel':''}</div> */}
  
  <div className="col-md-8">  <select  className="form-control"                        
                            name='order_status'
                            id='order_status'
                            onChange={this.onChange}
                            value={this.state.order_status}
                        >
                            <option value="">Select Status</option>
                            <option value="1">Pending</option>
                            <option value="2">Inprocessed</option>
                            <option value="3">Completed</option>
                            <option value="4">Cancel</option>
                        </select> </div>
  </li>
</ul>

        
<div className="error-msg"> {this.state.statusError}</div>


        
       
</Modal.Body>
        
<div className="modal-footer">     
     <button type="submit" className="save_bt">Submit</button>
      </div> 
        
         
          </form>
     
     
   
    </Modal>
            </div>

           
        </section>
          <Footer/>
        
          </>
        );
    }
}

export default OrderStore;