import Header from '../../components/partials/Header';
import Footer from '../../components/partials/Footer';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CONSTANT from '../../Constant';
import axios from 'axios';
import qs from 'qs';


class changePassword extends Component {

    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");

        let loggedIn = true;
        if (token == null) {
            loggedIn = false;
        }


let dd=localStorage.getItem('email');


 localStorage.setItem('email', dd);


        this.state = {
            email:dd ,
            password:'',
            confirmPassword:''
        }

        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }


    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
    }


    submitForm(e) {
        e.preventDefault();
        const isValid = this.validate();



        if (isValid) {

            this.setState({
                successmessage: "Please wait..."
            })

            const { password,email } = this.state;

            // console.log("sdsadsada222");
            // console.log(email);
            // console.log("sdsadsada111");

            axios.post(CONSTANT.STABASE_URL + 'users/resetpassword', qs.stringify({ password: password, email: localStorage.getItem('emailnew') }), {
                headers: { "x-access-token": CONSTANT.HEADER, 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(res => {

                if (res.data.response_status === 0) {
                    this.setState({ successmessage: "Please Try again!111", cls: "alert alert-danger" })
                } else {
                    localStorage.removeItem('email');
                    localStorage.removeItem('emailnew');

                    localStorage.removeItem('otp');
                    this.setState({ successmessage: "Password Changed!", cls: "alert alert-success" })
                    //return <Redirect to="/login" />
                    document.location.href = "/login"; 
                    
                }
            }, (error) => {
                this.setState({
                    successmessage: "Please provide valid login credential."
                })
            });
        }
    }


    validate = () => {
        // if (this.state.password == undefined || this.state.confirmPassword == undefined) {
        //     this.setState({ successmessage: "Please Enter Data", cls: "alert alert-danger" });
        //     return false;
        // } else if (this.state.password != this.state.confirmPassword) {
        //     this.setState({ successmessage: "Password did not Matched", cls: "alert alert-danger" });
        //     return false;
        // } else {
        //     this.setState({ successmessage: "Password Matched!", cls: "alert alert-success" });
        //     return true;
        // }
        let passwordError = '';
        let confirmPasswordError = '';
        let matchPasswordError = '';
        if (!this.state.password){
            passwordError = "Please Enter Data";
           }
        if (!this.state.confirmPassword){
            confirmPasswordError = "Please Enter Data";
            //this.setState({ successmessage: "Please Enter Data", cls: "alert alert-danger" });
        }
        if (this.state.password != this.state.confirmPassword) {
            matchPasswordError= "Password did not Matched";
        }
        if(passwordError){
            this.setState({successmessage:passwordError,cls: "alert alert-danger"}); 
            return false;
        }
        if(confirmPasswordError){
            this.setState({successmessage:confirmPasswordError,cls: "alert alert-danger"}); 
            return false;
        }
        if(matchPasswordError){
            this.setState({successmessage:matchPasswordError,cls: "alert alert-danger"}); 
            return false;
        }
        this.setState({successmessage:'',cls: "alert alert-success"});
        return true;
       

    }

    render() {

        // if (localStorage.getItem('email') == undefined) {
        //     return <Redirect to="/login" />
        // }

        return (
            <>
                <Header />
                <section id="login">
                    <div id="feed_wrapper_section" className="register_wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="login-user-wrapper">
                                        <div className="login-box">
                                            <h5>Enter New Password</h5>
                                            <form className="flp" onSubmit={this.submitForm}>
                                                {this.state.successmessage ? <p className={this.state.cls}>{this.state.successmessage}</p> : ''}
                                                <div className="form-group">
                                                    <input type="password" className="form-control" name="password" id="password" placeholder="New Password" value={this.state.password} onChange={this.onChange} />
                                                    <small id="emailHelp" className="form-text text-muted_error_message ok">{this.state.emailError}</small>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.onChange} />
                                                </div>
                                                <div className="post_bt_public register_login">
                                                    <ul><li><button type="submit" className="save_bt">Reset</button></li></ul>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }

}

export default changePassword;