import Header from '../components/partials/Header';
import Footer from '../components/partials/Footer';
import React, { Component } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import axios from 'axios';
// import Loader from "react-loader-spinner";
import Loader from '../Loader';
import CONSTANT from '../Constant';

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(document.location.href);
	if (results==null) {
	   return null;
	}
	return decodeURI(results[1]) || 0;
}

class WordPressAdmin extends Component {
    constructor(props) {
        super(props);
		this.state = {newloader: true};
    }
	
	async componentDidMount() {
		//console.log(document.location.href); return false;
		
		if(urlParam('code')){
			
			const response_status = localStorage.getItem("loggedInUserId");
			const profileData = localStorage.getItem("token");
			const stripe_user_id = urlParam('code');

			if(response_status != undefined)
			{
				const updateUserData = {				
					session_token: profileData,
					stripe_user_id: stripe_user_id				
				}			
				
				var url = CONSTANT.STABASE_URL+'store/saveStoreStripeConnect?scope=read_write&code='+stripe_user_id+'&state='+profileData
				
				const config = {
					headers: {
						'Content-Type': 'application/json',
						'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
					}
				}				
				let response = await axios.post(url, updateUserData, config);				
				if(response.data.response_status != 0){				
					localStorage.setItem('stripeConnect', 1);	
					
					if(urlParam('state')){
					var formData = {"session_token":profileData,"store_id":urlParam('state')}		
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'x-access-token':'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZWVyZW5kcmEiOiJkZXZlbmRyYSIsImlhdCI6MTQ3Mzg1MDYwNH0.YoeHbbF_GQdj1bvMwHzJU0R0KXyij11JfhL2HgyMEyg'
			}
		}		
		 let response = await axios.post(CONSTANT.STABASE_URL+'store/stripeCopyRemoveStore', formData, config);
	}

				} else {					
					localStorage.setItem('stripeConnect', 0);					
				}			
				document.location.href = '/vendor/myStores';		
				//this.props.history.push('/manageactivity?cont=yes');		
			}			
		}		
	}
		
	render() {
		
		return (		
			<>
				<Header data="inner-head"></Header>
				{this.state.newloader ? (<Loader/>) : ""}
				<Footer></Footer>
			</>
		);
    }
}
 
export default WordPressAdmin;